import React from "react";
import { Modal, Table} from "react-bootstrap";

class MassGraduationInfoModal extends React.Component {
  render() {
    let {
      student,
      show,
      toggleForceGradModal,
      incompleteGradInfo,
      fromMassAdvance,
    } = this.props;
    return (
      <Modal show={show} onHide={toggleForceGradModal}>
        <Modal.Header closeButton>
          {!fromMassAdvance ? (
            <Modal.Title>Graduation Evaluation:</Modal.Title>
          ) : (
            <Modal.Title>Requirements Information</Modal.Title>
          )}
        </Modal.Header>
        <Modal.Body>
          <strong>{student.display_name}</strong>
          <p></p>
          <Table bordered>
            <thead>
              <tr>
                <th>Requirements</th>
                <th> Value </th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(incompleteGradInfo).map((key, i) => (
                <tr key={i}>
                  <td>
                    {key.charAt(0).toUpperCase() +
                      key.replaceAll("_", " ").slice(1)}
                  </td>
                  <td>
                    {String(incompleteGradInfo[key]) === "true" ? (
                      <strong style={{ color: "#228c22" }}>&#10003;</strong>
                    ) : String(incompleteGradInfo[key]) === "false" ? (
                      <strong style={{ color: "#bb1919" }}>X</strong>
                    ) : (
                      String(incompleteGradInfo[key])
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          {/* This will allow someone to force graduate from the info modal on the
          table if functionality is implemented to make the api call, instead I've decided to make them click on the profile and go
          to the force graduation function in the student information component */}
          {/* {!fromMassAdvance ? (
            <>
              <strong>
                This will graduate a single student even if they have not met
                the requirments to graduate.
              </strong>{" "}
              If you would like to force graduate, press the "Force Graduate"
              button, otherwise click "X" at the top.
              <br />
              <Button id="forceGraduate" variant="info" className="float-end">
                Force Graduate
              </Button>
            </>
          ) : (
            ""
          )} */}
        </Modal.Body>
      </Modal>
    );
  }
}

export default MassGraduationInfoModal;
