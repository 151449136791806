import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import ErrorDisplay from "../components/ErrorDisplay";

class ErrorPage extends React.Component {
  render() {
    return (
      <Container>
        <Row className="justify-content-center">
          <Col xs="auto">
            <ErrorDisplay
              errorMessage="Error 404: We could not find the page you were looking for."
              noHelpDesk
            />
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col xs="auto">
            <Link to="/">Return Home</Link>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withRouter(ErrorPage);
