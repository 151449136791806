import "../styles/App.scss";
import { Spinner } from "react-bootstrap";
import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";

function LoggedOutPage() {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
      sessionStorage.clear();
    } , 1000 + Math.floor(Math.random() * 3000));
  })
  return (
    <>
      {loading ? (
        <div className="page-wrapper" style={{display: "flex", flexDirection: "column"}}>
          <div style={{display: "flex", flex: 1, flexDirection: "column", justifyContent: "center", alignContent: "center", alignItems: "center"}}>
            <Spinner animation={"border"} />
            <h1 className="loadingText" style={{marginTop: '1em', marginLeft: '0.5em'}}>Signing you out...</h1>
          </div>
        </div>
      ) : (
        <div className="page-wrapper" style={{display: "flex", flexDirection: "column"}}>
          <div style={{display: "flex", flex: 1, flexDirection: "column", justifyContent: "center", alignContent: "center", alignItems: "center"}}>
            <FontAwesomeIcon
              icon={faCheckCircle} // Use the ["far", "check-circle"] icon instead of faCheckCircle
              style={{ color: "green", fontSize: "4em", animation: "pulse 0.4s" }} // Apply desired styles
              size={60} // Set the size prop as needed
            />
            <h2 style={{marginBottom: '1em', marginLeft: '0.5em'}}>You have been signed out.</h2>
          </div>
        </div>
      )}
    </>
  )
}

export default LoggedOutPage;