import React from "react";
import { Modal, Button, Spinner, Form, Col, Row } from "react-bootstrap";
import api from "../../services/api";
import ErrorDisplay from "../ErrorDisplay";

class ViewGraduateReportModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      currentYear: new Date().getFullYear(),
      yearArray: [],
      year: "0",
      month: "0",
      errorMessage: "",
      customMessage: "",
    };

    this.buildYearTable = this.buildYearTable.bind(this);
    this.handleYearChange = this.handleYearChange.bind(this);
    this.handleMonthChange = this.handleMonthChange.bind(this);

    this.viewGraduateReport = this.viewGraduateReport.bind(this);

    this.buildYearTable();
  }

  async viewGraduateReport() {
    this.setState({ loading: true });

    let apiCall;

    if (this.state.month === "0" || this.state.month === undefined) {
      apiCall =
        "/reports/university_graduate_report?year=" +
        this.state.year.toString() +
        "&pdf=1";
    } else {
      apiCall =
        "/reports/university_graduate_report?year=" +
        this.state.year.toString() +
        "&month=" +
        this.state.month.toString() +
        "&format=pdf";
    }

    try {
      const { data: report } = await api.get(apiCall);

      window.open(report.url);
    } catch (err) {
      this.setState({
        errorMessage: err.response?.data ? err.response.data : err.message,
        customMessage: "Error downloading report",
      });
    }

    if (this.state.errorMessage === ""){
      this.props.onHide();
    }

    this.setState({ loading: false });
  }

  buildYearTable() {
    for (let i = 1973; i < this.state.currentYear; i++) {
      this.state.yearArray.push(i);
    }
    this.state.yearArray.reverse();
  }

  handleYearChange(e) {
    const { value } = e.target;
    this.setState({ year: value });
  }

  handleMonthChange(e) {
    const { value } = e.target;
    this.setState({ month: value });
  }

  render() {
    let { show, onHide } = this.props;
    return (
      <Modal show={show} onHide={onHide} backdrop="static">
        <Modal.Header>
          <Modal.Title>
            Graduate Report
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.state.loading ? (
            <div style={{ display: "flex", alignItems: "center" }}>
              <Spinner
                animation="border"
                style={{ marginRight: "0.5rem" }}
              />
              <div>This could take up to 10 seconds...</div>
            </div>
          ) : (
            <React.Fragment>
              <p>
                Select year and month (<em>optional</em>) to view graduate
                report.
              </p>

              <Row>
                <Col>
                  <Form.Select
                    id="year"
                    value={this.state.year}
                    onChange={this.handleYearChange}
                  >
                    <option value="0">Select Year</option>
                    <option value={this.state.currentYear}>
                      {this.state.currentYear}
                    </option>

                    {this.state.yearArray.map((item) => (
                      <option value={item} key={item}>{item}</option>
                    ))}
                  </Form.Select>
                </Col>

                <Col>
                  <Form.Select
                    id="month"
                    value={this.state.month}
                    onChange={this.handleMonthChange}
                  >
                    <option value="0">Select Month</option>
                    <option value="1">January</option>
                    <option value="2">Feburary</option>
                    <option value="3">March</option>
                    <option value="4">April</option>
                    <option value="5">May</option>
                    <option value="6">June</option>
                    <option value="7">July</option>
                    <option value="8">August</option>
                    <option value="9">September</option>
                    <option value="10">October</option>
                    <option value="11">November</option>
                    <option value="12">December</option>
                  </Form.Select>
                </Col>
              </Row>
            </React.Fragment>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="info"
            onClick={this.viewGraduateReport}
            disabled={
              this.state.loading || this.state.done || this.state.year === "0"
            }
          >
            View Graduate Report
          </Button>
          <Button
            variant="outline-info"
            onClick={this.props.onHide}
            disabled={this.state.loading}
          >
            Cancel
          </Button>
        </Modal.Footer>
        {this.state.errorMessage && (
          <ErrorDisplay errorMessage={this.state.errorMessage} customMessage={this.state.customMessage} />
        )}
      </Modal>
    );
  }
}

export default ViewGraduateReportModal;
