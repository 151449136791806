import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import DatabaseTools from './DatabaseTools';
import RegistrarTools from './RegistrarTools';

class AdminTools extends React.Component {



  render() {

    return (
      <Container className="p-5 bg-white">
        <Row>
          {this.props.roles.admin ? (
            <h1>All Admin Tools</h1>
          ) : this.props.roles.registrar ? (
            <h1>All Registrar Tools</h1>
          ) : this.props.roles.operator && (
            <h1>All Operator Tools</h1>
          )}
        </Row>
        <Row className="justify-content-center mt-4">
          <Col lg={4} className='mb-3'>
            <DatabaseTools roles={this.props.roles}/>
          </Col>
          {(this.props.roles.admin || this.props.roles.registrar) && (
            <Col lg={8}>
              <RegistrarTools roles={this.props.roles}/>
            </Col>
          )}
        </Row>
      </Container>
    )
  }

}

export default AdminTools;