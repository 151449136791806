import React from "react";
import { Modal, Button, Spinner, Form } from "react-bootstrap";
import api from "../../services/api";
import { generateCSV } from "../../utils/functions";
import generateExamNumberReport from "../../utils/generateExamNumberReport";
import ErrorDisplay from "../ErrorDisplay";

class ViewExamNumberModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = { loading: false, 
      sortVariable: "last_name", 
      format: "csv",
      errorMessage: "",
      customMessage: "", 
    };
    this.viewExamNumbers = this.viewExamNumbers.bind(this);
    this.handleSortVariableChange = this.handleSortVariableChange.bind(this);
    this.handleFormatChange = this.handleFormatChange.bind(this);
    this.onHide = this.onHide.bind(this);
  }

  async viewExamNumbers() {
    this.setState({ loading: true });
    try {
      const {data} = await api.get("/reports/examnumbers", {
        params: { format: "txt"},
      });
      const newWindow = window.open();
      newWindow.location.href = data.url;
    } catch (err) {
      this.setState({
        errorMessage: err.response?.data ? err.response.data : err.message,
        customMessage: "Error downloading report",
      });
    }
    if (this.state.errorMessage === ""){
      this.onHide();
    }
    this.setState({ loading: false });
  }

  handleSortVariableChange(e) {
    const { value } = e.target;
    this.setState({ sortVariable: value });
  }

  handleFormatChange(e) {
    const { value } = e.target;
    this.setState({ format: value });
  }

  onHide() {
    this.setState({ loading: false, sortVariable: "last_name", format: "csv" });
    this.props.onHide();
  }

  render() {
    let { show, onHide } = this.props;
    return (
      <Modal show={show} onHide={onHide} backdrop="static">
        <Modal.Header>
          <Modal.Title>View Exam Numbers</Modal.Title>
        </Modal.Header>
        {/* <Modal.Body>
          {this.state.loading ? (
            <React.Fragment>
              <Spinner animation="border" /> This could take up to 10 seconds...
            </React.Fragment>
          ) : (
            <React.Fragment>
              Choose how the exam numbers are sorted
              <Form.Select
                id="sort-variable"
                value={this.state.sortVariable}
                onChange={this.handleSortVariableChange}
              >
                <option value="last_name">Last Name</option>
                <option value="exam_number">Exam Number</option>
              </Form.Select>
              Choose the format
              <Form.Select
                id="format"
                value={this.state.format}
                onChange={this.handleFormatChange}
              >
                <option value="csv">CSV</option>
                <option value="pdf">PDF</option>
              </Form.Select>
            </React.Fragment>
          )}
        </Modal.Body> */}
        <Modal.Footer>
          {this.state.loading ? (
            <Button variant="info" disabled={true}>
              <Spinner
                animation={"border"}
                size={"sm"}
                style={{ marginRight: ".5rem" }}
              />
              View Exam Numbers
            </Button>
          ) : (
            <Button
              variant="info"
              onClick={this.viewExamNumbers}
            >
              View Exam Numbers
            </Button>
          )}
          <Button
            variant="outline-info"
            onClick={this.onHide}
            disabled={this.state.loading}
          >
            Cancel
          </Button>
        </Modal.Footer>
        {this.state.errorMessage && (
          <ErrorDisplay errorMessage={this.state.errorMessage} customMessage={this.state.customMessage} />
        )}
      </Modal>
    );
  }
}

export default ViewExamNumberModal;
