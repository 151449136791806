import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import copy from "../../images/copy.png";

class CopyIcon extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      clicked: false
    };
  }

  render() {
    const {
      item,
    } = this.props;

    const tooltip = <Tooltip>Copy to clipboard</Tooltip>;
    const tooltip2 = <Tooltip>Copied!</Tooltip>;

    return (
      <OverlayTrigger
        id="tip"
        placement="top"
        overlay={this.state.clicked ? tooltip2 : tooltip}
      >
        <img
          className="simple-view-icon"
          src={copy}
          alt={`copy to clipboard`}
          style={{
            marginLeft: "5px",
          }}
          onClick={(event) => {
            if (event.target == event.currentTarget) {
              event.stopPropagation();
            }
            navigator.clipboard.writeText(item);
            this.setState({clicked:true})

            // If there's an existing timeout, clear it
            if (this.timeout) {
                clearTimeout(this.timeout);
              }
  
              // Set a new timeout to set clicked back to false after 3 seconds
              this.timeout = setTimeout(() => {
                this.setState({ clicked: false });
              }, 3000);
          }}
        />
      </OverlayTrigger>
        );
  }
  
  /**
   * Unmounts the component and clears any existing timeout.
   *
   */
  componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }
}

export default CopyIcon;
