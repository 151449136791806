import React from "react";
import { Modal, Button, Form } from "react-bootstrap";

import ErrorDisplay from "../../ErrorDisplay";

class GraduateStudentModal extends React.Component {
  render() {
    let {
      show,
      manualGraduationInfo,
      graduateStudent,
      handleChange,
      toggleGraduateModal,
    } = this.props;
    return (
      <Modal show={show} onHide={toggleGraduateModal}>
        <Modal.Header closeButton>
          <Modal.Title>Student Has Not Graduated</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Because the student has not graduated, no degree shows. However, if
          you want to graduate the student, confirm the information below and
          then press the "Graduate" button.
          <br />
          <br />
          <Form.Label>
            <strong>Graduation Date</strong>
          </Form.Label>
          <Form.Control
            id="graduation-date"
            type="date"
            value={manualGraduationInfo.graduation_date}
            onChange={handleChange}
          ></Form.Control>
          <Form.Label>
            {" "}
            <strong>Degree Type</strong>
          </Form.Label>
          <Form.Select
            id="degree"
            value={manualGraduationInfo.degree}
            onChange={handleChange}
          >
            <option value="JD">JD</option>
            <option value="LLM">LLM</option>
          </Form.Select>
          <Form.Label>
            {" "}
            <strong>Joint Degree Type</strong>
          </Form.Label>
          <Form.Select
            id="joint-degree-grad"
            value={manualGraduationInfo.joint_degree}
            onChange={handleChange}
          >
            <option value="" key="">
              None
            </option>
            <option value="MBA" key="MBA">
              MBA
            </option>
            <option value="MPA" key="MPA">
              MPA
            </option>
            <option value="MAcc" key="MAcc">
              MAcc
            </option>
            <option value="MEd" key="MEd">
              {" "}
              MEd
            </option>
            <option value="MPP" key="MPP">
              {" "}
              MPP
            </option>
            {/* //These degrees are currently invalid in the backend, got an error back when I tried to add them. */}
            {/* <option value="PHDEd" key="PHDEd">
              {" "}
              PHDEd
            </option>
            <option value="PLSC" key="PLSC">
              {" "}
              PLSC
            </option>
            <option value="ISM" key="ISM">
              {" "}
              ISM
            </option>
            <option value="LibSci" key="LibSci">
              {" "}
              LibSci
            </option>
            <option value="MOB" key="MOB">
              {" "}
              MOB
            </option>
            <option value="MSW" key="MSW">
              MSW
            </option>
            <option value="Classics" key="Classics">
              Classics
            </option>
            <option value="H.Adm" key="H.Adm">
              H.Adm
            </option>
            <option value="MPH" key="MPH">
              MPH
            </option> */}
          </Form.Select>
          <Form.Label>
            {" "}
            <strong>Honors</strong>
          </Form.Label>
          <Form.Select
            id="honors"
            value={manualGraduationInfo.honors}
            onChange={handleChange}
          >
            <option value="">No</option>
            <option value="Summa Cum Laude">Summa Cum Laude</option>
            <option value="Magna Cum Laude">Magna Cum Laude</option>
            <option value="Cum Laude">Cum Laude</option>
          </Form.Select>
          <Form.Label>
            {" "}
            <strong>Honors Exception</strong>
          </Form.Label>
          <Form.Select
            id="honors-exception"
            value={manualGraduationInfo.honors_exception}
            onChange={handleChange}
          >
            <option value={false}>No</option>
            <option value={true}>Yes</option>
          </Form.Select>
          <br />
          <strong>NOTE:</strong> If you graduate a student, any changes you made
          without clicking "Save" will NOT be saved.
          <br />
          <Button id="noForceGraduate" variant="info" onClick={graduateStudent}>
            Graduate
          </Button>
        </Modal.Body>
        {this.props.errorMessage && this.props.showGradErrorMessage && (
          <ErrorDisplay errorMessage={this.props.errorMessage} />
        )}
      </Modal>
    );
  }
}

export default GraduateStudentModal;
