import React from "react";
import SimpleViewPersonRow from "./SimpleViewPersonRow";

class SimpleViewPerson extends React.Component {
  render() {
    const {
      dataSet,
      handleDisplayClick,
      handleDisplayButton,
      roles,
      fromStudent,
      fromFaculty,
      fromGraduate,
    } = this.props;

    return (
      <React.Fragment>
        {dataSet.map((person, index) => {
          return (
            <React.Fragment key={person.id}>
              <SimpleViewPersonRow
                person={person}
                index={index}
                dataSet={dataSet}
                handleDisplayClick={handleDisplayClick}
                handleDisplayButton={handleDisplayButton}
                roles={roles}
                fromStudent={fromStudent}
                fromFaculty={fromFaculty}
                fromGraduate={fromGraduate}
              />
            </React.Fragment>
          );
        })}
      </React.Fragment>
    );
  }
}

export default SimpleViewPerson;
