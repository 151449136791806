import React from "react";
import {
  Alert,
  Button,
  Col,
  Collapse, 
  Container,
  FloatingLabel,
  Form,
  InputGroup,
  Navbar,
  OverlayTrigger,
  Row,
  Spinner,
  Tooltip,
} from "react-bootstrap";
import _ from "lodash";
import {
  getCurrentSemester,
  releaseGradesGetCurrentSemester,
} from "../../utils/functions";
import ReactToPrint from "react-to-print";
import NavBarItem from "../NavBarItem.jsx"
import { faSearch, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import api from "../../services/api";
import courseDark from "../../images/courseDark.png"
import gradDark from "../../images/gradDark.png"
import staffDark from "../../images/staffDark.png"
import studentDark from "../../images/studentDark.png"
import courseLight from "../../images/courseLight.png"
import gradLight from "../../images/gradLight.png"
import staffLight from "../../images/staffLight.png"
import studentLight from "../../images/studentLight.png"
import { secureUserToken } from "../auth/secureSessionStorage";

// let trackingId = "";
// if (process.env.REACT_APP_STAGE !== "dev") {
//   trackingId = "UA-123656585-7";
// } else {
//   trackingId = "UA-NOTPROD";
// }

class DirectoryHeader extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
        open: false,
        id: "",
        year: "",
        class: "",
        gender: "",
        name: "",
        zip_code: "",
        phone: "",
        date_of_birth: "",
        carrel: "",
        course_year: "",
        semester: "",
        course_id: "",
        section: "",
        department: "",
        active: true,
        room: "",
        catalog_number: "",
        catalog_title: "",
        credits: "",
        isMyCourses: "",
        professor: "",
        monday: false,
        mondayStart: "*",
        mondayEnd: "*",
        tuesday: false,
        tuesdayStart: "*",
        tuesdayEnd: "*",
        wednesday: false,
        wednesdayStart: "*",
        wednesdayEnd: "*",
        thursday: false,
        thursdayStart: "*",
        thursdayEnd: "*",
        friday: false,
        fridayStart: "*",
        fridayEnd: "*",
        saturday: false,
        saturdayStart: "*",
        saturdayEnd: "*",
        facultyCourses: [],
        selectedCourse: "",
        semesterMap: "",
        gradeStatus: "",
        facultyCategories: [],
    };
    this.daysOfWeek = [
        {
            id: "monday",
            display: "Monday",
            short: "M",
            start: this.state.mondayStart,
            end: this.state.mondayEnd,
        },
        {
            id: "tuesday",
            display: "Tuesday",
            short: "Tu",
            start: this.state.tuesdayStart,
            end: this.state.tuesdayEnd,
        },
        {
            id: "wednesday",
            display: "Wednesday",
            short: "W",
            start: this.state.wednesdayStart,
            end: this.state.wednesdayEnd,
        },
        {
            id: "thursday",
            display: "Thursday",
            short: "Th",
            start: this.state.thursdayStart,
            end: this.state.thursdayEnd,
        },
        {
            id: "friday",
            display: "Friday",
            short: "F",
            start: this.state.fridayStart,
            end: this.state.fridayEnd,
        },
        {
            id: "saturday",
            display: "Saturday",
            short: "Sa",
            start: this.state.saturdayStart,
            end: this.state.saturdayEnd,
        },
    ];

    this.clearfilters = this.clearfilters.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handleCategoryChange = this.handleCategoryChange.bind(this);
    this.handleCategoryDeselect = this.handleCategoryDeselect.bind(this);
    this.directoryNavigator = this.props.directoryNavigator;
    this.buildYears = this.buildYears.bind(this);
    this.buildTimes = this.buildTimes.bind(this);
    this.urlWithFilters = this.urlWithFilters.bind(this);
    this.renderTooltip = this.renderTooltip.bind(this);
    this.timeMap = [];
    this.debouncedHandleFilterChange = _.debounce(this.debouncedFilterChange, 300);

    if (this.props.fromCourse) {
        //The default filter should lag behind a month by the end of the semester to make it easier for
        //professors to enter grades
        this.state.semester = releaseGradesGetCurrentSemester();
    };
  }

  async componentDidMount() {
    this.timeMap = this.buildTimes();
    if (this.props.roles.faculty && this.props.fromStudent) {
      this.getFacultyCourses();
    }
    if (this.props.fromCourse || this.props.fromStudent) {
      this.buildCourseYears();
    }

    if (window.location.search.length > 0) {
      let search = window.location.search.substring(1).split("&");

      if (this.props.fromCourse) {
        if (this.props.searchParams.has("semester") === false) {
          await this.fromURLFilters("semester", "");
        }

        if (
          this.props.roles.faculty &&
          this.props.searchParams.has("my_courses") === true
        ) {
          this.setState({ isMyCourses: true });
        }

        for (let index = 0; index < search.length; index++) {
          const element = search[index];
          let array = element.split("=");
          let key = array[0];
          let value = array[1];

          if (value.includes("+")) {
            value = value.replaceAll("+", " ");
          }
          if (value.includes("%3A")) {
            value = value.replace("%3A", ":");
            value = value.replace("%3A", ":");
          }

          await this.fromURLFilters(key, value);
        }
      } else if (
        this.props.fromFaculty ||
        this.props.fromStudent ||
        this.props.fromGraduate
      ) {
        for (let index = 0; index < search.length; index++) {
          const element = search[index];
          let array = element.split("=");
          let key = array[0];
          let value = array[1];

          await this.fromURLFilters(key, value);
        }
      }
    } else {
      if (window.location.pathname.split("/").length <= 2) {
        if (this.props.fromCourse) {
          this.props.searchParams.append("semester", this.state.semester);
        }

        // if (this.props.roles.faculty && this.props.fromCourse) {
        //   console.log("search", this.props.searchParams);
        //   //   this.props.searchParams.append("my_courses", this.state.isMyCourses);
        //   //   this.props.searchParams.append("professor", this.state.professor);
        // }
      } else {
        if (this.props.roles.faculty) {
          this.setState({ isMyCourses: false });
          this.setState({ professor: "" });
        }
      }
    }
  }

  /**
   * Renders the tooltip component.
   *
   * @param {Object} props - The props object to be passed to the tooltip.
   * @return {JSX.Element} The rendered Tooltip component.
   */
  renderTooltip(props) {
    return (
      <Tooltip {...props}>
        Select the days classes are on. For multiple days, select all days that
        apply.
      </Tooltip>
    );
  }
    
  /**
   * Sets the filters object in state from the values in the URL.
   *
   * @param {string} key - The name of the filter.
   * @param {any} value - The value to be assigned to the filter.
   * @return {Promise<void>} This function does not return anything.
   */
  async fromURLFilters(key, value) {
    const filters = this.state;
    const first_name = secureUserToken.first_name;
    const last_name = secureUserToken.last_name;
    const professor = `${first_name} ${last_name}`.trim();

    switch (key) {
      case "name":
        filters.name = value;
        this.setState({ name: value });
        break;
      case "phone":
        filters.phone = value;
        this.setState({ phone: value });
        break;
      case "id":
        filters.id = value;
        this.setState({ id: value });
        break;
      //Register Filters
      case "grade_status":
        filters.gradeStatus = value;
        this.setState({ gradeStatus: value });
        break;
      //Faculty Filters 2
      case "department":
        filters.department = value;
        this.setState({ department: value });
        break;
      case "room":
        filters.room = value;
        this.setState({ room: value });
        break;
      case "active":
        filters.active = value;
        this.setState({ active: value });
        break;
      case "category":
        while (value.includes("%2C")) {
          value = value.replace("%2C", ",");
        }
        while (value.includes("+")) {
          value = value.replaceAll("+", " ");
        }
        value = value.split(",");
        filters.facultyCategories = value;
        this.setState({ facultyCategories: value });
        break;
      case "year":
        filters.year = value;
        this.setState({ year: value });
        break;
      case "gender":
        filters.gender = value;
        this.setState({ gender: value });
        break;
      case "class":
        filters.class = "archive";
        this.setState({ class: "archive" });
        break;
      case "zip_code":
        filters.zip_code = value;
        this.setState({ zip_code: value });
        break;
      case "carrel":
        filters.carrel = value;
        this.setState({ carrel: value });
        break;
      case "selected_course":
        value = value.replace("+", " ");
        filters.selectedCourse = value;
        this.setState({ selectedCourse: value });
        this.setFiltersFromFacultyCourse(value, filters);
        break;
      case "course_id_year":
        let year_course = value;
        filters.course_year = year_course;
        this.setState({ year_course: year_course });
        break;
      case "course_id_semester":
        filters.semester = value;
        this.setState({ semester: value });
        break;
      case "course_id_number":
        let number = value;
        filters.course_id = number;
        this.setState({ course_id: number });
        break;
      case "course_id_section":
        let section = value;
        filters.section = section;
        this.setState({ section: section });
        break;
      //Course Filters
      case "semester":
        filters.semester = value;
        this.setState({ semester: value });
        break;
      case "catalog_number":
        filters.catalog_number = value;
        this.setState({ catalog_number: value });
        break;
      case "section":
        filters.section = value;
        this.setState({ section: value });
        break;
      case "catalog_title":
        filters.catalog_title = value;
        this.setState({ catalog_title: value });
        break;
      case "credits":
        filters.credits = value;
        this.setState({ credits: value });
        break;
      case "professor":
        filters.professor = value;
        if (professor === filters.professor) {
          filters.isMyCourses = true;
          this.urlWithFilters("my_courses", filters.isMyCourses);
        } else {
          filters.isMyCourses = false;
          this.urlWithFilters("my_courses", "");
        }
        this.setState({ isMyCourses: filters.isMyCourses, professor: value });
        break;
      case "my_courses":
        let checked = filters.isMyCourses;
        if (filters.isMyCourses) {
          filters.professor = professor;
          this.setState({ isMyCourses: checked, professor: professor });

          this.urlWithFilters("professor", professor);
          this.urlWithFilters("my_courses", checked);
        } else {
          filters.professor = "";
          this.urlWithFilters("professor", "");
          this.urlWithFilters("my_courses", "");
          this.setState({ professor: "" });
        }
        this.setState({ isMyCourses: checked, professor: professor });
        break;
      case "monday":
        let monValue = value.split("-");

        filters.mondayStart = monValue[0];
        filters.mondayEnd = monValue[1];
        filters.monday = value;

        this.setState({
          monday: filters.monday,
          mondayStart: filters.mondayStart,
          mondayEnd: filters.mondayEnd,
        });

        this.setState({
          daysOfWeek: this.daysOfWeek.map((obj) =>
            obj.id === "monday"
              ? Object.assign(obj, {
                  start: filters.mondayStart,
                  end: filters.mondayEnd,
                })
              : obj
          ),
        });

        break;
      case "tuesday":
        let tuesValue = value.split("-");

        filters.tuesdayStart = tuesValue[0];
        filters.tuesdayEnd = tuesValue[1];
        filters.tuesday = value;

        this.setState({
          tuesday: filters.tuesday,
          tuesdayStart: filters.tuesdayStart,
          tuesdayEnd: filters.tuesdayEnd,
        });

        this.setState({
          daysOfWeek: this.daysOfWeek.map((obj) =>
            obj.id === "tuesday"
              ? Object.assign(obj, {
                  start: filters.tuesdayStart,
                  end: filters.tuesdayEnd,
                })
              : obj
          ),
        });

        break;
      case "wednesday":
        let wedValue = value.split("-");

        filters.wednesdayStart = wedValue[0];
        filters.wednesdayEnd = wedValue[1];
        filters.wednesday = value;

        this.setState({
          wednesday: filters.wednesday,
          wednesdayStart: filters.wednesdayStart,
          wednesdayEnd: filters.wednesdayEnd,
        });

        this.setState({
          daysOfWeek: this.daysOfWeek.map((obj) =>
            obj.id === "wednesday"
              ? Object.assign(obj, {
                  start: filters.wednesdayStart,
                  end: filters.wednesdayEnd,
                })
              : obj
          ),
        });

        break;
      case "thursday":
        let thursValue = value.split("-");

        filters.thursdayStart = thursValue[0];
        filters.thursdayEnd = thursValue[1];
        filters.thursday = value;

        this.setState({
          thursday: filters.thursday,
          thursdayStart: filters.thursdayStart,
          thursdayEnd: filters.thursdayEnd,
        });

        this.setState({
          daysOfWeek: this.daysOfWeek.map((obj) =>
            obj.id === "thursday"
              ? Object.assign(obj, {
                  start: filters.thursdayStart,
                  end: filters.thursdayEnd,
                })
              : obj
          ),
        });

        break;
      case "friday":
        let friValue = value.split("-");

        filters.fridayStart = friValue[0];
        filters.fridayEnd = friValue[1];
        filters.friday = value;

        this.setState({
          friday: filters.friday,
          fridayStart: filters.fridayStart,
          fridayEnd: filters.fridayEnd,
        });

        this.setState({
          daysOfWeek: this.daysOfWeek.map((obj) =>
            obj.id === "friday"
              ? Object.assign(obj, {
                  start: filters.fridayStart,
                  end: filters.fridayEnd,
                })
              : obj
          ),
        });

        break;
      case "saturday":
        let satValue = value.split("-");

        filters.saturdayStart = satValue[0];
        filters.saturdayEnd = satValue[1];
        filters.saturday = value;

        this.setState({
          saturday: filters.saturday,
          saturdayStart: filters.saturdayStart,
          saturdayEnd: filters.saturdayEnd,
        });

        this.setState({
          daysOfWeek: this.daysOfWeek.map((obj) =>
            obj.id === "saturday"
              ? Object.assign(obj, {
                  start: filters.saturdayStart,
                  end: filters.saturdayEnd,
                })
              : obj
          ),
        });
        break;
      default:
        break;
    }
    this.directoryNavigator.actions.handleFilterChange(filters);
  }
    
  /**
   * Updates the URL with the given filters.
   *
   * @param {string} name - The name of the filter.
   * @param {any} value - The value of the filter.
   */
  urlWithFilters(name, value) {
    if (this.props.searchParams.has(name)) {
      if (value === "" || value === false || value.length === 0) {
        this.props.searchParams.delete(name);
      } else {
        this.props.searchParams.set(name, value);
      }
    } else if (value !== "") {
      this.props.searchParams.append(name, value);
    }
  }
    
  /**
   * Clears all the filters and resets the state of the component.
   *
   * @return {void} 
   */
  clearfilters() {
    const semesterFilter = this.props.fromCourse ? this.state.semester : "";

    this.setState({
      course_year: "",
      id: "",
      course_id: "",
      section: "",
      semester: semesterFilter,
      credits: "",
      isMyCourses: false,
      class: "",
      professor: "",

      catalog_number: "",
      catalog_title: "",
      selectedCourse: "",

      department: "",
      active: true,
      room: "",

      year: "",
      gender: "",
      name: "",
      zip_code: "",
      phone: "",
      carrel: "",
      date_of_birth: "",

      monday: false,
      mondayStart: "*",
      mondayEnd: "*",
      tuesday: false,
      tuesdayStart: "*",
      tuesdayEnd: "*",
      wednesday: false,
      wednesdayStart: "*",
      wednesdayEnd: "*",
      thursday: false,
      thursdayStart: "*",
      thursdayEnd: "*",
      friday: false,
      fridayStart: "*",
      fridayEnd: "*",
      saturday: false,
      saturdayStart: "*",
      saturdayEnd: "*",
      sunday: false,
      sundayStart: "*",
      sundayEnd: "*",
      facultyCategories: [],
      gradeStatus: "",
    }, async () => {
      let filters = this.state;
      let array = this.props.searchParams.toString().split("&");

      for (let index = 0; index < array.length; index++) {
        const element = array[index];
        let key = element.substring(0, element.indexOf("="));
        this.props.searchParams.delete(key);
      }
      
      await this.props.handleCategoryDeselect();
      this.directoryNavigator.actions.handleFilterChange(filters, true);
    });
  }

  /**
   * Handles the change of a filter and updates the state of the component.
   * 
   * @param {object} e - The event object with the filter and value.
   * @returns {void}
   * 
   */
  async handleFilterChange(e, debounce, isStudentCourseFilter=false, isAdditionalDataFilter=false) {
    let [filter, type] = e.target.id.split("-");
    let value = e.target.value;
    let checked = e.target.checked;
    let filters = this.state;
    const first_name = secureUserToken.first_name;
    const last_name = secureUserToken.last_name;
    const professor = `${first_name} ${last_name}`.trim();
    switch (filter) {
      case "name":  // this is the simple/generic search
        filters.name = value;
        this.setState({ name: value });
        this.urlWithFilters("name", value);
        break;
      case "phone":
        filters.phone = value;
        this.setState({ phone: value });
        this.urlWithFilters("phone", value);
        break;
      case "id":
        filters.id = value;
        this.setState({ id: value });
        this.urlWithFilters("id", value);
        break;

      //Registrar Filters
      case "grade_status":
        filters.gradeStatus = value;
        this.setState({ gradeStatus: value });
        this.urlWithFilters("grade_status", value);
        break;
      case "date_of_birth":
        filters.date_of_birth = value;
        this.setState({ date_of_birth: value });
        this.urlWithFilters("date_of_birth", value);
        break;

      //Faculty Filters
      case "department":
        filters.department = value;
        this.setState({ department: value });
        this.urlWithFilters("department", value);
        break;
      case "room":
        filters.room = value;
        this.setState({ room: value });
        this.urlWithFilters("room", value);
        break;
      case "active": //faculty active
        filters.active = checked;
        this.setState({ active: checked });
        this.urlWithFilters("active", checked);
        break;
      case "category":
        filters.facultyCategories = this.props.categoriesToShow.map(
          (cat) => cat.display_name
        );
        this.setState({
          facultyCategories: this.props.categoriesToShow.map(
            (cat) => cat.display_name
          ),
        });
        if (this.props.categoriesToShow.length > 0) {
          this.urlWithFilters(
            "category",
            this.props.categoriesToShow.map((cat) => cat.display_name)
          );
        } else {
          this.urlWithFilters("category", "");
        }
        break;
      //Student Filters
      case "year":
        filters.year = value;
        this.setState({ year: value });
        if (this.props.fromGraduate) {
          if (value.toString().length === 4) {
            this.urlWithFilters("year", value);
          } else {
            this.urlWithFilters("year", "");
          }
        } else {
          this.urlWithFilters("year", value);
        }
        break;
      case "gender":
        filters.gender = value;
        this.setState({ gender: value });

        this.urlWithFilters("gender", value);
        break;
      case "class":
        filters.class = checked === true ? "archive" : "";
        this.setState({ class: checked === true ? "archive" : "" });
        this.urlWithFilters("class", filters.class);
        break;
      case "zip_code":
        filters.zip_code = value;
        this.setState({ zip_code: value });
        this.urlWithFilters("zip_code", value);
        break;
      case "carrel":
        filters.carrel = value;
        this.setState({ carrel: value });
        this.urlWithFilters("carrel", value);
        break;
      case "course_id_year":
        let year_course = document.getElementById("course_id_year").value;
        filters.course_year = year_course;
        this.setState({ year_course: year_course });
        this.urlWithFilters("course_id_year", year_course);
        break;
      case "course_id_semester":
        let year = document.getElementById("course_id_year").value;
        let semester = document.getElementById("course_id_semester").value;
        value = year + semester;
        filters.semester = semester;
        this.setState({ semester: semester });
        this.urlWithFilters("course_id_semester", semester);
        break;
      case "course_id_number":
        let number = document.getElementById("course_id_number").value;
        filters.course_id = number;
        this.setState({ course_id: number });
        this.urlWithFilters("course_id_number", number);
        break;
      case "course_id_section":
        let section = document.getElementById("course_id_section").value;
        filters.section = section;
        this.setState({ section: section });
        this.urlWithFilters("course_id_section", section);
        break;
      //Course Filters
      case "semester":
        filters.semester = value;
        this.setState({ semester: value });
        this.urlWithFilters("semester", value);
        break;
      case "catalog_number":
        filters.catalog_number = value;
        this.setState({ catalog_number: value });
        this.urlWithFilters("catalog_number", value);
        break;
      case "section":
        filters.section = value;
        this.setState({ section: value });
        this.urlWithFilters("section", value);
        break;
      case "catalog_title":
        filters.catalog_title = value;
        this.setState({ catalog_title: value });
        this.urlWithFilters("catalog_title", value);
        break;
      case "credits":
        filters.credits = value;
        this.setState({ credits: value });
        this.urlWithFilters("credits", value);
        break;
      case "my_courses":
        filters.isMyCourses = checked;
        if (filters.isMyCourses) {
          filters.professor = professor;
          this.setState({ isMyCourses: checked, professor: professor });
          this.urlWithFilters("professor", professor);
          this.urlWithFilters("my_courses", checked);
        } else {
          filters.professor = "";
          this.urlWithFilters("professor", "");
          this.urlWithFilters("my_courses", "");
          this.setState({ professor: "" });
        }
        this.setState({ isMyCourses: checked, professor: professor });
        break;
      case "professor":
        filters.professor = value;
        if (professor === filters.professor) {
          filters.isMyCourses = true;
          this.urlWithFilters("my_courses", filters.isMyCourses);
        } else {
          filters.isMyCourses = false;
          this.urlWithFilters("my_courses", "");
        }
        this.setState({ isMyCourses: filters.isMyCourses, professor: value });
        this.urlWithFilters("professor", value);
        break;
      case "facultyCourses":
        let course = document.getElementById("facultyCourses").value;
        this.setState({ selectedCourse: course });
        this.urlWithFilters("selected_course", course);
        this.setFiltersFromFacultyCourse(course, filters);
        break;

      case "monday":
        if (type === "start") {
          filters.mondayStart = value;
        } else if (type === "end") {
          filters.mondayEnd = value;
        } else if (filters.monday) {
          filters.monday = false;
          filters.mondayStart = "*";
          filters.mondayEnd = "*";
        } else {
          filters.monday = true;
        }
        if (filters.monday) {
          filters.monday = `${filters.mondayStart}-${filters.mondayEnd}`;
        }
        this.urlWithFilters("monday", filters.monday);

        this.setState({
          monday: filters.monday,
          mondayStart: filters.mondayStart,
          mondayEnd: filters.mondayEnd,
        });
        this.setState({
          daysOfWeek: this.daysOfWeek.map((obj) =>
            obj.id === "monday"
              ? Object.assign(obj, {
                  start: filters.mondayStart,
                  end: filters.mondayEnd,
                })
              : obj
          ),
        });
        break;
      case "tuesday":
        if (type === "start") {
          filters.tuesdayStart = value;
        } else if (type === "end") {
          filters.tuesdayEnd = value;
        } else if (filters.tuesday) {
          filters.tuesday = false;
          filters.tuesdayStart = "*";
          filters.tuesdayEnd = "*";
        } else {
          filters.tuesday = true;
        }
        if (filters.tuesday) {
          filters.tuesday = `${filters.tuesdayStart}-${filters.tuesdayEnd}`;
        }

        this.urlWithFilters("tuesday", filters.tuesday);

        this.setState({
          tuesday: filters.tuesday,
          tuesdayStart: filters.tuesdayStart,
          tuesdayEnd: filters.tuesdayEnd,
        });

        this.setState({
          daysOfWeek: this.daysOfWeek.map((obj) =>
            obj.id === "tuesday"
              ? Object.assign(obj, {
                  start: filters.tuesdayStart,
                  end: filters.tuesdayEnd,
                })
              : obj
          ),
        });

        break;
      case "wednesday":
        if (type === "start") {
          filters.wednesdayStart = value;
        } else if (type === "end") {
          filters.wednesdayEnd = value;
        } else if (filters.wednesday) {
          filters.wednesday = false;
          filters.wednesdayStart = "*";
          filters.wednesdayEnd = "*";
        } else {
          filters.wednesday = true;
        }
        if (filters.wednesday) {
          filters.wednesday = `${filters.wednesdayStart}-${filters.wednesdayEnd}`;
        }

        this.urlWithFilters("wednesday", filters.wednesday);

        this.setState({
          wednesday: filters.wednesday,
          wednesdayStart: filters.wednesdayStart,
          wednesdayEnd: filters.wednesdayEnd,
        });

        this.setState({
          daysOfWeek: this.daysOfWeek.map((obj) =>
            obj.id === "wednesday"
              ? Object.assign(obj, {
                  start: filters.wednesdayStart,
                  end: filters.wednesdayEnd,
                })
              : obj
          ),
        });

        break;
      case "thursday":
        if (type === "start") {
          filters.thursdayStart = value;
        } else if (type === "end") {
          filters.thursdayEnd = value;
        } else if (filters.thursday) {
          filters.thursday = false;
          filters.thursdayStart = "*";
          filters.thursdayEnd = "*";
        } else {
          filters.thursday = true;
        }
        if (filters.thursday) {
          filters.thursday = `${filters.thursdayStart}-${filters.thursdayEnd}`;
        }

        this.urlWithFilters("thursday", filters.thursday);

        this.setState({
          thursday: filters.thursday,
          thursdayStart: filters.thursdayStart,
          thursdayEnd: filters.thursdayEnd,
        });

        this.setState({
          daysOfWeek: this.daysOfWeek.map((obj) =>
            obj.id === "thursday"
              ? Object.assign(obj, {
                  start: filters.thursdayStart,
                  end: filters.thursdayEnd,
                })
              : obj
          ),
        });
        break;
      case "friday":
        if (type === "start") {
          filters.fridayStart = value;
        } else if (type === "end") {
          filters.fridayEnd = value;
        } else if (filters.friday) {
          filters.friday = false;
          filters.fridayStart = "*";
          filters.fridayEnd = "*";
        } else {
          filters.friday = true;
        }
        if (filters.friday) {
          filters.friday = `${filters.fridayStart}-${filters.fridayEnd}`;
        }

        this.urlWithFilters("friday", filters.friday);

        this.setState({
          friday: filters.friday,
          fridayStart: filters.fridayStart,
          fridayEnd: filters.fridayEnd,
        });

        this.setState({
          daysOfWeek: this.daysOfWeek.map((obj) =>
            obj.id === "friday"
              ? Object.assign(obj, {
                  start: filters.fridayStart,
                  end: filters.fridayEnd,
                })
              : obj
          ),
        });
        break;
      case "saturday":
        if (type === "start") {
          filters.saturdayStart = value;
        } else if (type === "end") {
          filters.saturdayEnd = value;
        } else if (filters.saturday) {
          filters.saturday = false;
          filters.saturdayStart = "*";
          filters.saturdayEnd = "*";
        } else {
          filters.saturday = true;
        }
        if (filters.saturday) {
          filters.saturday = `${filters.saturdayStart}-${filters.saturdayEnd}`;
        }

        this.urlWithFilters("saturday", filters.saturday);

        this.setState({
          saturday: filters.saturday,
          saturdayStart: filters.saturdayStart,
          saturdayEnd: filters.saturdayEnd,
        });

        this.setState({
          daysOfWeek: this.daysOfWeek.map((obj) =>
            obj.id === "saturday"
              ? Object.assign(obj, {
                  start: filters.saturdayStart,
                  end: filters.saturdayEnd,
                })
              : obj
          ),
        });
        break;
      default:
        break;
    }
    if(isStudentCourseFilter) return;
    if (debounce) {
      this.debouncedHandleFilterChange(this.state);
    } else {
      this.directoryNavigator.actions.handleFilterChange(filters, isAdditionalDataFilter);
    }
  }

  debouncedFilterChange = (filters) => {
    this.directoryNavigator.actions.handleFilterChange(filters);
  };

  setFiltersFromFacultyCourse(course, filters) {
    let yearNum = course.slice(0, 4);
    filters.course_year = yearNum;
    this.setState({ year_course: yearNum });

    let semesterNum = course.substring(0, 5);
    filters.semester = semesterNum;
    this.setState({ semester: semesterNum });

    let courseNum = course.substring(6, course.indexOf("-"));
    filters.course_id = courseNum;
    this.setState({ course_id: courseNum });

    let sectionNum = course.substring(
      course.indexOf("-") + 1,
      course.length
    );
    filters.section = sectionNum;
    this.setState({ section: sectionNum });

    this.setState({ isMyCourses: filters.isMyCourses });
  }

  /**
   * Calls the handleCategoryChange function in the parent component and the handleFilterChange function.
   *
   * @param {object} field - The faculty category field to update.
   * @param {Event} e - The event object.
   * @return {Promise<void>} A promise that resolves when the category change is handled.
   */
  async handleCategoryChange(field, e) {
    await this.props.handleCategoryChange(field);
    this.handleFilterChange(e);
  }
    
  /**
   * Handles the deselection of all faculty category.
   *
   * @param {Event} e - The event object for the deselection.
   * @return {Promise<void>} A Promise that resolves with no value.
   */
  async handleCategoryDeselect(e) {
    await this.props.handleCategoryDeselect();
    this.setState({ facultyCategories: [] });
    this.handleFilterChange(e);
    this.urlWithFilters("category", "");
  }
    
  /**
   * Builds a list of semesters for a dropdown starting at a set year until the current year.
   *
   * @return {Array} The list of semesters in the format { key: String, value: String }.
   */
  buildYears() {
    let semesterMap = [];
    let currentYear = new Date().getFullYear();
    let endYear = currentYear - 5;
    while (currentYear > endYear) {
      semesterMap.push({
        key: String(currentYear),
        value: String(currentYear),
      });
      currentYear--;
    }
    return semesterMap;
  }

  /**
   * Generates an array of time values and their corresponding display strings.
   *
   * @return {Array} An array of time objects with `display` and `value` properties.
   */
  buildTimes() {
    const startHour = 6;
    const endHour = 22;

    let currentHour = startHour;
    let suffix = "AM";
    const timeMap = [];
    while (currentHour < endHour) {
      let displayHour = currentHour;
      if (currentHour === 0) {
        displayHour = 12;
      }
      if (currentHour > 12) {
        displayHour = currentHour - 12;
      }
      timeMap.push({
        display: String(displayHour) + ":00 " + suffix,
        value: String(currentHour) + ":00",
      });
      timeMap.push({
        display: String(displayHour) + ":15 " + suffix,
        value: String(currentHour) + ":15",
      });
      timeMap.push({
        display: String(displayHour) + ":30 " + suffix,
        value: String(currentHour) + ":30",
      });
      timeMap.push({
        display: String(displayHour) + ":45 " + suffix,
        value: String(currentHour) + ":45",
      });
      currentHour++;
      if (currentHour >= 12) {
        suffix = "PM";
      }
    }

    return timeMap;
  }
    
  /**
   * Retrieves the data for the given semester and sets it in the state as `nextSemesterDataArray`.
   *
   * @param {string} semester - The semester for which to retrieve the data.
   * @return {Promise} A Promise that resolves with the retrieved data.
   */
  async checkNextSemester(semester) {
    let { data: elementDataArray } = await api.get(`/courses/directory`, {
      params: { semester: semester },
    });

    this.setState({ nextSemesterDataArray: elementDataArray });
  }
    
  /**
   * Builds a list of semesters for a dropdown starting at 1973 until the current year.
   *
   * @return {Promise<void>}
   */
  buildCourseYears() {
    let semesterMap = [];

    let semester = 19735;

    let currentSemester = getCurrentSemester();

    if (this.props.fromStudent) {
      semester = currentSemester - 50;
    }

    while (semester <= currentSemester) {
      semester = this.addSemesterToMap(semester, semesterMap);
    }

    // add next semester to the list
    this.addSemesterToMap(semester, semesterMap);

    semesterMap = semesterMap.reverse();
    this.setState({ semesterMap: semesterMap });
  }
    
/**
 * Adds a semester to the semester map.
 *
 * @param {number} semester - The semester to be added.
 * @param {Array} semesterMap - The map of semesters.
 * @return {number} - The updated semester value.
 */
  addSemesterToMap(semester, semesterMap) {
    let stringStartSemester = semester.toString();
    let numSemester = stringStartSemester[4];
    let year = (semester - parseInt(stringStartSemester[4])) / 10;
    
    switch (numSemester) {
      case "5":
        semesterMap.push({
          key: String(year) + "5",
          value: String(year) + "-Fall",
        });
        semester = semester - 4 + 10;
        break;
      case "4":
        semesterMap.push({
          key: String(year) + "4",
          value: String(year) + "-Summer",
        });
        semester = semester + 1;
        break;
      case "3":
        semesterMap.push({
          key: String(year) + "3",
          value: String(year) + "-Spring",
        });
        semester = semester + 1;
        break;
      case "1":
        semesterMap.push({
          key: String(year) + "1",
          value: String(year) + "-Winter",
        });
        semester = semester + 2;
        break;
      default:
        break;
    }

    return semester;
  }
    
  /**
   * Retrieves the courses taught by a faculty member (the current user).
   *
   * @return {Promise<void>} - No return value.
   */
  async getFacultyCourses() {
    try {
      const firstName = secureUserToken.first_name;
      const lastName = secureUserToken.last_name;
      let { data: courses } = await api.get(
        `/courses/directory?instructor_name=${firstName}+${lastName}&semester=${this.state.semester}`
      );

      this.setState({ facultyCourses: courses });
    } catch (err) {
      if (!axios.isCancel(err)) {
        console.error(err);
        this.setState({
          errorMessage: err.response?.data ? err.response.data : err.message,
        });
      }
    }
  }
    
  /**
   * Returns the semester for display based on the given semester number.
   *
   * @param {number} semester - The semester number.
   * @return {string} The corresponding semester name and year for display.
   */
  printSemester(semester) {
    const str = semester.toString();
    const char = str.charAt(4);
    const year = str.substr(0,4);
    if (char === "5") {
      return "Fall " + year;
    } else if (char === "4") {
      return "Summer " + year;
    } else if (char === "3") {
      return "Spring " + year;
    } else if (char === "1") {
      return "Winter " + year;
    }
  }

  render() {
    const {
      csvLoading,
      disabled,
      flashCardLoading,
      groupOptions,
      loading,
      roles,
    } = this.props;
    const courseYearOptions = Array.from(this.state.semesterMap);

    /**
     * Renders a tooltip component with the given props.
     *
     * @param {Object} props - The props to be passed to the Tooltip component.
     * @return {React.Element} The rendered Tooltip component.
     */
    const renderTooltip = (props) => (
      <Tooltip id="button-tooltip" {...props}>
        If data is cut-off from the table, select the more settings option on
        the dropdown for formatting options
      </Tooltip>
    );

    const graduateFilters = this.props.fromGraduate && (
      <Container className="justify-content-center">
        <Form className="justify-content-center advanced-search-container">
          <Row>
            {(roles?.admin || roles.operator || roles.registrar) && (
              <Col md={6} className="advanced-search-text">
                <FloatingLabel label="Search by BYU ID">
                  <Form.Control
                    id="id"
                    type="search"
                    placeholder="Search by BYU ID"
                    aria-label="Search by BYU ID"
                    onChange={e => this.handleFilterChange(e, true)}
                    disabled={disabled}
                    value={this.state.id}
                  />
                </FloatingLabel>
              </Col>
            )}
            <Col md={6} lg={3} className="advanced-search-text">
              <FloatingLabel label="Search by Graduation Year">
                <Form.Control
                  id="year"
                  type="search"
                  placeholder="Search by Graduation Year"
                  aria-label="Search by Graduation Year"
                  onChange={e => this.handleFilterChange(e, true)}
                  disabled={disabled}
                  value={this.state.year}
                />
              </FloatingLabel>
            </Col>
            <Col md={6} lg={3} className="advanced-search-text">
              <FloatingLabel label="Search by Gender">
                <Form.Select
                  id="gender"
                  aria-label="Search by Gender"
                  onChange={e => this.handleFilterChange(e)}
                  disabled={disabled}
                  value={this.state.gender}
                  >
                  <option value={""}>Male and Female</option>
                  <option value={"male"}>Male Only</option>
                  <option value={"female"}>Female Only</option>
                </Form.Select>
              </FloatingLabel>
            </Col>
          </Row>
          <div className="divider" />
          <Row className="justify-content-center">
            <Col md="auto">
              <Button
                variant="primary"
                className="download-csv-button"
                onClick={this.directoryNavigator.actions.handleDownloadCSV}
                disabled={loading || csvLoading || disabled}
                alt="download csv button"
              >
                {csvLoading ? (
                  <React.Fragment>
                    <Spinner size="sm" animation="border" /> Loading
                  </React.Fragment>
                ) : (
                  <React.Fragment>Download CSV</React.Fragment>
                )}
              </Button>
            </Col>
            <Col md="auto">
              <Button 
                className="clear-filters-button"
                disabled={disabled}
                onClick={() => this.clearfilters()}>
                Clear Filters
              </Button>
            </Col>
          </Row>
          {this.props.showPopUpWarning && (
            <Row>
              <Col className="text-center">
                <Alert variant="info">
                  {" "}
                  If the download fails, please make sure pop-ups are
                  unblocked on your browser
                </Alert>
              </Col>
            </Row>
          )}
        </Form>
      </Container>
    );

    const studentFilters = this.props.fromStudent && (
      <Container className="justify-content-center">
        <Form className="justify-content-center advanced-search-container">
          <Row>
            {(roles.admin || roles.registrar) && (
              <Col md={6} className="advanced-search-text">
                <FloatingLabel label="Search by BYU ID">
                  <Form.Control
                    id="id"
                    type="search"
                    placeholder="Search by BYU ID"
                    aria-label="Search by BYU ID"
                    onChange={e => this.handleFilterChange(e, true)}
                    disabled={disabled}
                    value={this.state.id}
                  />
                </FloatingLabel>
              </Col>
            )}

            {(roles.admin || roles.registrar) && (
              <Col md={6} className="advanced-search-text">
                <FloatingLabel label="Search by Zip Code">
                  <Form.Control
                    id="zip_code"
                    type="search"
                    placeholder="Search by Zip Code"
                    aria-label="Search by Zip Code"
                    onChange={e => this.handleFilterChange(e, true)}
                    disabled={disabled}
                    value={this.state.zip_code}
                  />
                </FloatingLabel>
              </Col>
            )}

            <Col md={6} className="advanced-search-text">
              <FloatingLabel label="Search by Phone #">
                <Form.Control
                  id="phone"
                  type="search"
                  placeholder="Search by Phone #"
                  aria-label="Search by Phone #"
                  onChange={e => this.handleFilterChange(e, true)}
                  disabled={disabled}
                  value={this.state.phone}
                />
              </FloatingLabel>
            </Col>

            <Col md={6} lg={3} className="advanced-search-text">
              <FloatingLabel label="Search by Carrel">
                <Form.Control
                  id="carrel"
                  type="search"
                  placeholder="Search by Carrel"
                  aria-label="Search by Carrel"
                  onChange={e => this.handleFilterChange(e, true)}
                  disabled={disabled}
                  value={this.state.carrel}
                />
              </FloatingLabel>
            </Col>

            <Col md={6} lg={3} className="advanced-search-text">
              <FloatingLabel label="Search by Gender">
                <Form.Select
                  id="gender"
                  aria-label="Search by Gender"
                  onChange={e => this.handleFilterChange(e)}
                  disabled={disabled}
                  value={this.state.gender}
                  >
                  <option value={""}>Male and Female</option>
                  <option value={"male"}>Male Only</option>
                  <option value={"female"}>Female Only</option>
                </Form.Select>
              </FloatingLabel>
            </Col>

            <Col md={6} lg={3} className="advanced-search-text">
              <FloatingLabel label="Search by Group">
                <Form.Select
                  id="year"
                  aria-label="Search by Group"
                  onChange={e => this.handleFilterChange(e)}
                  disabled={disabled}
                  value={this.state.year}
                  >
                  {groupOptions?.map((item) => (
                    <option value={item.value} key={item.value}>{item.display}</option>
                  ))}
                </Form.Select>
              </FloatingLabel>
            </Col>

            {(roles.admin || roles.registrar) && (
              <Col md={6} lg={3} className="advanced-search-text">
                <div className="clear-filters">
                  <Form.Label className="p-0 m-0" xs={"auto"}>
                    Show Archived Students:
                  </Form.Label>
                  <Form.Check
                    className=""
                    type="switch"
                    id="class"
                    checked={this.state.class === "archive" ? true : false}
                    onChange={e => this.handleFilterChange(e, false, false, true)}
                    disabled={disabled}
                    value={this.state.class}
                  />
                </div>
              </Col>
            )}
          </Row>
          <div className="divider mb-3" />
          <h5>Search by Course Information</h5>
          <Row>
            {roles.faculty && !roles.registrar ? (
              <Col md={6} lg={3} className="advanced-search-text">
                <FloatingLabel label="Search by Course">
                  <Form.Select
                    id="facultyCourses"
                    aria-label="Search by Course"
                    onChange={e => this.handleFilterChange(e, false, true)}
                    disabled={disabled}
                    value={this.state.selectedCourse}
                  >
                    <option value={""}>Select Course</option>
                    {this.state.facultyCourses.map(
                      (course) => {
                        const courseString = this.printSemester(course.semester) + " " + course.catalog_number + "-" + course.section_number;
                          return <option
                            value={course.semester + " " + course.catalog_number + "-" + course.section_number}
                            key={courseString}
                          >
                            {courseString}
                          </option>
                      })
                    }
                  </Form.Select>
                </FloatingLabel>
              </Col>
            ) : (
              <>
                <Col md={6} lg={3} className="advanced-search-text">
                  <FloatingLabel label="Search by Semester">
                    <Form.Select
                      id="semester"
                      aria-label="Search by Semester"
                      onChange={e => this.handleFilterChange(e, false, true)}
                      disabled={disabled}
                      value={this.state.semester}
                    >
                      <option value={""}>All Semesters</option>
                      {courseYearOptions?.map((item, i) => (
                        <option value={item.key} key={item.key}>
                          {item.value}
                        </option>
                      ))}
                    </Form.Select>
                  </FloatingLabel>
                </Col>

                <Col md={6} lg={3} className="advanced-search-text">
                  <FloatingLabel label="Search by Course #">
                    <Form.Control
                      id="catalog_number"
                      type="search"
                      placeholder="Search by Course #"
                      aria-label="Search by Course #"
                      onChange={e => this.handleFilterChange(e, true, true)}
                      disabled={disabled}
                      value={this.state.catalog_number}
                    />
                  </FloatingLabel>
                </Col>

                <Col md={6} lg={3} className="advanced-search-text">
                  <FloatingLabel label="Search by Section #">
                    <Form.Control
                      id="section"
                      type="search"
                      placeholder="Search by Section #"
                      aria-label="Search by Section #"
                      onChange={e => this.handleFilterChange(e, true, true)}
                      disabled={disabled}
                      value={this.state.section}
                    />
                  </FloatingLabel>
                </Col>
              </>
            )}
            <Button
              className="rounded-5 text-primary"
              style={{ height: "58px", width: "150px" }}
              variant="gold"
              onClick={() => this.directoryNavigator.actions.handleFilterChange(this.state, true)}>
              Search
            </Button>
          </Row>
          <div className="divider" />
          <Row className="justify-content-center">
            <Col md="auto">
              <Button
                variant="primary"
                className="download-csv-button"
                onClick={this.directoryNavigator.actions.handleDownloadCSV}
                disabled={loading || csvLoading || disabled}
                alt="download csv button"
              >
                {csvLoading ? (
                  <React.Fragment>
                    <Spinner size="sm" animation="border" /> Loading
                  </React.Fragment>
                ) : (
                  <React.Fragment>Download CSV</React.Fragment>
                )}
              </Button>
            </Col>
            {this.props.fromStudent &&
              (this.props.roles.admin ||
                this.props.roles.registrar ||
                this.props.roles.faculty ||
                this.props.roles.operator) && (
              <Col md="auto">
                <Button
                  className="download-csv-button"
                  variant="primary"
                  alt="flash cards button"
                  disabled={loading || flashCardLoading || disabled}
                  onClick={
                    this.directoryNavigator.actions.handleFlashCards
                  }
                >
                  {flashCardLoading ? (
                    <React.Fragment>
                      <Spinner size="sm" animation="border" /> Loading
                    </React.Fragment>
                  ) : (
                    <React.Fragment>Flash Cards</React.Fragment>
                  )}
                </Button>
              </Col>
            )}
            <Col md="auto">
              <Button 
                className="clear-filters-button"
                disabled={disabled}
                onClick={() => this.clearfilters()}>
                Clear Filters
              </Button>
            </Col>
          </Row>
          {this.props.showPopUpWarning && (
            <Row>
              <Col className="text-center">
                <Alert variant="info">
                  {" "}
                  If the download fails, please make sure pop-ups are
                  unblocked on your browser
                </Alert>
              </Col>
            </Row>
          )}
        </Form>
      </Container>
    );

    const facultyFilters = this.props.fromFaculty && (
      <Container className="justify-content-center">
        <Form className="justify-content-center advanced-search-container">
          <Row>
            {(roles.admin || roles.registrar) && (
              <>
                <Col md={6} className="advanced-search-text">
                  <FloatingLabel label="Search by BYU ID">
                    <Form.Control
                      id="id"
                      type="search"
                      placeholder="Search by BYU ID"
                      aria-label="Search by BYU ID"
                      onChange={e => this.handleFilterChange(e, true)}
                      disabled={disabled}
                      value={this.state.id}
                    />
                  </FloatingLabel>
                </Col>
              
                <Col md={6} className="advanced-search-text">
                  <FloatingLabel label="Search by Phone #">
                    <Form.Control
                      id="phone"
                      type="search"
                      placeholder="Search by Phone #"
                      aria-label="Search by Phone #"
                      onChange={e => this.handleFilterChange(e, true)}
                      disabled={disabled}
                      value={this.state.phone}
                    />
                  </FloatingLabel>
                </Col>
              </>
            )}

            <Col md={6} className="advanced-search-text">
              <FloatingLabel label="Search by Department">
                <Form.Control
                  id="department"
                  type="search"
                  placeholder="Search by Department"
                  aria-label="Search by Department"
                  onChange={e => this.handleFilterChange(e, true)}
                  disabled={disabled}
                  value={this.state.department}
                />
              </FloatingLabel>
            </Col>

            <Col md={6} lg={3} className="advanced-search-text">
              <FloatingLabel label="Search by Room #">
                <Form.Control
                  id="room"
                  type="search"
                  placeholder="Search by Room #"
                  aria-label="Search by Room #"
                  onChange={e => this.handleFilterChange(e, true)}
                  disabled={disabled}
                  value={this.state.room}
                />
              </FloatingLabel>
            </Col>

            {roles.admin && (
              <Col md={6} lg={3} className="advanced-search-text">
                <Form.Check
                  inline
                  id="active"
                  label="Current"
                  onChange={e => this.handleFilterChange(e, false, false, true)}
                  disabled={disabled}
                  checked={this.state.active}
                  type="checkbox"
                  value={this.state.active}
                />
              </Col>
            )}

            <Form.Group>
              <Form.Label>
                <strong>Category:</strong>
              </Form.Label>
              <Row>
                <Col>
                  {this.props.facultyCategories[0].map((field) => (
                    <Form.Check
                      id="category"
                      type="checkbox"
                      label={field.display_name}
                      key={field.display_name}
                      onChange={(e) =>
                        this.handleCategoryChange(field, e)
                      }
                      disabled={disabled}
                      checked={
                        this.state.facultyCategories?.filter(
                          (e) => e === field.display_name
                        ).length > 0
                          ? true
                          : false
                      }
                    />
                  ))}
                </Col>
                <Col>
                  {this.props.facultyCategories[1].map((field) => (
                    <Form.Check
                      id="category"
                      type="checkbox"
                      label={field.display_name}
                      key={field.display_name}
                      disabled={disabled}
                      onChange={(e) =>
                        this.handleCategoryChange(field, e)
                      }
                      checked={
                        this.state.facultyCategories?.filter(
                          (e) => e === field.display_name
                        ).length > 0
                          ? true
                          : false
                      }
                    />
                  ))}
                  <Button
                    variant="danger"
                    onClick={(e) => this.handleCategoryDeselect(e)}
                    style={{ marginTop: 10 }}
                    disabled={disabled}
                  >
                    Deselect All
                  </Button>
                </Col>
              </Row>
            </Form.Group>
          </Row>
          <div className="divider" />
          <Row className="justify-content-center">
            <Col md="auto">
              <Button
                variant="primary"
                className="download-csv-button"
                onClick={this.directoryNavigator.actions.handleDownloadCSV}
                disabled={loading || csvLoading || disabled}
                alt="download csv button"
              >
                {csvLoading ? (
                  <React.Fragment>
                    <Spinner size="sm" animation="border" /> Loading
                  </React.Fragment>
                ) : (
                  <React.Fragment>Download CSV</React.Fragment>
                )}
              </Button>
            </Col>
            {((this.props.roles.admin || this.props.roles.registrar) &&
              this.props.currentFormat === "TextOnly" &&
              window.innerWidth > 575) && (
              <Col md="auto">
                <ReactToPrint
                  trigger={() => {
                    // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                    // to the root node of the returned component as it will be overwritten.
                    return (
                      <div>
                        <OverlayTrigger
                          placement="right"
                          delay={{ show: 250, hide: 400 }}
                          overlay={renderTooltip}
                        >
                          <Button variant="info" className="download-csv-button">Print Table</Button>
                        </OverlayTrigger>
                      </div>
                    );
                  }}
                  content={() => this.props.printRef.current}
                />
              </Col>
            )}
            <Col md="auto">
              <Button 
                className="clear-filters-button"
                disabled={disabled}
                onClick={() => this.clearfilters()}>
                Clear Filters
              </Button>
            </Col>
          </Row>
          {this.props.showPopUpWarning && (
            <Row>
              <Col className="text-center">
                <Alert variant="info">
                  {" "}
                  If the download fails, please make sure pop-ups are
                  unblocked on your browser
                </Alert>
              </Col>
            </Row>
          )}
        </Form>
      </Container>
    );

    const courseFilters = this.props.fromCourse && (
      <Container className="justify-content-center">
        <Form className="justify-content-center advanced-search-container">
            {roles.faculty && (
              <Row>
                <Col md={2} className="advanced-search-text">
                  <div className="clear-filters">
                    <Form.Label className="p-0 m-0" xs={"auto"}>
                      <h5>My Courses:</h5>
                    </Form.Label>
                    <Form.Check
                      className=""
                      type="switch"
                      id="my_courses"
                      checked={this.state.isMyCourses}
                      onChange={e => this.handleFilterChange(e)}
                      disabled={disabled}
                      value={this.state.class}
                    />
                  </div>
                </Col>
              </Row>
            )}
          <Row>
            {roles.registrar && (
              <Col md={6} lg={3} className="advanced-search-text">
                <FloatingLabel label="Search by Grade Status">
                  <Form.Select
                    id="grade_status"
                    aria-label="Search by Grade Status"
                    onChange={e => this.handleFilterChange(e)}
                    disabled={disabled}
                    value={this.state.gradeStatus}
                  >
                    <option value={""}>Select Grade Status</option>
                    <option value="registrar">Registrar</option>
                    <option value="1l_released">1l_released</option>
                    <option value="approved">Approved</option>
                    <option value="rejected">Rejected</option>
                    <option value="unsubmitted">Unsubmitted</option>
                    <option value="submitted">Submitted</option>
                  </Form.Select>
                </FloatingLabel>
              </Col>
            )}

            <Col md={6} lg={3} className="advanced-search-text">
              <FloatingLabel label="Search by Course #">
                <Form.Control
                  id="catalog_number"
                  type="search"
                  placeholder="Search by Course #"
                  aria-label="Search by Course #"
                  onChange={e => this.handleFilterChange(e, true)}
                  disabled={disabled}
                  value={this.state.catalog_number}
                />
              </FloatingLabel>
            </Col>

            <Col md={6} lg={3} className="advanced-search-text">
              <FloatingLabel label="Search by Section #">
                <Form.Control
                  id="section"
                  type="search"
                  placeholder="Search by Section #"
                  aria-label="Search by Section #"
                  onChange={e => this.handleFilterChange(e, true)}
                  disabled={disabled}
                  value={this.state.section}
                />
              </FloatingLabel>
            </Col>

            <Col md={6} lg={3} className="advanced-search-text">
              <FloatingLabel label="Search by Credit Hours">
                <Form.Control
                  id="credits"
                  type="search"
                  placeholder="Search by Credit Hours"
                  aria-label="Search by Credit Hours"
                  onChange={e => this.handleFilterChange(e, true)}
                  disabled={disabled}
                  value={this.state.credits}
                />
              </FloatingLabel>
            </Col>

            <Col md={6} className="advanced-search-text">
              <FloatingLabel label="Search by Instructor Name">
                <Form.Control
                  id="professor"
                  type="search"
                  placeholder="Search by Instructor Name"
                  aria-label="Search by Instructor Name"
                  onChange={e => this.handleFilterChange(e, true)}
                  disabled={disabled}
                  value={this.state.professor}
                />
              </FloatingLabel>
            </Col>

            <Col md={6} lg={3} className="advanced-search-text">
              <FloatingLabel label="Search by Semester">
                <Form.Select
                  id="semester"
                  aria-label="Search by Semester"
                  onChange={e => this.handleFilterChange(e)}
                  disabled={disabled}
                  value={this.state.semester}
                >
                  <option value={""}>All Semesters</option>
                  {courseYearOptions?.map((item, i) => (
                    <option value={item.key} key={item.key}>
                      {item.value}
                    </option>
                  ))}
                </Form.Select>
              </FloatingLabel>
            </Col>

            <Form.Group>
              <Form.Label>
                <Row style={{ justifyContent: "center" }}>
                  <h5 className="mb-0">
                    Day and Time{" "}
                    <OverlayTrigger overlay={this.renderTooltip}>
                      <Button variant="success" className="p-0 m-0" disabled={disabled}>
                        <FontAwesomeIcon
                          icon={faInfoCircle}
                          style={{ color: "002e5d" }}
                          size="sm"
                        />
                      </Button>
                    </OverlayTrigger>{" "}
                  </h5>
                </Row>
              </Form.Label>
              <Row className="p-0 m-0"></Row>
              <Col xl={9}>
              <div className="border rounded-3 p-2 bg-white">
                {this.daysOfWeek.map((day) => (
                  <Row className="no-margin mt-1" key={day.id + "row"}>
                    <Col xs="2" className="no-margin" key={day.id + "col"}>
                      <Button
                        className="fill-parent"
                        id={day.id}
                        key={day.id}
                        size="sm"
                        variant={
                          this.state[day.id] ? "primary" : "outline-primary"
                        }
                        onClick={e => this.handleFilterChange(e, false, false, true)}
                        disabled={disabled}
                        value={this.state[day.id]}
                      >
                        {day.short}
                      </Button>
                    </Col>
                    {this.state[day.id] && (
                      <React.Fragment>
                        <Col xs="1" className="no-margin"></Col>
                        <Col xs="4" className="no-margin">
                          <Form.Select
                            id={`${day.id}-start`}
                            as={"select"}
                            size="sm"
                            onChange={e => this.handleFilterChange(e, false, false, true)}
                            disabled={disabled}
                            // value={day.start}
                            value={day.start}
                          >
                            <option value={"*"}>Start Time</option>
                            {this.timeMap.map((time) => (
                              <option value={time.value} key={time.value}>{time.display}</option>
                            ))}
                          </Form.Select>
                        </Col>
                        <Col xs="1" className="no-margin">
                          <div className="center-text">-</div>
                        </Col>
                        <Col xs="4" className="no-margin">
                          <Form.Select
                            id={`${day.id}-end`}
                            as={"select"}
                            size="sm"
                            onChange={e => this.handleFilterChange(e, false, false, true)}
                            disabled={disabled}
                            value={day.end}
                          >
                            <option value={"*"}>End Time</option>
                            {this.timeMap.map((time) => (
                              <option value={time.value} key={time.value}>{time.display}</option>
                            ))}
                          </Form.Select>
                        </Col>
                      </React.Fragment>
                    )}
                  </Row>
                ))}
              </div>
              </Col>
            </Form.Group>
          </Row>
          <div className="divider" />
          <Row className="justify-content-center">
            <Col md="auto">
              <Button
                variant="primary"
                className="download-csv-button"
                onClick={this.directoryNavigator.actions.handleDownloadCSV}
                disabled={loading || csvLoading || disabled}
                alt="download csv button"
              >
                {csvLoading ? (
                  <React.Fragment>
                    <Spinner size="sm" animation="border" /> Loading
                  </React.Fragment>
                ) : (
                  <React.Fragment>Download CSV</React.Fragment>
                )}
              </Button>
            </Col>
            {((this.props.roles.admin || this.props.roles.registrar) &&
              this.props.currentFormat === "TextOnly" &&
              window.innerWidth > 575) && (
              <Col md="auto">
                <ReactToPrint
                  trigger={() => {
                    // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                    // to the root node of the returned component as it will be overwritten.
                    return (
                      <div>
                        <OverlayTrigger
                          placement="right"
                          delay={{ show: 250, hide: 400 }}
                          overlay={renderTooltip}
                        >
                          <Button variant="info" className="download-csv-button">Print Table</Button>
                        </OverlayTrigger>
                      </div>
                    );
                  }}
                  content={() => this.props.printRef.current}
                />
              </Col>
            )}
            <Col md="auto">
              <Button 
                className="clear-filters-button"
                disabled={disabled}
                onClick={() => this.clearfilters()}>
                Clear Filters
              </Button>
            </Col>
          </Row>
          {this.props.showPopUpWarning && (
            <Row>
              <Col className="text-center">
                <Alert variant="info">
                  {" "}
                  If the download fails, please make sure pop-ups are
                  unblocked on your browser
                </Alert>
              </Col>
            </Row>
          )}
        </Form>
      </Container>
    );

    return (
      <div className="directoryNav">
        <Navbar
          bg="secondary"
          className="px-0 pt-3 justify-content-center d-flex flex-wrap"
        >
          <NavBarItem
            className="directoryNavItem"
            isLink={true}
            title="Faculty & Staff"
            linkRef="/faculty-directory"
            iconDark={staffDark}
            iconLight={staffLight}
            alt="faculty & staff icon"
            isFromPage={this.props.fromFaculty}
          />
          <NavBarItem
            className="directoryNavItem"
            isLink={true}
            title="Courses"
            linkRef="/course-directory"
            iconDark={courseDark}
            iconLight={courseLight}
            alt="course icon"
            isFromPage={this.props.fromCourse}
          />
          {!roles.student && (
            <NavBarItem
              className="directoryNavItem"
              isLink={true}
              title="Graduate"
              linkRef="/graduate-directory"
              iconDark={gradDark}
              iconLight={gradLight}
              alt="graduate icon"
              isFromPage={this.props.fromGraduate}
            />
          )}
          <NavBarItem
            className="directoryNavItem"
            isLink={true}
            title="Student"
            linkRef="/student-directory"
            iconDark={studentDark}
            iconLight={studentLight}
            alt="student icon"
            isFromPage={this.props.fromStudent}
          />
        </Navbar>
        <Navbar
          bg="secondary"
          className="py-3"
        >
          <Container className="justify-content-center">
            <Form 
              className="d-flex flex-row" 
              onSubmit={e => { e.preventDefault(); }} 
              style={{ width: "100%" }}
            >
              <InputGroup className="directory-search rounded-0 d-flex">
                <FloatingLabel label="Search" style={{ flex: "1" }}>
                  <Form.Control
                    id="name"
                    type="search"
                    placeholder="Search"
                    aria-label="Search"
                    style={{ height: "50px" }}
                    disabled={disabled}
                    onChange={e => this.handleFilterChange(e, true)}
                    value={this.state.name}
                  />
                </FloatingLabel>
                <InputGroup.Text
                  id="search-addon" 
                  style={{
                    flex: "0",
                    backgroundColor: "white",
                    position: "absolute",
                    transform: "translateY(40%)",
                    border: "none",
                    right: "1.25rem",
                  }}
                >
                  <FontAwesomeIcon
                    icon={faSearch}
                    style={{ color: "dark-grey" }}
                  />
                </InputGroup.Text>
              </InputGroup>

              <Button 
                className="rounded-5 text-primary"
                style={{ height: "50px", width: "150px" }}
                variant="gold"
                disabled={disabled}
                onClick={() => this.setState({open: !(this.state.open)})}>
                {this.state.open ? "Close" : "Advanced"}
              </Button>
            </Form>
          </Container>
        </Navbar>
        <Collapse in={disabled ? false : this.state.open} className="advanced-search-collapse">
          {
          this.props.fromGraduate
            ? graduateFilters
            : this.props.fromStudent 
            ? studentFilters
            : this.props.fromFaculty
            ? facultyFilters
            : courseFilters
          }
        </Collapse>
      </div>
    );
  }
}
export default DirectoryHeader;