import React from "react";
import { Col, Row, Button, Spinner, Card } from "react-bootstrap";
import ApplicationInformation from "./ApplicationInformation";
import EditApplicationInformation from "./EditApplicationInformation";
import GraduationRequirementsJD from "./GraduationRequirementsJD";
import GraduationRequirementsLLM from "./GraduationRequirementsLLM";
import generateGraduationApplication from "../../utils/generateGraduationApplication";
import api from "../../services/api";

class GraduationEvaluation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      student: this.props.student,
      studentDegrees: this.props.studentDegrees,
      gradRequirementsMet: [],
      editing: false,
      loading: true,
    };
    this.handleEditChange = this.handleEditChange.bind(this);
    this.openGradEval = this.openGradEval.bind(this);
  }

  /**
   * Calls getGradRequirements to get the graduation requirements for the student.
   */
  componentDidMount() {
    this.getGradRequirements();
  }

  /**
   * Toggles the editing state of the component.
   */
  handleEditChange() {
    this.setState({ editing: !this.state.editing });
  }

  /**
   * Asynchronously retrieves graduation requirements for a student and updates the state accordingly.
   */
  async getGradRequirements() {
    const { data: gradRequirementsMet } = await api.get(
      `/students/graduation-eval/${this.props.student.id}`
    );
    this.setState({ gradRequirementsMet: gradRequirementsMet, loading: false });
  }

  /**
   * Asynchronously opens the Graduation Evaluation process.
   * Sets loading to true and then calls generateGraduationApplication.
   */
  async openGradEval() {
    this.setState({ loading: true });
    try {
      await generateGraduationApplication(
        this.state.student,
        this.state.gradRequirementsMet
      );
      this.setState({ errorMessage: null });
    } catch (err) {
      console.error(err);
      this.setState({ errorMessage: err.message });
    }
    this.setState({ loading: false });
  }

  render() {
    let { handleBack } = this.props;
    return (
      <React.Fragment>
        {this.state.loading ? (
          <Card className="shadow my-4">
            <Card.Header className="text-center" as="h2">
              Graduation Evaluation
            </Card.Header>
            <Card.Body>
              <Row className="justify-content-center mt-1">
                <Col xs="auto">
                  <h5>Instructions</h5>
                </Col>
              </Row>
              <Row className="justify-content-center mt-2">
                <Col xs="auto" xl="10">
                  Use the information under "Graduation Requirements Summary" to
                  evaluate your progress toward graduation. This is a basic
                  summary and does not constitute approval from the Law School.
                  Please double check against{" "}
                  <a
                    href="https://jrcb-course-catalog.byu.edu/graduation-requirements.php"
                    target="_blank"
                    rel="noreferrer"
                  >
                    the Law School's official requirements
                  </a>{" "}
                  to ensure you have met all requirements. Fill-in requested
                  information under "Graduation Application Information" as you
                  make decisions toward graduation. You may return to this form
                  and edit as often as needed. Once graduation requirements are
                  complete, prepare an application by clicking on the button
                  below, print and sign the application, and then return the
                  completed application to GaeLynn Kuchar in room 364A JRCB.
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Spinner animation="border" className="mb-2" />
              </Row>
            </Card.Body>
          </Card>
        ) : (
          <Card className="shadow my-4">
            <Card.Header className="text-center" as="h2">
              Graduation Evaluation
            </Card.Header>
            <Card.Body>
              <Row className="justify-content-center mt-1">
                <Col xs="auto">
                  <h5>Instructions</h5>
                </Col>
              </Row>
              <Row className="justify-content-center mt-2">
                <Col xs="auto" xl="10">
                  Use the information under "Graduation Requirements Summary" to
                  evaluate your progress toward graduation. This is a basic
                  summary and does not constitute approval from the Law School.
                  Please double check against{" "}
                  <a
                    href="https://jrcb-course-catalog.byu.edu/graduation-requirements.php"
                    target="_blank"
                    rel="noreferrer"
                  >
                    the Law School's official requirements
                  </a>{" "}
                  to ensure you have met all requirements. Fill-in requested
                  information under "Graduation Application Information" as you
                  make decisions toward graduation. You may return to this form
                  and edit as often as needed. Once graduation requirements are
                  complete, prepare an application by clicking on the button
                  below, print and sign the application, and then return the
                  completed application to GaeLynn Kuchar in room 364A JRCB.
                </Col>
              </Row>
              <Row className="justify-content-center mt-3">
                <Col xs="auto">
                  <h5>Graduation Requirements Summary</h5>
                </Col>
              </Row>
              {this.state.student.enrollment_status === "L" ? (
                <GraduationRequirementsLLM
                  student={this.state.student}
                  gradRequirementsMet={this.state.gradRequirementsMet}
                />
              ) : (
                <GraduationRequirementsJD
                  student={this.state.student}
                  gradRequirementsMet={this.state.gradRequirementsMet}
                />
              )}
              <Row className="justify-content-center mt-1">
                <Col xs="auto">
                  <h5>Graduation Application Information</h5>
                </Col>
              </Row>
              <Row className="justify-content-center mt-1">
                <Col xs="auto">
                  <Button onClick={this.handleEditChange}>Edit</Button>
                </Col>
              </Row>
              {this.state.editing ? (
                <EditApplicationInformation
                  student={this.state.student}
                  // studentDegrees={this.state.studentDegrees}
                  handleCancel={this.handleEditChange}
                  updateStudent={this.props.updateStudent}
                />
              ) : (
                <React.Fragment>
                  <ApplicationInformation
                    student={this.state.student}
                    // studentDegrees={this.state.studentDegrees}
                  />
                  <Row className="justify-content-center mt-1">
                    <Col xs="auto">
                      <Button className="mx-1" onClick={handleBack}>
                        Back
                      </Button>
                      <Button className="mx-1" onClick={this.openGradEval}>
                        Prepare Graduation Application
                      </Button>
                    </Col>
                  </Row>
                </React.Fragment>
              )}
            </Card.Body>
          </Card>
        )}
      </React.Fragment>
    );
  }
}

export default GraduationEvaluation;
