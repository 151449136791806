import React from "react";
import { Modal, Button } from "react-bootstrap";

class GraduateToFacultyModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
    };
    this.modalValue = this.modalValue.bind(this);
  }

  modalValue(temp) {
    this.setState({ value: temp });
  }

  render() {
    let { show, onHide, changeType } = this.props;
    // let file ;
    return (
      <Modal show={show} onHide={this.onHide}>
        <Modal.Header>
          <Modal.Title>Confirm Graduate to Faculty Change</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* {message} */}
          <div>
            <p>
              This id is already connected to a user. Would you like to change this user's type from "graduate" to "faculty"?
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="info" onClick={changeType}>
            Change Type
          </Button>
          <Button variant="outline-info" onClick={onHide}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default GraduateToFacultyModal;
