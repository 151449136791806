import React from "react";
import StudentInfromation from "../components/records/StudentInformation";
import { withRouter } from "react-router-dom";

class GraduteRecords extends React.Component {
  render() {
    return (
      <StudentInfromation isGraduate {...this.props} />
    );
  }
}

export default withRouter(GraduteRecords);
