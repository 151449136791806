import React from "react";
import { Col, Row, Form, Button, Card } from "react-bootstrap";

export default class EditPreLawSchools extends React.Component {
  render() {
    const {
      degrees,
      handlePreLawChange,
      addNewDegree,
      roles,
      handleDeleteDegree,
      degreesToAdd,
      handleDeleteNewDegree,
      handleNewPreLawChange,
    } = this.props;

    const isStudOrGrad = roles.student || roles.graduate;
    const isOperator = roles.operator;

    return (
      <Card className="mb-3 mt-4 p-0 shadow personal-info-card">
        <Card.Body>
          <Row className="p-0">
            <Col className="col-12 col-lg">
              <strong className="d-none d-lg-block">Pre-law Schools</strong>
              <strong className="d-lg-none ">Pre-law Degree(s)</strong>
            </Col>
            <Col className="col-12 col-lg d-none d-lg-block">
              <strong>Degrees</strong>
            </Col>
            <Col className="col-12 col-lg d-none d-lg-block">
              <strong>GPAs</strong>
            </Col>
            <Col className="col-12 col-lg d-none d-lg-block">
              <strong>Dates</strong>
            </Col>
            {(this.props.roles.admin || this.props.roles.regsitrar) && <Col />}
          </Row>
          {degrees &&
            degrees.map((item) => (
              <Row key={item.degree_num} className="p-0 mt-1">
                <Col className="col-12 col-lg">
                  <Form.Control
                    id={`${item.degree_num}-school`}
                    className="mb-1"
                    value={item.school}
                    onChange={handlePreLawChange}
                    disabled={isStudOrGrad || isOperator}
                  />
                </Col>
                <Col className="col-12 col-lg">
                  <Form.Control
                    id={`${item.degree_num}-deg`}
                    className="mb-1"
                    value={item.degree}
                    onChange={handlePreLawChange}
                    disabled={isStudOrGrad || isOperator}
                  />
                </Col>
                <Col className="col-12 col-lg">
                  <Form.Control
                    id={`${item.degree_num}-gpa`}
                    className="mb-1"
                    value={item.pre_law_gpa}
                    onChange={handlePreLawChange}
                    disabled={isStudOrGrad || isOperator}
                  />
                </Col>
                <Col className="col-12 col-lg">
                  <Form.Control
                    id={`${item.degree_num}-year`}
                    className="mb-1"
                    value={item.grad_year}
                    onChange={handlePreLawChange}
                    disabled={isStudOrGrad || isOperator}
                  />
                </Col>
                {(this.props.roles.admin || this.props.roles.registrar) && (
                  <Col>
                    <Button
                      className="mb-lg-1 mb-3"
                      id={`${item.degree_num}-delete`}
                      variant="outline-danger"
                      onClick={handleDeleteDegree}
                    >
                      Delete Degree
                    </Button>
                  </Col>
                )}
              </Row>
            ))}
          {degreesToAdd &&
            degreesToAdd.map((item) => (
              <React.Fragment>
                {!degrees.find((x) => x.degree_num === item.degree_num) && (
                  <Row key={item.degree_num} className="p-0 mt-1">
                    <Col className="col-12 col-lg">
                      <Form.Control
                        id={`${item.degree_num}-school`}
                        className="mb-1"
                        value={item.school}
                        placeholder="Pre-Law School"
                        onChange={handleNewPreLawChange}
                        disabled={isStudOrGrad}
                      />
                    </Col>
                    <Col className="col-12 col-lg">
                      <Form.Control
                        id={`${item.degree_num}-deg`}
                        className="mb-1"
                        value={item.degree}
                        placeholder="Degree"
                        onChange={handleNewPreLawChange}
                        disabled={isStudOrGrad}
                      />
                    </Col>
                    <Col className="col-12 col-lg">
                      <Form.Control
                        id={`${item.degree_num}-gpa`}
                        className="mb-1"
                        value={item.pre_law_gpa}
                        placeholder="GPA"
                        onChange={handleNewPreLawChange}
                        disabled={isStudOrGrad}
                      />
                    </Col>
                    <Col className="col-12 col-lg">
                      <Form.Control
                        id={`${item.degree_num}-year`}
                        className="mb-1"
                        value={item.grad_year}
                        placeholder="Date"
                        onChange={handleNewPreLawChange}
                        disabled={isStudOrGrad}
                      />
                    </Col>
                    <Col className="col-12 col-lg">
                      <Button
                        className="mb-lg-1 mb-3"
                        id={`${item.degree_num}-delete`}
                        variant="outline-danger"
                        onClick={handleDeleteNewDegree}
                      >
                        Delete Degree
                      </Button>
                    </Col>
                  </Row>
                )}
              </React.Fragment>
            ))}
          {(!isStudOrGrad && !isOperator) && (
            <Row className="p-0 mt-2 justify-content-center">
              <Col className="text-center">
                <Button variant="outline-primary" onClick={addNewDegree}>
                  Add Degree
                </Button>
              </Col>
            </Row>
          )}
        </Card.Body>
      </Card>
    );
  }
}
