import React from "react";
import { Nav } from "react-bootstrap";
/**
 * Returns a logout button that redirects the user to the '/logged-out' page.
 *
 * @return {JSX.Element} The logout button.
 */
function Logout() {
  /**
   * Redirects the user to the '/logged-out' page.
   *
   * @return {Promise<void>}
   */
  async function handleLogout() {
    window.location.assign("/logged-out");
  };


  return (
    <Nav.Link className="header-element" style={{color: "black", padding: 0, margin: 0}} onClick={handleLogout}>
      Log Out
    </Nav.Link>
  );
}

export default Logout;