import React from "react";
import { Button, OverlayTrigger, Row, Spinner, Tooltip } from "react-bootstrap";
import collapseArrow from "../../images/collapseArrow.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faClipboard,
  faEnvelope,
  faFilePdf,
  faImage,
} from "@fortawesome/free-regular-svg-icons";
import {
  faLink,
  faTimes,
  faUserPlus,
  faUserSlash,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

class ToolButton extends React.Component {
  
  render() {
    const tooltipText = this.props.tooltipText || "Not Available";
    const iconSource = this.props.disabled ?
      faTimes :
      this.props.icon === "pdf" ?
      faFilePdf :
      this.props.icon === "utility" ?
      faClipboard :
      this.props.icon === "link" ?
      faLink :
      this.props.icon === "image" ?
      faImage :
      this.props.icon === "mail" ?
      faEnvelope :
      this.props.icon === "user" ?
      faUserPlus :
      this.props.icon === "building" ?
      faBuilding :
      this.props.icon === "deleted" ?
      faUserSlash :
      null;

    return (
      <>
        {this.props.to ? (
          <Link 
            className={this.props.className ? this.props.className + " tool-button" : "tool-button"}
            to={this.props.to}
            {...this.props}
          >
            <Row className="d-flex" style={{ flexWrap: "wrap", alignItems: "center" }}>
              <h4 className="d-flex" style={{ flex: "1", marginBottom: 0, display: "flex", alignItems: "center" }}>
                {this.props.loading && (
                  <Spinner
                    size="sm"
                    animation="border"
                    style={{ height: "20px", width: "20px", marginRight: "0.5rem" }}
                  />
                )}
                {this.props.icon && !this.props.loading && (
                  <FontAwesomeIcon
                    icon={iconSource}
                    style={{ height: "20px", width: "20px", padding: 0, marginRight: "0.5rem" }}
                  />
                )}
                {this.props.image && (
                  <img
                    src={this.props.image}
                    style={{ height: "20px", width: "20px", padding: 0, marginRight: "0.5rem" }}
                    alt="Icon"
                  />
                )}
                {this.props.text}
              </h4>
              <img src={collapseArrow} alt="collapse arrow" className="tool-button-arrow" />
            </Row>
          </Link>
        ) : (
          <OverlayTrigger
            placement="bottom"
            overlay={this.props.disabled ? (
              <Tooltip>{tooltipText}</Tooltip>
            ) : (
              <></>
            )}
          >
            <span style={{ padding: 0 }}>
              <Button
                className={this.props.className ? this.props.className + " tool-button" : "tool-button"}
                onClick={this.props.onClick}
                style={{ backgroundColor: this.props.bg, width: "100%", ...this.props.style }}
                disabled={this.props.disabled || this.props.loading}
              >
                <Row className="d-flex" style={{ flexWrap: "wrap", alignItems: "center" }}>
                  <h4 className="d-flex" style={{ flex: "1", marginBottom: 0, display: "flex", alignItems: "center" }}>
                    {this.props.loading && (
                      <Spinner
                        size="sm"
                        animation="border"
                        style={{ height: "20px", width: "20px", marginRight: "0.5rem" }}
                      />
                    )}
                    {this.props.icon && !this.props.loading && (
                      <FontAwesomeIcon
                        icon={iconSource}
                        style={{ height: "20px", width: "20px", padding: 0, marginRight: "0.5rem" }}
                      />
                    )}
                    {this.props.text}
                  </h4>
                  <img src={collapseArrow} alt="collapse arrow" className="tool-button-arrow" />
                </Row>
              </Button>
            </span>
          </OverlayTrigger>
        )}
      </>
    )
  }
}

export default ToolButton;