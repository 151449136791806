import React from "react";
import { Button, Modal } from "react-bootstrap";

class AddPhotoModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      readerResult: "",
    };

    this.fileReader = this.fileReader.bind(this);
  }

/**
 * Reads a file from the input element with the id "image" and converts it to a data URL.
 *
 * @return {void}
 */
  fileReader() {
    var fileInput = document.getElementById("image");
    var reader = new FileReader();
    reader.readAsDataURL(fileInput.files[0]);
    var onloader = function () {
      // this.setState({ photoFile: reader.result });

      this.setState({ readerResult: reader.result });
    };
    reader.onload = onloader.bind(this);

    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  }

  render() {
    let { show, onHide, addPhoto } = this.props;
    // let file ;
    return (
      <Modal show={show} onHide={this.onHide}>
        <Modal.Header>
          <Modal.Title>Add Photo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* {message} */}
          <div>
            {" "}
            {/*} className="d-block border-dark"> */}
            {/* <Button onClick={selectPhoto}>select photo</Button> */}
            <input
              type="file"
              id="image"
              name="ImageStyle"
              onChange={this.fileReader}
            />
            {/* value={this.state.file} */}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="info"
            onClick={() => {
              this.props.selectPhoto(this.state.readerResult);
              this.props.addPhoto();
            }}
          >
            Add
          </Button>
          <Button variant="outline-info" onClick={onHide}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default AddPhotoModal;
