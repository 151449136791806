import axios from "axios";
import React from "react";
import {
  Button,
  Card,
  Col,
  Form,
  OverlayTrigger,
  Row,
  Spinner,
  Tab,
  Table,
  Tabs,
  Tooltip,
} from "react-bootstrap";
import api from "../../../services/api";
import ErrorDisplay from "../../ErrorDisplay";

export default class EditCourseTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      axiosCancelSource: false,
      studentID: this.props.studentID,
      courses: [], //this.props.courses,
      editableCourses: [],
      editableJDCourses: this.props.courses?.JD
        ? JSON.parse(JSON.stringify(this.props.courses.JD.courses))
        : null,
      editableLLMCourses: this.props.courses?.LLM
        ? JSON.parse(JSON.stringify(this.props.courses.LLM.courses))
        : null,
      editableOtherCourses: this.props.courses?.Other
        ? JSON.parse(JSON.stringify(this.props.courses.Other.courses))
        : null,
      errorMessage: null,
      disableApiButtons: false,
      showJD: false,
      showLLM: false,
      showOther: false,
      hasCoursesBeenEdited: false,
    };
    // This next line makes a copy of courses to be used in editableCourses
    this.handleEditCourseTable = this.handleEditCourseTable.bind(this);
    this.handleEditToggle = this.handleEditToggle.bind(this);
    this.addCourse = this.addCourse.bind(this);
    this.deleteCourse = this.deleteCourse.bind(this);
    this.isRowEditable = this.isRowEditable.bind(this);
    this.handleCancelEdit = this.handleCancelEdit.bind(this);
    this.handleSaveCourse = this.handleSaveCourse.bind(this);
    this.getCourseTitle = this.getCourseTitle.bind(this);
    this.getProfessorName = this.getProfessorName.bind(this);
    this.buildEditTable = this.buildEditTable.bind(this);

    this.handleSelect = this.handleSelect.bind(this);
    this.checkDegrees = this.checkDegrees.bind(this);
  }

  async componentDidMount() {
    await this.checkDegrees();
  }

  checkDegrees() {
    if (this.props.courses?.JD) {
      this.setState({ showJD: true });

      this.setState({ editableCourses: this.state.editableJDCourses });

      this.setState({ courses: this.props.courses.JD.courses });
    } else if (this.props.courses?.LLM) {
      this.setState({ showLLM: true });

      this.setState({ editableCourses: this.state.editableLLMCourses });

      this.setState({ courses: this.props.courses.LLM.courses });
    } else if (this.props.courses?.Other) {
      this.setState({ showOther: true });

      this.setState({ editableCourses: this.state.editableOtherCourses });

      this.setState({ courses: this.props.courses.Other.courses });
    } else {
      this.setState({
        editableCourses: JSON.parse(JSON.stringify(this.props.courses)),
      });

      // this.state.editableCourses = JSON.parse(
      //   JSON.stringify(this.props.courses)
      // );

      this.setState({ courses: this.props.courses });
    }
  }

  handleSelect(eventKey) {
    if (eventKey === "JD") {
      this.setState({ showLLM: false });
      this.setState({ showJD: true });
      this.setState({ showOther: false });

      this.setState({ editableCourses: this.state.editableJDCourses });

      this.setState({ courses: this.props.courses.JD.courses });
    } else if (eventKey === "LLM") {
      this.setState({ showJD: false });
      this.setState({ showLLM: true });
      this.setState({ showOther: false });

      this.setState({ editableCourses: this.state.editableLLMCourses });

      this.setState({ courses: this.props.courses.LLM.courses });
    } else if (eventKey === "Other") {
      this.setState({ showJD: false });
      this.setState({ showLLM: false });
      this.setState({ showOther: true });

      this.setState({ editableCourses: this.state.editableOtherCourses });

      this.setState({ courses: this.props.courses.Other.courses });
    }
  }

  async handleSaveCourse(e) {
    this.setState({ errorMessage: null });
    const elementID = e.target.id;
    const order = elementID.split("-")[0];
    let courses = this.state.courses;
    let editableCourses = this.state.editableCourses;
    const courseToSave = editableCourses.find(
      (course) => parseInt(course.order) === parseInt(order)
    );

    // const orignalCourse = this.props.courses.find(
    //   (course) => parseInt(course.order) === parseInt(order)
    // );
    // courseToSave.loading = true;
    // if (courseToSave.catalog_number !== "790R") {
    //   courseToSave.instructors = orignalCourse.instructors;
    // }
    this.setState({ editableCourses, disableApiButtons: true });

    if (this.state.showJD) {
      if (courseToSave.program !== 2) {
        if (courseToSave.program !== undefined) {
          courseToSave.old_program = 2;
          courseToSave.new_program = parseInt(courseToSave.program);
        } else {
          courseToSave.program = 2;
        }
      }
    } else if (this.state.showLLM) {
      if (courseToSave.program !== 1) {
        if (courseToSave.program !== undefined) {
          courseToSave.old_program = 1;
          courseToSave.new_program = parseInt(courseToSave.program);
        } else {
          courseToSave.program = 1;
        }
      }
    } else if (this.state.showOther) {
      if (courseToSave.program !== 3) {
        if (courseToSave.program !== undefined) {
          courseToSave.old_program = 3;
          courseToSave.new_program = parseInt(courseToSave.program);
        } else {
          courseToSave.program = 3;
        }
      }
    }
    try {
      if (courseToSave.new_program) {
        await api.post(
          `/student-course/program-change/${this.state.studentID}`,
          courseToSave
        );
      }
      await api.post(`/student-course/${this.state.studentID}`, courseToSave);
    } catch (err) {
      console.error(err);
      this.setState({
        errorMessage: err.response?.data ? err.response.data : err.message,
        disableApiButtons: false,
      });
      return;
    }

    this.setState({ editableCourses, disableApiButtons: true });

    const newCourseIndex = editableCourses.findIndex(
      (course) => parseInt(course.order) === parseInt(order)
    );
    let oldCourseIndex = courses.findIndex(
      (course) => parseInt(course.order) === parseInt(order)
    );

    if (oldCourseIndex !== -1) {
      courses[oldCourseIndex] = JSON.parse(
        JSON.stringify(editableCourses[newCourseIndex])
      );
    } else {
      courses.push(JSON.parse(JSON.stringify(editableCourses[newCourseIndex])));
      oldCourseIndex = courses.length - 1;
    }

    courses[oldCourseIndex].editable = false;
    editableCourses[newCourseIndex].editable = false;
    editableCourses[newCourseIndex].loading = false;
    this.setState({ courses, editableCourses, disableApiButtons: false });

    this.setState({ hasCoursesBeenEdited: true });
  }

  isRowEditable(order) {
    const course = this.state.editableCourses.find(
      (course) => parseInt(course.order) === parseInt(order)
    );
    if (course.editable === true) return true;
    return false;
  }

  isRowLoading(order) {
    const course = this.state.editableCourses.find(
      (course) => parseInt(course.order) === parseInt(order)
    );
    if (course.loading === true) return true;
    return false;
  }

  async getCourseTitle(course, courseIndex) {
    if (this.state.axiosCancelSource) this.state.axiosCancelSource.cancel();
    const cancelSource = axios.CancelToken.source();
    this.setState({ axiosCancelSource: cancelSource });
    const cancelToken = cancelSource.token;

    if (course.catalog_number === "XFER" || course.catalog_number === "BYU") {
      const editableCourses = this.state.editableCourses;
      editableCourses[courseIndex].catalog_title = "";
      this.setState({ editableCourses });
      return;
    }

    if (
      !(
        !(course.semester < 100000 && course.semester >= 19715) ||
        course.catalog_number === "" ||
        Number.isNaN(Number(course.section_number)) ||
        course.section_number === ""
      )
    ) {
      try {
        let { data: courseReceived } = await api.get(
          `/courses/directory?semester=${course.semester}&catalog_number=${course.catalog_number}&section_number=${course.section_number}`,
          { cancelToken }
        );
        courseReceived = courseReceived[0];
        const editableCourses = this.state.editableCourses;
        if (courseReceived && courseReceived.catalog_title) {
          editableCourses[courseIndex].catalog_title =
            courseReceived.catalog_title;
          editableCourses[courseIndex].instructors = [
            ...courseReceived.instructors,
          ];
          editableCourses[courseIndex].schedule = [...courseReceived.schedule];
        } else {
          editableCourses[courseIndex].catalog_title = "Class Doesn't Exist";
        }
        this.setState({ editableCourses, errorMessage: null });
      } catch (err) {
        if (!axios.isCancel(err)) {
          console.error(err);
          this.setState({
            errorMessage: err.response?.data ? err.response.data : err.message,
          });
        }
      }
    }
  }

  async getProfessorName(professorId, course, courseIndex) {
    if (this.state.axiosCancelSource) this.state.axiosCancelSource.cancel();
    const cancelSource = axios.CancelToken.source();
    this.setState({ axiosCancelSource: cancelSource });
    const cancelToken = cancelSource.token;

    const editableCourses = this.state.editableCourses;

    if (professorId?.match(/^\d{9}$/)) {
      try {
        const { data: professorReceived } = await api.get(
          // `/faculty/${professorId}`, Israel S. Note: Only changing to /person temporarily while /faculty is refactored.
          `/person/${professorId}`,
          { cancelToken }
        );
        if (professorReceived && professorReceived.last_name) {
          editableCourses[courseIndex].instructors[0].last_name =
            professorReceived.last_name;
          editableCourses[courseIndex].instructors[0].first_name =
            professorReceived?.first_name;
        } else {
          editableCourses[courseIndex].instructors[0].last_name =
            "Can't Find Prof";
        }
        this.setState({ editableCourses, errorMessage: null });
      } catch (err) {
        if (!axios.isCancel(err)) {
          if (err.response.status === 404) {
            editableCourses[courseIndex].instructors[0].last_name =
              "Can't Find Prof";
            this.setState({ editableCourses });
          } else {
            console.error("error", err);
            this.setState({
              errorMessage: err.response?.data
                ? err.response.data
                : err.message,
            });
          }
        }
      }
    }
  }

  handleEditCourseTable(e) {
    const { id: elementID, value, checked } = e.target;
    const { editableCourses } = this.state;

    const [courseOrder, field] = elementID.split("-");
    const courseIndex = editableCourses.findIndex(
      (course) => parseInt(course.order) === parseInt(courseOrder)
    );
    let course = editableCourses[courseIndex];
    switch (field) {
      case "semester":
        course.semester = Number.isNaN(parseInt(value)) ? "" : parseInt(value);
        this.getCourseTitle(course, courseIndex);
        break;
      case "course_num":
        let upperCaseValue = value.toUpperCase();
        course.catalog_number = upperCaseValue;
        this.getCourseTitle(course, courseIndex);
        break;
      case "section":
        course.section_number = value;
        this.getCourseTitle(course, courseIndex);
        if (course.catalog_number === "BYU" || course.catalog_number === "XFER")
          break;
        break;
      case "title":
        course.catalog_title = value;
        break;
      case "credits":
        course.credit_hour = value;
        break;
      case "grade":
        course.grade = value.toUpperCase();
        break;
      case "professor_id":
        if (course.instructors.length === 0) {
          course.instructors.push({
            id: value,
            last_name: "",
          });
        } else {
          course.instructors[0].id = value;
        }
        if (course.instructors[0].id === "") {
          course.instructors[0].last_name = "Staff";
        }
        this.getProfessorName(course.instructors[0].id, course, courseIndex);
        break;
      case "program":
        course.program = value;
        break;
      case "remote":
        course.remote = checked === true ? 1 : 0;
        break;
      default:
        return;
    }
    editableCourses[courseIndex] = course;

    this.setState({ editableCourses });
  }

  handleEditToggle(e) {
    const courseOrder = e.target.id;
    const editableCourses = this.state.editableCourses;
    const courseIndex = editableCourses.findIndex(
      (course) => parseInt(course.order) === parseInt(courseOrder)
    );
    if (editableCourses[courseIndex].editable === true) {
      editableCourses[courseIndex].editable = false;
    } else {
      editableCourses[courseIndex].editable = true;
    }
    this.setState({ editableCourses });
    this.setState({ hasCoursesBeenEdited: true });
  }

  handleCancelEdit(e) {
    const elementID = e.target.id;
    const courseOrder = elementID.split("-")[0];
    const editableCourses = this.state.editableCourses;
    const courses = this.state.courses;
    const oldCourseIndex = courses.findIndex(
      (course) => parseInt(course.order) === parseInt(courseOrder)
    );
    const newCourseIndex = editableCourses.findIndex(
      (course) => parseInt(course.order) === parseInt(courseOrder)
    );
    if (oldCourseIndex !== -1) {
      // revert to older changes if available
      editableCourses[newCourseIndex] = JSON.parse(
        JSON.stringify(courses[oldCourseIndex])
      );
    } else {
      // remove course from array if not original
      editableCourses.splice(newCourseIndex, 1);
    }
    this.setState({ editableCourses });
  }

  async deleteCourse(e) {
    this.setState({ errorMessage: null });
    const elementID = e.target.id;
    const order = elementID.split("-")[0];
    let courses = this.state.courses;
    let editableCourses = this.state.editableCourses;
    const courseToDelete = courses.find(
      (course) => parseInt(course.order) === parseInt(order)
    );
    const courseToDeleteFromEdit = editableCourses.find(
      (course) => parseInt(course.order) === parseInt(order)
    );
    courseToDeleteFromEdit.loading = true;
    this.setState({ editableCourses, disableApiButtons: true });
    try {
      await api.delete(`/student-course/${this.state.studentID}`, {
        data: courseToDelete,
      });
    } catch (err) {
      console.error(err);
      this.setState({
        errorMessage: err.response?.data ? err.response.data : err.message,
        disableApiButtons: false,
      });
      return;
    }
    courseToDeleteFromEdit.loading = false;

    editableCourses = editableCourses.filter(
      (course) => parseInt(course.order) !== parseInt(order)
    );
    courses = courses.filter(
      (course) => parseInt(course.order) !== parseInt(order)
    );
    this.setState({ courses, editableCourses, disableApiButtons: false });
    this.setState({ hasCoursesBeenEdited: true });
  }

  addCourse() {
    const { editableCourses } = this.state;
    const highestOrder = Math.max.apply(
      Math,
      editableCourses.map(function (course) {
        return course.order;
      })
    );

    let tempCourse = {
      id: this.state.studentID,
      semester: "",
      catalog_number: "",
      section_number: "",
      credit_hour: "",
      grade: "",
      editable: true,
      program: 2,
      order: highestOrder === -Infinity ? 1 : highestOrder + 1,
    };

    editableCourses.push(tempCourse);
    this.setState({ editableCourses });
  }

  buildEditTable(course) {
    let key = course.order;
    if (this.isRowLoading(key)) {
      return (
        <tr key={key}>
          <td colSpan="8">
            <Spinner animation="border" />
          </td>
        </tr>
      );
    } else if (this.isRowEditable(key)) {
      return (
        <tr key={key}>
          <td width="12%">
            <Form.Group>
              <Form.Control
                id={`${key}-semester`}
                type="number"
                value={course.semester === "NewCourse" ? "" : course.semester}
                isInvalid={
                  !(course.semester < 30000 && course.semester >= 19715)
                }
                onChange={this.handleEditCourseTable}
              />
            </Form.Group>
          </td>
          <td width="12%">
            <Form.Group>
              <Form.Control
                id={`${key}-course_num`}
                type="text"
                value={course.catalog_number}
                onChange={this.handleEditCourseTable}
                isInvalid={course.catalog_number === ""}
              />
            </Form.Group>
          </td>
          <td width="8%">
            <Form.Group>
              <Form.Control
                id={`${key}-section`}
                type="number"
                value={course.section_number}
                onChange={this.handleEditCourseTable}
                isInvalid={
                  Number.isNaN(Number(course.section_number)) ||
                  course.section_number === ""
                }
              />
            </Form.Group>
          </td>
          <td width="40%">
            <Form.Group>
              <Form.Control
                id={`${key}-title`}
                type="text"
                value={course.catalog_title}
                onChange={this.handleEditCourseTable}
                disabled={
                  !(
                    course.catalog_number === "BYU" ||
                    course.catalog_number === "XFER"
                  )
                }
                isInvalid={
                  course.catalog_title === "Class Doesn't Exist" ||
                  !(course?.instructors
                    ? (course?.instructors[0]?.id?.match(/^\d{9}$/) ||
                        course?.instructors[0]?.id === "") &&
                      course?.instructors[0]?.last_name !== "Can't Find Prof"
                    : true)
                }
              />
              <Form.Control.Feedback type="invalid">
                * Class Doesn't Exist Or the Class Has No Professor
              </Form.Control.Feedback>
              {course.catalog_number === "790R" &&
                course.catalog_title !== undefined &&
                course.catalog_title !== "Class Doesn't Exist" && (
                  <Row>
                    <Col className="ms-0">
                      <Form.Control
                        id={`${key}-professor_id`}
                        type="text"
                        onChange={this.handleEditCourseTable}
                        placeholder="Instructor's BYU ID"
                        value={
                          course.instructors
                            ? course?.instructors[0]?.id
                              ? course.instructors[0].id
                              : ""
                            : ""
                        }
                        isInvalid={
                          course?.instructors
                            ? !course.instructors[0]?.id?.match(/^\d{9}$/)
                            : true
                        }
                      />
                    </Col>
                    <Col className="ms-0">
                      <Form.Control
                        id={`${key}-professor_name`}
                        type="text"
                        placeholder="Name"
                        disabled={true}
                        value={
                          course.instructors
                            ? course.instructors[0]?.last_name !==
                              "[object Object]"
                              ? course.instructors[0].last_name
                              : ""
                            : ""
                        }
                        isInvalid={
                          course.instructors
                            ? course.instructors[0]?.last_name ===
                              "Can't Find Instructor"
                            : true
                        }
                      />
                    </Col>
                  </Row>
                )}
            </Form.Group>
          </td>
          <td width="5%">
            <Form.Check
              type="checkbox"
              id={`${key}-remote`}
              value={course.remote}
              checked={course.remote}
              onChange={this.handleEditCourseTable}
            />
          </td>
          {(this.props.roles.admin || this.props.roles.registrar) && (
            <>
              <td width="12%">
                <Form.Group>
                  <Form.Control
                    id={`${key}-credits`}
                    type="text"
                    value={course.credit_hour}
                    onChange={this.handleEditCourseTable}
                    isInvalid={
                      !(
                        course.credit_hour?.match(/^\d{0,2}\.?\d{1,2}$/) ||
                        course.credit_hour === ""
                      )
                    }
                  />
                </Form.Group>
              </td>
              <td width="10%">
                <OverlayTrigger
                  placement={"top"}
                  overlay={
                    !course.grade_is_released ? (
                      <Tooltip id={this.gradesNotReleasedTooltip}>
                        This grade has not been released
                      </Tooltip>
                    ) : (
                      <></>
                    )
                  }
                >
                  <Form.Group>
                    <Form.Control
                      id={`${key}-grade`}
                      type="text"
                      value={course.grade}
                      disabled={course.grade_is_released === false}
                      onChange={this.handleEditCourseTable}
                      isInvalid={
                        !(
                          course.grade?.match(
                            /^(([1][6-9])|([2-3][0-9])|40)$/
                          ) ||
                          course.grade === "P" ||
                          course.grade === "T" ||
                          course.grade === "" ||
                          course.grade === null
                        )
                      }
                    />
                  </Form.Group>
                </OverlayTrigger>
              </td>
              <td width="10%">
                <Form.Group>
                  <Form.Select
                    id={`${key}-program`}
                    size="sm"
                    value={course.program}
                    onChange={this.handleEditCourseTable}
                  >
                    <option value={2} key={2}>
                      JD
                    </option>
                    <option value={1} key={1}>
                      LLM
                    </option>
                    <option value={3} key={3}>
                      Other
                    </option>
                  </Form.Select>
                </Form.Group>
              </td>
              <td width="5%">
                <Button
                  id={`${key}-save`}
                  onClick={this.handleSaveCourse}
                  variant="info"
                  disabled={
                    !(course.semester < 30000 && course.semester >= 19715) ||
                    course.catalog_number === "" ||
                    Number.isNaN(Number(course.section_number)) ||
                    course.section_number === "" ||
                    this.state.disableApiButtons ||
                    !(
                      course.grade?.match(/^(([1][6-9])|([2-3][0-9])|40)$/) ||
                      course.grade === "P" ||
                      course.grade === "T" ||
                      course.grade === "" ||
                      course.grade === null
                    ) ||
                    !(
                      course.credit_hour?.match(/^\d{0,2}\.?\d{1,2}$/) ||
                      course.credit_hour === ""
                    ) ||
                    course.catalog_title === "Class Doesn't Exist" ||
                    !(course?.instructors
                      ? ((course?.instructors[0]?.id?.match(/^\d{9}$/) ||
                          course?.instructors[0]?.id === "") &&
                          course?.instructors[0]?.last_name !==
                            "Can't Find Prof") ||
                        course.catalog_number === "BYU" ||
                        course.catalog_number === "XFER"
                      : true)
                  }
                >
                  Save
                </Button>
              </td>
              <td width="5%">
                <Button
                  id={`${key}-cancel`}
                  onClick={this.handleCancelEdit}
                  variant="outline-info"
                >
                  Cancel
                </Button>
              </td>
            </>
          )}
        </tr>
      );
    } else {
      return (
        <tr key={key}>
          <td width="12%">
            <Form.Group>
              <Form.Control
                id={`${key}-semester`}
                type="text"
                value={course.semester === "NewCourse" ? "" : course.semester}
                disabled={true}
              />
            </Form.Group>
          </td>
          <td width="12%">
            <Form.Group>
              <Form.Control
                id={`${key}-course_num`}
                type="text"
                value={course.catalog_number}
                disabled={true}
              />
            </Form.Group>
          </td>
          <td width="8%">
            <Form.Group>
              <Form.Control
                id={`${key}-section`}
                type="text"
                value={course.section_number}
                disabled={true}
              />
            </Form.Group>
          </td>
          <td width="40%">
            <Form.Group>
              <Form.Control
                id={`${key}-title`}
                type="text"
                value={course.catalog_title}
                disabled={true}
              />
              {course.catalog_number === "790R" &&
                course.catalog_title !== undefined &&
                course.catalog_title !== "Class Doesn't Exist" && (
                  <Row>
                    <Col>
                      <Form.Control
                        id={`${key}-professor_id`}
                        type="number"
                        placeholder="BYU ID (9)"
                        onChange={this.handleEditCourseTable}
                        value={
                          course.instructors != null
                            ? course.instructors[0]?.id
                              ? course.instructors[0].id
                              : ""
                            : ""
                        }
                        disabled={true}
                      />
                    </Col>
                    <Col className="ms-0">
                      <Form.Control
                        id={`${key}-professor_name`}
                        type="text"
                        placeholder="Name"
                        disabled={true}
                        value={
                          course.instructors != null &&
                          course.instructors[0]?.last_name !== "[object Object]"
                            ? course.instructors[0]?.last_name
                            : ""
                        }
                        isInvalid={
                          course.instructors &&
                          course.instructors[0]?.last_name === "Can't Find Prof"
                        }
                      />
                    </Col>
                  </Row>
                )}
            </Form.Group>
          </td>
          <td width="5%">
            <Form.Check
              type="checkbox"
              id={`${key}-remote`}
              checked={course.remote}
              onChange={this.handleEditCourseTable}
              disabled={true}
            />
          </td>
          {(this.props.roles.admin || this.props.roles.registrar) && (
            <>
              <td width="12%">
                <Form.Group>
                  <Form.Control
                    id={`${key}-credits`}
                    type="text"
                    value={course.credit_hour}
                    disabled={true}
                  />
                </Form.Group>
              </td>
              <td width="10%">
                <Form.Group>
                  <Form.Control
                    id={`${key}-grade`}
                    type="text"
                    value={course.grade}
                    disabled={true}
                  />
                </Form.Group>
              </td>
              <td width="10%">
                <Form.Group>
                  <Form.Control
                    id={`${key}-program`}
                    type="text"
                    value={
                      parseInt(course.program) === 1
                        ? "LLM"
                        : parseInt(course.program) === 2
                        ? "JD"
                        : "Other"
                    }
                    disabled={true}
                  />
                </Form.Group>
              </td>
              <td width="5%">
                <Button
                  id={`${key}-edit`}
                  onClick={this.handleEditToggle}
                  variant="outline-primary"
                >
                  Edit
                </Button>
              </td>
              <td width="5%">
                <Button
                  id={`${key}-delete`}
                  onClick={this.deleteCourse}
                  variant="outline-primary"
                >
                  Delete
                </Button>
              </td>
            </>
          )}
        </tr>
      );
    }
  }

  render() {
    return (
      <React.Fragment>
        <Card className="mb-4 shadow bg-light">
          <Tabs
            id="tab-degree"
            transition={true}
            onSelect={this.handleSelect}
            className="mb-2"
          >
            {/* // && !this.props.courses.LLM.completed */}
            {this.props.courses?.JD && (
              <Tab
                eventKey="JD"
                title={
                  this.props.courses.JD.completed
                    ? `JD${
                        this.props.courses?.JD && this.props.courses?.LLM
                          ? " - Degree Complete"
                          : ""
                      }`
                    : `JD${
                        this.props.courses?.JD && this.props.courses?.LLM
                          ? " - Degree Not Complete"
                          : ""
                      }`
                }
              ></Tab>
            )}
            {this.props.courses?.LLM && (
              <Tab
                eventKey="LLM"
                title={
                  this.props.courses.LLM.completed
                    ? `LLM${
                        this.props.courses?.JD && this.props.courses?.LLM
                          ? " - Degree Complete"
                          : ""
                      }`
                    : `LLM${
                        this.props.courses?.JD && this.props.courses?.LLM
                          ? " - Degree Not Complete"
                          : ""
                      }`
                }
              ></Tab>
            )}
            {this.props.courses?.Other && (
              <Tab eventKey="Other" title={`Other`}></Tab>
            )}
          </Tabs>
          <Table className="m-0" striped bordered hover responsive>
            <thead>
              <tr key="header">
                <th>Semester</th>
                <th>Course</th>
                <th>Section</th>
                <th>Course Title</th>
                <th>Remote</th>
                {(this.props.roles.admin || this.props.roles.registrar) && (
                  <>
                    <th>Hours</th>
                    <th>GD</th>
                    <th>Program</th>
                    <th colSpan="2">Options</th>
                  </>
                )}
              </tr>
            </thead>
            <tbody>
              {this.state.editableCourses.map((course) =>
                this.buildEditTable(course, null)
              )}
            </tbody>
          </Table>
        </Card>
        <Row className="mt-2">
          {((this.state.showJD && !this.props.courses.JD.completed) ||
            (this.state.showLLM && !this.props.courses.LLM.completed) ||
            this.state.showOther ||
            !window.location.pathname.startsWith("/graduate-directory")) && (
            <Col className="d-flex justify-content-end" md={{ span: 6 }}>
              <Button
                variant="outline-primary"
                className="shadow-button"
                onClick={this.addCourse}
              >
                + Course
              </Button>
            </Col>
          )}
          <Col className="d-flex justify-content-end">
            <Button
              className="m-1 shadow-button"
              style={{ marginRight: ".5rem" }}
              variant="outline-info"
              id="cancel-button"
              onClick={this.props.onCancelChanges}
            >
              Cancel
            </Button>
            <Button
              className="m-1 shadow-button"
              variant="info"
              onClick={this.props.onSaveChanges}
              disabled={
                !this.props.hasStudentBeenEdited &&
                !this.state.hasCoursesBeenEdited
              }
            >
              Save Changes
            </Button>
          </Col>
        </Row>

        {this.state.errorMessage && (
          <ErrorDisplay errorMessage={this.state.errorMessage} />
        )}
      </React.Fragment>
    );
  }
}
