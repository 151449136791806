import React from "react";
import {
  Card,
  Container,
  Spinner,
} from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { withRouter } from "react-router-dom";
import ErrorDisplay from "../components/ErrorDisplay";
import GraduationEvaluation from "../components/graduation_evaulation/GraduationEvaluation";
import api from "../services/api";
import {
  getAddressOfType,
  getDevId,
  releaseGradesGetCurrentSemester,
} from "../utils/functions";
import RequestRecording from "./RequestRecording";
import ViewSemesterReportModal from "./ViewSemesterReportModal";
import ToolButton from "../components/tools/ToolButton";
import { secureUserToken } from "../components/auth/secureSessionStorage";
import axios from "axios";

class StudentRecord extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      roles: this.props.roles,
      selectedStudent: this.props.student || {},
      componentToRender: "default",
      loading: true,
      errorMessage: null,
      customMessage: null,
      deansList: null,
      rankingLetter: null,
      progressReport: null,
      honorsLetter: null,
      classPic: null,
      personalPic: false,
      finalExamShedule: false,
      progressReportData: [],
      deansListData: [],
      honorsLetterData: [],
      rankingLetterData: [],
      targetData: [],
      classPicLink: null,
      personalPicLink: null,
      viewReportModal: false,
      reportType: "nothing",
    };

    this.getStudent = this.getStudent.bind(this);
    this.handleOpenGradEval = this.handleOpenGradEval.bind(this);
    this.handleCloseSubPage = this.handleCloseSubPage.bind(this);
    this.handleOpenRequestRecording =
      this.handleOpenRequestRecording.bind(this);
    // this.handleCloseGradEval = this.handleCloseGradEval.bind(this);
    this.rankingLetterExists = this.rankingLetterExists.bind(this);
    this.deansListLetterExist = this.deansListLetterExists.bind(this);
    this.honorsLetterExists = this.honorsLetterExists.bind(this);
    this.newOpenHonors = this.newOpenHonors.bind(this);
    this.classPicExists = this.classPicExists.bind(this);
    this.personalPicExists = this.personalPicExists.bind(this);
    this.finalExamSheduleExists = this.finalExamSheduleExists.bind(this);

    this.toggleViewReportModal = this.toggleViewReportModal.bind(this);
    this.viewProgressReport = this.viewProgressReport.bind(this);
    this.generateProgressReport = this.generateProgressReport.bind(this);
    this.viewRankingLetter = this.viewRankingLetter.bind(this);
    this.viewDeansList = this.viewDeansList.bind(this);
    this.getFinalExamSchedule = this.getFinalExamSchedule.bind(this);
  }

  async getStudent() {
    this.setState({ loading: true });
    let isGraduate = this.props.roles.graduate;
    let studentId = sessionStorage.getItem('devRoles') ? getDevId() : secureUserToken.id;
    let student = {};
    let addresses = [];
    let preLaw = [];
    let courses = [];

    try {
      async function getStudentApi() {
        const { data } = isGraduate
          ? await api.get("/graduates/" + studentId)
          : await api.get("/students/" + studentId);
        student = data;
        addresses = data.addresses;
        preLaw = data.pre_law_degrees;
      }
      async function getCourseApi() {
        const { data } = await api.get("/student-course/" + studentId);
        if (data?.JD && data.JD.completed === false) {
          courses = data.JD.courses;
        } else if (data?.LLM && data.LLM.completed === false) {
          courses = data.LLM.courses;
        } else {
          courses = data;
        }
      }
      await Promise.all([getStudentApi(), getCourseApi()]);
    } catch (err) {
      console.error(err);
      this.setState({
        errorMerrorMessage: err.response?.data
          ? err.response.data
          : err.messagee,
        customMessage: "Error in loading student records: ",
      });
    }

    student.pre_law_degrees = preLaw;
    student.courses = courses;
    student.address = addresses;
    this.setState({
      selectedStudent: student,
    });

    this.setState({ loading: false });
  }

  async componentDidMount() {
    //check if instructors is null, common error with student courses
    if (this.props.student) {
      if (this.props.student.courses.JD) {
        this.props.student.courses.JD.courses.forEach((course) => {
          if (course.instructors === null) {
            course.instructors = [];
          }
        });
        this.setState({
          selectedStudent: (this.state.selectedStudent.courses =
            this.state.selectedStudent.courses.JD.courses),
        });
      } else if (this.props.student.courses.LLM) {
        this.props.student.courses.LLM.courses.forEach((course) => {
          if (course.instructors === null) {
            course.instructors = [];
          }
        });
        this.setState({
          selectedStudent: (this.state.selectedStudent.courses =
            this.state.selectedStudent.courses.LLM.courses),
        });
      } else {
        this.props.student.courses.forEach((course) => {
          if (course.instructors === null) {
            course.instructors = [];
          }
        });
      }
    }
    if (!this.props.student) {
      await this.getStudent();
    }

    switch (this.props.match.params.subPage) {
      case "grad-eval":
        this.setState({ componentToRender: "grad-eval" });
        this.updateURL(
          "student-record",
          this.state.selectedStudent.id,
          "grad-eval"
        );
        break;
      default:
        if (!this.props.fromDirectory) {
          this.updateURL("student-record", this.state.selectedStudent.id);
        }
        break;
    }

    if (this.props.student) {
      const selectedStudent = this.state.selectedStudent;
      if (this.props.student.address) {
        selectedStudent.homeAddress = getAddressOfType(
          this.props.student.address,
          "home"
        );
      } else {
        selectedStudent.homeAddress = {};
      }
      this.setState({
        selectedStudent: selectedStudent,
      });
      this.setState({
        loading: false,
      });
    }

    this.rankingLetterExists();
    this.deansListLetterExists();
    this.progressReportExists();
    this.honorsLetterExists();
    if (this.props.fromGraduate) {
      this.classPicExists();
    }
    this.personalPicExists();
    this.finalExamSheduleExists();
  }

  async honorsLetterExists() {
    const studentid = this.state.selectedStudent.id;
    const url = `/reports/allstoredreports?report=honorsletters&studentid=${studentid}`;

    try {
      const honorsletter = await api.get(url);

      this.setState({ honorsLetter: true });
      this.setState({ honorsLetterData: honorsletter.data });
    } catch {
      this.setState({ honorsLetter: false });
    }
  }

  async newOpenHonors() {
    const studentid = this.state.selectedStudent.id;
    const url = `/reports/allstoredreports?report=honorsletters&studentid=${studentid}`;
    const honorsletter = await api.get(url);

    try {
      window.open(honorsletter.data[0].url);
    } catch (err) {
      this.setState({
        customMessage: "Error downloading report",
      });
    }
  }

  async rankingLetterExists() {
    try {
      const studentid = this.state.selectedStudent.id;

      const url = `/reports/allstoredreports?report=rankingletters&studentid=${studentid}`;

      const rankingletter = await api.get(url);

      if (rankingletter.status === 200) {
        this.setState({ rankingLetter: true });
        await this.setState({ rankingLetterData: rankingletter.data });
      } else {
        this.setState({ rankingLetter: false });
      }
    } catch (err) {
      this.setState({ rankingLetter: false });
    }
  }

  async deansListLetterExists() {
    try {
      const studentid = this.state.selectedStudent.id;

      const url = `/reports/allstoredreports?report=deansletters&studentid=${studentid}`;

      const deanslist = await api.get(url);

      if (deanslist.status === 200) {
        this.setState({ deansList: true });
        await this.setState({ deansListData: deanslist.data });
      } else {
        this.setState({ deansList: false });
      }
    } catch (err) {
      this.setState({ deansList: false });
    }
  }

  async progressReportExists() {
    const studentid = this.state.selectedStudent.id;

    const url = `/reports/allstoredreports?report=progressreport&studentid=${studentid}`;
    try {
      const progressreport = await api.get(url);
      this.setState({ progressReport: true });
      this.setState({ progressReportData: progressreport.data });
    } catch {
      this.setState({ progressReport: false });
    }
  }

  async classPicExists() {
    try {
      if (this.state.selectedStudent.law_degrees) {
        const gradYear = new Date(
          this.state.selectedStudent.law_degrees[
            this.state.selectedStudent.law_degrees > 0
              ? this.state.selectedStudent.law_degrees.length - 1
              : 0
          ]?.date_degree_received
        ).toLocaleString("en-US", {
          timeZone: "UTC",
          year: "numeric",
        });

        const gradRange = parseInt(gradYear) - 3 + "-" + gradYear;

        const url =
          process.env.REACT_APP_STAGE === "dev"
            ? `https://s3.amazonaws.com/content.law-info.byu.edu-dev/class-pictures/${gradRange}.pdf?`
            : `https://s3-us-west-2.amazonaws.com/content.law-info.byu.edu/class-pictures/${gradRange}.pdf?`;

        const classPic = await fetch(url, { method: "HEAD" });

        if (classPic.ok) {
          this.setState({ classPic: true });
          await this.setState({ classPicLink: url });
          return;
        }
      }

      this.setState({ classPic: false });
    } catch (err) {
      this.setState({ classPic: false });
    }
  }

  async personalPicExists() {
    try {
      const studentid = this.state.selectedStudent.id;

          let link;
          if (process.env.REACT_APP_STAGE === "dev") {
            link =
              `https://s3.amazonaws.com/content.law-info.byu.edu-dev/student-pictures/${studentid}.jpg?` +
              new Date().getTime();
          } else {
            link =
              `https://s3-us-west-2.amazonaws.com/content.law-info.byu.edu/student-pictures/${studentid}.jpg?` +
              new Date().getTime();
          }
          const pic = link;

          this.setState({personalPicLink: link});

          const personalPic = await fetch(pic, { method: 'HEAD' }) ;

            if (personalPic.ok) {
              this.setState({ personalPic: true });
              await this.setState({ personalPicLink: pic });
              return;
            } 
    } catch (err) {
      this.setState({ personalPic: false });
    }
  }

  async finalExamSheduleExists() {
    try {
      const semester = this.getCurrentSemester();
      
      const finalExamShedule = await api.get(`/final-schedule/${semester}`);

      if (finalExamShedule.ok) {
        this.setState({ finalExamSheduleExists: true });
        return;
      }
    } catch (err) {
      this.setState({ finalExamSheduleExists: false });
    }
  }

  handleOpenGradEval() {
    this.setState({ componentToRender: "grad-eval" });
    this.updateURL(
      "student-record",
      this.state.selectedStudent.id,
      "grad-eval"
    );
  }

  handleOpenRequestRecording() {
    this.setState({ componentToRender: "request-recording" });
    this.updateURL(
      "student-record",
      this.state.selectedStudent.id,
      "request-recording"
    );
  }

  handleCloseSubPage() {
    this.setState({ componentToRender: "default" });
    this.updateURL("student-record", this.state.selectedStudent.id);
  }

  // TODO: Condense all UpdateURL functions into one (if possible)
  updateURL(nextPage, nextStudent, nextSubPage) {
    // NOTE: If we support Graduates on this in the future, this will need to change.
    const nextURL = `${window.location.protocol}//${window.location.host}/${
      this.props.roles.graduate
        ? this.props.fromDirectory
          ? "graduate-directory"
          : "graduates"
        : this.props.fromDirectory
        ? "student-directory"
        : "students"
    }/${this.props.fromDirectory ? nextPage + "/" : ""}${nextStudent}/${
      nextSubPage ? nextSubPage : ""
    }`;
    const nextTitle = document.title;

    // This will replace the current entry in the browser's history, without reloading
    window.history.replaceState({}, nextTitle, nextURL);
  }

  async viewProgressReport() {
    this.setState({ reportType: "progressreport"});
    let data = this.state.progressReportData;
    this.setState({ targetData: data});
    this.toggleViewReportModal();
  }

  async generateProgressReport() {
    this.setState({loading: true})
    let semester = releaseGradesGetCurrentSemester();
    let id = this.state.selectedStudent.id;
    try {
      let report = await api.get(
        `/reports/progressreport?semester=${semester}&format=pdf&studentid=${id}`
      );
      window.open(report.data.url);
      this.setState({loading: false})
    } catch (err) {
      if (!axios.isCancel(err)) {
        console.error(err);
        this.setState({
          errorMessage: err.response?.data ? err.response.data : err.message,
        });
        this.setState({loading: false})
      }
    }
  }

  viewRankingLetter(){
    this.setState({ reportType: "classrank"});
    let data = this.state.rankingLetterData;
    this.setState({ targetData: data});
    this.toggleViewReportModal();
  }

  viewDeansList(){
    this.setState({ reportType: "deanslist"});
    let data = this.state.deansListData;
    this.setState({ targetData: data});
    this.toggleViewReportModal();
  }

  toggleViewReportModal() {
    this.setState({ viewReportModal: !this.state.viewReportModal });
  }

  async getFinalExamSchedule() {
    let semester = releaseGradesGetCurrentSemester();

    const finalExamShedule = await api.get(`/final-schedule/${semester}`);

    window.open(finalExamShedule.data.url);
  }

  render() {
    return (
      <Container
        className={this.props.fromDirectory ? "my-4 px-0" : "my-4 px-4"}
      >
        {this.state.errorMessage && (
          <ErrorDisplay
            errorMessage={this.state.errorMessage}
            customMessage={this.state.customMessage}
          />
        )}
        {this.state.viewReportModal && (
          <ViewSemesterReportModal
            show={this.state.viewReportModal}
            onHide={this.toggleViewReportModal}
            data={this.state.targetData}
            reportType={this.state.reportType}
            // id={this.state.selectedStudent.id}
          />
        )}

        {this.state.progressReport === null &&
        this.state.rankingLetter === null &&
        this.state.deansList === null &&
        this.state.honorsLetter === null ? (
          <div className="loadingDiv">
            <h1 className="loadingText" style={{marginBottom: '1em', marginLeft: '0.5em'}}>Loading Documents...</h1>
            <Spinner animation={"border"} />
          </div>
        ) : (
          // COLOR CHANGE BACK TO WHITE WHEN DONE 
          <Card className='bg-primary shadow' style={{ color: "black", padding: "1rem" }}>
            <React.Fragment>
              <Row className="align-item-center" style={{ margin: "0 20px" }}>
                <Col style={{ color: "white" }}>
                  <h1>Documents and Reports</h1>
                </Col>
              </Row>
              <br/>
              <Row>
                {(this.props.roles.admin || this.props.roles.registrar) && (
                  <Col xs={2} md={4} className='tools-card'>
                    <Row>
                      <h5 style={{ textDecoration: "underline", color: "white"}}>Reports:</h5>
                    </Row>
                    <Row>
                      <ToolButton 
                      text={this.state.loading ? 
                      <><h3 className="loadingText" style={{marginRight: '1em'}}>
                        Loading
                      </h3> <Spinner animation={"border"} /></> : 
                      "Generate Progress Report"}
                      icon="pdf" 
                      onClick={this.generateProgressReport}
                      />
                    </Row>
                    {/* THIS WILL BE THE PAST GENERATED REPORTS BUTTON ONCE WE ADD THAT FUNCTIONALITY */}
                    {/* <Row>
                      <Button className="tool-button" onClick={() => window.open('', '_blank')}>
                        <Row>
                          <Col xs={10} md={11}>
                            <h4>Past Generated Reports</h4>
                          </Col>
                          <Col xs={2} md={1} className="justify-content-center align-content-center d-flex wrap">
                            <img src={collapseArrow} alt="collapse arrow" style={{ transform: "rotate(90deg)", maxHeight: "40px" }} />
                          </Col>
                        </Row>
                      </Button>
                    </Row> */}
                  </Col>
                )}
                <Col className='tools-card'>
                  <Row>
                    <h5 style={{
                      textDecoration: "underline",
                      color: "white",
                      margin: "0 12px"
                    }}>
                      View:
                    </h5>
                  </Row>
                  <Row>
                    <Col className='tools-card '>
                      <Row> 
                        <ToolButton
                          text="Progress Report"
                          icon="pdf"
                          onClick={this.viewProgressReport}
                          disabled={!this.state.progressReport}
                        />
                      </Row>
                      <Row> 
                        <ToolButton
                          text="Ranking Letter"
                          icon="pdf"
                          onClick={this.viewRankingLetter}
                          disabled={!this.state.rankingLetter}
                        />
                      </Row>
                      <Row>
                        <ToolButton
                          text="Dean's List Letter"
                          icon="pdf"
                          onClick={this.viewDeansList}
                          disabled={!this.state.deansList}
                        />
                      </Row>
                      {(this.props.roles.graduate || this.props.fromGraduate) && (
                      <Row>
                        <ToolButton
                          text="Honors Letter"
                          icon="pdf"
                          onClick={() => window.open(this.state.honorsLetterData[0].url, '_blank')}
                          disabled={!this.state.honorsLetter}
                        />
                      </Row>
                      )}
                      <Row>
                        <ToolButton
                          text="Final Exam Schedule"
                          icon="pdf"
                          onClick={this.getFinalExamSchedule}
                          disabled={!this.state.finalExamScheduleExists}
                        />
                      </Row>
                      <Row>
                        <ToolButton
                          text="Student Picture"
                          icon="image"
                          onClick={() => window.open(this.state.personalPicLink, '_blank')}
                          disabled={!this.state.personalPic}
                        />
                      </Row>
                      {(this.props.roles.graduate || this.props.fromGraduate) && (
                        <>
                        <Row>
                          <ToolButton
                            text="Class Picture"
                            icon="pdf"
                            onClick={() => window.open(this.state.classPicLink, '_blank')}
                            disabled={!this.state.classPic}
                          />
                        </Row>
                        </>
                      )}
                    </Col>
                    <Col className='tools-card'>
                      {(!this.props.roles.graduate && !this.props.fromGraduate) && (
                        <>
                          <Row>
                            <ToolButton text="Change myBYU Password" icon="link" onClick={() => window.open("https://accountrecovery.byu.edu/", '_blank')} />
                          </Row>
                          <Row>
                            <ToolButton text="Request Special Recording" icon="mail" onClick={this.handleOpenRequestRecording} />
                          </Row>
                          <Row>
                            <ToolButton text="Request Final Exam Time Change" icon="mail" onClick={() => window.open("https://byu.az1.qualtrics.com/jfe/form/SV_ezBgUbBY0KJK41E", '_blank')} />
                          </Row>
                          <Row>
                            <ToolButton text="Graduation Evaluation" icon="link" onClick={this.handleOpenGradEval} />
                          </Row>
                        </>
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
              {this.state.componentToRender === "grad-eval" && (
                <GraduationEvaluation
                  student={this.state.selectedStudent}
                  studentAddress={this.state.selectedStudent.address}
                  studentDegrees={this.state.selectedStudent.pre_law_degrees}
                  handleBack={this.handleCloseSubPage}
                  updateStudent={this.props.getStudent}
                />
              )}
              {this.state.componentToRender === "request-recording" && (
                <RequestRecording handleBack={this.handleCloseSubPage} />
              )}
            </React.Fragment>
          </Card>
        )}
      </Container>
    );
  }
}

export default withRouter(StudentRecord);
