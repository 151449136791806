import React from "react";

import { Col, Row, Card } from "react-bootstrap";

export default class PreLawSchools extends React.Component {
  render() {
    const { degrees, noShadow } = this.props;
    return (
      <Card className={noShadow ? "my-4 personal-info-card" : "shadow my-4 personal-info-card"}>
        <Card.Body>
          <Row className="mb-3">
            <Row>
              <Col className="col-12 col-lg">
                <strong className="d-none d-lg-block">Pre-law Schools</strong>
                <strong className="d-lg-none ">Pre-law Degree(s)</strong>
              </Col>
              <Col className="col-12 col-lg d-none d-lg-block">
                <strong>Degrees</strong>
              </Col>
              <Col className="col-12 col-lg d-none d-lg-block">
                <strong>GPAs</strong>
              </Col>
              <Col className="col-12 col-lg d-none d-lg-block">
                <strong>Dates </strong>
              </Col>
            </Row>
            {degrees?.map((item, idx) => (
              <Row key={idx}>
                <Col className="col-12 col-lg">
                  <p className="m-0 d-none d-lg-block">{item.school}</p>
                  <p className="d-lg-none ">
                    {item.school} <br /> {item.degree} | {item.pre_law_gpa} |{" "}
                    {item.grad_year}
                  </p>
                </Col>
                <Col className="col-12 col-lg d-none d-lg-block">
                  <p className="m-0">{item.degree}</p>
                </Col>
                <Col className="col-12 col-lg d-none d-lg-block">
                  <p className="m-0">{item.pre_law_gpa}</p>
                </Col>
                <Col className="col-12 col-lg d-none d-lg-block">
                  <p className="m-0">{item.grad_year}</p>
                </Col>
              </Row>
            ))}
          </Row>
        </Card.Body>
      </Card>
    );
  }
}
