import React from "react";
import { Modal, Button, Spinner } from "react-bootstrap";
import api from "../../services/api";
import ErrorDisplay from "../ErrorDisplay";

class AssignExamNumberModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = { loading: false, 
      done: false,
      errorMessage: "",
      customMessage: "", };
    this.assignExamNumbers = this.assignExamNumbers.bind(this);
    
  }

  async assignExamNumbers() {
    this.setState({ loading: true });
    try {
      await api.post("/students/examnumber/generate");
      this.setState({ done: true });
    } catch (err) {
      this.setState({
        errorMessage: err.response?.data ? err.response.data : err.message,
        customMessage: "Error downloading report",
      });
    }
    this.setState({ loading: false });
    if (this.state.errorMessage === ""){
      this.props.onHide();
    }
  }

  render() {
    let { show, onHide } = this.props;
    return (
      <Modal show={show} onHide={onHide} backdrop="static">
        <Modal.Header>Assign Exam Numbers</Modal.Header>
        <Modal.Body>
          {this.state.loading ? (
            <React.Fragment>
              <Spinner animation="border" /> This could take up to 30 seconds...
            </React.Fragment>
          ) : (
            <React.Fragment>
              {this.state.done ? (
                <React.Fragment>
                  New Exam Numbers have been assigned
                </React.Fragment>
              ) : (
                <React.Fragment>
                  Are you sure you want to assign new exam numbers to every
                  student?
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </Modal.Body>
        <Modal.Footer>
          {!this.state.done && (
            <Button
              variant="info"
              onClick={this.assignExamNumbers}
              disabled={this.state.loading || this.state.done}
            >
              Assign Exam Numbers
            </Button>
          )}
          <Button
            variant="outline-info"
            onClick={onHide}
            disabled={this.state.loading}
          >
            {this.state.done ? (
              <React.Fragment>Done</React.Fragment>
            ) : (
              <React.Fragment>Cancel</React.Fragment>
            )}
          </Button>
        </Modal.Footer>
        {this.state.errorMessage && (
          <ErrorDisplay errorMessage={this.state.errorMessage} customMessage={this.state.customMessage} />
        )}
      </Modal>
    );
  }
}

export default AssignExamNumberModal;
