import { DateTime } from "luxon";
import { PDFDocument, StandardFonts } from "pdf-lib";
import { getAddressOfType } from "./functions";

function putSpacesOnLeft(string, numSpaces) {
  return string.padStart(string.length + numSpaces, " ");
}

async function generateGraduationApplication(student, gradRequirementsMet) {
  let url = "/grad_app_form.pdf";
  let isLLM = false;
  if (student.enrollment_status === "L") {
    isLLM = true;
    url = "/llm_grad_app_form.pdf";
  }

  const existingPdfBytes = await fetch(url).then((res) => res.arrayBuffer());

  const pdfDoc = await PDFDocument.load(existingPdfBytes);

  const form = pdfDoc.getForm();

  const timesNew = await pdfDoc.embedFont(StandardFonts.TimesRoman);

  const today = new Date();
  let dateString = today.toDateString();
  dateString = dateString.slice(4, 10) + "," + dateString.slice(10);
  const dateField = form.getTextField("Todays Date");
  dateField.setText(dateString);

  const idField = form.getTextField("Student BYU ID");
  idField.setText(putSpacesOnLeft(student.id.toString(), 2));

  if (student.joint_degree) {
    const jointField = form.getTextField("JointJD");
    jointField.setText(" X");
    const jointDegreeField = form.getTextField("Joint Degree");
    jointDegreeField.setText(student.joint_degree);
  } else if (isLLM) {
    const llmField = form.getTextField("LLM");
    llmField.setText(" X");
  } else {
    const jdField = form.getTextField("JD");
    jdField.setText(" X");
  }

  const nameField = form.getTextField("Name");
  nameField.setText(
    student.name_on_diploma
      ? student.name_on_diploma
      : student.first_name + " " + student.last_name
  );

  if (student.plan_grad_date) {
    let plan_grad_date = DateTime.fromISO(student.plan_grad_date);
    const gradAprilField = form.getTextField("April");
    const gradDecField = form.getTextField("December");
    const gradYearField = form.getTextField("Grad Year");
    if (plan_grad_date.get("month") === 11) {
      //  Months are zero-indexed
      gradDecField.setText(" X");
    } else if (plan_grad_date.get("month") === 3) {
      gradAprilField.setText(" X");
    }
    gradYearField.setText(
      putSpacesOnLeft(plan_grad_date.get("year").toString(), 1)
    );
  }

  let studentAddress = getAddressOfType(student.address, "home");

  const addressField1 = form.getTextField("Current Address");
  addressField1.setText(studentAddress.street1 + studentAddress.street2);

  const cityField = form.getTextField("City");
  cityField.setText(studentAddress.city);
  const stateField = form.getTextField("State");
  stateField.setText(putSpacesOnLeft(studentAddress.state, 2));
  const zipCodeField = form.getTextField("Zip Code");
  zipCodeField.setText(putSpacesOnLeft(studentAddress.postal_code, 2));
  const phoneField = form.getTextField("Phone Number");
  phoneField.setText(studentAddress.phone);

  const barExamField = form.getTextField("Bar Exam State");
  barExamField.setText(student.plan_bar_state);
  if (student.plan_bar_date) {
    let plan_bar_date = DateTime.fromISO(student.plan_bar_date);
    const barExamJulyField = form.getTextField("July");
    const barExamFeburaryField = form.getTextField("Feb");
    const barExamYearField = form.getTextField("Bar Exam Year");
    if (plan_bar_date.get("month") === 1) {
      barExamFeburaryField.setText(" X");
    } else if (plan_bar_date.get("month") === 6) {
      barExamJulyField.setText(" X");
    }
    barExamYearField.setText(plan_bar_date.get("year").toString());
  }

  const barPrepCourseField = form.getTextField("Plan Bar Prep Course");
  barPrepCourseField.setText(student.bar_prep_course);

  if (student.pre_law_degrees) {
    if (student.pre_law_degrees[0]) {
      const degree1Field = form.getTextField("Degree 1");
      degree1Field.setText(student.pre_law_degrees[0].degree);
      const uni1Field = form.getTextField("University 1");
      uni1Field.setText(student.pre_law_degrees[0].school);
      const year1Field = form.getTextField("Year Received 1");
      year1Field.setText(student.pre_law_degrees[0].grad_year);
      if (student.pre_law_degrees[1]) {
        const degree2Field = form.getTextField("Degree 2");
        degree2Field.setText(student.pre_law_degrees[1].degree);
        const uni2Field = form.getTextField("University 2");
        uni2Field.setText(student.pre_law_degrees[1].school);
        const year2Field = form.getTextField("Year Received 2");
        year2Field.setText(student.pre_law_degrees[1].grad_year);
      }
    }
  }
  if (!isLLM) {
    const tortsField = form.getTextField("Torts");
    if (gradRequirementsMet.torts) {
      tortsField.setText(" X");
    }

    const contractsField = form.getTextField("Contracts");
    if (gradRequirementsMet.contracts) {
      contractsField.setText(" X");
    }

    const civilProcField = form.getTextField("Civil Proc");
    if (gradRequirementsMet.civil_procedure) {
      civilProcField.setText(" X");
    }

    const propertyField = form.getTextField("Property");
    if (gradRequirementsMet.property) {
      propertyField.setText(" X");
    }

    const criminalLawField = form.getTextField("Criminal Law");
    if (gradRequirementsMet.criminal_law) {
      criminalLawField.setText(" X");
    }

    const advocacyField = form.getTextField("Advocacy12");
    if (gradRequirementsMet.intro_to_advocacy) {
      advocacyField.setText(" X");
    }

    const legRegField = form.getTextField("Leg Reg");
    if (gradRequirementsMet.legislation_regulation) {
      legRegField.setText(" X");
    }

    const strcuturesOfConstitutionField = form.getTextField(
      "Structures of Constitution"
    );
    if (gradRequirementsMet.structures_constitution) {
      strcuturesOfConstitutionField.setText(" X");
    }

    const profResponsiblityField = form.getTextField(
      "Professional Responsibility"
    );
    if (gradRequirementsMet.prof_responsibility) {
      profResponsiblityField.setText(" X");
    }

    const SWField = form.getTextField("SW Paper");
    if (gradRequirementsMet.substantial_writing) {
      SWField.setText(" X");
    }

    const skillsField = form.getTextField("Skills");
    if (gradRequirementsMet.library_skills) {
      skillsField.setText(" X");
    }
  } else {
    const firstYearField = form.getTextField("First Year Course");
    if (gradRequirementsMet.first_year_course) {
      firstYearField.setText(" X");
    }
    const legalResField = form.getTextField("Legal Res and Writ");
    if (gradRequirementsMet.legal_research_writing) {
      legalResField.setText(" X");
    }
    const introToAmericanField = form.getTextField("Intro to American Law");
    if (gradRequirementsMet.intro_to_american_law) {
      introToAmericanField.setText(" X");
    }
  }

  const pfField = form.getTextField("Pass Fail");
  pfField.setText(gradRequirementsMet.pass_fail_credits?.toString());

  const gradedField = form.getTextField("Graded");
  gradedField.setText(gradRequirementsMet.graded_credits?.toString());

  const creditsField = form.getTextField("Credits Hrs Completed");
  creditsField.setText(gradRequirementsMet.credits?.toString());

  const enrollmentField = form.getTextField("Current Enrollment");
  enrollmentField.setText(gradRequirementsMet.current_credits?.toString());

  const requiredCreditsField = form.getTextField("Needed Credit Hours");
  requiredCreditsField.setText(
    gradRequirementsMet.required_credits?.toString()
  );

  const gpaField = form.getTextField("Current GPA");
  gpaField.setText(gradRequirementsMet.gpa?.toString());

  form.updateFieldAppearances(timesNew);

  form.flatten(true);

  let pdfBytes = await pdfDoc.save();

  const file = new Blob([pdfBytes], {
    type: "application/pdf",
  });
  file.name = "honors-letter.pdf";
  const fileURL = URL.createObjectURL(file);
  window.open(fileURL);
}

export default generateGraduationApplication;
