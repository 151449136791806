import React from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import ReactToPrint from "react-to-print";

import { faPrint } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class PageNav extends React.Component {
  constructor(props) {
    super(props);

    this.renderTooltip = this.renderTooltip.bind(this);
  }

  renderTooltip(props) {
    return (
      <Tooltip {...props}>
        {!this.props.edit
          ? "Must be in edit mode"
          : this.props.pageName === "Graduate Archive"
          ? "You cannot delete a graduate"
          : "This student will be deleted"}
      </Tooltip>
    );
  }

  render() {
    const pageStyle = `
    @media all {
      .page-break {
        display: none;
      }
    }
    
    @media print {
      html, body {
        height: initial !important;
        overflow: initial !important;
        -webkit-print-color-adjust: exact;
        zoom: 85%
      }
    }
    
    @media print {
      .page-break {
        margin-top: 1rem;
        display: block;
        page-break-before: auto;
      }
    }
    
    @page {
      size: portrait;
      margin: 20mm;
    }
`;
    const {
      pageName,
      nonEditable = false,
      options,
      currentPage,
      studentsNavigator,
      edit,
      disableEdit = false,
      roles,
      fromDirectory,
      studentView,
    } = this.props;

    const isStudOrGrad = roles.student || roles.graduate;

    const renderTooltip = (props) => (
      <Tooltip 
        id="button-tooltip"
        alt="print current page button"
        {...props}>
        Print Current Page
      </Tooltip>
    );

    return (
      <Card bg="light" className="px-3 py-2 shadow">
        <Container>
          <Row className="align-items-center" style={{ minWidth: "100%" }}>
            <Col xs={12} md={"auto"}>
              <h4 className="p-0 m-0">{pageName}</h4>
            </Col>
            <Col xs={12} md={"auto"} style={{ paddingLeft: "20px" }}>
              <Row>
                {!edit ? (
                  <Col
                    xs={{ span: 6, order: 0 }}
                    sm={{ span: "auto", order: 0 }}
                    className="p-1"
                  >
                    <Button
                      className="responsive-button"
                      variant="primary-light"
                      onClick={studentsNavigator.actions.edit}
                      disabled={disableEdit}
                      style={{ outlineColor: "red !important" }}
                      alt="edit button"
                    >
                      Edit
                    </Button>
                  </Col>
                ) : (
                  <Col
                    xs={{ span: 6, order: 0 }}
                    sm={{ span: "auto", order: 0 }}
                    className="p-1"
                  >
                    <Button
                      className="responsive-button"
                      variant="primary-light"
                      onClick={studentsNavigator.actions.edit}
                      disabled={disableEdit}
                      alt="cancel button"
                    >
                      Cancel
                    </Button>
                  </Col>
                )}
                {!isStudOrGrad && !nonEditable && !roles.operator && (
                  <React.Fragment>
                    <Col
                      xs={{ span: 6, order: 1 }}
                      sm={{ span: "auto", order: 1 }}
                      className="p-1"
                    >
                      <OverlayTrigger
                        placement="bottom"
                        overlay={this.renderTooltip}
                      >
                        <div>
                          <Button
                            className="responsive-button"
                            variant="primary-light"
                            onClick={studentsNavigator.actions.delete}
                            disabled={!edit || pageName === "Graduate Archive"}
                            alt="delete button"
                          >
                            Delete
                          </Button>
                        </div>
                      </OverlayTrigger>
                    </Col>

                    <Col
                      xs={{ span: 6, order: 4 }}
                      sm={{ span: "auto", order: 2 }}
                      className="p-1 arrows-right"
                    >
                      <Button
                        className="responsive-button"
                        variant="primary-light"
                        onClick={studentsNavigator.actions.first}
                        disabled={edit}
                        alt="skip to first button"
                      >
                        ⇤
                      </Button>
                    </Col>
                    <Col
                      xs={{ span: 6, order: 2 }}
                      sm={{ span: "auto", order: 3 }}
                      className="p-1"
                    >
                      <Button
                        className="responsive-button"
                        variant="primary-light"
                        onClick={studentsNavigator.actions.prev}
                        disabled={edit}
                        alt="previous button"
                      >
                        ← Prev
                      </Button>
                    </Col>

                    <Col
                      xs={{ span: 6, order: 3 }}
                      sm={{ span: "auto", order: 4 }}
                      className="p-1"
                    >
                      <Button
                        className="responsive-button"
                        variant="primary-light"
                        onClick={studentsNavigator.actions.next}
                        disabled={edit}
                        alt="next button"
                      >
                        Next →
                      </Button>
                    </Col>
                    <Col
                      xs={{ span: 6, order: 5 }}
                      sm={{ span: "auto", order: 5 }}
                      className="p-1"
                    >
                      <Button
                        className="responsive-button"
                        variant="primary-light"
                        onClick={studentsNavigator.actions.last}
                        disabled={edit}
                        alt="skip to last button"
                      >
                        ⇥
                      </Button>
                    </Col>
                  </React.Fragment>
                )}
              </Row>
            </Col>
            <Col sm={12} md={4} xl={"auto"}>
              <Form.Select
                value={currentPage}
                // eventHandler={studentsNavigator.actions.handlePageChange}
                disabled={edit || studentView}
                onChange={this.props.handleTabChange}
              >
                {options.map((item, key) => {
                  return (
                    <option key={key} value={item.value}>
                      {item.display}
                    </option>
                  );
                })}
              </Form.Select>
            </Col>
            {!isStudOrGrad && fromDirectory && (
              <React.Fragment>
                <Col sm={12} md={4} xl={"auto"} className="py-2">
                  <Form.Check
                    type="switch"
                    label="Student View"
                    disabled={edit}
                    checked={studentView}
                    onChange={studentsNavigator.actions.handleToggleStudentView}
                  />
                </Col>
                <Col sm={12} md={4} xl={"auto"}>
                  {this.props.currentPage !== "reports-utils" ? (
                    <ReactToPrint
                      trigger={() => {
                        // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                        // to the root node of the returned component as it will be overwritten.
                        return (
                          <Button
                            className="m-0 p-2"
                            variant="success"
                            aria-label="print current page"
                          >
                            <OverlayTrigger
                              placement="bottom"
                              delay={{ show: 250, hide: 400 }}
                              overlay={renderTooltip}
                            >
                              <div>
                                {/* <Button className="m-2 p-0" variant="success" aria-label="print page"> */}
                                <FontAwesomeIcon
                                  icon={faPrint}
                                  alt="print page"
                                  style={{ color: "002e5d" }}
                                />
                                {/* </Button> */}
                              </div>
                            </OverlayTrigger>
                          </Button>
                        );
                      }}
                      content={this.props.thisIsRef}
                      pageStyle={pageStyle}
                    />
                  ) : (
                    ""
                  )}
                </Col>
                <Col sm={12} md={4} xl={"auto"}>
                  {this.props.fromMassAdvance === "Return to Mass Advance" ? (
                    <Button
                      className="mx-1"
                      onClick={
                        studentsNavigator.actions.handleReturnToDirectory
                      }
                      disabled={edit}
                    >
                      {this.props.fromMassAdvance}
                    </Button>
                  ) : this.props.fromMassGrad ===
                    "Return to Mass Graduation" ? (
                    <Button
                      className="mx-1"
                      onClick={
                        studentsNavigator.actions.handleReturnToDirectory
                      }
                      disabled={edit}
                    >
                      {this.props.fromMassGrad}
                    </Button>
                  ) : (
                    <Button
                      className="mx-1"
                      onClick={
                        studentsNavigator.actions.handleReturnToDirectory
                      }
                      disabled={edit}
                    >
                      Return to Directory
                    </Button>
                  )}
                </Col>
              </React.Fragment>
            )}
          </Row>
        </Container>
      </Card>
    );
  }
}

export default PageNav;
