import React from "react";
import {
  Alert,
  Button,
  Spinner,
  Tab,
  Table,
  Tabs,
} from "react-bootstrap";
import axiosApi from "../services/api";
import MultipleErrorDisplay from "./MultipleErrorDisplay";

// History accepts six props:
// apis, onRestore, displayNames, disableRestore, isTableOfDeletedItems, and enableHighlight
// apis is a list of apis you would want the history of
// onRestore is an optional function to be called when the user restores a record
// The function will be passed the record being restored as the first parameter
// displayNames is an optional list of strings
// They will be displayed in place of the api url of the corresponding index
// disableRestore is an optional list of booleans
// They control whether restore buttons will be displayed per api
// isTableOfDeletedItems is an optional boolean
// It controls whether to use /create (true) or regular POST (false/undef) apis
// enableHighlight is a list of booleans. Each one corresponds to the api of the same index
// They control whether or not differences between rows are highlighted
class History extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      apis: this.props.apis,
      postApi: this.props.postApi,
      onRestore: this.props.onRestore || null,
      enableHighlight: this.props.enableHighlight || null,
      displayNames: this.props.displayNames || null,
      activeTab: this.props.apis[0],
      apiData: new Array(this.props.apis.length),
      loading: true,
      loadingMessage: "Loading Edit History...",
      errorMessage: null,
      errorMessageArray: [],
      isTableOfDeletedItems: this.props.isTableOfDeletedItems || false,
      disableRestore: this.props.disableRestore || null,
      hasJD: false,
      hasLLM: false,
    };
    this.setTab = this.setTab.bind(this);
    this.buildTab = this.buildTab.bind(this);
    this.getApiData = this.getApiData.bind(this);
    this.handleRestore = this.handleRestore.bind(this);
    this.getHistoryForApi = this.getHistoryForApi.bind(this);
  }

  async handleRestore(e) {
    this.setState({ loading: true, loadingMessage: "Restoring data..." });
    const [firstIndex, lastIndex, subTabName] = e.target.id.split("-");
    const postApi = this.state.postApi;

    let payload;
    if (
      Object.keys(this.state.apiData[firstIndex]).includes("person") &&
      subTabName === "person"
    ) {
      payload = this.state.apiData[firstIndex].person[lastIndex];
    } else if (
      Object.keys(this.state.apiData[firstIndex]).includes(
        "student_information"
      ) &&
      subTabName === "student_information"
    ) {
      payload = this.state.apiData[firstIndex].student_information[lastIndex];
    } else if (
      this.state.apiData[firstIndex]?.JD ||
      this.state.apiData[firstIndex]?.LLM ||
      this.state.apiData[firstIndex]?.Other
    ) {
      if (this.state.hasJD) {
        payload = this.state.apiData[firstIndex].JD.courses[lastIndex];
      } else if (this.state.hasLLM) {
        payload = this.state.apiData[firstIndex].LLM.courses[lastIndex];
      } else if (this.state.hasOther) {
        payload = this.state.apiData[firstIndex].Other.courses[lastIndex];
      }
    } else {
      payload = this.state.apiData[firstIndex][lastIndex];
    }

    if (payload && payload.deleted !== undefined && payload.deleted === 1) {
      payload.deleted = 0;
    }

    try {
      await axiosApi.post("/person/create", payload);
    } catch (err) {
      this.setState({
        errorMessageArray: [
          ...this.state.errorMessageArray,
          {
            customMessage: err.response ? `${err.response?.data}; ` : "",
            errorMessage: `${err.message} while restoring data, please try again.`,
          },
        ],
      });
      this.setState({
        loading: false,
        // errorMessage: `${err.message} while restoring data, please try again. Contact Help Desk if the error persists.`,
      });
      console.error(err);
    }

    try {
      if (this.state.isTableOfDeletedItems) {
        await axiosApi.post(postApi, payload);
      } else {
        await axiosApi.post(postApi, payload);
      }
      if (this.state.onRestore) {
        this.state.onRestore(payload);
      }
      this.setState({ loading: false });
    } catch (err) {
      this.setState({
        errorMessageArray: [
          ...this.state.errorMessageArray,
          {
            customMessage: err.response ? `${err.response?.data}; ` : "",
            errorMessage: `${err.message} while restoring data, please try again.`,
          },
        ],
      });
      this.setState({
        loading: false,
        // errorMessage: `${err.message} while restoring data, please try again. Contact Help Desk if the error persists.`,
      });
      console.error(err);
    }
  }

  async getHistoryForApi(api, index, apiData) {
    try {
      const params = {
        history: 1,
      };
      let { data } = await axiosApi.get(api, { params });
      if (data.length === 0) {
        data = [{ No_Data: "No history exists for this record" }];
      }
      apiData[index] = data;
    } catch (err) {
      const alert = (
        <Alert variant="danger">{`${err.message} while getting history for ${api}. Contact Help Desk if the error persists`}</Alert>
      );
      apiData[index] = [{ No_Data: alert }];
      console.error(err);
    }
  }

  async getApiData() {
    const { apiData } = this.state;
    await Promise.all(
      this.state.apis.map((api, index) =>
        this.getHistoryForApi(api, index, apiData)
      )
    );

    if (apiData[0]?.JD) {
      this.setState({ hasJD: true });
    }

    if (apiData[0]?.LLM) {
      this.setState({ hasLLM: true });
    }

    if (apiData[0]?.Other) {
      this.setState({ hasOther: true});
    }

    this.setState({ loading: false, apiData });
  }

  componentDidMount() {
    this.getApiData();
  }

  setTab(k) {
    this.setState({ activeTab: k });
  }

  buildSubTab(api, index, subTabName, subTabDisplayName, enableHighlight) {
    const lastObject = {};

    let theData = this.state.apiData[index][subTabName];

    if (subTabName === "JD") {
      theData = this.state.apiData[index][subTabName].courses;
    } else if (subTabName === "LLM") {
      theData = this.state.apiData[index][subTabName].courses;
    } else if (subTabName === "Other") {
      theData = this.state.apiData[index][subTabName].courses;
    }

    return (
      <Tab eventKey={subTabName} title={subTabDisplayName}>
        <Table
          className="table-fixed fill-parent"
          responsive
          striped
          bordered
          hover
        >
          <thead>
            <tr>
              {theData.length > 0 ? (
                <>
                  {theData[0]?.No_Data !== undefined || (
                    <React.Fragment>
                      {this.state.disableRestore ? (
                        this.state.disableRestore[index] || <th>Restore</th>
                      ) : (
                        <th>Restore</th>
                      )}
                      <th>updated_by</th>
                      <th>updated_at</th>
                    </React.Fragment>
                  )}
                  {Object.keys(theData[0]).map((item) => {
                    if (
                      item === "sequence" ||
                      item === "updated_by" ||
                      item === "created_at" ||
                      item === "updated_at"
                    ) {
                      return <React.Fragment />;
                    }
                    return <th>{item}</th>;
                  })}
                </>
              ) : (
                ""
              )}
            </tr>
          </thead>
          <tbody>
            {theData?.map((object, i) => {
              return (
                <tr>
                  {theData[0]?.No_Data !== undefined || (
                    <React.Fragment>
                      {this.state.disableRestore ? (
                        this.state.disableRestore[index] || (
                          <td>
                            <Button
                              id={`${index}-${i}`}
                              onClick={(e) => this.handleRestore(e)}
                            >
                              Restore
                            </Button>
                          </td>
                        )
                      ) : (
                        <td>
                          <Button
                            id={`${index}-${i}-${subTabName}`}
                            onClick={(e) => this.handleRestore(e)}
                          >
                            Restore
                          </Button>
                        </td>
                      )}
                      <td>{object.updated_by}</td>
                      <td>{new Date(object.updated_at).toLocaleString()}</td>
                    </React.Fragment>
                  )}
                  {Object.keys(object).map((key) => {
                    const value = object[key];
                    //if the value is an object or array, return stringified values
                    if (typeof value === "object" && value !== null) {
                      if (Array.isArray(value)) {
                        return (
                          <td>
                            {value.map((innerObject) =>
                              JSON.stringify(innerObject)
                            )}
                          </td>
                        );
                      } else {
                        return <td>{JSON.stringify(value)}</td>;
                      }
                    }
                    if (
                      key === "updated_by" ||
                      key === "updated_at" ||
                      key === "created_at" ||
                      key === "sequence"
                    ) {
                      return <React.Fragment />;
                    }
                    if (
                      enableHighlight &&
                      enableHighlight[index] &&
                      i >= theData.length - 1
                    ) {
                      if (value === "No history exists for this record") {
                        return <td>{value}</td>;
                      } else {
                        return <td className="highlight">{value}</td>;
                      }
                    }
                    if (
                      enableHighlight &&
                      enableHighlight[index] &&
                      object[key] !== theData[i + 1][key]
                    ) {
                      lastObject[key] = object[key];
                      return <td className="highlight">{value}</td>;
                    }
                    return <td>{value}</td>;
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Tab>
    );
  }

  buildTab(api, index) {
    const lastObject = {};
    if (this.state.apiData[index].person) {
      let myTabs = [];
      myTabs.push(
        this.buildSubTab(
          api,
          index,
          "person",
          "Person",
          this.props.enableHighlight
        )
      );
      if (this.state.apiData[index].student_information) {
        myTabs.push(
          this.buildSubTab(
            api,
            index,
            "student_information",
            "Student Information",
            this.props.enableHighlight
          )
        );
      }
      if (this.state.apiData[index].law_degree) {
        myTabs.push(
          this.buildSubTab(api, index, "law_degree", "Law Degree", false)
        );
      }
      if (this.state.apiData[index].bar_attempts) {
        myTabs.push(
          this.buildSubTab(api, index, "bar_attempts", "Bar Attempts", false)
        );
      }
      if (this.state.apiData[index].addresses) {
        myTabs.push(
          this.buildSubTab(api, index, "addresses", "Addresses", false)
        );
      }
      if (this.state.apiData[index].pre_law_degrees) {
        myTabs.push(
          this.buildSubTab(api, index, "pre_law_degrees", "Pre-Law Degrees", false)
        );
      }
      if (this.state.apiData[index].language) {
        myTabs.push(
          this.buildSubTab(api, index, "language", "Languages", false)
        );
      }
      if (this.state.apiData[index].faculty_information) {
        myTabs.push(
          this.buildSubTab(
            api,
            index,
            "faculty_information",
            "Faculty Information",
            this.props.enableHighlight
          )
        );
      }
      return (
        <Tab
          eventKey={api}
          title={this.state.displayNames ? this.state.displayNames[index] : api}
        >
          <Tabs>{myTabs}</Tabs>
        </Tab>
      );
    } else if (this.state.hasJD || this.state.hasLLM) {
      let myTabs = [];
      if (this.state.hasJD) {
        myTabs.push(
          this.buildSubTab(api, index, "JD", "JD", this.props.enableHighlight)
        );
      }
      if (this.state.hasLLM) {
        myTabs.push(
          this.buildSubTab(api, index, "LLM", "LLM", this.props.enableHighlight)
        );
      }
      if (this.state.hasOther) {
        myTabs.push(
          this.buildSubTab(api, index, "Other", "Other", this.props.enableHighlight)
        );
      }
      return (
        <Tab
          eventKey={api}
          title={this.state.displayNames ? this.state.displayNames[index] : api}
        >
          <Tabs>{myTabs}</Tabs>
        </Tab>
      );
    }
    return (
      <Tab
        eventKey={api}
        title={this.state.displayNames ? this.state.displayNames[index] : api}
      >
        <Table
          className="table-fixed fill-parent"
          responsive
          striped
          bordered
          hover
        >
          <thead>
            <tr>
              {this.state.apiData[index][0].No_Data !== undefined || (
                <React.Fragment>
                  {this.state.disableRestore ? (
                    this.state.disableRestore[index] || <th>Restore</th>
                  ) : (
                    <th>Restore</th>
                  )}
                  <th>updated_by</th>
                  <th>updated_at</th>
                </React.Fragment>
              )}
              {Object.keys(this.state.apiData[index][0]).map((item) => {
                if (
                  item === "sequence" ||
                  item === "updated_by" ||
                  item === "created_at" ||
                  item === "updated_at"
                ) {
                  return <React.Fragment />;
                }
                return <th>{item}</th>;
              })}
            </tr>
          </thead>
          <tbody>
            {this.state.apiData[index].map((object, i) => {
              return (
                <tr>
                  {this.state.apiData[index][0].No_Data !== undefined || (
                    <React.Fragment>
                      {this.state.disableRestore ? (
                        this.state.disableRestore[index] || (
                          <td>
                            <Button
                              id={`${index}-${i}`}
                              onClick={(e) => this.handleRestore(e)}
                            >
                              Restore
                            </Button>
                          </td>
                        )
                      ) : (
                        <td>
                          <Button
                            id={`${index}-${i}`}
                            onClick={(e) => this.handleRestore(e)}
                          >
                            Restore
                          </Button>
                        </td>
                      )}
                      <td>{object.updated_by}</td>
                      <td>{new Date(object.updated_at).toLocaleString()}</td>
                    </React.Fragment>
                  )}
                  {Object.keys(object).map((key) => {
                    const value = object[key];
                    //if the value is an object or array, return stringified values
                    if (typeof value === "object" && value !== null) {
                      if (Array.isArray(value)) {
                        return (
                          <td>
                            {value.map((innerObject) =>
                              JSON.stringify(innerObject)
                            )}
                          </td>
                        );
                      } else {
                        return <td>{JSON.stringify(value)}</td>;
                      }
                    }
                    if (
                      key === "updated_by" ||
                      key === "updated_at" ||
                      key === "created_at" ||
                      key === "sequence"
                    ) {
                      return <React.Fragment />;
                    }
                    if (
                      this.props.enableHighlight &&
                      this.props.enableHighlight[index] &&
                      i >= this.state.apiData[index].length - 1
                    ) {
                      if (value === "No history exists for this record") {
                        return <td>{value}</td>;
                      } else {
                        return <td className="highlight">{value}</td>;
                      }
                    }
                    if (
                      this.props.enableHighlight &&
                      this.props.enableHighlight[index] &&
                      object[key] !== this.state.apiData[index][i + 1][key]
                    ) {
                      lastObject[key] = object[key];
                      return <td className="highlight">{value}</td>;
                    }
                    return <td>{value}</td>;
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Tab>
    );
  }

  render() {
    if (this.state.loading) {
      return (
        <div className="loadingDiv">
          <h1 className="loadingText" style={{marginBottom: '1em', marginLeft: '0.5em'}}>{this.state.loadingMessage}</h1>
          <Spinner animation={"border"} />
        </div>
      );
    }
    return (
      <div className="fill-parent">
        {this.state.errorMessageArray.length > 0 && (
          <MultipleErrorDisplay
            errorMessageArray={this.state.errorMessageArray}
          />
        )}
        <Tabs
          id="history-tabs"
          className="flex-nowrap history-tabs"
          activeKey={this.state.activeTab}
          onSelect={(k) => this.setTab(k)}
          transition={false}
        >
          {this.state.apis.map((api, index) => this.buildTab(api, index))}
        </Tabs>
      </div>
    );
  }
}

export default History;
