import React from "react";
import { Container, Card, Col, Row } from "react-bootstrap";
import ToolButton from "../tools/ToolButton.jsx";
import ViewExamNumberModal from "../reps_utils/ViewExamNumberModal.jsx";
import ViewGraduateReportModal from "../reps_utils/ViewGraduateReportModal.jsx";
import AllYearReportModal from "../reps_utils/AllYearReportModal.jsx";
import OITGradeReportModal from "../reps_utils/OITGradeReportModal.jsx";
import GradesAlphabeticalModal from "../reps_utils/GradesAlphabeticalModal.jsx";
import ClassMeanReportModal from "../reps_utils/ClassMeanReportModal.jsx";
import PercentileReportModal from "../reps_utils/PercentileReportModal.jsx";
import DeansListModal from "../reps_utils/DeansListModal.jsx";
import api from "../../services/api";
import { getCurrentSemester } from "../../utils/functions.js";

class AdminRecords extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showViewExamNumberModal: false,
      showGraduateReportModal: false,
      showAllYearReportModal: false,
      showOITGradeReportModal: false,
      showGradesAlphabeticalModal: false,
      showClassMeanReportModal: false,
      showDeansListModal: false,
      showPercentileReportModal: false,
    };
    this.toggleViewExamNumbersModal =
      this.toggleViewExamNumbersModal.bind(this);
    this.toggleGraduateReportModal = this.toggleGraduateReportModal.bind(this);
    this.toggleAllYearReportModal = this.toggleAllYearReportModal.bind(this);
    this.downloadAllReports = this.downloadAllReports.bind(this);
    this.toggleOITGradeReportModal = this.toggleOITGradeReportModal.bind(this);
    this.openGradesAlphabeticalModal =
      this.openGradesAlphabeticalModal.bind(this);
    this.hideGradesAlphabeticalModal =
      this.hideGradesAlphabeticalModal.bind(this);
    this.toggleClassMeanReportModal =
      this.toggleClassMeanReportModal.bind(this);
    this.toggleDeansListModal = this.toggleDeansListModal.bind(this);
    this.togglePercentileReportModal =
      this.togglePercentileReportModal.bind(this);
  }

  async downloadAllReports(year) {
    try {
      // const url = `reports/allstoredreports?report=progressreport&studentid=all${year}s`;
      const url = `reports/progressreport?semester=${getCurrentSemester()}&format=pdf&merged=1&year_in_program=${year}`;
      const report = await api.get(url);
      window.open(report.data.url[report.data.url.length - 1]);
    } catch (err) {
      console.error(err);
      this.setState({
        errorMessage: err.response?.data ? err.response.data : err.message,
        customMessage: `Error getting ${year}Ls; `,
      });
    }
  }

  toggleViewExamNumbersModal() {
    this.setState({
      showViewExamNumberModal: !this.state.showViewExamNumberModal,
    });
  }

  toggleGraduateReportModal() {
    this.setState({
      showGraduateReportModal: !this.state.showGraduateReportModal,
    });
  }

  toggleAllYearReportModal() {
    this.setState({
      showAllYearReportModal: !this.state.showAllYearReportModal,
    });
  }

  toggleOITGradeReportModal() {
    this.setState({
      showOITGradeReportModal: !this.state.showOITGradeReportModal,
    });
  }

  hideGradesAlphabeticalModal() {
    this.setState({ showGradesAlphabeticalModal: false });
  }

  openGradesAlphabeticalModal() {
    this.setState({ showGradesAlphabeticalModal: true });
  }

  toggleClassMeanReportModal() {
    this.setState({
      showClassMeanReportModal: !this.state.showClassMeanReportModal,
    });
  }

  toggleDeansListModal() {
    this.setState({ showDeansListModal: !this.state.showDeansListModal });
  }

  togglePercentileReportModal() {
    this.setState({
      showPercentileReportModal: !this.state.showPercentileReportModal,
    });
  }

  render() {
    const { roles } = this.props;
    return (
      <Container className="p-5 bg-white">
        <Row>
          <h1>Documents</h1>
        </Row>
        <Row className="justify-content-center mt-4">
          <Col md={6} className="mb-3">
            <Card className="bg-primary shadow" style={{ color: "white" }}>
              <Card.Body className="tools-card">
                <Row>
                  <h3 style={{ padding: 0 }}>Reports</h3>
                </Row>
                <Row>
                  <ToolButton
                    text="View Exam #'s"
                    icon="pdf"
                    onClick={this.toggleViewExamNumbersModal}
                  />
                </Row>
                <Row>
                  <ToolButton
                    text="View Graduate Report"
                    icon="pdf"
                    onClick={this.toggleGraduateReportModal}
                  />
                </Row>
                <Row>
                  <ToolButton
                    text="Download All Progress Reports"
                    icon="pdf"
                    onClick={this.toggleAllYearReportModal}
                  />
                </Row>
                {(roles.admin || roles.registrar || roles.secretary) && (
                  <>
                    <Row>
                      <ToolButton
                        text="View Class Mean Report"
                        icon="pdf"
                        onClick={this.toggleClassMeanReportModal}
                      />
                    </Row>
                    <Row>
                      <ToolButton
                        text="View Grades Report (Alphabetical)"
                        icon="pdf"
                        onClick={this.openGradesAlphabeticalModal}
                      />
                    </Row>
                    <Row>
                      <ToolButton
                        text="View Dean's List Report"
                        icon="pdf"
                        onClick={this.toggleDeansListModal}
                      />
                    </Row>
                    <Row>
                      <ToolButton
                        text="View Percentile Report"
                        icon="pdf"
                        onClick={this.togglePercentileReportModal}
                      />
                    </Row>
                    <Row>
                      <ToolButton
                        text="View OIT Grade Report"
                        icon="pdf"
                        onClick={this.toggleOITGradeReportModal}
                      />
                    </Row>
                    <Row>
                      <ToolButton
                        text="View Past Generated Reports"
                        disabled={true}
                        tooltipText="Under construction"
                        icon="pdf"
                      />{" "}
                      {/* No implementation of feature yet */}
                    </Row>
                  </>
                )}
              </Card.Body>
            </Card>
          </Col>
          {(roles.admin || roles.registrar) && (
            <Col md={6}>
              <Card className="bg-primary shadow" style={{ color: "white" }}>
                <Card.Body className="tools-card">
                  <Row>
                    <h3 style={{ padding: 0 }}>Other:</h3>
                  </Row>
                  <Row>
                    <ToolButton
                      text="View Rankings"
                      disabled={true}
                      tooltipText="Under construction"
                      icon="pdf"
                    />{" "}
                    {/* No implementation of feature yet */}
                  </Row>
                  <Row>
                    <ToolButton
                      text="View Dean's List Letter"
                      disabled={true}
                      tooltipText="Under construction"
                      icon="pdf"
                    />{" "}
                    {/* No implementation of feature yet */}
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          )}
        </Row>

        <ViewExamNumberModal
          onHide={this.toggleViewExamNumbersModal}
          show={this.state.showViewExamNumberModal}
        />
        <ViewGraduateReportModal
          onHide={this.toggleGraduateReportModal}
          show={this.state.showGraduateReportModal}
        />
        <GradesAlphabeticalModal
          onHide={this.hideGradesAlphabeticalModal}
          show={this.state.showGradesAlphabeticalModal}
        />
        <PercentileReportModal
          onHide={this.togglePercentileReportModal}
          show={this.state.showPercentileReportModal}
        />
        <AllYearReportModal
          onHide={this.toggleAllYearReportModal}
          show={this.state.showAllYearReportModal}
          downloadAllReports={this.downloadAllReports}
        />
        <OITGradeReportModal
          onHide={this.toggleOITGradeReportModal}
          show={this.state.showOITGradeReportModal}
        />
        <ClassMeanReportModal
          onHide={this.toggleClassMeanReportModal}
          show={this.state.showClassMeanReportModal}
        />
        <DeansListModal
          onHide={this.toggleDeansListModal}
          show={this.state.showDeansListModal}
        />
      </Container>
    );
  }
}

export default AdminRecords;
