import React from "react";
import { Button, Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import api from "../../services/api";
import ErrorDisplay from "../ErrorDisplay";

class DeansListModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      semester: 0,
      year: 0,
      program: 0,
      errorMessage: "",
      customMessage: "",
      optional: false,
    };
    this.selectYear = this.selectYear.bind(this);
    this.selectSemester = this.selectSemester.bind(this);
    this.selectProgram = this.selectProgram.bind(this);
    this.selectOptional = this.selectOptional.bind(this);
    this.downloadDeansList = this.downloadDeansList.bind(this);
  }

  getYear() {
    return new Date().getFullYear();
  }

  setYearOptions() {
    const thisYear = this.getYear();
    let options = [];
    for (let i = thisYear; i >= thisYear - 4; i--) {
      options.push(i);
    }
    return options;
  }

  selectYear(e) {
    console.log(e.target.value);
    this.setState({ year: e.target.value });
  }

  selectSemester(e) {
    this.setState({ semester: e.target.value });
  }

  selectProgram(e) {
    this.setState({ program: e.target.value });
  }

  selectOptional(e) {
    this.setState({ optional: e.target.value });
  }

  async downloadDeansList() {
    this.setState({ loading: true });
    
    try {
      const report = await api.get(
        `/reports/deanslist?format=pdf&semester=${this.state.year}${this.state.semester}&year_in_program=${this.state.program}&get_all_students=${this.state.optional}`
      );
      window.open(report.data.url);
    } catch (err) {
      this.setState({
        errorMessage: err.response?.data ? err.response.data : err.message,
        customMessage: "Error downloading report",
      });
    }

    this.setState({ loading: false });

    if (this.state.errorMessage === ""){
      this.props.onHide();
    }
  }

  render() {
    let { show, onHide } = this.props;
    const yearOptions = this.setYearOptions();
    return (
      <Modal show={show} onHide={this.onHide}>
        <Modal.Header>
          <Modal.Title>Dean's List Report</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Select the year and semester of the report you wish to view</p>
          <Row>
            <Col>
              <Form.Select
                id="year"
                onChange={this.selectYear}
                disabled={this.state.loading === true}
              >
                <option value={0}>Select Year</option>
                {yearOptions.map((year) => (
                  <option key={year} value={year}>{year}</option>
                ))}
              </Form.Select>
            </Col>
            <Col>
              <Form.Select
                id="semester"
                onChange={this.selectSemester}
                disabled={this.state.loading === true}
              >
                <option value={0}>Select Semester</option>
                <option value={5}>Fall</option>
                <option value={1}>Winter</option>
                <option value={3}>Spring</option>
                <option value={4}>Summer</option>
              </Form.Select>
            </Col>
          </Row>
          <p>Please select the Year in Program</p>
          <Row>
            <Col>
              <Form.Select
                id="program"
                onChange={this.selectProgram}
                disabled={this.state.loading === true}
              >
                <option value={0}>Select Year in Program</option>
                <option value={1}>1L</option>
                <option value={2}>2L</option>
                <option value={3}>3L</option>
              </Form.Select>
            </Col>
          </Row>
          <p>Please select Optional Parameter</p>
          <Row>
            <Col>
              <Form.Select
                id="optional"
                onChange={this.selectOptional}
                disabled={this.state.loading === true}
              >
                <option value={false}>Select Optional Parameter</option>
                <option value={true}>All Students</option>
              </Form.Select>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          {this.state.loading ? (
            <Button variant="info" disabled={true}>
              <Spinner
                animation={"border"}
                size={"sm"}
                style={{ marginRight: "0.5rem" }}
              />
              Download
            </Button>
          ) : (
            <Button
              variant="info"
              disabled={this.state.year === 0}
              onClick={this.downloadDeansList}
            >
              Download
            </Button>
          )}
          <Button variant="outline-info" onClick={onHide}>
            Cancel
          </Button>
        </Modal.Footer>
        {this.state.errorMessage && (
          <ErrorDisplay errorMessage={this.state.errorMessage} customMessage={this.state.customMessage} />
        )}
      </Modal>
    );
  }
}

export default DeansListModal;
