import React from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { handleImageError } from "../../../utils/functions";
import History from "../../History";
import ProgressReportEntry from "./ProgressReportEntry";
import CourseTable from "./CourseTable";
import BarAttempts from "./BarAttempts";
import { CleanImage } from "../../SafeImage";

export default class DisplayProgressReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      student: this.props.student,
      scrollToCourseHistory: false,
      pic: "",
    };
    this.courseScrollRef = React.createRef();
    this.toggleHistory = this.toggleHistory.bind(this);
    this.toggleCourseHistory = this.toggleCourseHistory.bind(this);
  }

  componentDidMount() {
    const { id } = this.props.student;

    let link;
    if (process.env.REACT_APP_STAGE === "dev") {
      link =
        `https://s3.amazonaws.com/content.law-info.byu.edu-dev/student-pictures/${id}.jpg?` +
        new Date().getTime();
    } else {
      link =
        `https://s3-us-west-2.amazonaws.com/content.law-info.byu.edu/student-pictures/${id}.jpg?` +
        new Date().getTime();
    }

    const pic = link;

    this.setState({ pic });
  }
  componentDidUpdate() {
    if (this.state.scrollToCourseHistory) {
      this.courseScrollRef.current?.scrollIntoView();
    }
  }

  toggleHistory() {
    this.setState({
      showHistory: !this.state.showHistory,
      scrollToCourseHistory: false,
    });
  }

  toggleCourseHistory() {
    this.setState({
      showCourseHistory: !this.state.showCourseHistory,
      scrollToCourseHistory: true,
    });
  }

  render() {
    const { roles } = this.props;
    const isStudOrGrad = roles.student || roles.graduate;
    const student = this.state.student;

    return (
      <React.Fragment>
        <Row>
          <Col>
            <h1 className="mt-4">Academic Progress</h1>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col
            md={12}
            lg={6}
            className="d-flex align-items-center"
            style={{ flexDirection: "column" }}
          >
            <Card className="shadow academic-progress-card mb-3">
              <Card.Body>
                <Row>
                  <Col lg={6} className="academic-progress-col">
                    <CleanImage
                      src={this.state.pic}
                      alt={`student headshot`}
                      placeholder="student_img"
                      style={{
                        display: "block",
                        margin: "auto",
                        maxWidth: "275px",
                      }}
                      className="shadow card-img"
                      onError={(e) => {
                        handleImageError(e, student);
                      }}
                      person={student}
                      fluid
                      rounded
                    />
                  </Col>
                  <Col lg={6} className="academic-progress-col">
                    <ProgressReportEntry
                      label="Name:"
                      data={
                        student?.known_by &&
                        student?.known_by !== student?.last_name
                          ? student?.known_by + " " + student?.last_name
                          : student?.first_name + " " + student?.last_name
                      }
                    />
                    <ProgressReportEntry label="ID:" data={student.id ? student.id : "-"} />
                    <ProgressReportEntry
                      label="Enrollment Status:"
                      data={
                        student.enrollment_status === "NL"
                          ? "Non-Law"
                          : student.enrollment_status === "V"
                          ? "Visiting"
                          : student.enrollment_status === "T"
                          ? "Transfer"
                          : student.enrollment_status === "LLM"
                          ? "LLM"
                          : student.enrollment_status === "W"
                          ? "Withdrawn"
                          : student.enrollment_status === "D"
                          ? "Dismissed"
                          : student.enrollment_status === "G"
                          ? "Graduated"
                          : student.enrollment_status === "JD"
                          ? "JD"
                          : "-"
                      }
                    />
                    <ProgressReportEntry
                      label="Class:"
                      data={student.year_in_program ? student.year_in_program : "-"}
                    />
                    {!this.props.isGraduate ? (
                      <ProgressReportEntry
                        label="Expected Graduation:"
                        data={student.expected_grad_year ? student.expected_grad_year : "-"}
                      />
                    ) : (
                      <>
                        {student?.expected_grad_year?.JD && (
                          <ProgressReportEntry
                            label="Graduated JD:"
                            data={student?.expected_grad_year?.JD}
                          />
                        )}
                        {student?.expected_grad_year?.LLM && (
                          <ProgressReportEntry
                            label="Graduated LLM:"
                            data={student?.expected_grad_year?.LLM}
                          />
                        )}
                      </>
                    )}
                    <ProgressReportEntry
                      label="Skills:"
                      data={
                        student.library_skills == (true || 1 || "1")
                          ? "Yes"
                          : "No"
                      }
                    />
                    <ProgressReportEntry
                      label="Subst Writing:"
                      data={
                        student.substantial_writing == (true || 1 || "1")
                          ? "Yes"
                          : "No"
                      }
                    />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
            {this.props.isGraduate && (
              <BarAttempts student={student} roles={this.props.roles} />
            )}
          </Col>
          <Col md={12} lg={6}>
            <Card className="shadow academic-progress-card mb-3">
              <Card.Body>
                <Row className="mb-3">
                  <Col className="academic-progress-col">
                    {!this.props.roles.operator && (
                      <>
                        <ProgressReportEntry
                          label="Class Rank"
                          className="entry-select"
                          data={student.rank ? student.rank : "-"}
                        />
                        <ProgressReportEntry
                          label="Percentile"
                          className="entry-select"
                          data={
                            student.percentile ? student.percentile : "-"
                          }
                        />
                        <ProgressReportEntry
                          label="CR-Hours"
                          className="entry-select"
                          data={student.credits ? student.credits : "-"}
                        />
                        <ProgressReportEntry
                          label="GD-Points"
                          className="entry-select"
                          data={
                            student.grade_point ? student.grade_point : "-"
                          }
                        />
                        <ProgressReportEntry
                          label="GPA"
                          className="entry-select"
                          data={student.gpa ? student.gpa : "-"}
                        />
                        {!isStudOrGrad && (
                          <ProgressReportEntry
                            label="LGPA"
                            className="entry-select"
                            data={student.long_gpa ? student.long_gpa : "-"}
                          />
                        )}
                      </>
                    )}
                    <ProgressReportEntry
                      className="entry-select"
                      label="Pre-law GPA:"
                      data={student.pre_law_degrees[0]?.pre_law_gpa ? student.pre_law_degrees[0]?.pre_law_gpa : "-"}
                    />
                    <ProgressReportEntry
                      className="entry-select"
                      label="LSAT Score:"
                      data={student.lsat_score ? student.lsat_score : "-"}
                    />
                    <ProgressReportEntry
                      className="entry-select"
                      label="LSAT Index:"
                      data={student.lsat_index ? student.lsat_index : "-"}
                    />
                    <ProgressReportEntry
                      label="Print Percentile:"
                      className="entry-select"
                      data={student.print_percentile ? "Yes" : "No"}
                    />
                    <ProgressReportEntry
                      label="Degree:"
                      className="entry-select"
                      data={
                        student.law_degrees[0]
                          ? student.law_degrees
                              .map((degreeNum) => degreeNum.degree)
                              .join(", ")
                          : "-"
                      }
                    />
                    <ProgressReportEntry
                      label="Joint Degree:"
                      className="entry-select"
                      data={
                        student.year_in_program === "G"
                          ? !student.law_degrees[0]?.joint_degree
                            ? "-"
                            : student.law_degrees[0]?.joint_degree
                          : !student?.joint_degree
                          ? "-"
                          : student?.joint_degree
                      }
                    />
                    <ProgressReportEntry
                      label="Date Received:"
                      className="entry-select"
                      data={
                        student?.law_degrees[0]
                          ? student.law_degrees
                              .map((degreeNum) =>
                                degreeNum.date_degree_received
                                  ? new Date(
                                      degreeNum.date_degree_received
                                    ).toLocaleDateString("en-US", {
                                      timeZone: "UTC",
                                      year: "numeric",
                                      month: "numeric",
                                      day: "numeric",
                                    })
                                  : "N/A"
                              )
                              .join(", ")
                          : "-"
                      }
                    />
                    <ProgressReportEntry
                      label="Expected Honors:"
                      className="entry-select"
                      data={
                        this.props.isGraduate ? (
                          <React.Fragment>
                            {student.law_degrees === ("" || undefined)
                              ? "-"
                              : student.law_degrees
                                  .map((degreeNum) =>
                                    degreeNum.honors === ""
                                      ? "-"
                                      : degreeNum.honors
                                  )
                                  .join(", ")}
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            {student.expected_honors === ""
                              ? "-"
                              : student.expected_honors}
                          </React.Fragment>
                        )
                      }
                    />
                    <ProgressReportEntry
                      label="Exam #:"
                      className="entry-select"
                      data={student.exam_number ? student.exam_number : "-"}
                    />
                    <ProgressReportEntry
                      label="Exception:"
                      className="entry-select"
                      data={
                        student.law_degrees === ("" || undefined || null) ||
                        student.law_degrees.length === 0
                          ? student.honors_exception === 1
                            ? "Yes"
                            : "No"
                          : student.law_degrees
                              .map((degreeNum) =>
                                degreeNum.honors_exception === 1 ? "Yes" : "No"
                              )
                              .join(", ")
                      }
                    />
                    <ProgressReportEntry
                      label="Disciplinary Action:"
                      className="entry-select"
                      data={student.disciplinary ? student.disciplinary : "-"}
                    />
                    <ProgressReportEntry
                      label="Comments:"
                      className="entry-select"
                      data={student.comments ? student.comments : "-"}
                    />
                    <ProgressReportEntry
                      label="Date of Birth:"
                      className="entry-select"
                      data={
                        student.date_of_birth
                          ? new Date(student.date_of_birth).toLocaleDateString(
                              "en-US",
                              {
                                timeZone: "Etc/UTC",
                              }
                            )
                          : "-"
                      }
                    />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        {(this.props.roles.admin || this.props.roles.registrar) &&
          this.props.isGraduate && (
            <Row className="mb-4 mt-2">
              <Col
                className="d-flex justify-content-beginning"
                md={{ span: "auto" }}
              >
                {this.state.showHistory ? (
                  <Button
                    className="my-1 me-1 shadow-button"
                    onClick={this.toggleHistory}
                  >
                    Hide Progress Report History
                  </Button>
                ) : (
                  <Button
                    className="my-1 me-1 shadow-button"
                    onClick={this.toggleHistory}
                  >
                    Show Progress Report History
                  </Button>
                )}
                {this.state.showCourseHistory ? (
                  <Button
                    className="m-1 shadow-button"
                    onClick={this.toggleCourseHistory}
                  >
                    Hide Course History
                  </Button>
                ) : (
                  <Button
                    className="m-1 shadow-button"
                    onClick={this.toggleCourseHistory}
                  >
                    Show Course History
                  </Button>
                )}
              </Col>
            </Row>
          )}
        {(this.props.roles.admin || this.props.roles.registrar) &&
          this.props.isGraduate &&
          this.state.showHistory && (
            <React.Fragment>
              <Card className="shadow mt-4">
                <Card.Header as="h5">
                  Progress Report change history:{" "}
                </Card.Header>
                <Card.Body>
                  <p className="m-0">{student.history}</p>
                  <Row>
                    <Col style={{ maxHeight: "70vh" }}>
                      <History
                        apis={[
                          `/${
                            this.props.isGraduate ? "graduates" : "students"
                          }/${this.state.student.id}`,
                        ]}
                        disableRestore={[true, false]}
                        enableHighlight={[true, false]}
                        onRestore={this.props.updateStudent}
                        displayNames={["Student Data"]}
                      />
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
              <br />
            </React.Fragment>
          )}
        {(this.props.roles.admin || this.props.roles.registrar) &&
          this.props.isGraduate &&
          this.state.showCourseHistory && (
            <Row ref={this.courseScrollRef}>
              <React.Fragment>
                <Col>
                  <Card className="shadow mb-4">
                    <Card.Header as="h5">Course change history: </Card.Header>
                    <Card.Body style={{ height: "80vh" }}>
                      <History
                        apis={[`/student-course/${this.state.student.id}`]}
                        disableRestore={[true]}
                        enableHighlight={[false]}
                        displayNames={["Student Courses"]}
                      />
                    </Card.Body>
                  </Card>
                </Col>
              </React.Fragment>
            </Row>
          )}

        <Row className="mt-3">
          <Col>
            <CourseTable
              roles={this.props.roles}
              courses={student.courses}
              student={this.state.student}
            />
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}
