import React from "react";
import { Modal, Button, Spinner, Form, Col, Row } from "react-bootstrap";
import api from "../../services/api";
import {
    releaseGradesGetCurrentSemester,
  } from "../../utils/functions";
import ErrorDisplay from "../ErrorDisplay";

class GradesAlphabeticalModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      currentSemester: releaseGradesGetCurrentSemester(),
      programYear: "0",
      errorMessage: "",
      customMessage: "",
    };

    this.handleProgramYearChange = this.handleProgramYearChange.bind(this);

    this.viewGradesAlphabetical = this.viewGradesAlphabetical.bind(this);
  }

  async viewGradesAlphabetical() {
    this.setState({ loading: true });

    let apiCall = 
        "/reports/alphalist?format=pdf&semester=" +
        this.state.currentSemester.toString() +
        "&year_in_program=" +
        this.state.programYear.toString();

    try {
      const { data: report } = await api.get(apiCall);

      window.open(report.url);
    } catch (err) {
      this.setState({
        errorMessage: err.response?.data ? err.response.data : err.message,
        customMessage: "Error downloading report",
      });
    }

    this.setState({ loading: false });
    
    if (this.state.errorMessage === ""){
      this.props.onHide();
    }
  }

  handleProgramYearChange(e) {
    const { value } = e.target;
    this.setState({ programYear: value });
  }

  render() {
    let { show, onHide } = this.props;
    return (
      <Modal show={show} onHide={onHide} backdrop="static">
        <Modal.Header>
          <Modal.Title>Grades, Alphabetical</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.state.loading ? (
            <div style={{ display: "flex", alignItems: "center" }}>
              <Spinner
                animation="border"
                style={{ marginRight: "0.5rem" }}
              />
              <div>This could take up to 10 seconds...</div>
            </div>
          ) : (
            <React.Fragment>
              <p>
                Select the year in program to view grades
              </p>

              <Row>
                <Col>
                  <Form.Select
                    id="programYear"
                    value={this.state.programYear}
                    onChange={this.handleProgramYearChange}
                  >
                    <option value="0">Select Program Year</option>
                    <option value="1">1L</option>
                    <option value="2">2L</option>
                    <option value="3">3L</option>
                  </Form.Select>
                </Col>
              </Row>
            </React.Fragment>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="info"
            onClick={this.viewGradesAlphabetical}
            disabled={
              this.state.loading || this.state.done || this.state.programYear === "0"
            }
          >
            View Grades
          </Button>
          <Button
            variant="outline-info"
            onClick={this.props.onHide}
            disabled={this.state.loading}
          >
            Cancel
          </Button>
        </Modal.Footer>
        {this.state.errorMessage && (
          <ErrorDisplay errorMessage={this.state.errorMessage} customMessage={this.state.customMessage} />
        )}
      </Modal>
    );
  }
}

export default GradesAlphabeticalModal;
