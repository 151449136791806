import React from "react";
import { Button, Container, Modal, Spinner } from "react-bootstrap";

import { withRouter } from "react-router-dom";
import StudentRecord from "../../pages/StudentRecord";
import api from "../../services/api";
import { generateCSV, getDevId } from "../../utils/functions";
import StudentDirectoryInfo from "../directories/student_directory/StudentDirectoryInfo";
import ErrorDisplay from "../ErrorDisplay";
import PageNav from "../PageNav";
import RepUtil from "../RepUtil";
import ToolsNav from "../ToolsNav";
import PersonalInfo from "./personal_info/PersonalInfo";
import ProgressReport from "./progress_report/ProgressReport";
import { secureUserToken } from "../auth/secureSessionStorage";

class StudentInformation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      roles: this.props.roles,
      loading: true,
      loadingStudent: false,
      errorMessage: null,
      customMessage: null,
      students: this.props.students ? this.props.students : [],
      selectedStudentId: this.props.match.params.studentId
        ? this.props.match.params.studentId
        : this.props.studentId
        ? this.props.studentId
        : 0,
      selectedStudent: {},
      index: 0,
      edit: false,
      show: false,
      tabs:
        this.props.roles.admin || this.props.roles.registrar
          ? this.props.isGraduate
            ? [
                { value: "progress-report", display: "Progress Report" },
                { value: "information", display: "Personal Information" },
                { value: "student-record", display: "Student Record" },
              ]
            : [
                { value: "progress-report", display: "Progress Report" },
                { value: "information", display: "Personal Information" },
                { value: "student-record", display: "Student Record" },
              ]
          : this.props.roles.student || this.props.roles.graduate
          ? [
              { value: "progress-report", display: "Progress Report" },
              { value: "information", display: "Personal Information" },
              { value: "student-record", display: "My Student Records" },
            ]
          : [
              { value: "progress-report", display: "Progress Report" },
              { value: "information", display: "Personal Information" },
            ],
      currentTab: this.props.match.params.page
        ? this.props.match.params.page
        : this.props.roles.admin || this.props.roles.registrar
        ? "progress-report"
        : "information",
      endpoint: this.props.isGraduate ? "graduates" : "students",
      studentView: false,
      printingRef: "",
    };
    this.studentCourseApi = this.studentCourseApi.bind(this);
    this.handleClickEdit = this.handleClickEdit.bind(this);
    this.handleDeleteModal = this.handleDeleteModal.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.handlePrev = this.handlePrev.bind(this);
    this.handleFirst = this.handleFirst.bind(this);
    this.handleLast = this.handleLast.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.refreshStudentList = this.refreshStudentList.bind(this);
    this.handleTabChange = this.handleTabChange.bind(this);
    this.handleTabChangeFromNavBar = this.handleTabChangeFromNavBar.bind(this);
    this.renderSwitch = this.renderSwitch.bind(this);
    this.closeEdit = this.closeEdit.bind(this);
    this.getStudent = this.getStudent.bind(this);
    this.handleToggleStudentView = this.handleToggleStudentView.bind(this);
    this.downloadStudentInfoCSV = this.downloadStudentInfoCSV.bind(this);

    this.handleReturnToDirectory = this.props.handleReturnToDirectory;
  }
  async studentCourseApi(studentId) {
    let courses = [];
    try {
      const { data } = await api.get(`/student-course/${studentId}`);
      courses = data;
    } catch (err) {
      console.error(err.message);
      this.setState({
        errorMessage: err.response?.data ? err.response.data : err.message,
        customMessage: "Error found in loading student courses: ",
      });
    }
    return courses;
  }
  async getStudent(studentId) {
    const { isGraduate } = this.props;

    let student = {};
    let address = [];
    let degrees = [];
    let languages = [];
    let courses = [];

    this.setState({ loadingStudent: true });

    const studentApi = async () => {
      try {
        const { data } = await api.get(`/${this.state.endpoint}/${studentId}`);
        student = data;
        address = data.addresses;
        degrees = data.pre_law_degrees;
        languages = data.languages;
      } catch (err) {
        console.error(err.message);
        this.setState({
          errorMessage: err.response?.data ? err.response.data : err.message,
          customMessage: "Error found in loading student: ",
        });
      }
    };
    const studentCourseApi = async () => {
      courses = await this.studentCourseApi(studentId);
    };
    this.setState({ errorMessage: null });

    const promiseArray = [studentApi(), studentCourseApi()];

    // if (isGraduate) {
    //   // promiseArray.push(barAttemptApi());
    // }

    await Promise.all(promiseArray);
    student.address = address;
    student.pre_law_degrees = degrees;
    student.languages = languages;
    student.courses = courses;
    student.law_degrees = student.law_degrees ? student.law_degrees : [];
    if (isGraduate) {
      student.bar_attempts = student.bar_attempts ? student.bar_attempts : [];
    }
    this.setState({ selectedStudent: student, loadingStudent: false });
  }

  async componentDidMount() {
    try {
      let students = {};

      // Accept data from directory, or get own data from API
      if (this.props.fromDirectory) {
        students = this.state.students;
        this.setState({ loading: false });
      } else {
        students = await api.get(
          `/${this.state.endpoint}/${
            (process.env.REACT_APP_STAGE === "dev"
              ? getDevId()
              : secureUserToken.id)
          }`
        );
        students = students.data;
      }

      // If a student has been passed in to be selected, set the selected student's info here
      let student = {};

      if (Array.isArray(students)) {
        let selectedStudentIndex = 0;
        if (this.state.selectedStudentId) {
          selectedStudentIndex = students.findIndex(
            (student) =>
              parseInt(student.id) === parseInt(this.state.selectedStudentId)
          );
          if (selectedStudentIndex === -1) selectedStudentIndex = 0;
        }
        student = students[selectedStudentIndex];
      } else {
        student = students;
      }
      this.updateURL(this.state.currentTab, student.id);

      // Get extra data for the selected student
      await this.getStudent(student.id);

      this.setState({
        students,
        selectedStudentId: student.id,
        loading: false,
      });
    } catch (err) {
      this.setState({
        loading: false,
        errorMessage: err.response?.data ? err.response.data : err.message,
        customMessage: "Error found in loading student data: ",
      });
      console.error(err.message);
    }
  }

  async downloadStudentInfoCSV() {
    let array = this.state.students;
    array = array.map((obj) => {
      delete obj.address;
      delete obj.pre_law_degrees;
      delete obj.languages;
      delete obj.courses;
      delete obj.law_degrees;
      delete obj.homeAddress;
      delete obj.permanentAddress;
      return obj;
    });
    generateCSV(array, "student_data.csv");
  }

  closeEdit() {
    this.props.fromDirectory && this.props.enableDirectoryPageNav();
    this.setState({ edit: false });
  }

  async refreshStudentList(student) {
    const { data: students } = await api.get(`/${this.state.endpoint}`);
    const studentId = student ? student.id : students[0].id;
    this.updateURL("progress-report", studentId);
    this.getStudent(studentId);
    this.setState({
      students,
      selectedStudentId: studentId,
      edit: false,
    });
  }

  handleClickEdit() {
    const { edit: currentEditState } = this.state;

    if (this.props.fromDirectory) {
      if (this.state.edit) {
        // to keep filters disabled
        // this.props.enableDirectoryPageNav();
      } else {
        this.props.disableDirectoryPageNav();
      }
    }

    if (
      !currentEditState &&
      (this.state.roles.student || this.state.roles.graduate)
    ) {
      this.setState({ edit: !currentEditState, currentTab: "information" });
      this.updateURL("information", this.state.selectedStudentId);
    } else {
      this.setState({ edit: !currentEditState });
    }
  }

  async handleDelete() {
    if (!this.state.edit) {
      return;
    }
    try {
      await api.delete(
        `/${this.state.endpoint}/${this.state.selectedStudentId}`
      );
    } catch (err) {
      this.setState({
        errorMessage: err.response?.data ? err.response.data : err.message,
        customMessage: "Error found in deleting student: ",
      });
      console.error(err.message);
    }
    this.refreshStudentList();
    this.props.enableDirectoryPageNav();
    this.handleCloseModal();
  }

  handleDeleteModal() {
    this.setState({ show: true });
  }

  handleCloseModal() {
    this.setState({ show: false });
  }

  handleNext() {
    const { students, selectedStudentId } = this.state;

    let index = students.findIndex(({ id }) => id === selectedStudentId);
    if (index >= students.length - 1) {
      return;
    }
    index++;
    const newStudent = students[index];
    const newSelectedStudentId = newStudent.id;
    this.updateURL(this.state.currentTab, newSelectedStudentId);
    this.getStudent(newSelectedStudentId);
    this.setState({ selectedStudentId: newSelectedStudentId, index });
  }

  handlePrev() {
    const { students, selectedStudentId } = this.state;

    let index = students.findIndex(({ id }) => id === selectedStudentId);
    if (index <= 0) {
      return;
    }
    index--;
    const newStudent = students[index];
    const newSelectedStudentId = newStudent.id;
    this.updateURL(this.state.currentTab, newSelectedStudentId);
    this.getStudent(newSelectedStudentId);
    this.setState({ selectedStudentId: newSelectedStudentId, index });
  }

  handleFirst() {
    const { students } = this.state;
    const selectedStudentId = students[0].id;
    this.updateURL(this.state.currentTab, selectedStudentId);
    this.getStudent(selectedStudentId);
    this.setState({
      selectedStudentId,
      index: 0,
    });
  }

  handleLast() {
    const { students } = this.state;
    const selectedStudentId = students[students.length - 1].id;
    this.updateURL(this.state.currentTab, selectedStudentId);
    this.getStudent(selectedStudentId);
    this.setState({
      selectedStudentId,
      index: students.length - 1,
    });
  }

  handleToggleStudentView() {
    if (this.state.studentView) {
      this.setState({ currentTab: "progress-report" });
    } else {
      this.setState({ currentTab: "directory", edit: false });
    }
    this.updateURL("progress-report", this.state.selectedStudentId);
    this.setState({ studentView: !this.state.studentView });
  }

  updateURL(nextPage, nextStudent) {
    const nextURL = `${window.location.protocol}//${window.location.host}/${
      this.props.fromMassGrad
        ? "mass-graduate"
        : this.props.fromMassAdvance
        ? "mass-advance"
        : this.props.fromDirectory
        ? this.props.isGraduate
          ? "graduate-directory"
          : "student-directory"
        : this.props.isGraduate
        ? "graduate-archive"
        : "enrollment"
    }/${nextPage}/${nextStudent}`;
    const nextTitle = document.title;

    // This will replace the current entry in the browser's history, without reloading
    window.history.replaceState({}, nextTitle, nextURL);
  }

  handleTabChange(event) {
    this.updateURL(event.target.value, this.state.selectedStudentId);
    this.setState({ currentTab: event.target.value });
  }

  handleTabChangeFromNavBar(tab) {
    this.updateURL(tab, this.state.selectedStudentId);
    this.setState({ currentTab: tab });
  }

  renderSwitch(currentTab) {
    if (this.state.loadingStudent) {
      return (
        <div
          className={
            this.props.fromDirectory ? "directoryLoading" : "loadingDiv"
          }
        >
          <h1
            className="loadingText"
            style={{ marginBottom: "1em", marginLeft: "0.5em" }}
          >
            Loading Student...
          </h1>
          <Spinner animation={"border"} />
        </div>
      );
    }
    switch (currentTab) {
      case "reports-utils":
        return (
          <RepUtil
            roles={this.state.roles}
            studentId={this.state.selectedStudentId}
            student={this.state.selectedStudent}
            isGraduate={this.props.isGraduate}
            downloadStudentInfoCSV={this.downloadStudentInfoCSV}
            updateStudent={() => this.getStudent(this.state.selectedStudentId)}
          />
        );
      case "progress-report":
        return (
          <ProgressReport
            studentId={this.state.selectedStudentId}
            getStudent={() => this.getStudent(this.state.selectedStudentId)}
            student={this.state.selectedStudent}
            edit={this.state.edit}
            changeEdit={this.closeEdit}
            roles={this.state.roles}
            isGraduate={this.props.isGraduate}
          />
        );
      case "directory":
        return (
          <StudentDirectoryInfo
            refreshStudent
            student={this.state.selectedStudent}
            roles={this.props.roles}
            fromGraduate={this.props.isGraduate}
            fromStudent={!this.props.isGraduate}
          />
        );
      case "student-record":
        return (
          <StudentRecord
            student={this.state.selectedStudent}
            fromDirectory
            studentId={this.state.selectedStudentId}
            roles={this.props.roles}
            fromGraduate={this.props.isGraduate}
            fromStudent={!this.props.isGraduate}
            getStudent={() => this.getStudent(this.state.selectedStudentId)}
            studentCourseApi={() =>
              this.studentCourseApi(this.state.selectedStudentId)
            }
          />
        );
      case "information":
      default:
        return (
          <PersonalInfo
            getStudent={() => this.getStudent(this.state.selectedStudentId)}
            student={this.state.selectedStudent}
            studentId={this.state.selectedStudentId}
            editMode={this.state.edit}
            changeEdit={this.closeEdit}
            roles={this.state.roles}
            refreshStudentList={this.refreshStudentList}
            isGraduate={this.props.isGraduate}
            handleClickEdit={this.handleClickEdit}
          />
        );
    }
  }

  render() {
    const studentsNavigator = {
      actions: {
        edit: this.handleClickEdit,
        delete: this.handleDeleteModal,
        next: this.handleNext,
        prev: this.handlePrev,
        first: this.handleFirst,
        last: this.handleLast,
        handleReturnToDirectory: this.handleReturnToDirectory,
        handlePageChange: this.handleTabChange,
        handleToggleStudentView: this.handleToggleStudentView,
      },
    };
    if (this.state.loading) {
      return (
        <div className="loadingDiv">
          <h1
            className="loadingText"
            style={{ marginBottom: "1em", marginLeft: "0.5em" }}
          >
            Loading Student Data...
          </h1>
          <Spinner animation={"border"} />
        </div>
      );
    }

    return (
      <React.Fragment>
        {!this.props.fromDirectory && (
          <ToolsNav
            currentTab={this.state.currentTab}
            handleTabChange={this.handleTabChangeFromNavBar}
            roles={this.props.roles}
            disabled={this.state.edit}
          />
        )}
        <Container className="my-4 mt-0 mb-0">
          {(this.state.roles.admin || this.state.roles.registrar) && (
            <Modal show={this.state.show} onHide={this.handleCloseModal}>
              <Modal.Header closeButton>Delete Student</Modal.Header>
              <Modal.Body>
                Are you sure you want to delete this student?
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={this.handleCloseModal}>
                  Cancel
                </Button>
                <Button variant="danger" onClick={this.handleDelete}>
                  Delete
                </Button>
              </Modal.Footer>
            </Modal>
          )}
          <div className="px-5 mt-0">
            {this.props.fromDirectory && (
              <PageNav
                fromDirectory={this.props.fromDirectory}
                fromMassAdvance={this.props.fromMassAdvance}
                fromMassGrad={this.props.fromMassGrad}
                pageName={
                  this.props.isGraduate ? "Graduate Archive" : "Student"
                }
                currentPage={this.state.currentTab}
                options={this.state.tabs}
                studentsNavigator={studentsNavigator}
                handleTabChange={this.handleTabChange}
                edit={this.state.edit}
                roles={this.props.roles}
                disableEdit={
                  this.state.errorMessage ||
                  this.state.currentTab === "reports-utils" ||
                  this.state.currentTab === "directory" ||
                  this.state.currentTab === "student-record"
                }
                //nonEditable={this.props.isGraduate}
                studentView={this.state.studentView}
                thisIsRef={() => this.componentRef}
              />
            )}
            {this.state.errorMessage ? (
              <ErrorDisplay
                errorMessage={this.state.errorMessage}
                customMessage={this.state.customMessage}
              />
            ) : (
              <div
                ref={(el) => {
                  this.componentRef = el;
                }}
              >
                {this.renderSwitch(this.state.currentTab)}
              </div>
            )}
          </div>
        </Container>
      </React.Fragment>
    );
  }
}

export default withRouter(StudentInformation);
