import {
  faCheck,
  faInfoCircle,
  faSort,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Nav,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import MassGraduationModal from "../../components/graduation_evaulation/MassGraduationInfoModal";
import api from "../../services/api";
import StudentInformation from "../records/StudentInformation";
import MassAdvanceSubmitModal from "./MassAdvanceSubmitModal";

const renderTooltip = (props) => (
  <Tooltip id="button-tooltip" {...props}>
    Hold shift to multi-select
  </Tooltip>
);

class MassAdvanceTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fieldsToShow: [
        "Advance",
        "Name",
        "Year",
        "Substantial Writing",
        "Library Skills",
        "Info",
        "Comments",
        "Id",
      ],
      advanceCheck: false,
      allData: [],
      isLoaded: false,
      currentStudentModal: "",
      currentSelectedStudent: "",
      showRequirementsModal: false,
      yearinprogramfilter: "",
      showAdvanceSubmitModal: false,
      studentsToAdvance: [],
      editAll: false,
      editableStudents: [],
      showChecked: false,
      lastBoxChecked: {},
      advanceSortAscending: true,
      nameSortAscending: true,
      yearSortAscending: true,
      subWritingAscending: true,
      librarySkillsAscending: true,
      idAscending: true,
      commentsAscending: true,
      showComments: false,
      showId: false,
      showIndividualStudent: false,
      page: "mass-advance",
    };
    this.populateData = this.populateData.bind(this);
    this.handleclick = this.handleclick.bind(this);
    this.toggleRequirementsModal = this.toggleRequirementsModal.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handleAdvanceCheck = this.handleAdvanceCheck.bind(this);
    this.handleAdvanceSubmitClick = this.handleAdvanceSubmitClick.bind(this);
    this.toggleAdvanceSubmitModal = this.toggleAdvanceSubmitModal.bind(this);
    this.getCheckedStudentsToAdvance =
      this.getCheckedStudentsToAdvance.bind(this);
    this.handleSingleEditCancel = this.handleSingleEditCancel.bind(this);
    this.handleCheckDeselectAll = this.handleCheckDeselectAll.bind(this);
    this.handleSort = this.handleSort.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.getStudent = this.getStudent.bind(this);
    this.toggleRequirementsModal = this.toggleRequirementsModal.bind(this);
    this.toggleComments = this.toggleComments.bind(this);
    this.handleDisplayClick = this.handleDisplayClick.bind(this);
    this.updateURL = this.updateURL.bind(this);
    this.handleDisplayClick = this.handleDisplayClick.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.disablePageNav = this.disablePageNav.bind(this);
    this.enablePageNav = this.enablePageNav.bind(this);
    this.toggleId = this.toggleId.bind(this);
    this.handleCheckSelectAll = this.handleCheckSelectAll.bind(this);
  }

  disablePageNav() {
    this.setState({ disablePageNav: true });
  }

  enablePageNav() {
    this.setState({ disablePageNav: false });
  }
  handleDisplayClick(element) {
    this.updateURL(element.id);

    this.setState({
      storeFormat: this.state.currentFormat,
      currentFormat: "Display",
      selectedData: element,
      disablePaginator: true,
      showPopUpWarning: false,
    });
    this.setState({ showIndividualStudent: !this.state.showIndividualStudent });
  }

  handleCancel() {
    this.updateURL(false);
    this.setState({
      currentFormat: this.state.storeFormat,
      storeFormat: "",
      selectedData: {},
      disablePaginator: false,
    });

    this.setState({ showIndividualStudent: !this.state.showIndividualStudent });
    //Once serverless is setup, we will need to handle repopulating checks while
    //populating the data on return from student information component in the populate data api call
    // this.populateData();
  }

  updateURL(nextId) {
    if (!this.props.fromFaculty) {
      nextId = false;
    }
    let nextURL = "";
    if (
      nextId &&
      (this.props.roles.admin ||
        this.props.roles.registrar ||
        this.props.roles.operator)
    ) {
      nextURL = `${window.location.protocol}//${window.location.host}/${this.state.page}/${nextId}`;
    } else {
      nextURL = `${window.location.protocol}//${window.location.host}/${this.state.page}`;
    }
    const nextTitle = document.title;
    // This will replace the current entry in the browser's history, without reloading
    window.history.replaceState({}, nextTitle, nextURL);
  }

  toggleComments() {
    this.setState({ showComments: !this.state.showComments });
  }

  toggleId() {
    this.setState({ showId: !this.state.showId });
  }

  toggleRequirementsModal() {
    this.setState({
      showRequirementsModal: !this.state.showRequirementsModal,
    });
  }

  async getStudent(id) {
    try {
      let studentid = id.toString();
      let { data: elementDataArray } = await api.get(
        `/students/graduation-eval/` + studentid,
        {}
      );
      this.setState({ currentStudentModal: elementDataArray });
      this.toggleRequirementsModal();
    } catch (err) {
      console.error(err.message);
    }
  }

  async componentDidMount() {
    await this.populateData();
    if (this.props.match.params.byuId && this.props.match.params.page) {
      const selectedData = this.state.allData.find(
        (data) => parseInt(data.id) === parseInt(this.props.match.params.byuId)
      );
      if (
        selectedData &&
        (this.props.roles.admin ||
          this.props.roles.registrar ||
          this.props.roles.operator)
      ) {
        this.handleDisplayClick(selectedData);
      } else {
        this.updateURL(false);
      }
    }
  }

  handleSort(field) {
    switch (field) {
      case "Year":
        if (this.state.yearSortAscending === false) {
          this.sortAscending("year_in_program");
        } else {
          this.sortDecending("year_in_program");
        }
        this.setState({ yearSortAscending: !this.state.yearSortAscending });
        break;
      case "Advance":
        if (this.state.advanceSortAscending === true) {
          this.sortAscending("checkedForAdvance");
        } else {
          this.sortDecending("checkedForAdvance");
        }
        this.setState({
          advanceSortAscending: !this.state.advanceSortAscending,
        });
        break;
      case "Name":
        if (this.state.nameSortAscending === true) {
          this.sortAscending("last_name");
        } else {
          this.sortDecending("last_name");
        }
        this.setState({ nameSortAscending: !this.state.nameSortAscending });
        break;
      case "Substantial Writing":
        if (this.state.subWritingAscending === true) {
          this.sortAscending("substantial_writing");
        } else {
          this.sortDecending("substantial_writing");
        }
        this.setState({ subWritingAscending: !this.state.subWritingAscending });
        break;
      case "Library Skills":
        if (this.state.librarySkillsAscending === true) {
          this.sortAscending("library_skills");
        } else {
          this.sortDecending("library_skills");
        }
        this.setState({
          librarySkillsAscending: !this.state.librarySkillsAscending,
        });
        break;
      case "Comments":
        if (this.state.commentsAscending === true) {
          this.sortAscending("comments");
        } else {
          this.sortDecending("comments");
        }
        this.setState({
          commentsAscending: !this.state.commentsAscending,
        });
        break;
      case "Id":
        if (this.state.idAscending === true) {
          this.sortAscending("id");
        } else {
          this.sortDecending("id");
        }
        this.setState({
          idAscending: !this.state.idAscending,
        });
        break;
      default:
        break;
    }
    this.setState({ allData: this.state.allData });
  }

  sortAscending(key) {
    this.state.allData.sort(function (a, b) {
      var x = a[key];
      var y = b[key];
      return x === null ? 1 : y === null ? -1 : x > y ? -1 : x < y ? 1 : 0;
    });
  }

  sortDecending(key) {
    this.state.allData.sort(function (a, b) {
      var x = a[key];
      var y = b[key];
      return x === null ? 1 : y === null ? -1 : x < y ? -1 : x > y ? 1 : 0;
    });
  }

  handleCheckDeselectAll() {
    this.state.allData.forEach((student) => {
      if (student.checkedForAdvance) {
        student.checkedForAdvance = false;
      }
    });
    this.setState({ allData: this.state.allData });
  }

  handleCheckSelectAll() {
    this.state.allData.forEach((student) => {
      if (
        student.year_in_program === this.state.yearinprogramfilter ||
        this.state.yearinprogramfilter === ""
      )
        student.checkedForAdvance = true;
    });
    this.setState({ allData: this.state.allData });
  }

  async handleSingleEditCancel(student) {
    let editStudent = this.state.allData;
    editStudent.forEach((item) => {
      if (item.id === student.id) {
        item.singleEdit = !item.singleEdit;
      }
    });
    let filteredStudents = [];
    this.state.editableStudents.forEach((item) => {
      if (item.id !== student.id) {
        filteredStudents.push(item);
      }
    });
    await this.setState({
      allData: editStudent,
      editableStudents: filteredStudents,
    });
  }

  async handleAdvanceCheck(e) {
    let editStudent = this.state.allData;
    editStudent.forEach((item) => {
      if (item.id === e.target.value) {
        item.checkedForAdvance = !item.checkedForAdvance;
      }
    });
    await this.setState({
      allData: editStudent,
      lastIDChecked: e.target.value,
    });
  }

  //This will handle a shift key press to select all students inbetween the last box checked.
  handleKeyPress(e) {
    if (e.shiftKey === true) {
      let editStudent = this.state.allData;
      let foundFirstCheck = false;
      let done = false;
      editStudent.forEach((item) => {
        if (
          foundFirstCheck === true &&
          done === false &&
          item.id !== e.target.value &&
          item.id !== this.state.lastIDChecked &&
          (item.year_in_program === this.state.yearinprogramfilter ||
            this.state.yearinprogramfilter === "")
        ) {
          item.checkedForAdvance = !item.checkedForAdvance;
        }

        if (
          (item.id === this.state.lastIDChecked ||
            item.id === e.target.value) &&
          foundFirstCheck === true
        ) {
          done = true;
        }

        if (
          item.id === this.state.lastIDChecked &&
          foundFirstCheck === false &&
          foundFirstCheck === false
        ) {
          foundFirstCheck = true;
        } else if (item.id === e.target.value && foundFirstCheck === false) {
          foundFirstCheck = true;
        }
      });
      this.setState({ allData: editStudent, lastIDChecked: e.target.value });
    }
  }

  async handleFilterChange(e) {
    let [filter] = e.target.id.split("-");
    let value = e.target.value;
    switch (filter) {
      case "year":
        this.setState({ yearinprogramfilter: value });
        break;
      case "checked":
        this.setState({ showChecked: !this.state.showChecked });
        break;
      default:
        break;
    }
  }

  async handleclick(id, student) {
    await this.getStudent(id);
    await this.setState({ currentSelectedStudent: student });
  }

  async populateData() {
    let { data: elementDataArray } = await api.get(`/students/directory`, {});
    await elementDataArray.forEach((data) => {
      if (data.known_by && data.known_by !== data.last_name) {
        data.display_name = data.known_by + " " + data.last_name;
      } else {
        data.display_name = data.first_name + " " + data.last_name;
      }
      data.checkedForAdvance = false;
      data.singleEdit = false;
    });

    let stateArray = [];
    //delete all the not wanted data from all data
    await elementDataArray.forEach((data) => {
      if (data.year_in_program === "2" || data.year_in_program === "1") {
        stateArray.push(data);
      }
    });

    this.setState({ allData: stateArray });
    this.setState({ isLoaded: true });
  }

  toggleAdvanceSubmitModal() {
    this.setState({
      showAdvanceSubmitModal: !this.state.showAdvanceSubmitModal,
    });
  }

  async handleAdvanceSubmitClick() {
    await this.getCheckedStudentsToAdvance();
    await this.toggleAdvanceSubmitModal();
  }

  async getCheckedStudentsToAdvance() {
    this.setState({ studentsToAdvance: [] });
    let arr = [];
    this.state.allData.forEach((student) => {
      if (student.checkedForAdvance) {
        arr.push(student);
      }
    });
    await this.setState({ studentsToAdvance: arr });
  }

  render() {
    const DisplayData = this.state.allData.map((student) => {
      return (this.state.showChecked === true &&
        student.checkedForAdvance === true) ||
        (this.state.showChecked === false &&
          (student.year_in_program === this.state.yearinprogramfilter ||
            (this.state.yearinprogramfilter === "" &&
              student.year_in_program !== "3" &&
              student.year_in_program !== "NL" &&
              student.year_in_program !== "Select" &&
              student.year_in_program !== "G"))) ? (
        <tr>
          <td width="8%">
            <OverlayTrigger
              placement="bottom"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip}
            >
              <Form.Check
                id={student.id}
                value={student.id}
                checked={student.checkedForAdvance === true}
                onChange={this.handleAdvanceCheck}
                onMouseUp={this.handleKeyPress}
                role="button"
              ></Form.Check>
            </OverlayTrigger>
          </td>

          <td
            onClick={() => this.handleDisplayClick(student)}
            className="clickable"
          >
            {student.display_name}
          </td>

          <>
            <td
              onClick={() => this.handleDisplayClick(student)}
              className="clickable"
            >
              {student.year_in_program !== "LLM" &&
              student.year_in_program !== "NL" &&
              student.year_in_program !== "G" &&
              student.year_in_program !== "Select"
                ? student.year_in_program + "L"
                : student.year_in_program}
            </td>
            <td
              onClick={() => this.handleDisplayClick(student)}
              className="clickable"
            >
              {student.substantial_writing === 1 ? (
                <FontAwesomeIcon style={{ color: "228c22" }} icon={faCheck} />
              ) : (
                <FontAwesomeIcon style={{ color: "bb1919" }} icon={faTimes} />
              )}
            </td>
            <td
              onClick={() => this.handleDisplayClick(student)}
              className="clickable"
            >
              {student.library_skills === 1 ? (
                <FontAwesomeIcon style={{ color: "228c22" }} icon={faCheck} />
              ) : (
                <FontAwesomeIcon style={{ color: "bb1919" }} icon={faTimes} />
              )}
            </td>
            <td>
              <Button
                className="m-0 p-0"
                size="m"
                variant="success"
                value={student.id}
                onClick={this.handleclick.bind(this, student.id, student)}
              >
                <FontAwesomeIcon
                  icon={faInfoCircle}
                  style={{ color: "002e5d" }}
                />
              </Button>
            </td>
            {this.state.showComments ? <td>{student.comments}</td> : ""}
            {this.state.showId ? <td>{student.id}</td> : ""}
          </>
        </tr>
      ) : (
        ""
      );
    });

    return this.state.isLoaded ? (
      <React.Fragment>
        <MassGraduationModal
          show={this.state.showRequirementsModal}
          toggleForceGradModal={this.toggleRequirementsModal}
          incompleteGradInfo={this.state.currentStudentModal}
          student={this.state.currentSelectedStudent}
          fromMassAdvance={true}
        />
        <MassAdvanceSubmitModal
          show={this.state.showAdvanceSubmitModal}
          studentsToAdvance={this.state.studentsToAdvance}
          toggleAdvanceModal={this.toggleAdvanceSubmitModal}
          populateData={this.populateData}
        />
        <Row className="no-margin">
          <Col mxs={12} md={5} lg={4} xl={3} className="sidebar">
            <Nav>
              <Container className="p-0">
                <Card className="shadow">
                  <Card.Header as={"h4"}>Mass Advance</Card.Header>
                  <Card.Body>
                    <p>
                      To select or deselect multiple students at once for
                      advancement, select one student, and hold shift when
                      selecting the next student to select all students
                      inbetween.
                    </p>
                    <Row className="py-1">
                      <Col>
                        <h5 className="p-0 m-0">Select Year:</h5>
                      </Col>
                      {/* Debating adding a full edit all option here */}
                      <Col>
                        <Form.Group>
                          <div className="text-photo">
                            <Form.Check
                              className="text-photo-item item-switch"
                              type="switch"
                              id="checked"
                              onChange={this.handleFilterChange}
                            />
                            <Form.Label className="text-photo-item">
                              Show All Selections
                            </Form.Label>
                          </div>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className="py-1 px-0 align-items-center">
                      <Col xs={6}>
                        <Form.Select
                          id="year-in-program"
                          onChange={this.handleFilterChange}
                          defaultValue={this.state.yearinprogramfilter}
                          value={this.state.yearinprogramfilter}
                        >
                          <option value="">All Students</option>
                          <option value="1">1L</option>
                          <option value="2">2L</option>
                        </Form.Select>
                      </Col>
                    </Row>
                    <p></p>
                    <Row className="py-1 px-0 justify-content-center">
                      <Col xs={5}>
                        <Button
                          className="fill-parent"
                          variant="info"
                          onClick={this.handleAdvanceSubmitClick.bind(this)}
                        >
                          Advance
                        </Button>
                      </Col>
                      <Col>
                        <div
                          style={{
                            justifyContent: "center",
                            display: "flex",
                          }}
                        >
                          <Button
                            variant="primary"
                            onClick={this.handleCheckSelectAll}
                            className="float-end"
                            style={{ marginRight: 3 }}
                          >
                            Select All
                          </Button>
                          <Button
                            variant="danger"
                            onClick={this.handleCheckDeselectAll}
                            className="float-end"
                            style={{ marginRight: 3 }}
                          >
                            Deselect All
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Container>
            </Nav>
            <Card style={{ marginTop: 10 }} className="shadow">
              <Card.Header>
                <strong>Table Fields</strong>
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col>
                    {/* {this.state.availableFields[0].map((field) => (
                      <Form.Check
                        type="checkbox"
                        label={field.display_name}
                        onChange={this.handleFieldChange.bind(this, field)}
                        checked={
                          this.state.fieldsToShow?.filter(
                            (e) => e.display_name === field.display_name
                          ).length > 0
                            ? true
                            : false
                        }
                      />
                    ))} */}
                    <Form.Check
                      type="checkbox"
                      label={"Comments"}
                      onChange={this.toggleComments}
                      checked={this.state.showComments}
                    />
                    <Form.Check
                      type="checkbox"
                      label={"Id"}
                      onChange={this.toggleId}
                      checked={this.state.showId}
                    />
                  </Col>
                  <Col>
                    {/* {this.state.availableFields[1].map((field) => (
                      <Form.Check
                        type="checkbox"
                        label={field.display_name}
                        onChange={this.handleFieldChange.bind(this, field)}
                        checked={
                          this.state.fieldsToShow?.filter(
                            (e) => e.display_name === field.display_name
                          ).length > 0
                            ? true
                            : false
                        }
                      />
                    ))} */}
                    {/* <Button
                      variant="danger"
                      onClick={this.handleDeselect}
                      style={{ marginTop: 10 }}
                    >
                      Deselect All
                    </Button> */}
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col
            xs={12}
            md={7}
            lg={8}
            xl={9}
            className="no-margin-but-padding directory-display-wrapper"
          >
            {!this.state.showIndividualStudent ? (
              <React.Fragment>
                <Card className="directory-shadow">
                  <Table striped bordered hover responsive className="mb-0">
                    <thead>
                      <tr>
                        {this.state.fieldsToShow.map((field) => (
                          <>
                            {field === "Comments" ? (
                              <>
                                {this.state.showComments ? (
                                  <th>
                                    {field}{" "}
                                    <Button
                                      className="m-0 p-0"
                                      size="sm"
                                      variant="success"
                                      onClick={this.handleSort.bind(
                                        this,
                                        field
                                      )}
                                    >
                                      <FontAwesomeIcon
                                        icon={faSort}
                                        style={{ color: "002e5d" }}
                                      />
                                    </Button>
                                  </th>
                                ) : (
                                  ""
                                )}
                              </>
                            ) : field === "Id" ? (
                              <>
                                {this.state.showId ? (
                                  <th>
                                    {field}{" "}
                                    <Button
                                      className="m-0 p-0"
                                      size="sm"
                                      variant="success"
                                      onClick={this.handleSort.bind(
                                        this,
                                        field
                                      )}
                                    >
                                      <FontAwesomeIcon
                                        icon={faSort}
                                        style={{ color: "002e5d" }}
                                      />
                                    </Button>
                                  </th>
                                ) : (
                                  ""
                                )}
                              </>
                            ) : (
                              <th>
                                {field}{" "}
                                <Button
                                  className="m-0 p-0"
                                  size="sm"
                                  variant="success"
                                  onClick={this.handleSort.bind(this, field)}
                                >
                                  <FontAwesomeIcon
                                    icon={faSort}
                                    style={{ color: "002e5d" }}
                                  />
                                </Button>
                              </th>
                            )}
                          </>
                        ))}
                      </tr>
                    </thead>
                    <tbody>{DisplayData}</tbody>
                  </Table>
                </Card>
              </React.Fragment>
            ) : (
              <StudentInformation
                isGraduate={this.props.fromGraduate}
                fromDirectory
                fromMassAdvance={"Return to Mass Advance"}
                disableDirectoryPageNav={this.disablePageNav}
                enableDirectoryPageNav={this.enablePageNav}
                handleReturnToDirectory={this.handleCancel}
                students={this.state.allData}
                studentId={this.state.selectedData.id}
                roles={this.props.roles}
              />
            )}
          </Col>
        </Row>
      </React.Fragment>
    ) : (
      <div className="loadingDiv">
        <h1 className="loadingText" style={{marginBottom: '1em', marginLeft: '0.5em'}}>Loading Data...</h1>
        <Spinner animation={"border"} />
      </div>
    );
  }
}

export default MassAdvanceTable;
