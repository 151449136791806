import React from 'react';
import { Card, Row } from 'react-bootstrap';
import ToolButton from './ToolButton.jsx';
import AssignExamNumbersModal from '../reps_utils/AssignExamNumberModal';

class DatabaseTools extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showExamNumberModal: false,
      showViewExamNumberModal: false,
    };
    this.openExamNumbersModal = this.openExamNumbersModal.bind(this);
    this.hideExamNumbersModal = this.hideExamNumbersModal.bind(this);
  }

  openExamNumbersModal() {
    this.setState({
      showExamNumberModal: true,
      showViewExamNumberModal: false,
    });
  }

  hideExamNumbersModal() {
    this.setState({ showExamNumberModal: false });
  }

  render() {

    if (!this.props.roles.registrar && !this.props.roles.admin) {
      return (
        <Card className='bg-primary shadow' style={{ color: "white" }}>
          <Card.Body className="tools-card">
            <h3>Database Tools</h3>
            <Row>
              <ToolButton text="Add New Student" to="/create-student"/>
            </Row>
          </Card.Body>
        </Card>
      )
    }

    return (
      <Card className='bg-primary shadow' style={{ color: "white" }}>
        {this.props.roles.admin && (
          <AssignExamNumbersModal
            onHide={this.hideExamNumbersModal}
            show={this.state.showExamNumberModal}
          />
        )}
        <Card.Body className="tools-card">
          <h3>Database Tools</h3>
          <Row>
            <ToolButton text="Add New User" icon="user" to="/create-user"/>
          </Row>
          <Row>
            <ToolButton text="Add New Student" icon="user" to="/create-student"/>
          </Row>
          {this.props.roles.admin && (
            <Row>
              <ToolButton text="Edit Departments" icon="building" to="/departments"/>
            </Row>
          )}
          <Row>
            <ToolButton text="Deleted Students" icon="deleted" to="/deleted-students"/>
          </Row>
          {this.props.roles.admin && (
            <>
              <Row>
                <ToolButton text="Deleted Faculty" icon="deleted" to="/deleted-faculty"/>
              </Row>
              <h5 style={{ textDecoration: "underline" }}>Other Tools:</h5>
              <Row>
                <ToolButton text="Assign Exam #" icon="utility" onClick={() => this.setState({ showExamNumberModal: true })}/>
              </Row>
            </>
          )}
        </Card.Body>
      </Card>
    )
  }
  
}

export default DatabaseTools;