import React from "react";
import { Button, Modal } from "react-bootstrap";

class AddSoundModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      readerResult: "",
      filename: "",
    };

    this.fileReader = this.fileReader.bind(this);
  }

  /**
   * Reads a file from the input element with the id "image" and converts it to a data URL.
   *
   * @return {void}
   */
  fileReader() {
    var fileInput = document.getElementById("image");
    var reader = new FileReader();
    reader.readAsDataURL(fileInput.files[0]);

    this.setState({ filename: fileInput.files[0].name });

    var onloader = function () {
      this.setState({ readerResult: reader.result });
    };
    reader.onload = onloader.bind(this);

    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  }

  render() {
    let { show, onHide, addSound } = this.props;

    return (
      <Modal show={show} onHide={this.onHide}>
        <Modal.Header>
          <Modal.Title>Add Pronunciation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* {message} */}
          <div>
            <input
              type="file"
              id="image"
              name="sound"
              onChange={this.fileReader}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="info"
            onClick={() => {
              this.props.selectSound(
                this.state.readerResult,
                this.state.filename
              );
              this.props.addSound();
            }}
          >
            Add
          </Button>
          <Button variant="outline-info" onClick={onHide}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default AddSoundModal;
