import React from "react";
import { Button, Col, Row, Spinner, Tooltip } from "react-bootstrap";
import api from "../../services/api";
import ConfirmDeleteModal from "../ConfirmDeleteModal.jsx";
import ErrorDisplay from "../ErrorDisplay.jsx";
import DisplayFacultyInformation from "./DisplayFacultyInformation.jsx";
import EditFacultyInformation from "./EditFacultyInformation.jsx";
import ToolsNav from "../ToolsNav.jsx";
import FacultyTools from "../tools/FacultyTools";
import AdminTools from "../tools/AdminTools";
import AdminRecords from "../records/AdminRecords";
import { secureUserToken } from "../auth/secureSessionStorage";
import { getDevId } from "../../utils/functions";

class FacultyInformation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      edit: false,
      person: {},
      personID:
        (sessionStorage.getItem("devRoles") ? getDevId() : secureUserToken.id),
      editablePerson: {},
      loadingMessage: "Loading...",
      loading: true,
      errorMessage: null,
      customMessage: null,
      isLoadingError: false,
      facultyEdited: false,
      categoryEdited: false,
      deaultCategories: [],
      showDeleteModal: false,
      isAuthorized:
        !props.fromDirectory || props.roles.admin || props.roles.operator,
      currentTab: this.getCurrentTabFromUrl(),
    };
    this.deleteMessage =
      "Are you sure you want to delete this record?  Remember, you can mark the record as inactive to hide it from all directories.";
    this.changeEdit = this.changeEdit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCategoryChange = this.handleCategoryChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.getFaculty = this.getFaculty.bind(this);
    this.handleRestore = this.handleRestore.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
    this.handlePhotoSave = this.handlePhotoSave.bind(this);
    this.handlePhotoChange = this.handlePhotoChange.bind(this);
    this.handleTabChangeFromNavBar = this.handleTabChangeFromNavBar.bind(this);
    this.updateURL = this.updateURL.bind(this);
    // this.handleSavePhotoChange = this.handleSavePhotoChange.bind(this);
  }

  /**
   * Retrieves the current tab from the URL.
   *
   * @return {string} The current tab extracted from the URL.
   */
  getCurrentTabFromUrl() {
    const pathName = window.location.pathname;
    const pathParts = pathName.split("/");
    return pathParts[pathParts.length - 1];
  }

  async componentDidMount() {
    this.getFaculty();

    this.setState({ errorMessage: null });
    await Promise.all([this.getFaculty(), this.getDepartmentOptions()]);
    await Promise.all([this.getFaculty(), this.getCategoryOptions()]);
    this.setState({ loading: false });
  }

  /**
   * Retrieves the faculty information and sets it in the state.
   *
   * @return {Promise<void>}
   */
  async getFaculty() {
    if (this.props.fromDirectory) {
      this.setState({ person: this.props.person });
      return;
    }
    try {
      if (this.props.roles.faculty) {
        let { data: person } = await api.get(`/faculty/${this.state.personID}`);
        this.setState({ person });
      } else {
        let { data: person } = await api.get(`/person/${this.state.personID}`);
        this.setState({ person });
      }
    } catch (err) {
      this.setState({
        errorMessage: err.response?.data ? err.response.data : err.message,
        customMessage: "Error found in loading Faculty: ",
        isLoadingError: true,
      });
    }
  }

  /**
   * Retrieves a list of all departments and sets it in the state.
   *
   * @return {Promise<void>} A promise that resolves when the department options are retrieved.
   */
  async getDepartmentOptions() {
    try {
      const { data: departments } = await api.get("/departments");
      this.setState({
        departments: departments,
      });
    } catch (err) {
      console.error(err);
      this.setState({
        errorMessage: err.response?.data ? err.response.data : err.message,
        customMessage: "Error found in loading departments: ",
      });
    }
  }

  /**
   * Retrieves a list of all faculty category options and sets it in the state.
   *
   * @return {Promise<void>} - Resolves when the category options are successfully retrieved.
   */
  async getCategoryOptions() {
    try {
      const { data: category } = await api.get("/faculty-category");
      this.setState({
        category: category.categories,
      });
    } catch (err) {
      console.error(err);
      this.setState({
        errorMessage: err.response?.data ? err.response.data : err.message,
      });
    }
  }

  /**
   * Toggles the showDeleteModal state to true or false.
   *
   * @return {void}
   */
  toggleDeleteModal() {
    this.setState({ showDeleteModal: !this.state.showDeleteModal });
  }

  /**
   * Shows the delete modal, sets loading to true, and deletes the faculty.
   *
   * @return {Promise<void>} - A promise that resolves when the delete operation is completed.
   */
  async handleDelete() {
    this.toggleDeleteModal();
    this.setState({ loading: true });
    try {
      await api.delete(`/faculty/${this.state.person.id}`);
      this.props.handleCancel();
    } catch (err) {
      console.error(err);
      this.setState({
        errorMessage: err.response?.data ? err.response.data : err.message,
        customMessage: `Error found in deleteing Faculty with id ${this.state.person.id}: `,
        loading: false,
      });
      return;
    }
  }

/**
 * Posts the form data to the server and updates the state with the new data.
 *
 * @param {Event} e - The form submission event.
 * @return {Promise<void>} A promise that resolves once the form submission is handled.
 */
  async handleFormSubmit(e) {
    e.preventDefault();
    this.setState({ loading: true, errorMessage: null });
    try {
      if (this.state.categoryEdited) {
        await api.post(
          `/faculty-category/${this.state.person.id}`,
          this.state.editablePerson
        );
      }
      const { data: person } = await api.post(
        `/faculty/${this.state.person.id}`,
        this.state.editablePerson
      );
      this.changeEdit();
      this.setState({
        loading: false,
        person: person,
      });
    } catch (err) {
      this.setState({
        loading: false,
        errorMessage: err.response?.data ? err.response.data : err.message,
        customMessage: "Error found in submiting faculty information: ",
      });
    }
  }

  /**
   * Toggles the edit mode for the component, updates the state,
   * and enables or disables the directory page navigation.
   *
   */
  changeEdit() {
    if (this.props.fromDirectory) {
      if (this.state.edit) {
        this.props.enableDirectoryPageNav();
      } else {
        this.props.disableDirectoryPageNav();
      }
    }
    let editablePerson = { ...this.state.person };
    if (editablePerson.active_faculty === null) {
      editablePerson.active_faculty = true;
    }
    this.setState({
      edit: !this.state.edit,
      editablePerson,
      errorMessage: null,
      facultyEdited: false,
      categoryEdited: false,
    });
  }

  /**
   * Restores the state of the component to its previous state and disables the edit mode.
   *
   */
  handleRestore() {
    this.setState({ error: null, loading: true });
    this.getFaculty();
    this.changeEdit();
    this.setState({ loading: false });
  }

/**
 * Updates the state of the component with the new value for the given field.
 *
 * @param {Event} e - The change event object.
 */
  handleChange(e) {
    const field = e.target.id;
    const value = e.target.value;
    const person = this.state.editablePerson;
    let facultyChanged = false;
    switch (field) {
      case "show_on_public":
        person.show_on_public = e.target.checked;
        facultyChanged = true;
        break;
      case "active":
        person.active_faculty = e.target.checked;
        facultyChanged = true;
        break;
      case "first_name":
        person.first_name = value;
        facultyChanged = true;
        break;
      case "last_name":
        person.last_name = value;
        facultyChanged = true;
        break;
      case "middle_name":
        person.middle_name = value;
        facultyChanged = true;
        break;
      case "suffix":
        person.suffix = value;
        facultyChanged = true;
        break;
      case "title":
        person.title = value;
        facultyChanged = true;
        break;
      case "id":
        person.id = value;
        facultyChanged = true;
        break;
      case "net_id":
        person.net_id = value;
        facultyChanged = true;
        break;
      case "department":
        person.department = value;
        facultyChanged = true;
        break;
      case "email":
        person.email = value;
        facultyChanged = true;
        break;
      case "phone":
        person.phone = value;
        facultyChanged = true;
        break;
      case "room":
        person.room = value;
        facultyChanged = true;
        break;
      case "known_by":
        person.known_by = value;
        facultyChanged = true;
        break;
      case "picture_link":
        person.picture_link = value;
        facultyChanged = true;
        break;
      case "bio":
        person.bio_link = value;
        facultyChanged = true;
        break;
      default:
        break;
    }
    this.setState({ editablePerson: person, facultyEdited: facultyChanged });
  }

  /**
   * Updates the state of the component with the new faculty category values.
   *
   * @param {Event} e - The event object for the category change.
   */
  handleCategoryChange(e) {
    let categoryChanged = true;
    let person = this.state.editablePerson;
    let categories = e.map((cat) => cat.value);
    person.category = categories;
    this.setState({ editablePerson: person, categoryEdited: categoryChanged });
  }

  /**
   * Sets facultyEdited to true.
   *
   */
  handlePhotoChange() {
    let facultyChanged = true;

    this.setState({ facultyEdited: facultyChanged });
  }

  /**
   * Updates the photo link of the editable person and sets the facultyEdited state to true.
   *
   * @param {type} value - The new photo link.
   */
  handlePhotoSave(value) {
    const person = this.state.editablePerson;

    let facultyChanged = true;

    person.picture_link = value;

    this.setState({ editablePerson: person, facultyEdited: facultyChanged });
  }

  croppedTooltip = (props) => (
    <Tooltip id="cropped-tooltip" {...props}>
      This version of the image will show up where a square picture is required,
      such as the Faculty and Staff Directory main page.
    </Tooltip>
  );

  currentlyEmployedTooltip = (props) => (
    <Tooltip id="cropped-tooltip" {...props}>
      Disabling this removes this record from all directories EXCEPT admin view
      on Law Resources. Admins may still view and edit this record. Disabled
      records will only be used to show course instructor's names.
    </Tooltip>
  );

  hideRecordTooltip = (props) => (
    <Tooltip id="cropped-tooltip" {...props}>
      Disabling this means that this information will not be displayed on
      publicly available directories, such as the interactive directory on the
      main floor of the Law Building. When disabled this record will still
      appear to anyone who logs in to Law Resources.
    </Tooltip>
  );

  /**
   * Updates the URL in the browser's address bar with the given next page without reloading the page.
   *
   * @param {string} nextPage - The next page to navigate to.
   */
  updateURL(nextPage) {
    const nextURL = `${window.location.protocol}//${window.location.host}/faculty/${nextPage}`;
    const nextTitle = document.title;

    // This will replace the current entry in the browser's history, without reloading
    window.history.replaceState({}, nextTitle, nextURL);
  }

  /**
   * Handles the tab change from the navigation bar and updates the URL.
   *
   * @param {string} tab - the new tab to be selected
   */
  handleTabChangeFromNavBar(tab) {
    this.updateURL(tab);
    this.setState({ currentTab: tab });
  }

  /**
   * Renders the appropriate component based on the current tab.
   *
   * @param {string} currentTab - The current tab.
   * @return {JSX.Element} The rendered component.
   */
  renderSwitch(currentTab) {
    if (this.state.loading) {
      return (
        <div className="loadingDiv">
          <h1
            className="loadingText"
            style={{ marginBottom: "1em", marginLeft: "0.5em" }}
          >
            {this.state.loadingMessage}
          </h1>
          <Spinner animation={"border"} />
        </div>
      );
    }
    if (this.state.errorMessage && this.state.isLoadingError) {
      return (
        <ErrorDisplay
          errorMessage={this.state.errorMessage}
          customMessage={this.state.customMessage}
        />
      );
    }
    switch (currentTab) {
      case "tools":
        return <FacultyTools />;
      case "admin-tools":
        return <AdminTools roles={this.props.roles} />;
      case "documents":
        return <AdminRecords roles={this.props.roles} />;
      case "information":
        return (
          <DisplayFacultyInformation
            isAuthorized={this.state.isAuthorized}
            changeEdit={this.changeEdit}
            person={this.state.person}
            roles={this.props.roles}
          />
        );
      default:
        return (
          <DisplayFacultyInformation
            isAuthorized={this.state.isAuthorized}
            changeEdit={this.changeEdit}
            person={this.state.person}
            roles={this.props.roles}
          />
        );
    }
  }

  render() {
    const { roles, handleCancel, fromDirectory } = this.props;
    if (this.state.loading) {
      return (
        <div className="directoryLoading">
          <h1
            className="loadingText"
            style={{ marginBottom: "1em", marginLeft: "0.5em" }}
          >
            {this.state.loadingMessage}
          </h1>
          <Spinner animation={"border"} />
        </div>
      );
    }
    if (this.state.errorMessage && this.state.isLoadingError) {
      return (
        <ErrorDisplay
          errorMessage={this.state.errorMessage}
          customMessage={this.state.customMessage}
        />
      );
    }
    if (this.state.edit) {
      return (
        <React.Fragment>
          {fromDirectory ? (
            <React.Fragment>
              <Row className="justify-content-center no-margin">
                <Col xs={"auto"} className="mt-2">
                  <Button
                    style={{ margin: "auto" }}
                    onClick={handleCancel}
                    className="shadow-button"
                    disabled
                  >
                    Return to Directory
                  </Button>
                </Col>
              </Row>
              <ConfirmDeleteModal
                show={this.state.showDeleteModal}
                onHide={this.toggleDeleteModal}
                deleteRole={this.handleDelete}
                message={this.deleteMessage}
              />
            </React.Fragment>
          ) : (
            <ToolsNav currentTab={this.state.currentTab} roles={roles} />
          )}
          <EditFacultyInformation
            roles={roles}
            handleFormSubmit={this.handleFormSubmit}
            handleChange={this.handleChange}
            handleDelete={this.toggleDeleteModal}
            changeEdit={this.changeEdit}
            editablePerson={this.state.editablePerson}
            facultyEdited={this.state.facultyEdited}
            categoryEdited={this.state.categoryEdited}
            tooltips={{
              croppedTooltip: this.croppedTooltip,
              hideRecordTooltip: this.hideRecordTooltip,
              currentlyEmployedTooltip: this.currentlyEmployedTooltip,
            }}
            departments={this.state.departments}
            category={this.state.category}
            handleCategoryChange={this.handleCategoryChange}
            handleRestore={this.handleRestore}
            handlePhotoSave={this.handlePhotoSave}
            handlePhotoChange={this.handlePhotoChange}

            // handleSavePhotoChange = {this.handleSavePhotoChange}
          />
          {this.state.errorMessage && (
            <ErrorDisplay
              errorMessage={
                this.state.errorMessage + ": Submit failed, try again."
              }
            />
          )}
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        {fromDirectory ? (
          <Row className="justify-content-center no-margin">
            <Col xs={"auto"} className="mt-2 mb-3">
              <Button
                style={{ margin: "auto" }}
                onClick={handleCancel}
                className="shadow-button"
              >
                Return to Directory
              </Button>
            </Col>
          </Row>
        ) : (
          <ToolsNav
            currentTab={this.state.currentTab}
            handleTabChange={this.handleTabChangeFromNavBar}
            roles={roles}
          />
        )}
        {this.renderSwitch(this.state.currentTab)}
      </React.Fragment>
    );
  }
}

export default FacultyInformation;
