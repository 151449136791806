import React from "react";
import { Col, Row, Button, Card, Container } from "react-bootstrap";
import { handleImageError } from "../../utils/functions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import PersonalInfoEntry from "../records/personal_info/PersonalInfoEntry";
import { CleanImage } from "../SafeImage";

class DisplayFacultyInformation extends React.Component {
  render() {
    const { changeEdit, person, isAuthorized, roles } = this.props;

    return (
      <Container className="px-5 pb-5 bg-white">
        <div
          className="d-flex flex-wrap"
          style={{ justifyContent: "space-between", alignItems: "center" }}
        >
          <h1>Personal Info</h1>
          {isAuthorized && (
            <Button
              variant="primary"
              onClick={changeEdit}
              style={{ width: "auto"}}
              className="shadow"
            >
              <FontAwesomeIcon icon={faPen} className="edit-icon" />
              Edit
            </Button>
          )}
        </div>
        <Row className="justify-content-center mt-4">
          <Col>
            <Card className="shadow personal-info-card">
              <Card.Header as="h3">
                {person.first_name +
                  (person.middle_name ? " " + person.middle_name : "") +
                  (person.last_name ? " " + person.last_name : "") +
                  (person.suffix ? " " + person.suffix : "")}
              </Card.Header>
              <Card.Body className="personal-info-card-body" style={{ width: "100%" }}>
                <Col className="personal-info-col">
                  <div className="avatar personal-info-img" >
                    <CleanImage
                      src={`${person.picture_link}?` + new Date().getTime()}
                      alt={`${person.first_name} ${person.last_name} profile picture`}
                      placeholder="person_img"
                      className="shadow"
                      onError={(e) => {
                        handleImageError(e, person);
                      }}
                      fluid
                      person={person}
                    />
                  </div>
                </Col>
                <Col className="personal-info-col" style={{ marginRight: "50px" }}>
                  {(sessionStorage.id === person.id || roles.admin) && (
                    <>
                    <PersonalInfoEntry
                      label="BYU ID:"
                      data={person.id ? person.id : "None"}
                    />
                    <PersonalInfoEntry
                      label="Net ID:"
                      data={person.net_id ? person.net_id : "None"}
                    />
                    </>
                  )}
                  {isAuthorized ? (
                    <>
                      <PersonalInfoEntry
                        label="Title:"
                        data={person.title ? person.title : "None"}
                      />
                      <PersonalInfoEntry
                        label="Phone:"
                        data={person.phone ? person.phone : "None"}
                        isPhone={true}
                      />
                      <PersonalInfoEntry
                        label="E-mail:"
                        data={person.email ? person.email : "None"}
                        isEmail={true}
                      />
                      <PersonalInfoEntry
                        label="Room:"
                        data={person.room ? person.room : "None"}
                      />
                      <PersonalInfoEntry
                        label="Department:"
                        data={person.department ? person.department : "None"}
                      />
                      <PersonalInfoEntry
                        label="Category:"
                        data={person.category ? person.category.join(", ") : "None"}
                      />
                      {person.bio_link && (
                        <PersonalInfoEntry
                          label="Bio:"
                          data={
                            <a
                              href={person.bio_link}
                              rel="noopener noreferrer"
                              target="_blank"
                              style={{ cursor: "pointer" }}
                            >
                              {person.first_name} {person.last_name}
                            </a>
                          }
                        />
                      )}
                    </>
                  ) : (
                    <>
                      <PersonalInfoEntry
                        label="E-mail:"
                        data={person.email ? person.email : "None"}
                      />
                      <PersonalInfoEntry
                        label="Room:"
                        data={person.room ? person.room : "None"}
                      />
                      <PersonalInfoEntry
                        label="Category:"
                        data={person.category ? person.category.join(", ") : "None"}
                      />
                    </>
                  )}
                </Col>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      
    );
  }
}

export default DisplayFacultyInformation;
