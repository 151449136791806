import { secureUserToken } from "../components/auth/secureSessionStorage";

const blankAddress = {
  id: "",
  address_type: "",
  street1: "",
  street2: "",
  city: "",
  state: "",
  postal_code: "",
  country: "",
  phone: "",
};

function getDevId(devBooleanRoles = sessionStorage.getItem('devRoles') ? JSON.parse(sessionStorage.getItem('devRoles')) : {}) {
  if (process.env.REACT_APP_STAGE !== "dev"
    || sessionStorage.getItem('devRolesOn') === 'false'
  ) {
    return secureUserToken.id;
  }
  return devBooleanRoles.admin
    ? "111111111"
    : devBooleanRoles.registrar
    ? "222222222"
    : devBooleanRoles.student
    ? "333333333"
    : devBooleanRoles.faculty
    ? "444444444"
    : devBooleanRoles.graduate
    ? "555555555"
    : devBooleanRoles.operator
    ? "666666666"
    : "777777777"
}

function getAddressOfType(addresses, type) {
  if (addresses) {
    for (let i = 0; i < addresses.length; i++) {
      if (addresses[i].address_type === type) {
        return addresses[i];
      }
    }
  }
  return blankAddress;
}

function getIndexOfAddressOfType(addresses, type) {
  if (addresses) {
    for (let i = 0; i < addresses.length; i++) {
      if (addresses[i].address_type === type) {
        return i;
      }
    }
  }
  return -1;
}

function generateCSV(array, documentName) {
  const titles = Object.keys(array[0]);
  array = array.map((element) =>
    Object.values(element).map((value) => {
      if (!(value === null || value === undefined)) {
        value = '"' + value.toString() + '"';
      }
      return value;
    })
  );
  let csvContent =
    titles.join(",") +
    "\n" +
    array.map((element) => Object.values(element).join(",")).join("\n");

  let csvData = new Blob([csvContent], { type: "text/csv" });
  let encodedUri = URL.createObjectURL(csvData);

  let link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute("download", documentName);
  document.body.appendChild(link); // Required for FF
  link.click();
}

function dateValidator(date) {
  if (date === null) {
    return true;
  } else {
    return date.match(
      /^(1[0-2]|0?[1-9])\/(3[01]|[12][0-9]|0?[1-9])\/(?:[0-9]{2})?[0-9]{2}$/
    );
  }
}

function displaySemester(semester) {
  const semesterMap = [
    "",
    "Winter",
    "Winter",
    "Spring",
    "Summer",
    "Fall",
    "Fall",
  ];
  const semesterString = semester.toString();
  const semesterOut = semesterMap[semesterString.slice(4)];
  const yearOut = semesterString.slice(0, 4);
  return [yearOut, semesterOut];
}

function getCurrentSemester() {
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth();
  let semester = "0";
  if (currentMonth <= 3) semester = "1";
  else if (currentMonth < 5) semester = "3";
  else if (currentMonth < 7) semester = "4";
  else if (currentMonth <= 11) semester = "5";
  const universitySemester = currentYear + semester;
  return universitySemester;
}

function releaseGradesGetCurrentSemester() {
  let currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth();
  const currentDay = new Date().getDate();
  let semester = "0";
  if (currentMonth == 0 && currentDay <= 20) {
    semester = "5";
    currentYear = currentYear - 1;
  } else if (currentMonth <= 4) semester = "1";
  else if (currentMonth < 6) semester = "3";
  else if (currentMonth < 8) semester = "4";
  else if (currentMonth <= 11) semester = "5";
  const universitySemester = currentYear + semester;
  return universitySemester;
}

function handleImageError(e, person) {
  // console.log(`handleImageError triggered`);
  const fullName = `${person.first_name} ${person.last_name}`;
  e.target.src = `https://ui-avatars.com/api/?name=${fullName}&size=275&background=E2E0DD`;
}

const stateList = [ 'AL', 'AK', 'AS', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FM', 'FL', 'GA', 'GU', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MH', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'MP', 'OH', 'OK', 'OR', 'PW', 'PA', 'PR', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VI', 'VA', 'WA', 'WV', 'WI', 'WY', 'Other' ];

export {
  blankAddress,
  dateValidator,
  displaySemester,
  handleImageError,
  generateCSV,
  getAddressOfType,
  getCurrentSemester,
  getDevId,
  getIndexOfAddressOfType,
  releaseGradesGetCurrentSemester,
  stateList,
};
