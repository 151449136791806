import React, { useEffect, useState } from "react";
import {
  Button,
  Container,
  Col,
  Dropdown,
  DropdownButton,
  Nav,
  Navbar,
} from "react-bootstrap";
import ReactGA from "react-ga";
import { Link } from "react-router-dom";
import lawLogo from "../images/lawLogo.png";
import userIcon from "../images/user-icon.png";
import Logout from "./auth/Logout";
import { secureUserToken } from "./auth/secureSessionStorage";

let trackingId = "";
if (process.env.REACT_APP_STAGE !== "dev") {
  trackingId = "UA-123656585-7";
} else {
  trackingId = "UA-NOTPROD";
}
ReactGA.initialize(trackingId);
ReactGA.pageview(window.location.pathname + window.location.search);

/**
 * Returns a link to the appropriate information page based on the user's roles.
 *
 * @param {object} roles - An object containing the user's roles.
 * @return {string} - The URL of the information page.
 */
const getMyInfoLink = (roles) => {
  return roles.registrar ||
    roles.faculty ||
    roles.admin ||
    roles.operator ||
    roles.secretary
    ? "/faculty/information"
    : roles.graduate
    ? "/graduate-archive/information"
    : roles.student
    ? "/enrollment/information"
    : "/";
};

/**
 * Returns a link to the appropriate documents page based on the user's roles.
 *
 * @param {object} roles - The user's roles.
 * @return {string} - The URL path to the documents page.
 */
const getMyDocumentsLink = (roles) => {
  return roles.admin || roles.registrar || roles.operator || roles.secretary
    ? "/faculty/documents"
    : roles.graduate
    ? "/graduate-archive/student-record"
    : roles.student
    ? "/enrollment/student-record"
    : "/";
};

const Header = (props) => {
  const myInfoLink = getMyInfoLink(props.roles);
  const myDocumentsLink = getMyDocumentsLink(props.roles);
  const [roles, setRoles] = useState(props.roles);
  const [devRoles, setDevRoles] = useState(
    Object.entries(props.roles)
      .filter(([role, active]) => active)
      .map(([role, active]) => role)
  );
  const [showDevRoles, setShowDevRoles] = useState(false);
  const [devRolesChanged, setDevRolesChanged] = useState(false);
  const [impersonationVisible, setImpersonationVisible] = useState(false);
  const [devRolesOn, setDevRolesOn] = useState(
    process.env.REACT_APP_STAGE !== "dev"
      ? false
      : sessionStorage.getItem("devRolesOn") === "false"
      ? false
      : true
  );

  useEffect(() => {
    if (process.env.REACT_APP_STAGE !== "dev") {
      return;
    }
    const newDevRoles = Object.entries(props.roles)
      .filter(([role, active]) => active)
      .map(([role, active]) => role);
    const rolesAreDifferent =
      !newDevRoles.every((role) => devRoles.includes(role)) ||
      newDevRoles.length !== devRoles.length;
    if (newDevRoles.length < 1 || rolesAreDifferent) {
      return;
    }
    setDevRoles(newDevRoles);
    //setDevRoleInSessionStorageForApiPageReload(newDevRoles);
  }, [props.roles]);

  /**
   * Updates the user's roles in state with what the user selected and sets devRolesChanged to true.
   *
   * @param {String} selectedRole - The role to be toggled.
   */
  const toggleImpersonateRole = (selectedRole) => {
    if (devRoles.includes(selectedRole)) {
      setDevRoles(devRoles.filter((role) => role !== selectedRole));
    } else {
      setDevRoles([...devRoles, selectedRole]);
    }
    setDevRolesChanged(true);
  };

  useEffect(() => {
    setRoles(props.roles);
  }, [props.roles]);

  /**
   * Sets the development roles in the session storage for the API page reload.
   *
   * @param {undefined | Array<string>} givenDevRoles - The development roles to be set in the session storage. If not provided, the default development roles will be used.
   */
  const setDevRoleInSessionStorageForApiPageReload = (
    givenDevRoles = undefined
  ) => {
    //return if not in development mode
    if (process.env.REACT_APP_STAGE !== "dev") {
      return;
    }
    let booleanRoles = {
      admin: false,
      registrar: false,
      student: false,
      faculty: false,
      graduate: false,
      operator: false,
      secretary: false,
    };
    for (const role of givenDevRoles || devRoles) {
      if (role in booleanRoles) booleanRoles[role] = true;
    }
    sessionStorage.setItem("devRoles", JSON.stringify(booleanRoles));
  };

  /**
   * Cancels the impersonation by removing the "actastoken" and "userToken" from the session storage
   * and redirects the user to the home page where they will be logged in again.
   *
   */
  const cancelImpersonation = () => {
    sessionStorage.removeItem("actastoken");
    sessionStorage.removeItem("userToken");

    window.location.assign("/");
  };

  /**
   * Sets the dev roles and redirects the user to the home page.
   *
   */
  const handleRoleChange = () => {
    if (process.env.REACT_APP_STAGE !== "dev") {
      return;
    }
    if (devRolesOn) {
      sessionStorage.setItem("devRolesOn", "true");
      setDevRoleInSessionStorageForApiPageReload();
      window.location.assign("/");
    } else {
      sessionStorage.setItem("devRolesOn", "false");
      cancelImpersonation();
    }
  };

/**
 * Toggles the dev roles on or off and sets the devRolesChanged to true.
 *
 */
  const toggleDevRoles = () => {
    setDevRolesOn(!devRolesOn);
    setDevRolesChanged(true);
  };

  const firstName = secureUserToken.first_name;

  let nameRoles = firstName;

  for (const [roleName, hasRole] of Object.entries(roles)) {
    if (hasRole) {
      nameRoles = `${nameRoles} · ${roleName
        .slice(0, 1)
        .toUpperCase()}${roleName.slice(1)}`;
    }
  }

  return (
    <div className="container-fluid p-0">
      <Navbar
        bg={sessionStorage.getItem("actastoken") ? "impersonate" : "primary"}
        variant="dark"
        className="text-light d-flex flex-wrap"
      >
        <Container>
          <Navbar.Brand as={Link} to="/">
            <img
              src={lawLogo}
              className="d-inline-block mx-2"
              alt="BYU Law logo"
              style={{ maxHeight: "20px" }}
            />
          </Navbar.Brand>
          <Nav
            className="pl-3 text-light justify-content-end"
            style={{ width: "auto" }}
          >
            <Dropdown>
              <Dropdown.Toggle
                style={{
                  backgroundColor:
                    sessionStorage.getItem("actastoken") && "#e6ac00",
                  border: "none",
                }}
              >
                {nameRoles}
                <img
                  src={userIcon}
                  className="px-2"
                  alt="user icon"
                  style={{ maxHeight: "20px" }}
                />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item href={myInfoLink}>My Info</Dropdown.Item>
                <Dropdown.Divider></Dropdown.Divider>
                <Dropdown.Item>
                  <Logout oktaAuth={props.oktaAuth} />
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
        </Container>
      </Navbar>

      <Navbar
        bg={sessionStorage.getItem("actastoken") ? "impersonate" : "primary"}
        // bg="primary"
        variant="dark"
        className="text-light justify-content-center d-flex flex-wrap"
      >
        <Container
          style={{
            borderTop: sessionStorage.getItem("actastoken")
              ? "1px solid white"
              : "1px solid #EBBA71",
            paddingTop: "0.5rem",
            justifyContent: "space-between",
            paddingLeft: 0,
          }}
        >
          <Nav>
            {(roles.faculty ||
              roles.student ||
              roles.secretary ||
              roles.operator ||
              roles.admin ||
              roles.registrar) && (
              <Col className="px-2">
                <Dropdown>
                  <Dropdown.Toggle
                    style={{
                      backgroundColor:
                        sessionStorage.getItem("actastoken") && "#e6ac00",
                      // "#002e5d",
                      border: "none",
                    }}
                  >
                    Directories
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="nav-dropdown">
                    <Dropdown.Item href="/faculty-directory">
                      Faculty and Staff Directory
                    </Dropdown.Item>
                    {(roles.faculty ||
                      roles.student ||
                      roles.secretary ||
                      roles.operator ||
                      roles.admin ||
                      roles.registrar) && (
                      <Dropdown.Item href="/course-directory">
                        Course Directory
                      </Dropdown.Item>
                    )}
                    {(roles.faculty ||
                      roles.secretary ||
                      roles.operator ||
                      roles.admin ||
                      roles.registrar) && (
                      <Dropdown.Item href="/graduate-directory">
                        Graduate Directory
                      </Dropdown.Item>
                    )}
                    {(roles.faculty ||
                      roles.student ||
                      roles.secretary ||
                      roles.operator ||
                      roles.admin ||
                      roles.registrar) && (
                      <Dropdown.Item href="/student-directory">
                        Student Directory
                      </Dropdown.Item>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
            )}
            <Col className="px-2">
              <Dropdown>
                <Dropdown.Toggle
                  style={{
                    backgroundColor:
                      sessionStorage.getItem("actastoken") && "#e6ac00",
                    // "#002e5d",
                    border: "none",
                  }}
                >
                  My Tools
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item href={myInfoLink}>
                    My Info
                  </Dropdown.Item>
                  {(roles.admin || roles.registrar || roles.operator) && (
                    <Dropdown.Item href="/faculty/admin-tools">
                      {roles.admin ? "Admin Tools" : roles.registrar ? "Registrar Tools" : "Operator Tools"}
                    </Dropdown.Item>
                  )}
                  {roles.faculty && (
                    <Dropdown.Item href="/faculty/tools">
                      Faculty Tools
                    </Dropdown.Item>
                  )}
                  {(roles.graduate || roles.student) && (
                    <Dropdown.Item
                      href={
                        roles.graduate
                          ? "/graduate-archive/progress-report"
                          : "/enrollment/progress-report"
                      }
                    >
                      Academic Progress
                    </Dropdown.Item>
                  )}
                  {(roles.student ||
                    roles.secretary ||
                    roles.operator ||
                    roles.registrar ||
                    roles.graduate ||
                    roles.admin) && (
                    <Dropdown.Item href={myDocumentsLink}>
                      Documents
                    </Dropdown.Item>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </Col>
          </Nav>
          {sessionStorage.getItem("actastoken") && (
            <div style={{ display: "flex" }}>
              <Button variant="danger" onClick={cancelImpersonation}>
                Stop Impersonating
              </Button>
            </div>
          )}
          {(process.env.REACT_APP_STAGE === "dev" &&
            !sessionStorage.getItem("actastoken")) && (
            <div style={{ display: "flex" }}>
              {devRolesChanged && devRoles.length > 0 && (
                <Button
                  style={{ marginRight: "1rem" }}
                  variant="secondary"
                  onClick={handleRoleChange}
                >
                  Apply
                </Button>
              )}
              <DropdownButton
                show={showDevRoles}
                onToggle={(nextShow) => setShowDevRoles(nextShow)}
                autoClose="outside"
                title={`
                    ${
                      devRoles && devRoles?.length > 0
                        ? devRoles
                            .map(
                              (impersonationRole) =>
                                impersonationRole.charAt(0).toUpperCase() +
                                impersonationRole.slice(1)
                            )
                            .join(", ")
                        : showDevRoles
                        ? "Close"
                        : "Open"
                    }
                  `}
              >
                {[
                  "admin",
                  "registrar",
                  "operator",
                  "secretary",
                  "faculty",
                  "graduate",
                  "student",
                ].map((impersonationRole, key) => {
                  return (
                    <Dropdown.Item
                      key={key}
                      active={devRoles.includes(impersonationRole)}
                      style={{
                        textAlign: "center",
                        fontStyle: "normal",
                      }}
                      disabled={!devRolesOn}
                      onClick={() => {
                        toggleImpersonateRole(impersonationRole);
                      }}
                    >
                      {impersonationRole.charAt(0).toUpperCase() +
                        impersonationRole.slice(1)}
                    </Dropdown.Item>
                  );
                })}
                <Dropdown.Item onClick={toggleDevRoles}>
                  {devRolesOn ? "Turn off dev roles" : "Turn on dev roles"}
                </Dropdown.Item>
              </DropdownButton>
            </div>
          )}
        </Container>
      </Navbar>
    </div>
  );
};

export default Header;
export { getMyDocumentsLink, getMyInfoLink };
