import React from "react";
import { 
  Button,
  Col,
  Form,
  Row,
  Spinner,
} from "react-bootstrap";
import api from "../../services/api";
import PreLawSchools from "../records/personal_info/PreLawSchools";
import { blankAddress, getIndexOfAddressOfType, stateList } from "../../utils/functions";

class EditApplicationInformation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editedStudent: JSON.parse(JSON.stringify(this.props.student)),
      indexOfHomeAddress: getIndexOfAddressOfType(
        this.props.student.address,
        "home"
      ),
      barDateMonth: "",
      barDateYear: "",
      gradDateMonth: "",
      gradDateYear: "",
      loading: false,
      studentEdited: false,
      addressEditedHome: false,
      gradDateEdited: false,
      barDateEdited: false,
      state_other: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  /**
   * Formats the dates and sets the state. Determines if the state is other or in US.
   */
  componentDidMount() {
    let barDateYear = this.props.student.plan_bar_date
      ? new Date(this.props.student.plan_bar_date).toLocaleString("en-US", {
          timeZone: "Etc/UTC",
          year: "numeric",
        })
      : "";
    let barDateMonth = this.props.student.plan_bar_date
      ? new Date(this.props.student.plan_bar_date).toLocaleString("en-US", {
          timeZone: "Etc/UTC",
          month: "long",
        })
      : "";

    let gradDateMonth = this.props.student.plan_grad_date
      ? new Date(this.props.student.plan_grad_date).toLocaleString("en-US", {
          timeZone: "Etc/UTC",
          month: "long",
        })
      : "";
    let gradDateYear = this.props.student.plan_grad_date
      ? new Date(this.props.student.plan_grad_date).toLocaleString("en-US", {
          timeZone: "Etc/UTC",
          year: "numeric",
        })
      : "";

    let { editedStudent } = this.state;
    if (!editedStudent.name_on_diploma) {
      editedStudent.name_on_diploma = editedStudent.full_name;
    }

    if (!stateList.includes(editedStudent.address[this.state.indexOfHomeAddress].state)) {
      this.setState({
        state_other: editedStudent.address[this.state.indexOfHomeAddress].state
      })
      editedStudent.address[this.state.indexOfHomeAddress].state = "Other";
    }

    this.setState({
      barDateMonth,
      barDateYear,
      editedStudent,
      gradDateMonth,
      gradDateYear,
    });
  }

  /**
   * Generates an array of graduation year options based on the current date.
   *
   * @return {Array} an array of graduation year options
   */
  gradYearOptions() {
    const currentDate = new Date();
    var years = [];
    for (var i = currentDate.getFullYear() - 3; i <= currentDate.getFullYear() + 10; i++) {
      years.push(i);
    }
    years.splice(0, 0, "Select Year");
    var gradYears = years.map((year) => (
      <option key={year} value={year}>
        {year}
      </option>
    ));
    return gradYears;
  }

  /**
   * Updates the state of the component based on user input.
   *
   * @param {object} e - The event object containing information about the change.
   */
  handleChange(e) {
    const { id, value } = e.target;
    let {
      editedStudent,
      indexOfHomeAddress,
      addressEditedHome,
      studentEdited,
      barDateEdited,
      gradDateEdited,
    } = this.state;

    switch (id) {
      case "name_on_diploma":
        editedStudent.name_on_diploma = value;
        studentEdited = true;
        break;
      case "address-home-line1":
        editedStudent.address[indexOfHomeAddress].street1 = value;
        addressEditedHome = true;
        break;
      case "address-home-line2":
        editedStudent.address[indexOfHomeAddress].street2 = value;
        addressEditedHome = true;
        break;
      case "address-home-city":
        editedStudent.address[indexOfHomeAddress].city = value;
        addressEditedHome = true;
        break;
      case "address-home-zip":
        editedStudent.address[indexOfHomeAddress].postal_code = value;
        addressEditedHome = true;
        break;
      case "address-home-state":
        editedStudent.address[indexOfHomeAddress].state = value;
        if (value !== "Other") {
          this.setState({ state_other: "" });
        }
        addressEditedHome = true;
        break;
      case "address-home-state-other":
        this.setState({ state_other: value });
        addressEditedHome = true;
        break;
      case "address-home-country":
        editedStudent.address[indexOfHomeAddress].country = value;
        addressEditedHome = true;
        break;
      case "address-home-phone":
        editedStudent.address[indexOfHomeAddress].phone = value;
        addressEditedHome = true;
        break;
      case "bar-prep-course":
        editedStudent.bar_prep_course = value;
        studentEdited = true;
        break;
      case "plan-bar-state":
        editedStudent.plan_bar_state = value;
        studentEdited = true;
        break;
      case "grad-month":
        this.setState({ gradDateMonth: value });
        gradDateEdited = true;
        studentEdited = true;
        break;
      case "grad-year":
        this.setState({ gradDateYear: value });
        gradDateEdited = true;
        studentEdited = true;
        break;
      case "bar-month":
        this.setState({ barDateMonth: value });
        barDateEdited = true;
        studentEdited = true;
        break;
      case "bar-year":
        this.setState({ barDateYear: value });
        barDateEdited = true;
        studentEdited = true;
        break;
      default:
        break;
    }
    this.setState({
      editedStudent,
      addressEditedHome,
      studentEdited,
      barDateEdited,
      gradDateEdited,
    });
  }

  /**
   * Formats the dates and sends the updated student object to the server.
   *
   * @param {Object} e - The form submission event
   * @return {Promise} A promise that resolves when the function completes
   */
  async handleSubmit(e) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ loading: true });
    let {
      editedStudent,
      studentEdited,
      addressEditedHome,
      gradDateMonth,
      gradDateYear,
      barDateMonth,
      barDateYear,
      barDateEdited,
      gradDateEdited,
    } = this.state;
    if (this.state.state_other) {
      editedStudent.address[this.state.indexOfHomeAddress].state = this.state.state_other;
    }
    if (studentEdited) {
      editedStudent.type = "student";
      if (gradDateEdited) {
        editedStudent.plan_grad_date = new Date(
          gradDateMonth + gradDateYear
        ).toISOString();
      }
      if (barDateEdited) {
        editedStudent.plan_bar_date = new Date(
          barDateMonth + barDateYear
        ).toISOString();
      }
      await api.post(`/students/${editedStudent.id}`, editedStudent);
    }
    if (addressEditedHome) {
      const editedAddress = editedStudent.address;
      await api.post(
        `/address/${editedStudent.id}`,
        editedAddress[this.state.indexOfHomeAddress]
      );
    }
    this.props.updateStudent();
    this.props.handleCancel();
    this.setState({ loading: false });
  }

  render() {
    let { student } = this.props;
    let studentAddress =
      this.state.editedStudent.address[this.state.indexOfHomeAddress] ||
      blankAddress;
    return (
      <Form onSubmit={this.handleSubmit}>
        <Row className="justify-content-center">
          <Col xs="12" md="6">
            <Form.Group>
              <Form.Label>
                <strong>Name: </strong>
              </Form.Label>
              <Form.Control
                id="name_on_diploma"
                value={this.state.editedStudent.name_on_diploma}
                onChange={this.handleChange}
              />{" "}
              (Ensure this name is as you would like it to appear on your
              diploma)
            </Form.Group>
            <strong>Degree to be recieved: </strong>
            {student.enrollment_status === "L" ? (
              <span>LLM</span>
            ) : (
              <span>J.D.</span>
            )}{" "}
            (Contact the registrar to get this changed)
          </Col>
          <Col xs="12" md="6">
            <Form.Group>
              <Form.Label>
                <strong>I plan to graduate:</strong>
              </Form.Label>
              <Form.Select
                id="grad-month"
                value={this.state.gradDateMonth}
                onChange={this.handleChange}
                isInvalid={
                  this.state.gradDateEdited &&
                  (this.state.gradDateMonth === "Select Month" ||
                    this.state.gradDateMonth === "")
                }
              >
                <option key={3}>Select Month</option>
                <option key={1}>April</option>
                <option key={2}>December</option>
              </Form.Select>
              <Form.Select
                id="grad-year"
                value={this.state.gradDateYear}
                onChange={this.handleChange}
                isInvalid={
                  this.state.gradDateEdited &&
                  (this.state.gradDateYear === "Select Year" ||
                    this.state.gradDateYear === "")
                }
              >
                <this.gradYearOptions />
              </Form.Select>
            </Form.Group>
          </Col>
          <Col xs="6">
            <Form.Label className="p-0 m-0 mb-1">
              <strong>Current address</strong>
            </Form.Label>
            <Form.Control
              id="address-home-line1"
              placeholder="Line 1"
              className="mb-1"
              value={studentAddress.street1}
              onChange={this.handleChange}
            />
            <Form.Control
              id="address-home-line2"
              placeholder="Line 2"
              className="mb-1"
              value={studentAddress.street2}
              onChange={this.handleChange}
            />
            <Form.Label className="p-0 m-0 mt-2">
              <strong>City</strong>
            </Form.Label>
            <Form.Control
              id="address-home-city"
              className="mb-1"
              value={studentAddress.city}
              onChange={this.handleChange}
            />
            <Form.Label className="p-0 m-0 mt-2">
              <strong>ZIP Code</strong>
            </Form.Label>
            <Form.Control
              id="address-home-zip"
              className="mb-1"
              value={studentAddress.postal_code}
              onChange={this.handleChange}
            />
          </Col>
          <Col xs="6">
            <Form.Label className="p-0 m-0 mb-1">
              <strong>State/Province</strong>
            </Form.Label>
            <Form.Select
              id="address-home-state"
              className="mb-1"
              onChange={this.handleChange}
              value={studentAddress.state}
            >
              <option value=""></option>
              {stateList.map((state) => (
                <option key={state} value={state}>
                  {state}
                </option>
              ))}
            </Form.Select>
            {studentAddress.state === "Other" && (
              <>
                <Form.Label className="p-0 m-0 mb-1">
                  <strong>Other State/Province</strong>
                </Form.Label>
                <Form.Control
                  id="address-home-state-other"
                  className="mb-1"
                  value={this.state.state_other}
                  onChange={this.handleChange}
                />
              </>
            )}
            <Form.Label className="p-0 m-0 mb-1">
              <strong>Country</strong>
            </Form.Label>
            <Form.Control
              id="address-home-country"
              className="mb-1"
              value={studentAddress.country}
              onChange={this.handleChange}
            />
            <Form.Label className="p-0 m-0 mb-1">
              <strong>Phone</strong>
            </Form.Label>
            <Form.Control
              id="address-home-phone"
              className="mb-1"
              value={studentAddress.phone}
              onChange={this.handleChange}
            />
          </Col>
          <PreLawSchools degrees={student.pre_law_degrees} noShadow />
          <Col xs="12" md="6">
            <Form.Group>
              <Form.Label>
                <strong>I will be taking the following State Bar Exam: </strong>
              </Form.Label>
              <Form.Select
                id="plan-bar-state"
                onChange={this.handleChange}
                value={this.state.editedStudent.plan_bar_state}
              >
                <option value=""></option>
                {stateList.map((state) => (
                  <option key={state} value={state}>
                    {state}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col xs="12" md="6">
            <Form.Group>
              <Form.Group>
                <Form.Label>
                  <strong> When? </strong>
                </Form.Label>
                <Form.Select
                  id="bar-month"
                  value={this.state.barDateMonth}
                  onChange={this.handleChange}
                  isInvalid={
                    this.state.barDateEdited &&
                    (this.state.barDateMonth === "Select Month" ||
                      this.state.barDateMonth === "")
                  }
                >
                  <option key={3}>Select Month</option>
                  <option key={1}>February</option>
                  <option key={2}>July</option>
                </Form.Select>
                <Form.Select
                  id="bar-year"
                  value={this.state.barDateYear}
                  onChange={this.handleChange}
                  isInvalid={
                    this.state.barDateEdited &&
                    (this.state.barDateYear === "Select Year" ||
                      this.state.barDateYear === "")
                  }
                >
                  <this.gradYearOptions />
                </Form.Select>
              </Form.Group>
            </Form.Group>
          </Col>
          <Col xs="12">
            <Form.Group>
              <Form.Label>
                <strong>
                  What Bar Preparation Course are you planning to take?{" "}
                </strong>
              </Form.Label>
              <Form.Control
                id="bar-prep-course"
                value={this.state.editedStudent.bar_prep_course}
                onChange={this.handleChange}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="justify-content-center mt-1">
          <Col xs="auto">
            <Button
              variant="outline-info"
              type=""
              id="cancel-button"
              onClick={this.props.handleCancel}
            >
              Cancel
            </Button>
          </Col>
          <Col xs="auto">
            <Button
              variant="info"
              type="submit"
              disabled={
                !(this.state.studentEdited || this.state.addressEditedHome) ||
                (this.state.barDateEdited &&
                  (this.state.barDateMonth === "Select Month" ||
                    this.state.barDateYear === "Select Year" ||
                    this.state.barDateMonth === "" ||
                    this.state.barDateYear === "")) ||
                (this.state.gradDateEdited &&
                  (this.state.gradDateMonth === "Select Month" ||
                    this.state.gradDateYear === "Select Year" ||
                    this.state.gradDateMonth === "" ||
                    this.state.gradDateYear === ""))
              }
            >
              Save Changes
              {this.state.loading && (
                <Spinner animation="border" size="sm" style={{ marginLeft:".5rem" }} />
              )}
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }
}

export default EditApplicationInformation;
