import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";

const UpdateNotification = () => {
  const [showNotification, setShowNotification] = useState(false);

  useEffect(() => {
    // Function to check if the user has seen the message before
    const isNotificationDismissed = () => {
      return localStorage.getItem("updateMessageSeen") === "true";
    };

    // Check if the user has already seen the message
    if (!isNotificationDismissed()) {
      setShowNotification(true);
    }
  }, []);

  // Function to dismiss the notification and set the flag in local storage
  const dismissNotification = () => {
    setShowNotification(false);
    localStorage.setItem("updateMessageSeen", "true");
  };

  return (
    showNotification && (
      <Modal show={showNotification} onHide={dismissNotification}>
        <Modal.Header>
          <Modal.Title>Welcome to Law Info</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          The User Interface has been updated! Check out the new design and feel
          free to leave us feedback by clicking "Give Feedback" at the bottom of
          the page.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-info" onClick={dismissNotification}>
            Dismiss
          </Button>
        </Modal.Footer>
      </Modal>
    )
  );
};

export default UpdateNotification;
