import { faSort } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import moment from "moment";
import React from "react";
import {
  Button,
  Card,
  Col,
  Form,
  OverlayTrigger,
  Row,
  Spinner,
  Table,
  Tooltip,
} from "react-bootstrap";
import api from "../../../services/api";
import MultipleErrorDisplay from "../../MultipleErrorDisplay";
import BlindModal from "./BlindModal";
import CourseStatsModal from "./CourseStatsModal.jsx";
import GradessubmitModal from "./GradesSubmitModal";

function sortArrayOfJsonHelper(prop, direction) {
  return (a_in, b_in) => {
    let { a, b } =
      direction === "desc" ? { a: a_in, b: b_in } : { b: a_in, a: b_in };
    if (typeof a[prop] === "string" && typeof b[prop] === "string") {
      if (a[prop].toLowerCase() < b[prop].toLowerCase()) {
        return 1;
      } else if (a[prop].toLowerCase() > b[prop].toLowerCase()) {
        return -1;
      }
    } else if (a[prop] < b[prop]) {
      return 1;
    } else if (a[prop] > b[prop]) {
      return -1;
    }
    return 0;
  };
}

export default class GradeSubmission extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      course: this.props.course,
      studentData: [],
      errorMessageArray: [],
      edit: false,
      editableStudentData: [],
      invalid: false,
      showCourseStats: false,
      courseStats: [],
      saveDraft: false,
      showSubmitModal: false,
      blind: this.props.roles.registrar ? false : true,
      showBlindModal: false,
      loading: true,
      errorWithDrafts: false,
      errorSavingDraft: false,
      showDraftSavedPrompt: false,
      sortedDirection: null,
      fieldsToShow: this.props.roles.registrar
        ? [
            { field_name: "last_name", display_name: "Name" },
            { field_name: "id", display_name: "BYU ID #" },
            { field_name: "exam_number", display_name: "Exam #" },
            { field_name: "grade", display_name: "Grade" },
          ]
        : [
            { field_name: "exam_number", display_name: "Exam #" },
            { field_name: "grade", display_name: "Grade" },
          ],
      savingDraft: false,
      changeToGrades: false,
      isActiveSort: "exam_number",
      lastSaved: new Date(),
      lastSavedString: moment(new Date()).fromNow(),
      isPassFailCourse: false,
    };

    this.getStudentGrade = this.getStudentGrade.bind(this);
    this.handleEditToggle = this.handleEditToggle.bind(this);
    this.handleSaveGrade = this.handleSaveGrade.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleInvalid = this.handleInvalid.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.setEdiableData = this.setEdiableData.bind(this);
    this.getCourseStats = this.getCourseStats.bind(this);
    this.toggleCourseStatsModal = this.toggleCourseStatsModal.bind(this);
    this.renderTooltip = this.renderTooltip.bind(this);
    this.toggleBlind = this.toggleBlind.bind(this);
    this.toggleBlindModal = this.toggleBlindModal.bind(this);
    this.handleModalCancel = this.handleModalCancel.bind(this);
    this.sortStudents = this.sortStudents.bind(this);
    this.handleSaveDraftGrade = this.handleSaveDraftGrade.bind(this);
    this.toggleSubmitModal = this.toggleSubmitModal.bind(this);

    const { protocol, host } = window.location;
    const { semester, catalog_number, section_number } = this.state.course;
    const nextURL = `${protocol}//${host}/course-directory/${semester}/${catalog_number}/${section_number}/GradeSubmission`;

    const nextTitle = document.title;

    window.history.replaceState({}, nextTitle, nextURL);
  }

  /**
   * Renders a tooltip component.
   *
   * @param {Object} props - The props for the tooltip.
   * @return {ReactNode} The rendered tooltip component.
   */
  renderTooltip(props) {
    return (
      <Tooltip {...props}>
        In order to edit the grades, they must be unsubmitted or have been
        rejected.
      </Tooltip>
    );
  }

  toggleSubmitModal() {
    this.setState({ showSubmitModal: !this.state.showSubmitModal });
  }

  handleModalCancel() {
    this.toggleBlindModal();
  }

  toggleBlindModal() {
    this.setState({ showBlindModal: !this.state.showBlindModal });
  }

  /**
   * Toggles the blind mode of the component. If blind, only exam number and 
   * grade are shown. If not blind, all fields are shown.
   *
   * @return {void} 
   */
  toggleBlind() {
    const blind = !this.state.blind;
    this.setState({ blind: blind });
    const fieldsToShow = [
      { field_name: "exam_number", display_name: "Exam #" },
      { field_name: "grade", display_name: "Grade" },
    ];
    if (!blind) {
      if (this.props.roles.registrar)
        fieldsToShow.unshift({ field_name: "id", display_name: "BYU ID #" });
      fieldsToShow.unshift({ field_name: "last_name", display_name: "Name" });
    }
    this.setState({ fieldsToShow });
    this.toggleBlindModal();
  }

  toggleCourseStatsModal() {
    this.setState({ showCourseStats: !this.state.showCourseStats });
  }

  /**
   * Retrieves the course statistics from the API to check grade requirements.
   *
   * @return {Promise<void>} - A Promise that resolves when the course statistics are retrieved successfully.
   */
  async getCourseStats() {
    const element = this.state.course;
    try {
      const { data: courseStats } = await api.post(
        `/grades/${element.semester}/${element.catalog_number}/${element.section_number}`,
        this.state.editableStudentData,
        {
          params: { test: 1 },
        }
      );

      this.setState({ courseStats, showCourseStats: true });
    } catch (err) {
      this.setState({
        errorMessageArray: [
          ...this.state.errorMessageArray,
          {
            errorMessage: err.response?.data ? err.response.data : err.message,
          },
        ],
      });
    }
  }

  /**
   * Asynchronously handles the saving of a grade when users clicks Submit.
   *
   * @return {undefined} No return value.
   */
  async handleSaveGrade() {
    const { course, editableStudentData } = this.state;
    this.handleSaveDraftGrade();
    try {
      await api.post(
        `/grades/${course.semester}/${course.catalog_number}/${course.section_number}`,
        editableStudentData
      );
      window.location.reload();
    } catch (err) {
      if (!axios.isCancel(err)) {
        console.error(err);
        this.setState({
          errorMessageArray: [
            ...this.state.errorMessageArray,
            {
              errorMessage: err.response?.data
                ? err.response.data
                : err.message,
            },
          ],
        });
      }
    }

    this.setState({ edit: !this.state.edit });
  }

  /**
   * Handles saving the draft grade.
   *
   * @return {Promise<void>} - A promise that resolves when the draft grade is saved.
   */
  async handleSaveDraftGrade() {
    const { course, editableStudentData } = this.state;
    this.setState({ showDraftSavedPrompt: true });
    this.setState({ savingDraft: true });
    this.setState({ errorMessageArray: [] });
    try {
      await api.post(
        `/grades/draft/${course.semester}/${course.catalog_number}/${course.section_number}`,
        editableStudentData
      );
    } catch (err) {
      if (!axios.isCancel(err)) {
        console.error(err);
        this.setState({ errorSavingDraft: true });
        this.setState({
          errorMessageArray: [
            ...this.state.errorMessageArray,
            {
              errorMessage: err.response?.data
                ? err.response.data
                : err.message,
              customMessage: "Error saving the draft: ",
            },
          ],
        });
      }
    }
    this.setState({ lastSaved: new Date() });
    this.setState({ savingDraft: false });
    this.setState({ changeToGrades: false });
  }

  /**
   * Checks whether the input is valid based on class type and sets isValid property on data.
   *
   * @param {object} data - The data to handle. A person object.
   * @return {boolean} Returns true if the data is invalid, otherwise false.
   */
  handleInvalid(data) {
    if (!data.grade) {
      return;
    }
    const valid =
      (this.state.isPassFailCourse
        ? data.grade?.match(/^(16|27|P)$/)
        : data.grade?.match(/^(([1][6-9])|([2-3][0-9])|40|P|T)$/)) ||
      data.grade === "T";

    data.isInvalid = !valid;
    return !valid;
  }

  /**
   * Handles any input in the grade input. 
   * Updates the state of the component with the new input and checks if the input is valid.
   *
   * @param {Event} e - The event object containing information about the change.
   * @return {void} No return value.
   */
  handleChange(e) {
    const { id: elementID, value } = e.target;
    this.setState({ changeToGrades: true });

    const index = elementID.split("-")[0];
    const studentNum = Number(index);
    const student = this.state.editableStudentData[studentNum];

    student.grade = value;
    this.handleInvalid(student);

    let allValid = true;
    for (const element of this.state.editableStudentData) {
      if (element.isInvalid === true || !element.grade) {
        this.setState({ invalid: true });
        allValid = false;
        break;
      }
    }

    if (allValid) {
      this.setState({ invalid: false });
    }

    this.setState({
      editableStudentData: this.state.editableStudentData,
      saveDraft: true,
    });
  }

  /**
   * Retrieves the student grade data from the main API and the draft API.
   * Populates the state with the retrieved data and handles any errors that occur.
   *
   * @return {Promise<void>} - Resolves when the grade data is retrieved and the state is updated.
   */
  async getStudentGrade() {
    const { course } = this.state;
    this.setState({ loading: true });

    //Get the grade data from the main api to populate students and such
    try {
      const { data: studentData } = await api.get(
        `/grades/${course.semester}/${course.catalog_number}/${course.section_number}`
      );
      this.setState({ studentData });

      const copy = this.state.studentData;
      this.setState({ editableStudentData: copy });
    } catch (err) {
      if (!axios.isCancel(err)) {
        console.error(err);
        this.setState({
          errorMessageArray: [
            ...this.state.errorMessageArray,
            {
              errorMessage: err.response?.data
                ? err.response.data
                : err.message,
            },
          ],
        });
      }
    }

    //Get the draft data and change the grades displayed to the ones in an existing draft
    try {
      const { data: draftStudentData } = await api.get(
        `/grades/draft/${course.semester}/${course.catalog_number}/${course.section_number}`
      );
      this.setState({ draftData: draftStudentData });

      const studentDataCopy = this.state.studentData;
      studentDataCopy.forEach((draftStudent) => {
        draftStudentData?.forEach((courseStudent) => {
          if (draftStudent.id === courseStudent.id) {
            draftStudent.grade = courseStudent.grade;
          }
        });
      });

      this.setState({ studentData: studentDataCopy });

      const copy = this.state.studentData;
      this.setState({ editableStudentData: copy });
      this.setState({ errorWithGettingDrafts: false });
    } catch (err) {
      if (!axios.isCancel(err)) {
        console.error(err);
        this.setState({ errorWithGettingDrafts: true });

        //If there is no draft in dynamoDB, then we need to save one, and call the draft api again to make sure the get is working
        if (err.response?.data === "Error retrieving grade draft data") {
          await this.handleSaveDraftGrade();
          try {
            const { data: draftStudentData } = await api.get(
              `/grades/draft/${course.semester}/${course.catalog_number}/${course.section_number}`
            );
            this.setState({ draftData: draftStudentData });

            const studentDataCopy = this.state.studentData;
            studentDataCopy.forEach((draftStudent) => {
              draftStudentData?.forEach((courseStudent) => {
                if (draftStudent.id === courseStudent.id) {
                  draftStudent.grade = courseStudent.grade;
                }
              });
            });

            this.setState({ studentData: studentDataCopy });

            const copy = this.state.studentData;
            this.setState({ editableStudentData: copy });
            this.setState({ errorWithGettingDrafts: false });
          } catch {
            console.error(err);
            // this.setState({
            //   errorMessageArray: [
            //     ...this.state.errorMessageArray,
            //     {
            //       errorMessage: err.response?.data
            //         ? err.response.data
            //         : err.message,
            //       customMessage: "Error getting the draft: ",
            //     },
            //   ],
            // });
          }
        }
        //Else, if it's an error just display the error
        // else {
        //   this.setState({
        //     errorMessageArray: [
        //       ...this.state.errorMessageArray,
        //       {
        //         errorMessage: err.response?.data
        //           ? err.response.data
        //           : err.message,
        //         customMessage: "Error getting the draft: ",
        //       },
        //     ],
        //   });
        // }
      }
    }
    this.setState({ loading: false });
  }

  handleEditToggle() {
    this.setState({ edit: !this.state.edit });
  }

  /**
   * Handles the cancellation of the edit. Saves the current state of the student
   * grade data to the draft API.
   *
   * @return {Promise<void>} Resolves when the cancellation is complete.
   */
  async handleCancel() {
    this.setState({
      edit: !this.state.edit,
    });

    const copy = JSON.parse(JSON.stringify(this.state.studentData));
    await this.setState({ editableStudentData: copy });
    this.handleSaveDraftGrade();
  }

  setEdiableData() {
    this.setState({ editableStudentData: this.state.studentData });
  }

  /**
   * Sorts the students based on the provided event target id. (Name, ID, Exam Number, etc.)
   *
   * @param {Object} e - The event object.
   * @return {void}
   */
  sortStudents(e) {
    const { id } = e.target;

    let editableStudentData;
    let sortedDirection = this.state.sortedDirection;
    if (this.state.isActiveSort === id) {
      sortedDirection = sortedDirection === "asc" ? "desc" : "asc";
    } else {
      sortedDirection = "asc";

      this.setState({ isActiveSort: id });
    }

    this.setState({ sortedDirection });

    editableStudentData = this.state.editableStudentData.sort(
      sortArrayOfJsonHelper(id, sortedDirection)
    );

    this.setState({ editableStudentData, isActiveSort: id });
  }

  /**
   * Initializes the component and performs several operations, including:
   * - Setting up the event object `e`
   * - Calling the `getStudentGrade` function
   * - Sorting students
   * - Triggering the `intervalSave` function
   * - Checking if the course grade requirements are "Pass/Fail Course" and updating the component state accordingly
   *
   * @return {Promise<void>} A promise that resolves when the component has finished initializing
   */
  async componentDidMount() {
    const e = {
      target: {
        id: "exam_number",
      },
    };
    // e.target.id = "Name";
    await this.getStudentGrade();
    this.sortStudents(e);
    this.intervalSave();

    if (this.props.course.grade_requirements === "Pass/Fail Course") {
      this.setState({ isPassFailCourse: true });
    }

    let allValid = true;
    for (const element of this.state.editableStudentData) {
      this.handleInvalid(element);
      if (element.isInvalid === true || !element.grade) {
        this.setState({ invalid: true });
        allValid = false;
        break;
      }
    }

    if (allValid) {
      this.setState({ invalid: false });
    }
  }

  /**
   * Clears the interval before the component is unmounted.
   *
   * @return {void}
   */
  componentWillUnmount() {
    clearInterval(this.state.intervalID);
  }

  /**
   * Saves the current state to the draft API at regular intervals.
   *
   * @return {void} 
   */
  intervalSave() {
    const interval = setInterval(() => {
      this.setState({
        lastSavedString: moment(this.state.lastSaved).fromNow(),
      });
      if (
        (this.state.edit && this.state.saveDraft) ||
        this.state.changeToGrades
      ) {
        this.handleSaveDraftGrade();
        this.setState({ saveDraft: false });
      }
    }, 5000);

    this.setState({ intervalID: interval });
  }

  render() {
    const tooltip = (
      <Tooltip id="tooltip">All students must have a valid grade to check grade requirements.</Tooltip>
    );

    return (
      <React.Fragment>
        <Row className="justify-content-center mt-2">
          {(this.props.roles.registrar || this.props.roles.faculty) &&
            // String(this.props.course.semester) === getCurrentSemester() &&
            //We decided to make the grade submission lag behind by a month for grading after the end of the semester
            //and to make it line up with when grades can be released.

            /*String(this.props.course.semester) ===
              releaseGradesGetCurrentSemester() &&*/

            (this.props.course.grade_status !== "unsubmitted" &&
            this.props.course.grade_status !== "rejected" ? (
              <OverlayTrigger overlay={this.renderTooltip} placement="bottom">
                <Col className="pb-2 text-sm-center text-md-end" xs="auto">
                  <Button
                    variant="outline-primary"
                    className=" shadow"
                    disabled
                  >
                    Edit
                  </Button>
                </Col>
              </OverlayTrigger>
            ) : this.state.edit ? (
              <Col className="pb-2 text-sm-center text-md-end" xs="auto">
                <Button
                  onClick={this.handleEditToggle}
                  className="shadow"
                  variant="outline-primary"
                >
                  Exit Edit
                </Button>
              </Col>
            ) : (
              <Col className="pb-2 text-sm-center text-md-end" xs="auto">
                <Button
                  onClick={this.handleEditToggle}
                  className=" shadow"
                  variant="outline-primary"
                >
                  Edit
                </Button>
              </Col>
            ))}
          <Col xs="auto">
            <Button
              onClick={this.props.onGradeDisplay}
              className="me-4 shadow"
              variant="outline-primary"
              disabled={this.state.edit}
            >
              Return to Course
            </Button>
            <Button
              onClick={this.props.handleReturnToDirectory}
              className="shadow"
              disabled={this.state.edit}
            >
              Return to Directory
            </Button>
          </Col>
        </Row>
        <Row className="justify-content-center ">
          <Col style={{ maxWidth: "30rem" }}>
            <Col className="d-flex justify-content-end">
              <p>
                Grade Type:{" "}
                <a
                  href="https://law.byu.edu/wp-content/uploads/2023/09/PoliciesAndProcedures2023Sep01-dn.pdf#nameddest=gr"
                  target="_blank"
                  rel="noreferrer"
                >
                  {this.props.course.grade_requirements}
                </a>
              </p>
            </Col>
            <Col className="d-flex">
              {this.state.edit ? (
                <></>
              ) : (
                <>
                  <Form.Check
                    type="switch"
                    checked={this.state.blind}
                    onChange={this.toggleBlindModal}
                  />
                  <Form.Label>Blind Grading</Form.Label>
                </>
              )}
              <Col className="d-flex justify-content-end">
                {this.props.course.grade_status !== "unsubmitted" &&
                this.props.course.grade_status !== "rejected" ? (
                  <p className="grades-locked">
                    Grades are locked.
                    <br />
                    Current Grade Status: {this.props.course.grade_status}
                  </p>
                ) : this.props.course.grade_status === "rejected" ? (
                  <p className="grades-rejected">
                    Grades were rejected.
                    <br />
                    Current Grade Status: {this.props.course.grade_status}
                  </p>
                ) : (
                  <p className="grades-unsubmitted">
                    Current Grade Status: {this.props.course.grade_status}
                  </p>
                )}
              </Col>
            </Col>
            <Col className="d-flex">
              {this.state.edit ? (
                <>
                  <Form.Check
                    type="switch"
                    checked={this.state.blind}
                    onChange={this.toggleBlindModal}
                  />
                  <Form.Label>Blind Grading</Form.Label>
                </>
              ) : (
                ""
              )}
              {this.state.edit && this.state.showDraftSavedPrompt ? (
                <>
                  {!this.state.errorSavingDraft ? (
                    <>
                      {!this.state.savingDraft ? (
                        <Col className="d-flex justify-content-end">
                          <Form.Label>
                            Draft Saved: {this.state.lastSavedString}
                          </Form.Label>
                        </Col>
                      ) : (
                        <Col className="d-flex justify-content-end">
                          <Form.Label>
                            Draft Saved: {this.state.lastSavedString}
                          </Form.Label>
                          <Spinner animation="grow" variant="info" />
                        </Col>
                      )}
                    </>
                  ) : (
                    <Col className="d-flex justify-content-end">
                      <>{"Draft Not Saved, See Error Above"}</>
                    </Col>
                  )}
                </>
              ) : (
                ""
              )}
            </Col>

            <Card className="directory-shadow p-0">
              {this.state.loading ? (
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ height: "20rem" }}
                >
                  <Spinner
                    animation="border"
                    style={{ marginRight: "0.5em" }}
                  />{" "}
                  Loading
                </div>
              ) : (
                <Table striped bordered hover responsive className="mb-0">
                  <React.Fragment>
                    <thead>
                      <tr>
                        {this.state.fieldsToShow.map((field) => (
                          <th key={field.field_name}>
                            <Button
                              id={field.field_name}
                              variant="success"
                              onClick={this.sortStudents}
                            >
                              {field.display_name}{" "}
                              <FontAwesomeIcon
                                title={`sort by ${field.display_name}`}
                                icon={faSort}
                                style={{ color: "002e5d" }}
                              />
                            </Button>
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.editableStudentData.map((person, index) => (
                        <tr key={person.id}>
                          {!this.state.blind && (
                            <React.Fragment>
                              <td>
                                {`${person.last_name}, ${person.first_name}`}
                              </td>
                              {this.props.roles.registrar && (
                                <td>{person.id}</td>
                              )}
                            </React.Fragment>
                          )}
                          <td>{person.exam_number}</td>
                          {this.state.edit ? (
                            !this.state.isPassFailCourse ? (
                              <td width={this.state.blind ? "50%" : "25%"}>
                                <Form.Group>
                                  <Form.Control
                                    id={`${index}-grade`}
                                    type="text"
                                    onChange={this.handleChange}
                                    value={person.grade || ""}
                                    isInvalid={this.handleInvalid(person)}
                                    disabled={false}
                                  />
                                  <div className="invalid-feedback">
                                    Must be between 16 to 40 or T
                                  </div>
                                </Form.Group>
                              </td>
                            ) : (
                              <td width={this.state.blind ? "50%" : "25%"}>
                                <Form.Group>
                                  <Form.Select
                                    id={`${index}-grade`}
                                    type="text"
                                    onChange={this.handleChange}
                                    value={person.grade || ""}
                                    isInvalid={this.handleInvalid(person)}
                                    disabled={false}
                                  >
                                    <option value=""></option>
                                    <option value="16">16 - Fail</option>
                                    <option value="27">27 - Low Pass</option>
                                    <option value="P">P - Pass</option>
                                    <option value="T">T - Incomplete</option>
                                  </Form.Select>
                                </Form.Group>
                              </td>
                            )
                          ) : (
                            <td width={this.state.blind ? "50%" : "25%"}>
                              {person.grade}
                            </td>
                          )}
                        </tr>
                      ))}
                    </tbody>
                  </React.Fragment>
                </Table>
              )}
            </Card>

            <Row className="my-2">
              <BlindModal
                show={this.state.showBlindModal}
                onHide={this.handleModalCancel}
                changeBlind={this.toggleBlind}
                blind={this.state.blind}
              />

              <GradessubmitModal
                show={this.state.showSubmitModal}
                onHide={this.toggleSubmitModal}
                handleSaveGrade={this.handleSaveGrade}
              />

              {this.state.edit && (
                <React.Fragment>
                  <CourseStatsModal
                    show={this.state.showCourseStats}
                    toggleCourseStatsModal={this.toggleCourseStatsModal}
                    courseStats={this.state.courseStats}
                  />

                  <Col className="d-flex justify-content-end mr-4">
                    {/* <Button
                      className="mb-4 mx-2 shadow"
                      onClick={this.handleCancel}
                      variant="outline-info"
                    >
                      Cancel
                    </Button> */}
                    {this.state.showDraftSavedPrompt ? (
                      <>
                        {!this.state.errorSavingDraft ? (
                          <>
                            {!this.state.savingDraft ? (
                              <Col className="d-flex justify-content-end">
                                <Form.Label>
                                  Draft Saved: {this.state.lastSavedString}
                                </Form.Label>
                              </Col>
                            ) : (
                              <Col className="d-flex justify-content-end">
                                <Spinner animation="grow" variant="info" />
                                <Form.Label>
                                  Draft Saved: {this.state.lastSavedString}
                                </Form.Label>
                              </Col>
                            )}
                          </>
                        ) : (
                          <Col className="d-flex justify-content-end">
                            <>{"Draft Not Saved, See Error Above"}</>
                          </Col>
                        )}
                      </>
                    ) : (
                      ""
                    )}

                    {this.state.invalid ? (
                      <OverlayTrigger
                        placement="bottom"
                        overlay={tooltip}
                      >
                        <div className="mb-4 mx-2 shadow">
                          <Button
                            style={{ height: "100%" }}
                            onClick={this.getCourseStats}
                            variant="outline-primary"
                            disabled={true}
                          >
                            Check Grade Requirements
                          </Button>
                        </div>
                      </OverlayTrigger>
                    ) : (
                        <Button
                          className="mb-4 mx-2 shadow"
                          onClick={this.getCourseStats}
                          variant="outline-primary"
                        >
                          Check Grade Requirements
                        </Button>
                    )}

                    <Button
                      className="mb-4 ms-2 shadow"
                      type="submit"
                      onClick={() => this.handleSaveDraftGrade()}
                      variant="info"
                    >
                      Save Draft
                    </Button>
                  </Col>
                </React.Fragment>
              )}
            </Row>
            <Row className="my-2">
              <BlindModal
                show={this.state.showBlindModal}
                onHide={this.handleModalCancel}
                changeBlind={this.toggleBlind}
                blind={this.state.blind}
              />
              {this.state.edit && (
                <React.Fragment>
                  <CourseStatsModal
                    show={this.state.showCourseStats}
                    toggleCourseStatsModal={this.toggleCourseStatsModal}
                    courseStats={this.state.courseStats}
                  />

                  <Col className="d-flex justify-content-end mr-4">
                    <Button
                      className="mb-4 ms-2 shadow"
                      type="submit"
                      onClick={this.toggleSubmitModal}
                      variant="info"
                      disabled={this.state.invalid}
                    >
                      Submit Grades
                    </Button>
                  </Col>
                </React.Fragment>
              )}
            </Row>
          </Col>
          {this.state.errorMessageArray?.length > 0 ? (
            <MultipleErrorDisplay
              errorMessageArray={this.state.errorMessageArray}
            />
          ) : (
            ""
          )}
        </Row>
      </React.Fragment>
    );
  }
}
