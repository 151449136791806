import React from "react";
import { Nav, Navbar, Button, Collapse, Container, Form, Row, Col } from "react-bootstrap";
import simpleView from "../../images/simpleView.png"
import tableSetting from "../../images/tableSetting.png"
import table from "../../images/table.png"
import chevron from "../../images/chevron.png"

class TableHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  /**
   *  Closes the table header when user changes from Table View to Simple View.
   *
   *  @return {Promise<void>} - A promise that resolves when the format change is handled.
   */
  async handleFormatChange() {
    await this.props.handleFormatChange();
    if (this.props.currentFormat !== "TextOnly") {
      this.setState({open: false})
    }
  }

  render() {
    const { disablePageNav } = this.props;

    return (
      <div className="tableNav">
        <Navbar
          bg="primary-gray"
          className="p-2 rounded-0 d-flex flex-wrap"
        >
          <Nav className="me-auto  d-flex flex-wrap">
            {this.props.currentFormat === "TextOnly" ? (
              <Nav.Item className="px-2 mx-2 table-header-nav-item">
                <Button
                  variant="transparent"
                  onClick={() => this.handleFormatChange()}
                  disabled={disablePageNav}
                >
                  <img
                    src={simpleView}
                    alt="simple view icon"
                    style={{ maxHeight: "30px" }}
                  />
                  Simple view
                </Button>
              </Nav.Item>
            ) : (
              <Nav.Item className="px-2 mx-2 table-header-nav-item">
                <Button
                  variant="transparent"
                  onClick={() => this.handleFormatChange()}
                  disabled={disablePageNav}
                >
                  <img
                    src={table}
                    alt="table view icon"
                    style={{ maxHeight: "30px" }}
                  />
                  Table View
                </Button>
              </Nav.Item>
            )}
            {(this.props.currentFormat === "TextOnly" && this.props.availableFields.length > 0) && (
              <Nav.Item className="px-2 mx-2 table-header-nav-item">
                <Button
                  variant="transparent"
                  onClick={() => this.setState({open: !(this.state.open)})}
                  disabled={disablePageNav}
                >
                  <img
                    src={tableSetting}
                    alt="table simple view icon"
                    style={{ maxHeight: "30px" }}
                  />
                  Table Settings
                </Button>
              </Nav.Item>
            )}
            <Nav.Item className="px-2 mx-2 table-header-nav-item">
              Results: Showing {this.props.paginator.currentElementStart}-{this.props.paginator.currentElementEnd} of {this.props.numResults} 
            </Nav.Item>
          </Nav>

          <Nav.Item>
            <Button
              variant="transparent"
              onClick={this.props.paginator.actions.prev}
              disabled={disablePageNav}
            >
              <img
                src={chevron}
                alt="prev page button"
                style={{ maxHeight: "30px", transform: "rotate(-180deg)" }}
              />
            </Button>
          </Nav.Item>
          <Nav.Item>
            <Button
              variant="transparent"
              onClick={this.props.paginator.actions.next}
              disabled={disablePageNav}
            >
              <img
                src={chevron}
                alt="next page button"
                style={{ maxHeight: "30px" }}
              />
            </Button>
          </Nav.Item>
        </Navbar>
        <Collapse in={disablePageNav ? false : this.state.open} className="p-2 table-settings-collapse">
          <Container className="justify-content-center">
            <strong>Table Fields</strong>
            <Row>
              {this.props.availableFields.map((field) => (
                <Col key={field.display_name + "col"} md={2}>
                  <Form.Check
                    type="checkbox"
                    label={field.display_name}
                    key={field.display_name}
                    onChange={() =>
                      this.props.handleFieldChange(field)
                    }
                    checked={
                      this.props.fieldsToShow?.filter(
                        (e) => e.display_name === field.display_name
                      ).length > 0
                        ? true
                        : false
                    }
                  />
                </Col>
              ))}
            </Row>
            <Row className="justify-content-end">
              <Col md={2}>
                <Button
                  variant="danger"
                  onClick={this.props.handleDeselect}
                  className="clear-filters-button"
                >
                  Deselect All
                </Button>
              </Col>
            </Row>
          </Container>
        </Collapse>
      </div>
    )
  }
}
export default TableHeader;