import React from 'react';
import { Col } from 'react-bootstrap';

class PersonalInfoEntry extends React.Component {
  render() {
    const {
      label,
      data,
      isPhone,
      isEmail,
    } = this.props;

    if (isPhone || isEmail) {
      return (
        <Col className='personal-info-entry'>
          <strong className="p-0">{label}</strong>
          <a
            href={isPhone ? `tel:${data}` : `mailto:${data}`}
            style={{ color: "black" }}
            onClick={(e) => e.stopPropagation()}
          >
            {data}
          </a>
        </Col>  
      )
    }

    return (
      <Col className='personal-info-entry'>
        <strong className="p-0">{label}</strong>
        {data}
      </Col>
    )
  }
}

export default PersonalInfoEntry;