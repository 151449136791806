import React from "react";
import Directory from "../components/directories/Directory";
import { withRouter } from "react-router-dom";

class FacultyDirectory extends React.Component {
  render() {
    return <Directory fromFaculty {...this.props} />;
  }
}

export default withRouter(FacultyDirectory);
