import React from "react";
import { Modal, Button } from "react-bootstrap";

class BlindModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
    };
    this.modalValue = this.modalValue.bind(this);
  }

  modalValue(temp) {
    this.setState({ value: temp });
  }

  render() {
    let { show, onHide, changeBlind } = this.props;
    // let file ;
    return (
      <Modal show={show} onHide={this.onHide}>
        <Modal.Header>
          <Modal.Title>Blind Grading</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* {message} */}
          <div>
            {this.props.blind ? (
              //   <p>This will change blind grading to see names and id numbers.</p>
              <p>
                Turn off blind grading. Confirm to see names and ID numbers.
              </p>
            ) : (
              <p>
                Change to blind grading. Confirm to hide names and ID numbers.
              </p>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="info" onClick={changeBlind}>
            Confirm
          </Button>
          <Button variant="outline-info" onClick={onHide}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default BlindModal;
