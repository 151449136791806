import React from "react";
import { Modal, Button, Table, Spinner } from "react-bootstrap";
import api from "../../services/api";

class MassAdvanceSubmitModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      responseData: [],
      loadingAdvance: false,
    };

    this.handleAdvance = this.handleAdvance.bind(this);
    this.switchLoading = this.switchLoading.bind(this);
  }

  switchLoading() {
    this.setState({ loadingAdvance: !this.state.loadingAdvance });
  }

  async handleAdvance() {
    this.switchLoading();
    try {
      let studentArray = [];
      this.props.studentsToAdvance.forEach((student) => {
        studentArray.push({
          id: student.id,
          first_name: student.first_name,
          last_name: student.last_name,
        });
      });

      let response = await api.post(`/students/massadvance`, studentArray);
      this.setState({ responseData: response.data });
      this.props.populateData();
      this.props.toggleAdvanceModal();
    } catch (err) {
      console.log(err);
    }
    this.switchLoading();
    // window.location.reload();
  }

  render() {
    let { show, studentsToAdvance, toggleAdvanceModal } = this.props;
    return (
      <Modal show={show} onHide={toggleAdvanceModal}>
        <Modal.Header closeButton>
          <Modal.Title>Advance Students</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {studentsToAdvance.length === 0 ? (
            "You have not selected any students to advance"
          ) : (
            <React.Fragment>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>{"Student"}</th>
                    <th>{"Current Year"}</th>
                    <th>{"Advancing To"}</th>
                  </tr>
                </thead>
                <tbody>
                  {studentsToAdvance.map((student) => (
                    <>
                      <tr>
                        <td>{student.display_name}</td>
                        <td>
                          {student.year_in_program !== "LLM" &&
                          student.year_in_program !== "NL" &&
                          student.year_in_program !== "G" &&
                          student.year_in_program !== "Select"
                            ? student.year_in_program + "L"
                            : student.year_in_program}
                        </td>
                        <td>
                          {student.year_in_program !== "LLM" &&
                          student.year_in_program !== "NL" &&
                          student.year_in_program !== "G" &&
                          student.year_in_program !== "Select"
                            ? (
                                parseInt(student.year_in_program) + 1
                              ).toString() + "L"
                            : student.year_in_program}
                        </td>
                      </tr>
                    </>
                  ))}
                </tbody>
              </Table>
              <Button
                variant="info"
                className="m-2 float-end"
                onClick={this.handleAdvance}
              >
                {this.state.loadingAdvance ? (
                  <Spinner animation="border" size="sm" />
                ) : (
                  "Advance"
                )}
              </Button>

              <Button
                variant="danger"
                className="m-2 float-end"
                onClick={toggleAdvanceModal}
              >
                Cancel
              </Button>
            </React.Fragment>
          )}
        </Modal.Body>
      </Modal>
    );
  }
}

export default MassAdvanceSubmitModal;
