import { Spinner } from "react-bootstrap"
import Footer from "../components/Footer"
import Header from "../components/Header"
import "../styles/App.scss";
const Loading = () => {
  return (
    <>
      <div className="page-wrapper" style={{display: "flex", flexDirection: "column"}}>
        <Header noButtons roles={[]} />
        <div style={{display: "flex", flex: 1, flexDirection: "column", justifyContent: "center", alignContent: "center", alignItems: "center"}}>
          <h1 className="loadingText" style={{marginBottom: '1em', marginLeft: '0.5em'}}>Loading...</h1>
          <Spinner animation={"border"} />
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Loading;