import axios from "axios";
import React from "react";
import { Card, Spinner } from "react-bootstrap";
import Alert from "react-bootstrap/Alert";
import { withRouter } from "react-router-dom";
import api from "../../services/api";
import {
  generateCSV,
  getDevId,
  releaseGradesGetCurrentSemester,
} from "../../utils/functions";
import { getMyInfoLink } from "../Header";
import generateFlashCards from "../../utils/generateFlashCards";
import ErrorDisplay from "../ErrorDisplay";
import FacultyInformation from "../faculty_information/FacultyInformation";
import StudentInformation from "../records/StudentInformation";
import CourseDisplay from "./course_directory/CourseDisplay";
import StudentDirectoryInfo from "./student_directory/StudentDirectoryInfo";
import TextOnly from "./TextOnly";
import DirectoryHeader from "./DirectoryHeader";
import TableHeader from "./TableHeader";
import TableFooter from "./TableFooter";
import SimpleViewPerson from "./SimpleViewPerson";
import SimpleViewCourse from "./SimpleViewCourse";

const FlexSearch = require("flexsearch");
const STUDENT_API_FIELDS = ["class", "semester", "catalog_number", "section_number"];
const COURSE_FACULTY_API_FIELDS = ["semester", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "active", "category"];

class Directory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentFormat:
        this.props.fromCourse || this.props.fromFaculty
          ? "TextOnly"
          : "TextPhoto",
      storeFormat:
        this.props.fromCourse || this.props.fromFaculty
          ? "TextOnly"
          : "TextPhoto",
      disablePageNav: false,
      loading: true,
      flashCardLoading: false,
      csvLoading: false,
      axiosCancelSource: false,
      lastFilters: {},
      errorMessage: null,
      customMessage: null,
      pageData: [],
      isGradeStatusInEdit: false,
      baseData: [],
      allData: [],
      index: {},
      dataMap: null,
      prevSemester: "",
      prevCategory: "",
      elementsPerPage: 10,
      selectedData: {},
      elementsPerPagePicture: 10,
      elementsPerPageText: 25,
      currentElementPage: 0,
      endpoint: "",
      page: "",
      pageName: "",
      csvTitle: "",
      fieldsToShow: [],
      availableFields: [],
      categoriesToShow: [],
      facultyCategories: [],
      disablePaginator: false,
      disableRender: false,
      showPopUpWarning: false,
      childElement: {},
      gradeSubmissionDisplay: false,
      searchParams: new URLSearchParams(""),
      fieldSortStates: new Map(),
      displayPrintedComp: false,
      tablePrintRef: React.createRef(),
      tableTop: React.createRef(),
    };
    this.handleFormatChange = this.handleFormatChange.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.populateData = this.populateData.bind(this);
    this.setElementPage = this.setElementPage.bind(this);
    this.handleDownloadCSV = this.handleDownloadCSV.bind(this);
    this.handleDisplayClick = this.handleDisplayClick.bind(this);
    this.handleDisplayButton = this.handleDisplayButton.bind(this);
    this.handleEditStatus = this.handleEditStatus.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleFirst = this.handleFirst.bind(this);
    this.handleLast = this.handleLast.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.handlePrev = this.handlePrev.bind(this);
    this.updateURL = this.updateURL.bind(this);
    this.disablePageNav = this.disablePageNav.bind(this);
    this.enablePageNav = this.enablePageNav.bind(this);
    this.handleFlashCards = this.handleFlashCards.bind(this);
    this.handleLoadingStatus = this.handleLoadingStatus.bind(this);
    this.setFieldSortStates = this.setFieldSortStates.bind(this);
    this.handleSort = this.handleSort.bind(this);
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.handleDeselect = this.handleDeselect.bind(this);
    this.handleCategoryChange = this.handleCategoryChange.bind(this);
    this.handleCategoryDeselect = this.handleCategoryDeselect.bind(this);
    this.handleCourseNext = this.handleCourseNext.bind(this);
    this.handleCoursePrev = this.handleCoursePrev.bind(this);
    this.updateSelectedCourse = this.updateSelectedCourse.bind(this);
    this.scrollToTableTop = this.scrollToTableTop.bind(this);

    //if on course directory
    if (this.props.fromCourse) {
      this.state.endpoint = "/courses/directory";
      this.state.page = "course-directory";
      this.state.pageName = "Course Directory";
      this.state.csvTitle = "course_data";
      this.state.fieldsToShow = this.props.roles.registrar
        ? [
            { field_name: "semester", display_name: "Semester" },
            {
              field_name: ["catalog_number", "section_number"],
              display_name: "Course",
            },
            // { field_name: "section_number", display_name: "Section" },
            { field_name: "catalog_title", display_name: "Title" },
            { field_name: "credit_hours", display_name: "Credit" },
            { field_name: "professors", display_name: "Instructors" },
            { field_name: "days_of_week", display_name: "Days" },
            {
              field_name: "meets_grade_reqs",
              display_name: "Reqs Met",
            },
            {
              field_name: "grade_status",
              display_name: "Grade Status",
            },
            {
              field_name: "actions",
              display_name: "Actions",
            },
          ]
        : this.props.roles.faculty
        ? [
            { field_name: "semester", display_name: "Semester" },
            {
              field_name: ["catalog_number", "section_number"],
              display_name: "Course",
            },
            // { field_name: "section_number", display_name: "Section" },
            { field_name: "catalog_title", display_name: "Title" },
            { field_name: "credit_hours", display_name: "Credit" },
            { field_name: "professors", display_name: "Instructors" },
            { field_name: "days_of_week", display_name: "Days" },
            {
              field_name: "actions",
              display_name: "Actions",
            },
          ]
        : [
            { field_name: "semester", display_name: "Semester" },
            {
              field_name: ["catalog_number", "section_number"],
              display_name: "Course",
            },
            // { field_name: "section_number", display_name: "Section" },
            { field_name: "catalog_title", display_name: "Title" },
            { field_name: "credit_hours", display_name: "Credit" },
            { field_name: "professors", display_name: "Instructors" },
            { field_name: "days_of_week", display_name: "Days" },
          ];

      this.state.availableFields = [...this.state.fieldsToShow];
      this.state.facultyCategories = [
        this.state.categoriesToShow.slice(
          0,
          this.state.categoriesToShow.length / 2
        ),
        this.state.categoriesToShow.slice(
          -(this.state.categoriesToShow.length / 2)
        ),
      ];
      this.state.elementsPerPage = 30;
      this.state.elementsPerPageText = 30;
    }
    //if on student directory
    if (this.props.fromStudent) {
      this.state.endpoint = "/students/directory";
      this.state.page = "student-directory";
      this.state.pageName = "Student Directory";
      this.state.csvTitle = "student_data";

      if (this.props.roles.student) {
        this.state.fieldsToShow = [
          { field_name: "first_name", display_name: "First Name" },
          { field_name: "last_name", display_name: "Last Name" },
          { field_name: "email", display_name: "Email" },
          { field_name: "year_in_program", display_name: "Year in Program" },
        ];
      } else {
        this.state.fieldsToShow = [
          { field_name: "first_name", display_name: "First Name" },
          { field_name: "last_name", display_name: "Last Name" },
          { field_name: "id", display_name: "BYU ID" },
          { field_name: "net_id", display_name: "Net ID" },
          { field_name: "email", display_name: "Email" },
          { field_name: "year_in_program", display_name: "Year in Program" },
        ];
      }
      if (this.props.roles.admin || this.props.roles.registrar)
        this.state.availableFields = [
          { field_name: "first_name", display_name: "First Name" },
          { field_name: "last_name", display_name: "Last Name" },
          { field_name: "id", display_name: "BYU ID" },
          { field_name: "net_id", display_name: "Net ID" },
          { field_name: "email", display_name: "Email" },
          { field_name: "year_in_program", display_name: "Year in Program" },
          { field_name: "display_name", display_name: "Full Name" },
          { field_name: "display_address", display_name: "Address" },
          { field_name: "phone", display_name: "Phone" },
          { field_name: "date_of_birth", display_name: "Birthday" },
          { field_name: "carrel", display_name: "Carrel" },
          { field_name: "comments", display_name: "Comments" },
          { field_name: "country", display_name: "Country" },
          { field_name: "exam_number", display_name: "Exam Number" },
          { field_name: "lsat_score", display_name: "LSAT Score" },
          { field_name: "pre_law_gpa", display_name: "Pre Law GPA" },
          {
            field_name: "substantial_writing_display",
            display_name: "Substantial Writing",
          },
          {
            field_name: "library_skills_display",
            display_name: "Library Skills",
          },
          {
            field_name: "gender",
            display_name: "Gender",
          },
          {
            field_name: "religion",
            display_name: "Religion",
          },
          {
            field_name: "ethnicity",
            display_name: "Ethnicity",
          },
          {
            field_name: "expected_honors",
            display_name: "Expected Honors",
          },
        ];
      this.state.elementsPerPage = 10;
      this.state.elementsPerPagePicture = 10;
      this.state.elementsPerPageText = 25;
    }
    if (this.props.fromFaculty) {
      this.state.endpoint = "/faculty/directory";
      this.state.page = "faculty-directory";
      this.state.pageName = "Faculty and Staff Directory";
      this.state.csvTitle = "faculty_data";
      this.state.fieldsToShow = [
        { field_name: "display_name", display_name: "Name" },
        { field_name: "room", display_name: "Room" },
        { field_name: "email", display_name: "Email" },
        { field_name: "faculty_type", display_name: "Categories" },
      ];
      if (this.props.roles.admin) {
        this.state.fieldsToShow.push(
          {
            field_name: "phone",
            display_name: "Phone",
          },
          {
            field_name: "department",
            display_name: "Department",
          }
        );
      }
      if (this.props.roles.registrar)
        this.state.availableFields = [
          { field_name: "display_name", display_name: "Name" },
          { field_name: "room", display_name: "Room" },
          { field_name: "email", display_name: "Email" },
          { field_name: "faculty_type", display_name: "Categories" },
          { field_name: "department", display_name: "Department" },
          { field_name: "first_name", display_name: "First Name" },
          { field_name: "last_name", display_name: "Last Name" },
          { field_name: "id", display_name: "BYU ID" },
        ];
      if (this.props.roles.admin)
        this.state.availableFields = [
          { field_name: "display_name", display_name: "Name" },
          { field_name: "room", display_name: "Room" },
          { field_name: "email", display_name: "Email" },
          { field_name: "phone", display_name: "Phone" },
          { field_name: "faculty_type", display_name: "Categories" },
          { field_name: "department", display_name: "Department" },
          { field_name: "first_name", display_name: "First Name" },
          { field_name: "last_name", display_name: "Last Name" },
          { field_name: "id", display_name: "BYU ID" },
        ];
      this.state.facultyCategories = [
        [
          { field_name: "Adjunct Faculty", display_name: "Adjunct Faculty" },
          { field_name: "Deans", display_name: "Deans" },
          { field_name: "Emeriti Faculty", display_name: "Emeriti Faculty" },
          {
            field_name: "Faculty in Memoriam",
            display_name: "Faculty in Memoriam",
          },
          {
            field_name: "Full-Time Faculty",
            display_name: "Full-Time Faculty",
          },
        ],
        [
          {
            field_name: "Law Library Faculty",
            display_name: "Law Library Faculty",
          },
          {
            field_name: "Legal Writing Faculty",
            display_name: "Legal Writing Faculty",
          },
          {
            field_name: "Visiting Professors and Fellows",
            display_name: "Visiting Professors and Fellows",
          },
        ],
      ];
      this.state.elementsPerPage = 25;
      this.state.elementsPerPagePicture = 10;
      this.state.elementsPerPageText = 25;
    }
    if (this.props.fromGraduate) {
      this.state.endpoint = "/graduates/directory";
      this.state.page = "graduate-directory";
      this.state.pageName = "Graduate Directory";
      this.state.csvTitle = "graduate_data";
      this.state.fieldsToShow = [
        { field_name: "display_name", display_name: "Full Name" },
        { field_name: "display_address", display_name: "Address" },
        { field_name: "phone", display_name: "Phone" },
      ];
      if (this.props.roles.admin || this.props.roles.registrar)
        this.state.availableFields = [
          { field_name: "first_name", display_name: "First Name" },
          { field_name: "last_name", display_name: "Last Name" },
          { field_name: "id", display_name: "BYU ID" },
          { field_name: "net_id", display_name: "Net ID" },
          { field_name: "email", display_name: "Email" },
          { field_name: "display_name", display_name: "Full Name" },
          { field_name: "display_address", display_name: "Address" },
          { field_name: "phone", display_name: "Phone" },
          { field_name: "date_of_birth", display_name: "Birthday" },
          { field_name: "country", display_name: "Country" },
          { field_name: "gender", display_name: "Gender" },
          { field_name: "religion", display_name: "Religion" },
          { field_name: "ethnicity", display_name: "Ethnicity" },
          { field_name: "spouse", display_name: "Spouse" },
          { field_name: "mission", display_name: "Mission" },
          { field_name: "languages", display_name: "Language" },
        ];
      this.state.elementsPerPage = 10;
      this.state.elementsPerPagePicture = 10;
      this.state.elementsPerPageText = 25;
    }
  }

  /**
   * Scrolls the table to the top.
   *
   * @return {none} 
   */
  scrollToTableTop() {
    this.state.tableTop.current.scrollIntoView(false);
  }

  /**
   * Deselects all fields.
   */
  handleDeselect() {
    this.setState({ fieldsToShow: [] });
  }

  /**
   * Handles selecting or deselecting a field.
   *
   * @param {object} field - The field object that has been changed.
   * @return {void} This function does not return anything.
   */
  async handleFieldChange(field) {
    if (
      this.state.fieldsToShow.filter(
        (e) => e.display_name === field.display_name
      ).length === 0
    ) {
      let arr = [];
      this.state.fieldsToShow.map((item) => arr.push(item));
      arr.push(field);
      await this.setState({ fieldsToShow: arr });
      this.updatePagination(this.state.allData);
      this.setFieldSortStates();
      return;
    }
    if (
      this.state.fieldsToShow.filter(
        (e) => e.display_name === field.display_name
      ).length > 0
    ) {
      await this.setState({
        fieldsToShow: this.state.fieldsToShow.filter(
          (e) => e.display_name !== field.display_name
        ),
      });
      this.updatePagination(this.state.allData);
      this.setFieldSortStates();
      return;
    }
  }

  /**
   * Deselects all faculty categories to filter by.
   *
   */
  handleCategoryDeselect() {
    this.setState({ categoriesToShow: [] });
  }

  /**
   * Handles selecting or deselecting a faculty category to filter by.
   *
   * @param {object} category - The faculty category object.
   * @returns {void}
   */
  async handleCategoryChange(category) {
    if (
      this.state.categoriesToShow.filter(
        (e) => e.display_name === category.display_name
      ).length === 0
    ) {
      let arr = [];
      this.state.categoriesToShow.map((item) => arr.push(item));
      arr.push(category);
      await this.setState({ categoriesToShow: arr });
      this.updatePagination(this.state.allData);
      return;
    }
    if (
      this.state.categoriesToShow.filter(
        (e) => e.display_name === category.display_name
      ).length > 0
    ) {
      await this.setState({
        categoriesToShow: this.state.categoriesToShow.filter(
          (e) => e.display_name !== category.display_name
        ),
      });
      this.updatePagination(this.state.allData);
      return;
    }
  }

  /**
   * Sets the sort states for all fields to false.
   *
   * @param {void}
   * @return {void}
   */
  setFieldSortStates() {
    let map = new Map();
    this.state.fieldsToShow.map((field) => map.set(field.field_name, false));
    this.setState({ fieldSortStates: map });
  }

  /**
   * Handles sorting of rows by the given item. If the item is already sorted, it will reverse the sort.
   *
   * @param {string} item - The item to be sorted by.
   * @return {void} Returns nothing.
   */
  handleSort(item) {
    let findField = "";
    this.state.fieldsToShow.forEach((x) => {
      if (x.display_name === item) {
        findField = x.field_name;
      }
    });

    if (Array.isArray(findField)) {
      //This will handle sorting courses catalog number and section number since it is an array
      if (this.state.fieldSortStates.get(findField[0]) === true) {
        //We want to sort back to front, since the first element sort (catalog number) takes precedence
        for (let i = findField.length - 1; i >= 0; --i) {
          this.sortAscending(findField[i]);
        }
        this.state.fieldSortStates.set(findField[0], false);
      } else if (this.state.fieldSortStates.get(findField) === false) {
        //We want to sort back to front, since the first element sort (catalog number) takes precedence
        for (let i = findField.length - 1; i >= 0; --i) {
          this.sortDecending(findField[i]);
        }
        this.state.fieldSortStates.set(findField[0], true);
      }
    } else {
      //This will handle any other case with just a string
      if (this.state.fieldSortStates.get(findField) === true) {
        this.sortAscending(findField);
        this.state.fieldSortStates.set(findField, false);
      } else if (this.state.fieldSortStates.get(findField) === false) {
        this.sortDecending(findField);
        this.state.fieldSortStates.set(findField, true);
      }
    }

    this.setState({
      allData: this.state.allData,
      fieldSortStates: this.state.fieldSortStates,
    });

    this.updatePagination(this.state.allData);
  }

  /**
   * Sorts the data in ascending order based on the given key.
   *
   * @param {string} key - The key (field) to sort the data by.
   * @return {void}
   */
  sortAscending(key) {
    this.state.allData.sort(function (a, b) {
      var x = a[key];
      var y = b[key];
      return x === null ? 1 : y === null ? -1 : x > y ? -1 : x < y ? 1 : 0;
    });
  }
  /**
   * Sorts the data in descending order based on the given key.
   *
   * @param {string} key - The key (field) to sort the data by.
   * @return {void}
   */
  sortDecending(key) {
    this.state.allData.sort(function (a, b) {
      var x = a[key];
      var y = b[key];
      return x === null ? 1 : y === null ? -1 : x < y ? -1 : x > y ? 1 : 0;
    });
  }

  /**
   * Initializes the component after it has been mounted.
   * Sets the default filters and applies any filters from the URL.
   * Calls the populateData function to load the data from the server.
   *
   * @return {Promise} A promise that resolves when the component is fully initialized.
   */
  async componentDidMount() {
    this.setFieldSortStates();
    const defaultFilters = {
      professor: "",
    };
    let pathname = window.location.pathname.split("/");

    if (this.props.fromCourse) {
      defaultFilters.semester = releaseGradesGetCurrentSemester();

      if (pathname.length >= 4) {
        this.setState({ currentFormat: "Display" });
        defaultFilters.semester = pathname[2];
        //needs filter to load the pages even if it is nothing in the filters
        this.setState({ lastFilters: defaultFilters });
        await this.populateData(defaultFilters);
      } else {
        if (window.location.search.length > 0) {
          let s = new URLSearchParams(window.location.search);
          this.setState({ searchParams: s });
        } else {
          this.setState({ lastFilters: defaultFilters });
          await this.populateData(defaultFilters);
        }
      }
    } else if (
      this.props.fromFaculty ||
      this.props.fromStudent ||
      this.props.fromGraduate
    ) {
      if (window.location.search.length > 0) {
        let s = new URLSearchParams(window.location.search);
        this.setState({ searchParams: s });
      } else {
        this.setState({ lastFilters: defaultFilters });
        await this.populateData(defaultFilters);
      }
    }

    //Disable render while loading so re-renders don't occur
    this.setState({ disableRender: true });

    if (
      this.props.match.params.byuId &&
      (this.props.match.params.page || this.props.fromFaculty)
    ) {
      const selectedData = this.state.allData.find(
        (data) => parseInt(data.id) === parseInt(this.props.match.params.byuId)
      );
      if (
        selectedData &&
        (this.props.roles.admin ||
          this.props.roles.registrar ||
          this.props.roles.operator)
      ) {
        this.handleDisplayClick(selectedData, false);
      } else {
        this.updateURL(false);
      }
    }
    this.setState({ disableRender: false });
  }

  /**
   * Populates data based on the provided filters and updates the URL.
   *
   * @param {object} filters - The filters to be applied.
   * @return {Promise} A promise that resolves when the data is populated.
   */
  async populateData(filters, additionalDataFilter=false) {
    try {
      if (this.state.axiosCancelSource) this.state.axiosCancelSource.cancel();
      const cancelSource = axios.CancelToken.source();
      this.setState({ loading: true, axiosCancelSource: cancelSource });
      let params = {};
      if (this.props.fromCourse) {
        if (filters.catalog_number)
          params.catalog_number = filters.catalog_number;
        if (filters.name) params.catalog_title = filters.name;
        if (filters.professor) params.instructor_name = filters.professor;
        if (filters.credits) params.credits = filters.credits;
        if (filters.semester) params.semester = filters.semester; // changing this filter always calls the API
        if (filters.section) params.section_number = filters.section;
        if (filters.monday) params.monday = filters.monday; // filtering by days or schedule also calls the API
        if (filters.tuesday) params.tuesday = filters.tuesday;
        if (filters.wednesday) params.wednesday = filters.wednesday;
        if (filters.thursday) params.thursday = filters.thursday;
        if (filters.friday) params.friday = filters.friday;
        if (filters.saturday) params.saturday = filters.saturday;
        if (filters.gradeStatus) params.grade_status = filters.gradeStatus;
      }
      if (this.props.fromStudent) {
        params = {
          id: filters.id, 
          class: filters.class, // archived students switch
          year: filters.year, // group dropdown (year_in_program)
          gender: filters.gender, 
          full_name: filters.name, 
          postal_code: filters.zip_code, 
          phone: filters.phone, 
          carrel: filters.carrel,
          semester: filters.semester, 
          catalog_number: filters.course_id, 
          section_number: filters.section, 
        };
      }
      if (this.props.fromGraduate) {
        params = {
          id: filters.id,
          graduating_year: filters.year,
          gender: filters.gender,
          full_name: filters.name,
        };
      }
      if (this.props.fromFaculty) {
        let cat = filters.facultyCategories  // category calls the API
          ? filters.facultyCategories.join(",")
          : null;
        params = {
          id: filters.id,
          full_name: filters.name,
          room: filters.room,
          department: filters.department,
          phone: filters.phone,
          active: filters.active,
          category: cat,
        };
      }

      let dataCallFilters = {};

      // check for filters that require another API call
      if(additionalDataFilter) {
        // clear baseData
        await this.setState({ baseData: [] });
        // build params to pass to API call
        for (const [key, value] of Object.entries(params)) {
          if((this.props.fromStudent && STUDENT_API_FIELDS.includes(key)) || 
          ((this.props.fromCourse || this.props.fromFaculty) && COURSE_FACULTY_API_FIELDS.includes(key))) {
            dataCallFilters[key] = value;
          }
        }
      }
      else if(this.props.fromCourse) {
        if(filters.semester !== this.state.prevSemester) {
          // clear baseData
          await this.setState({ baseData: [] });
        }
        // filter by semester
        dataCallFilters["semester"] = filters.semester;
      }
      else if(this.props.fromFaculty) {
        if(params.category !== this.state.prevCategory) {
          // clear baseData
          await this.setState({ baseData: [] });
        }
        // filter by semester
        dataCallFilters["category"] = params.category;
      }

      if(this.state.baseData.length === 0) {
        const cancelToken = cancelSource.token;
        let { data: elementDataArray } = await api.get(`${this.state.endpoint}`, {
          params: dataCallFilters,
          cancelToken,
        });
        this.setState({ baseData: elementDataArray, allData: elementDataArray });
        if(this.props.fromCourse) this.setState({ prevSemester: filters.semester });
        else if(this.props.fromFaculty) this.setState({ prevCategory: params.category });

        // customize flexsearch with baseData
        const index = new FlexSearch.Document({
          document: {
            id: "id",
            index: "body"
          },
          tokenize: "full"
        });

        // function to generate course ids for course directory
        const getCourseId = (course) => {
          let idStr = "";
          idStr = idStr.concat(course.semester, course.catalog_number, course.section_number);
          return idStr;
        }

        // function to convert
        const convert = (obj) => {
          let bodyStr = "";
          for (const [key, value] of Object.entries(obj)) {
            if(key === "phone" && value) {
              let phoneKey = "";
              let numParts = value.split('-');
              numParts.forEach((num) => {
                phoneKey = phoneKey.concat(key, num, " ");
              });
              let noHyphenKey = value.replaceAll("-", "");
              bodyStr = bodyStr.concat(key, noHyphenKey, " ");
              bodyStr = bodyStr.concat(phoneKey);
              bodyStr = bodyStr.concat(key, value, " ");
            }
            else if(key === "grad_year") {
              bodyStr = bodyStr.concat(key, value.JD, " ");
              bodyStr = bodyStr.concat(key, value.LLM, " ");
            }
            else if(key === "department" && value) {
              let deptParts = value.split(' ');
              for(let i = 0; i < deptParts.length; i++) {
                bodyStr = bodyStr.concat(key, deptParts[i], ' ');
              }
            }
            else if(key === "instructors") {
              let altKey = "instructor_name";
              for(let i = 0; i < value.length; i++) {
                bodyStr = bodyStr.concat(altKey, value[i].first_name, " ");
                bodyStr = bodyStr.concat(altKey, value[i].last_name, " ");
              }
            }
            else bodyStr = bodyStr.concat(key, value, " ");
          }
          if(this.props.fromCourse) return { id: getCourseId(obj), body: bodyStr };
          else return { id: obj.id, body: bodyStr };
        };

        // add documents to map and store index
        const dataMap = new Map();
        this.state.baseData.forEach((record) => {
          index.add(convert(record));
          if(this.props.fromCourse) dataMap.set(getCourseId(record), record);
          else dataMap.set(record.id, record);
        });
        this.setState({ index: index, dataMap: dataMap });
      }

      // build query
      let query = "";
      for (const [key, value] of Object.entries(params)) {
        if(value) {
          let searchKey = key;
          if((this.props.fromStudent && STUDENT_API_FIELDS.includes(key)) || 
          ((this.props.fromCourse || this.props.fromFaculty) && COURSE_FACULTY_API_FIELDS.includes(key))) continue;
          if(key === "year") searchKey = "year_in_program"; // translate keys to match documents
          if(key === "graduating_year") searchKey = "grad_year";
          if(key === "credits") searchKey = "credit_hours";
          if(key === "full_name" || key === "catalog_title") searchKey = ""; // allows basic searches to search every part of the document
          query = query.concat(searchKey, value, " ");
        }
      }

      // pull out search results and initialize
      if(query) {
        // query search
        const searchLimit = this.state.baseData.length;
        let results = await this.state.index.search({ field: "body", query: query, limit: searchLimit });

        let newData = [];
        if(results.length > 0) {
          results[0].result.forEach((resultID) => {
            newData.push(this.state.dataMap.get(resultID))
          })
        }
        await this.setState({ allData: newData });
      }
      else await this.setState({ allData: this.state.baseData });

      this.updatePagination(this.state.allData);
    } catch (error) {
      if (!axios.isCancel(error)) {
        console.error(error);
        this.setState({
          loading: false,
          errorMessage: error.message,
          customMessage: "An error occurred while loading directory data: ",
        });
      }
    }

    let params;
    if (this.state.searchParams.toString().length > 0) {
      this.state.searchParams.sort();
      params = "?" + this.state.searchParams.toString();
    } else {
      params = "";
    }

    const nextURL = window.location.origin + window.location.pathname + params;
    const nextTitle = document.title;
    window.history.replaceState({}, nextTitle, nextURL);
  }

  /**
   * Updates the pagination of the element data.
   * Cleans up the data before it is displayed.
   *
   * @param {Array} elementDataArray - The array of data elements to be displayed.
   * @return {void}
   */
  async updatePagination(elementDataArray) {
    //setup data for display
    elementDataArray.forEach((data) => {
      if (data.known_by && data.known_by !== data.last_name) {
        data.display_name = data.known_by + " " + data.last_name;
      } else {
        data.display_name = data.first_name + " " + data.last_name;
      }
      if (data.substantial_writing === 0 || data.substantial_writing === null) {
        data.substantial_writing_display = "Not Complete";
      } else {
        data.substantial_writing_display = "Complete";
      }
      if (data.library_skills === 0 || data.library_skills === null) {
        data.library_skills_display = "Not Complete";
      } else {
        data.library_skills_display = "Complete";
      }
      data.display_address =
        data.street1 +
        " " +
        (data.street2 === ("" || undefined) ? " " + data.street2 : "") +
        (data.city ? " " + data.city : "") +
        (data.state ? ", " + data.state : "") +
        (data.postal_code ? ", " + data.postal_code : "");

      if (this.props.fromStudent || this.props.fromGraduate) {
        if (process.env.REACT_APP_STAGE === "dev") {
          data.picture_link =
            `https://s3.amazonaws.com/content.law-info.byu.edu-dev/student-pictures/${data.id}.jpg?` +
            new Date().getTime();
        } else {
          data.picture_link =
            `https://s3-us-west-2.amazonaws.com/content.law-info.byu.edu/student-pictures/${data.id}.jpg?` +
            new Date().getTime();
        }
      } else if (this.props.fromFaculty && data.picture_link === "") {
        if (process.env.REACT_APP_STAGE === "dev") {
          if (data.id !== undefined) {
            data.picture_link =
              `https://s3.amazonaws.com/content.law-info.byu.edu-dev/faculty-pictures/${data.id}.jpg` +
              new Date().getTime();
          } else {
            const full_name = `${data.first_name} ${data.last_name}`;
            data.picture_link = `https://ui-avatars.com/api/?name=${full_name}&size=275&background=E2E0DD`;
          }
        } else {
          data.picture_link =
            `https://s3-us-west-2.amazonaws.com/content.law-info.byu.edu/faculty-pictures/${data.id}.jpg?` +
            new Date().getTime();
        }
      }
      if (this.props.fromCourse) {
        data.days_of_week = "";
        data.room = "";
        data.start_time = "";
        data.end_time = "";
        data.schedule.forEach((course) => {
          switch (course.day) {
            case "Monday":
              data.days_of_week += "M";
              break;
            case "Tuesday":
              data.days_of_week += "Tu";
              break;
            case "Wednesday":
              data.days_of_week += "W";
              break;
            case "Thursday":
              data.days_of_week += "Th";
              break;
            case "Friday":
              data.days_of_week += "F";
              break;
            case "Saturday":
              data.days_of_week += "Sa";
              break;
            default:
              break;
          }

          data.room = course.room;
          data.start_time = course.start;
          data.end_time = course.end;
        });
        data.professors = "";
        data.instructors.forEach((professor) => {
          if (!professor) return;
          data.professors +=
            (data.professors !== "" ? " | " : "") +
            professor.last_name +
            (professor.first_name ? ", " + professor.first_name : "");
        });
        data.editable = false;
      }
      if (data.category) {
        data.faculty_type = data.category.join(", ");
      }
    });

    //get the first "page" of elements data
    const currentElementPage = this.state.currentElementPage;
    const lastPage = Math.ceil(
      elementDataArray.length / this.state.elementsPerPage - 1
    );

    if (currentElementPage > lastPage) {
      this.setState({ currentElementPage: 0 });
      this.setElementPage(0, this.state.elementsPerPage, elementDataArray);
    } else {
      this.setElementPage(
        currentElementPage,
        this.state.elementsPerPage,
        elementDataArray,
        elementDataArray
      );
    }

    if (elementDataArray.length === 0) {
      this.updateURL(false);
      this.setState({
        loading: false,
        selectedData: {},
        allData: elementDataArray,
        axiosCancelSource: false,
        currentFormat:
          this.state.currentFormat === "Display"
            ? this.state.storeFormat
            : this.state.currentFormat,
      });
    } else if (elementDataArray[0]) {
      this.setState({
        loading: false,
        selectedData: elementDataArray[0],
        allData: elementDataArray,
        axiosCancelSource: false,
      });
    } else {
      this.setState({
        loading: false,
        selectedData: {},
        allData: elementDataArray,
        axiosCancelSource: false,
      });
    }
  }

  /**
   * Gets the subset of data to be displayed on the current page.
   *
   * @param {number} pageNumber - The current page number.
   * @param {number} elementsPerPage - The number of elements per page.
   * @param {Array} allData - The array containing all the data.
   */
  setElementPage(pageNumber, elementsPerPage, allData) {
    if (allData) {
      const pageData = allData.slice(
        pageNumber * elementsPerPage,
        pageNumber * elementsPerPage + elementsPerPage
      );
      this.setState({
        pageData,
      });
      return;
    }
    if (elementsPerPage) {
      const pageData = this.state.allData.slice(
        pageNumber * elementsPerPage,
        pageNumber * elementsPerPage + elementsPerPage
      );
      this.setState({ pageData });
      return;
    }
    const pageData = this.state.allData.slice(
      pageNumber * this.state.elementsPerPage,
      pageNumber * this.state.elementsPerPage + this.state.elementsPerPage
    );
    this.setState({
      pageData,
    });
  }

  /**
   * Handles the change of format between TextOnly and TextPhoto.
   *
   * @return {void}
   */
  async handleFormatChange() {
    this.setState({ loading: true });
    const newFormat =
      this.state.currentFormat === "TextOnly" ? "TextPhoto" : "TextOnly";
    let elementsPerPage = 0;
    if (newFormat === "TextPhoto")
      elementsPerPage = this.state.elementsPerPagePicture;
    else if (newFormat === "TextOnly")
      elementsPerPage = this.state.elementsPerPageText;
    this.setElementPage(0, elementsPerPage);
    this.setState({
      storeFormat: this.state.currentFormat,
      currentElementPage: 0,
      currentFormat: newFormat,
      loading: false,
      elementsPerPage,
    });
  }

  /**
   * Updates the component state with the new filters and triggers the data population process.
   *
   * @param {Object} filters - The new filters to be applied.
   * @return {Promise<void>} - A promise that resolves when the data population is complete.
   */
  async handleFilterChange(filters, additionalDataFilter=false) {
    this.setState({
      loading: true,
      lastFilters: filters,
      errorMessage: null,
    });
    this.handleFirst();
    await this.populateData(filters, additionalDataFilter);
  }

  /**
   * Remove specified fields from an object.
   *
   * @param {Object} obj - The object to modify.
   * @param {Array} fields - An array of strings specifying the fields to remove.
   */
  modifyObjectByFields(obj, fields) {
    for (let index = 0; index < fields.length; index++) {
      const element = fields[index];

      switch (element) {
        case "id":
          delete obj?.id;
          break;
        case "first_name":
          delete obj?.first_name;
          break;
        case "last_name":
          delete obj?.last_name;
          break;
        case "known_by":
          delete obj?.known_by;
          break;
        case "net_id":
          delete obj?.net_id;
          break;
        case "gender":
          delete obj?.gender;
          break;
        case "ethnicity":
          delete obj?.ethnicity;
          break;
        case "year_in_program":
          delete obj?.year_in_program;
          break;
        case "date_of_birth":
          delete obj?.date_of_birth;
          break;
        case "email":
          delete obj?.email;
          break;
        case "exam_number":
          delete obj?.exam_number;
          break;
        case "lsat_score":
          delete obj?.lsat_score;
          break;
        case "pre_law_gpa":
          delete obj?.pre_law_gpa;
          break;
        case "comments":
          delete obj?.comments;
          break;
        case "street1":
          delete obj?.street1;
          break;
        case "street2":
          delete obj?.street2;
          break;
        case "city":
          delete obj?.city;
          break;
        case "state":
          delete obj?.state;
          break;
        case "country":
          delete obj?.country;
          break;
        case "postal_code":
          delete obj?.postal_code;
          break;
        case "phone":
          delete obj?.phone;
          break;
        case "spouse":
          delete obj?.spouse;
          break;
        case "carrel":
          delete obj?.carrel;
          break;
        case "substantial_writing":
          delete obj?.substantial_writing;
          break;
        case "library_skills":
          delete obj?.library_skills;
          break;
        case "display_name":
          delete obj?.display_name;
          break;
        case "display_address":
          delete obj?.display_address;
          break;
        case "library_skills_display":
          delete obj?.library_skills_display;
          break;
        case "substantial_writing_display":
          delete obj?.substantial_writing_display;
          break;
        case "expected_honors":
          delete obj?.expected_honors;
          break;
        case "religion":
          delete obj?.religion;
          break;
        case "restricted_contact_information":
          delete obj?.restricted_contact_information;
          break;

        //faculty
        case "middle_name":
          delete obj?.middle_name;
          break;
        case "suffix":
          delete obj?.suffix;
          break;
        case "title":
          delete obj?.title;
          break;
        case "full_name": // similar to display_name
          delete obj?.full_name;
          break;
        case "type":
          delete obj?.type;
          break;
        case "active": // similar to active_faculty
          delete obj?.active;
          break;
        case "record_lock":
          delete obj?.record_lock;
          break;
        case "bio_link":
          delete obj?.bio_link;
          break;
        case "notes": // similar to comments
          delete obj?.notes;
          break;
        case "active_faculty": // similar to active
          delete obj?.active_faculty;
          break;
        case "room":
          delete obj?.room;
          break;
        case "department":
          delete obj?.department;
          break;
        case "category":
          delete obj?.category;
          break;
        case "faculty_type":
          delete obj.faculty_type;
          break;

        // graduates
        case "mission":
          delete obj?.mission;
          break;
        case "languages":
          delete obj?.languages;
          break;
        case "grad_year":
          delete obj?.grad_year;
          break;
        case "date_of_graduation":
          delete obj?.date_of_graduation;
          break;

        default:
          break;
      }
    }
  }

  /**
   * Prepares the data and downloads it as a CSV.
   * Removes any fields that the user should not see.
   *
   * @return {void} No return value
   */
  async handleDownloadCSV() {
    if (this.state.allData.length <= 0) return;

    // Make a deep copy of the data
    const array = JSON.parse(JSON.stringify(this.state.allData));

    this.setState({ csvLoading: true });

    let filteredArray, fields;
    let textOnly;
    await (textOnly = this.state.currentFormat === "TextOnly");
    if (textOnly) {
      filteredArray = []; //new Array();
      fields = []; //new Array();
      for (let index = 0; index < this.state.fieldsToShow.length; index++) {
        const element = this.state.fieldsToShow[index];
        fields.push(element.field_name);
      }

      let objKeys = Object.keys(array[0]);
      filteredArray = objKeys.filter((item) => !fields.includes(item));
    }

    let newArray = array.map((obj) => {
      delete obj.picture_link;
      delete obj.show_on_public;
      delete obj.instructors;
      delete obj.schedule;

      delete obj?.honors_exception;
      delete obj?.name_on_diploma;

      if (!textOnly) {
        delete obj.display_address;
        delete obj.display_name;
        delete obj?.substantial_writing;
        delete obj?.library_skills;
      }

      if (this.props.fromFaculty || this.props.fromGraduate) {
        delete obj?.substantial_writing_display;
        delete obj?.library_skills_display;
      }

      if (obj.law_degrees !== undefined) {
        if (obj.law_degrees[0] !== undefined) {
          obj.law_degree_1 = obj.law_degrees[0].degree;
          if (obj.law_degrees[0].date_degree_received !== null) {
            obj.law_degree_1_date =
              obj.law_degrees[0].date_degree_received.split("T")[0];
          } else {
            obj.law_degree_1_date = "";
          }
        }

        if (obj.law_degrees[1] !== undefined) {
          obj.law_degree_2 = obj.law_degrees[1].degree;
          if (obj.law_degrees[1].date_degree_received !== null) {
            obj.law_degree_2_date =
              obj.law_degrees[1].date_degree_received.split("T")[0];
          } else {
            obj.law_degree_2_date = "";
          }
        }
      }

      delete obj.law_degrees;

      //Only Admins and Registrars should see spouses
      if (!this.props.roles.registrar && !this.props.roles.admin) {
        delete obj.spouse;
      }

      if (obj.date_of_birth) {
        obj.date_of_birth = obj.date_of_birth.split("T")[0];
      }

      // Students can't see DOBs or BYU IDs
      if (this.props.roles.student) {
        delete obj.id;
        delete obj.date_of_birth;
        delete obj.substantial_writing_display;
        delete obj.library_skills_display;
        delete obj.restricted_contact_information;
      }

      if (textOnly) {
        //delete obj?.expected_honors;
        delete obj?.joint_degree;

        delete obj?.law_degree_1;
        delete obj?.law_degree_1_date;
        delete obj?.law_degree_2;
        delete obj?.law_degree_2_date;

        this.modifyObjectByFields(obj, filteredArray);
      }

      return obj;
    });
    this.setState({ showPopUpWarning: true, csvLoading: false });
    generateCSV(newArray, `${this.state.csvTitle}.csv`);
  }

/**
 * Sets flash card loading to true and calls function to generate flash cards.
 *
 * @return {Promise<void>} - A promise that resolves when the function is complete.
 */
  async handleFlashCards() {
    const array = this.state.allData;
    this.setState({ flashCardLoading: true, showPopUpWarning: true });
    await generateFlashCards(array);
    this.setState({ flashCardLoading: false });
  }

  /**
   * Handles the click event on the display element.
   * Sets the state of the component to display the selected element.
   *
   * @param {object} element - The element that was clicked.
   * @param {boolean} showGrades - Whether to show grades or not. Defaults to false.
   */
  handleDisplayClick(element, showGrades = false) {
    if (element.id === getDevId()) {
      this.setState({ loading: true });
      window.location.href = getMyInfoLink(this.props.roles);
    }
    this.updateURL(element.id);
    //disable filters on click
    this.disablePageNav();

    this.setState({
      storeFormat: this.state.currentFormat,
      currentFormat: "Display",
      gradeSubmissionDisplay: showGrades,
      selectedData: element,
      disablePaginator: true,
      showPopUpWarning: false,
    });

    if (showGrades) {
      let url =
        window.location.origin +
        "/" +
        "course-directory" +
        "/" +
        element.semester +
        "/" +
        element.catalog_number +
        "/" +
        element.section_number +
        "/GradeSubmission";

      window.history.replaceState({}, "Grade Submission", url);
    }
  }

  /**
   * Allows the user to press enter or space to toggle the display of the element.
   *
   * @param {Event} event - The event object.
   * @param {Element} element - The element to display.
   * @param {boolean} [showGrades=false] - Whether to show grades or not. Defaults to false.
   */
  handleDisplayButton(event, element, showGrades = false) {
    if (event.keyCode === 13 || event.keyCode === 32) {
      this.handleDisplayClick(element, showGrades);
    }
  }

/**
 * Toggle the edit status of the element.
 *
 * @param {Object} element - The element to handle.
 * @return {void}
 */
  handleEditStatus(element) {
    const pageData = this.state.pageData;
    let isGradeStatusInEdit = false;
    pageData[pageData.indexOf(element)].editable = !element.editable;
    isGradeStatusInEdit = element.editable;
    this.setState({ pageData, isGradeStatusInEdit });
  }

  /**
   * Updates the loading status of an element in the pageData array and
   * updates the state accordingly.
   *
   * @param {object} element - The element whose loading status will be updated
   * @return {void} 
   */
  handleLoadingStatus(element) {
    const pageData = this.state.pageData;
    // let isLoading = false;
    pageData[pageData.indexOf(element)].loading = !element.loading;

    this.setState({ pageData });
  }

  /**
   * Asynchronously handles the cancel action to go back to the directory.
   *
   * @return {void}
   */
  async handleCancel() {
    this.updateURL(false);
    this.setState({
      currentFormat: this.state.storeFormat,
      storeFormat: "",
      selectedData: {},
      disablePaginator: false,
    });

    //enable filters on return to directory
    this.enablePageNav();

    await this.populateData(this.state.lastFilters);
  }

  /**
   * Sets the current element page to the first page.
   *
   * @return {Promise<void>} Does not return anything.
   */
  async handleFirst() {
    const firstPage = 0;
    if (this.state.currentElementPage === firstPage) return;
    this.setState({ loading: true, errorMessage: null });
    await this.setElementPage(firstPage);
    this.setState({ loading: false, currentElementPage: firstPage });
    window.scrollTo(0, 0);
  }

  /**
   * Sets the current element page to the last page.
   *
   * @return {Promise<void>} - Returns a promise that resolves to nothing.
   */
  async handleLast() {
    //get last page number
    const lastPage = Math.ceil(
      this.state.allData.length / this.state.elementsPerPage - 1
    );
    if (this.state.currentElementPage === lastPage) return;
    //move to the last page
    this.setState({ loading: true, errorMessage: null });
    await this.setElementPage(lastPage);
    this.setState({ loading: false, currentElementPage: lastPage });
    window.scrollTo(0, 0);
  }

  /**
   * Changes the current element page to the next page.
   *
   * @return {Promise<void>} - A promise that resolves when the action is complete.
   */
  async handleNext() {
    //check if page is beyond the end of the array
    const nextPage = this.state.currentElementPage + 1;
    if (
      nextPage >
      Math.ceil(this.state.allData.length / this.state.elementsPerPage - 1)
    )
      return;
    //move to the next page
    this.setState({ loading: true, errorMessage: null });
    await this.setElementPage(nextPage);
    this.setState({ loading: false, currentElementPage: nextPage });
    window.scrollTo(0, 0);
  }

  /**
   * Changes the current element page to the previous page.
   *
   * @return {Promise<void>} - Returns a promise that resolves when the action is complete.
   */
  async handlePrev() {
    //check if page is before the start of the array
    const prevPage = this.state.currentElementPage - 1;
    if (prevPage < 0) return;
    //move to the first page
    this.setState({ loading: true, errorMessage: null });
    await this.setElementPage(prevPage);
    this.setState({ loading: false, currentElementPage: prevPage });
    window.scrollTo(0, 0);
  }

  /**
   * Updates the URL based on the given nextId.
   *
   * @param {any} nextId - The nextId to update the URL with.
   * @return {void} This function does not return a value.
   */
  updateURL(nextId) {
    if (!this.props.fromFaculty) {
      nextId = false;
    }
    let nextURL = "";
    if (
      nextId &&
      (this.props.roles.admin ||
        this.props.roles.registrar ||
        this.props.roles.operator)
    ) {
      nextURL = `${window.location.protocol}//${window.location.host}/${this.state.page}/${nextId}`;
    } else {
      nextURL = `${window.location.protocol}//${window.location.host}/${this.state.page}`;
    }
    const nextTitle = document.title;
    // This will replace the current entry in the browser's history, without reloading
    window.history.replaceState({}, nextTitle, nextURL);
  }

  /**
   * Disable the page navigation.
   *
   * @return {void}
   */
  disablePageNav() {
    this.setState({ disablePageNav: true });
  }

  /**
   * Enable page navigation by setting `disablePageNav` to `false`.
   *
   * @return {void}
   */
  enablePageNav() {
    this.setState({ disablePageNav: false });
  }

  /**
   * Updates the selected course information.
   *
   * @param {object} course - the course data to set as selected
   * @return {void}
   */
  updateSelectedCourse(course) {
    this.setState({ selectedData: course });
  }

  /**
   * Selects the next course in the list.
   *
   * @return {Promise} Resolves when the function is complete.
   */
  async handleCourseNext() {
    let courses = this.state.allData;
    let selectedCourse = this.state.selectedData;
    let newSelectedCourse = {};
    for (let i = 0; i < courses.length; ++i) {
      let currCourse = courses[i];
      if (
        currCourse.curriculum_id === selectedCourse.curriculum_id &&
        currCourse.section_number === selectedCourse.section_number &&
        currCourse.catalog_number === selectedCourse.catalog_number
      ) {
        if (i === courses.length - 1) {
          newSelectedCourse = courses[0];
        } else {
          newSelectedCourse = courses[i + 1];
        }
      }
    }
    await this.handleDisplayClick(newSelectedCourse, false);
    await this.setState({ storeFormat: "" });
  }

  /**
   * Selects the previous course in the list.
   *
   * @return {Promise} A Promise that resolves when the action is completed.
   */
  async handleCoursePrev() {
    let courses = this.state.allData;
    let selectedCourse = this.state.selectedData;
    let newSelectedCourse = {};
    for (let i = 0; i < courses.length; ++i) {
      let currCourse = courses[i];
      if (
        currCourse.curriculum_id === selectedCourse.curriculum_id &&
        currCourse.section_number === selectedCourse.section_number &&
        currCourse.catalog_number === selectedCourse.catalog_number
      ) {
        if (i === 0) {
          newSelectedCourse = courses[courses.length - 1];
        } else {
          newSelectedCourse = courses[i - 1];
        }
      }
    }
    await this.handleDisplayClick(newSelectedCourse, false);
    await this.setState({ storeFormat: "" });
  }

  /**
   * Renders the correct component based on the current format.
   *
   * @param {string} currentFormat - The current format to render.
   * @return {JSX.Element} The rendered component based on the current format.
   */
  renderSwitch(currentFormat) {
    if (this.state.loading || this.state.disableRender) {
      return (
        <div className="directoryLoading">
          <h1
            className="loadingText"
            style={{ marginBottom: "1em", marginLeft: "0.5em" }}
          >
            Loading Data...
          </h1>
          <Spinner animation={"border"} />
        </div>
      );
    }
    if (this.state.errorMessage) {
      return (
        <ErrorDisplay
          errorMessage={this.state.errorMessage}
          customMessage={this.state.customMessage}
        />
      );
    }
    if (this.state.pageData.length === 0) {
      return (
        <Card className="directory-shadow">
          <Card.Body>No results</Card.Body>
        </Card>
      );
    }
    switch (currentFormat) {
      case "TextPhoto":
        if (this.props.fromCourse) {
          return (
            <SimpleViewCourse
              fromCourse={this.props.fromCourse}
              fromFaculty={this.props.fromFaculty}
              fromStudent={this.props.fromStudent}
              fromGraduate={this.props.fromGraduate}
              dataSet={this.state.pageData}
              handleDisplayButton={this.handleDisplayButton}
              handleDisplayClick={this.handleDisplayClick}
              roles={this.props.roles}
            />
          );
        }
        return (
          <SimpleViewPerson
            fromCourse={this.props.fromCourse}
            fromFaculty={this.props.fromFaculty}
            fromStudent={this.props.fromStudent}
            fromGraduate={this.props.fromGraduate}
            dataSet={this.state.pageData}
            handleDisplayButton={this.handleDisplayButton}
            handleDisplayClick={this.handleDisplayClick}
            roles={this.props.roles}
          />
        );
      case "Display":
        if (this.props.fromCourse) {
          return (
            <CourseDisplay
              selectedCourseData={this.state.selectedData}
              handleCancel={this.handleCancel}
              disableDirectoryPageNav={this.disablePageNav}
              enableDirectoryPageNav={this.enablePageNav}
              roles={this.props.roles}
              gradeSubmissionDisplay={this.state.gradeSubmissionDisplay}
              handleNext={this.handleCourseNext}
              handlePrev={this.handleCoursePrev}
              lastFilters={this.state.lastFilters}
              populateNewData={this.populateData}
              updateSelectedCourse={this.updateSelectedCourse}
            />
          );
        }
        if (this.props.fromFaculty) {
          return (
            <FacultyInformation
              fromDirectory
              person={this.state.selectedData}
              handleCancel={this.handleCancel}
              disableDirectoryPageNav={this.disablePageNav}
              enableDirectoryPageNav={this.enablePageNav}
              roles={this.props.roles}
            />
          );
        }
        if (this.props.fromStudent || this.props.fromGraduate) {
          if (
            this.props.roles.admin ||
            this.props.roles.registrar ||
            this.props.roles.operator
          ) {
            return (
              <StudentInformation
                isGraduate={this.props.fromGraduate}
                fromDirectory
                fromMassAdvance={this.props.location.state?.from}
                disableDirectoryPageNav={this.disablePageNav}
                enableDirectoryPageNav={this.enablePageNav}
                handleReturnToDirectory={this.handleCancel}
                students={this.state.allData}
                studentId={this.state.selectedData.id}
                roles={this.props.roles}
              />
            );
          } else {
            return (
              <StudentDirectoryInfo
                student={this.state.selectedData}
                roles={this.props.roles}
                disableDirectoryPageNav={this.disablePageNav}
                enableDirectoryPageNav={this.enablePageNav}
                handleReturnToDirectory={this.handleCancel}
                fromGraduate={this.props.fromGraduate}
                fromStudent={this.props.fromStudent}
              />
            );
          }
        }
        break;
      default:
        return (
          <TextOnly
            dataSet={this.state.pageData}
            isGradeStatusInEdit={this.state.isGradeStatusInEdit}
            handleDisplayButton={this.handleDisplayButton}
            handleDisplayClick={this.handleDisplayClick}
            fieldsToShow={this.state.fieldsToShow}
            handleEditStatus={this.handleEditStatus}
            handleLoadingStatus={this.handleLoadingStatus}
            roles={this.props.roles}
            handleSort={this.handleSort}
            fromPrint={false}
            pageName={this.state.pageName}
            fromCourse={this.props.fromCourse}
            tableTop={this.state.tableTop}
          />
        );
    }
  }

  render() {
    const directoryNavigator = {
      actions: {
        handleFormatChange: this.handleFormatChange,
        handleFilterChange: this.handleFilterChange,
        handleDownloadCSV: this.handleDownloadCSV,
        handleFlashCards: this.handleFlashCards,
      },
    };
    const paginator = {
      actions: {
        next: this.handleNext,
        last: this.handleLast,
        first: this.handleFirst,
        prev: this.handlePrev,
        // max: this.handleLast.lastPage,
        // min: this.handleFirst.firstPage,
      },
      totalRecords: this.state.allData.length,
      currentPage:
        this.state.allData.length > 0 ? this.state.currentElementPage + 1 : 0,
      totalPages: Math.ceil(
        this.state.allData.length / this.state.elementsPerPage
      ),
      currentElementStart:
        this.state.allData.length > 0 ?
          1 + this.state.currentElementPage * this.state.elementsPerPage :
          0,
      currentElementEnd:
        this.state.currentElementPage * this.state.elementsPerPage +
          this.state.elementsPerPage <
        this.state.allData.length
          ? this.state.currentElementPage * this.state.elementsPerPage +
            this.state.elementsPerPage
          : this.state.allData.length,
    };

    const formatOptions = [
      { value: "TextPhoto", display: "Text With Photo" },
      { value: "TextOnly", display: "Text Only" },
    ];

    const groupOptions = [
      { value: "", display: "All Students" },
      { value: "1", display: "1Ls Only" },
      { value: "2", display: "2Ls Only" },
      { value: "3", display: "3Ls Only" },
      { value: "llm", display: "LLMs Only" },
      { value: "nl", display: "Non-Law Only" },
    ];

    // const renderTooltip = (props) => (
    //   <Tooltip id="button-tooltip" {...props}>
    //     If data is cut-off from the table, select the more settings option on
    //     the dropdown for formatting options
    //   </Tooltip>
    // );

    return (
      <div className="container-fluid p-0">
        <DirectoryHeader
          {...this.props}
          fromCourse={this.props.fromCourse}
          fromFaculty={this.props.fromFaculty}
          fromStudent={this.props.fromStudent}
          fromGraduate={this.props.fromGraduate}
          disabled={this.state.disablePageNav}
          currentFormat={this.state.currentFormat}
          storeFormat={this.state.storeFormat}
          pageName={this.state.pageName}
          formatOptions={formatOptions}
          groupOptions={groupOptions}
          directoryNavigator={directoryNavigator}
          roles={this.props.roles}
          loading={this.state.loading || this.state.allData.length === 0}
          flashCardLoading={this.state.flashCardLoading}
          csvLoading={this.state.csvLoading}
          disablePaginator={this.state.disablePaginator}
          disablePageCount={this.state.currentFormat === "Display"}
          showPopUpWarning={this.state.showPopUpWarning}
          searchParams={this.state.searchParams}
          facultyCategories={this.state.facultyCategories}
          categoriesToShow={this.state.categoriesToShow}
          handleCategoryChange={this.handleCategoryChange}
          handleCategoryDeselect={this.handleCategoryDeselect}
          printRef={this.state.tablePrintRef}
        />
        <Card
          className="py-0 px-0 mb-5 mb-sm-0 directory-display-wrapper"
        >
          <TableHeader
            numResults={this.state.allData.length}
            currentElementPage={this.state.currentElementPage}
            firstPage={this.state.handleFirst}
            previousPage={this.state.handlePrev}
            currentPage={this.state.currentElementPage}
            nextPage={this.state.handleNext}
            lastPage={this.state.handleLast}
            totalPages
            currentFormat={this.state.currentFormat}
            paginator={paginator}
            elementsPerPage={this.state.elementsPerPage}
            availableFields={this.state.availableFields}
            fieldsToShow={this.state.fieldsToShow}
            handleFieldChange={this.handleFieldChange}
            handleDeselect={this.handleDeselect}
            handleFormatChange={this.handleFormatChange}
            disablePageNav={this.state.disablePageNav}
            {...this.props}
          />
          <div style={{ display: "none" }}>
            <TextOnly
              style={{ marginTop: 25 }}
              dataSet={this.state.allData}
              isGradeStatusInEdit={this.state.isGradeStatusInEdit}
              handleDisplayClick={this.handleDisplayClick}
              fieldsToShow={this.state.fieldsToShow}
              handleEditStatus={this.handleEditStatus}
              handleLoadingStatus={this.handleLoadingStatus}
              roles={this.props.roles}
              handleSort={this.handleSort}
              ref={this.state.tablePrintRef}
              fromPrint={true}
              pageName={this.state.pageName}
            />
          </div>
          {this.state.fieldsToShow.length === 0 &&
          this.state.currentFormat === "TextOnly" ? (
            <Alert variant="info">
              You need to select a field to see the table
            </Alert>
          ) : (
            this.renderSwitch(this.state.currentFormat)
          )}
          <TableFooter
            paginator={paginator}
            disablePageNav={this.state.disablePageNav}
            {...this.props}
          />
        </Card>
      </div>
    );
  }
}

export default withRouter(Directory);
