import React from "react";
import { Button, Form, Modal } from "react-bootstrap";
import ErrorDisplay from "../components/ErrorDisplay";
import { displaySemester } from "../utils/functions";

class ViewSemesterReportModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      semester: "",
      reportData: this.props.data,
      urlSelection: "0",
      nameOfReport: null,
      errorMessage: "noErrors",
    };
    this.openSelectedReport = this.openSelectedReport.bind(this);
    this.makeSemesterSelection = this.makeSemesterSelection.bind(this);
    this.handleSemesterChange = this.handleSemesterChange.bind(this);
    this.getNameOfReport = this.getNameOfReport.bind(this);
  }

  async componentDidMount() {
    await this.getNameOfReport();
  }

  getNameOfReport() {
    let name = "noName";

    switch (this.props.reportType) {
      case "progressreport":
        name = "Progress Report";
        break;
      case "deanslist":
        name = "Deans List Letter";
        break;
      case "classrank":
        name = "Ranking Letter";
        break;
      case "honorsletter":
        name = "Honors Letter";
        break;
      default:
        break;
    }
    this.setState({ nameOfReport: name });
  }

  handleSemesterChange(e) {
    this.setState({ urlSelection: e.target.value });
  }

  makeSemesterSelection(semester) {
    semester =
      displaySemester(semester)[1] + " " + displaySemester(semester)[0];
    return semester;
  }

  openSelectedReport() {
    //open the object.data.url
    if (this.state.urlSelection !== "0") {
      window.open(this.state.urlSelection);
    }

    this.props.onHide();
  }

  render() {
    let { show, onHide } = this.props;
    return (
      <Modal show={show} onHide={this.onHide}>
        <Modal.Header>
          <Modal.Title>View {this.state.nameOfReport}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p> Select the semester to view your {this.state.nameOfReport}</p>

          <Form.Select
            id="Semester"
            value={this.state.urlSelection}
            onChange={this.handleSemesterChange}
          >
            <option value="0">Select Semester</option>
            {this.state.reportData.map((item) => (
              <option value={item.url}>
                {this.makeSemesterSelection(item.semester)}
              </option>
            ))}
          </Form.Select>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="info"
            onClick={this.openSelectedReport}
            disabled={this.state.urlSelection === "0"}
          >
            View
          </Button>
          <Button variant="outline-info" onClick={onHide}>
            Cancel
          </Button>
        </Modal.Footer>
        {this.state.errorMessage !== "noErrors" && (
          <ErrorDisplay
            errorMessage={this.state.errorMessage}
            customMessage="An error occurred while loading data: "
          />
        )}
      </Modal>
    );
  }
}

export default ViewSemesterReportModal;
