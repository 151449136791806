import { faFilePdf } from "@fortawesome/free-regular-svg-icons";
import { faLink, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React from "react";
import { Button, Card, Col, Container, Row, Spinner } from "react-bootstrap";
import ViewSemesterReportModal from "../pages/ViewSemesterReportModal";
import api from "../services/api";
import {
  displaySemester,
  releaseGradesGetCurrentSemester,
} from "../utils/functions";
import ErrorDisplay from "./ErrorDisplay";
import ToolButton from "./tools/ToolButton";

class RepUtil extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      student: this.props.student,
      address: this.props.student.address,
      validSemesters: [],
      loadingExamNumbers: false,
      showExamNumberModal: false,
      deansList: false,
      rankingLetter: false,
      progressReport: false,
      honorsLetter: false,
      loadingHonorsLetter: false,
      progressReportData: [],
      deansListData: [],
      rankingLetterData: [],
      honorsLetterData: [],
      targetData: [],
      viewReportModal: false,
      reportType: "nothing",
      currentSemesterDisplay:
        displaySemester(releaseGradesGetCurrentSemester())[1] +
        " " +
        displaySemester(releaseGradesGetCurrentSemester())[0],
      errorMessage: null,
    };
    this.rankingLetterExists = this.rankingLetterExists.bind(this);
    this.deansListLetterExist = this.deansListLetterExists.bind(this);
    this.progressReportExists = this.progressReportExists.bind(this);
    this.honorsLetterExists = this.honorsLetterExists.bind(this);
    this.newOpenHonors = this.newOpenHonors.bind(this);
    this.toggleViewReportModal = this.toggleViewReportModal.bind(this);
    this.viewReport = this.viewReport.bind(this);
    this.regenerateProgressReport = this.regenerateProgressReport.bind(this);

    //Find the valid semesters
    const student = this.state.student;
    let validSemesters = [];
    for (let i = 0; i < student.courses.length; i++) {
      // Add the semester if it isn't already in there
      if (
        !validSemesters.find((x) => student.courses[i].semester === x.value) &&
        student.courses[i].catalog_number !== "BYU" &&
        student.courses[i].catalog_number !== "XFER"
      ) {
        let display = displaySemester(student.courses[i].semester);
        display = display[1] + " " + display[0];
        validSemesters.push({
          value: student.courses[i].semester,
          display: display,
        });
      }
    }
    this.state.validSemesters = validSemesters;
  }

  async componentDidMount() {
    this.setState({ loading: true });

    await this.deansListLetterExist();
    await this.rankingLetterExists();
    await this.progressReportExists();
    await this.honorsLetterExists();

    this.setState({ loading: false });
  }

  async honorsLetterExists() {
    const studentid = this.state.student.id;
    const url = `/reports/allstoredreports?report=honorsletters&studentid=${studentid}`;

    try {
      const honorsletter = await api.get(url);

      this.setState({ honorsLetter: true });
      this.setState({ honorsLetterData: honorsletter.data });
    } catch {
      this.setState({ honorsLetter: false });
    }
  }

  async newOpenHonors() {
    this.setState({ loadingHonorsLetter: true });

    const studentid = this.state.student.id;
    const url = `/reports/allstoredreports?report=honorsletters&studentid=${studentid}`;
    const honorsletter = await api.get(url);

    try {
      window.open(honorsletter.data[0].url);
    } catch (err) {
      this.setState({
        customMessage: "Error downloading report",
      });
    }

    this.setState({ loadingHonorsLetter: false });
  }

  async progressReportExists() {
    const studentid = this.state.student.id;

    const url = `/reports/allstoredreports?report=progressreport&studentid=${studentid}`;
    try {
      const progressreport = await api.get(url);
      this.setState({ progressReport: true });
      this.setState({ progressReportData: progressreport.data });
    } catch {
      this.setState({ progressReport: false });
    }
  }

  async rankingLetterExists() {
    const studentid = this.state.student.id;

    const url = `/reports/allstoredreports?report=rankingletters&studentid=${studentid}`;
    try {
      const rankingletter = await api.get(url);
      this.setState({ rankingLetter: true });
      this.setState({ rankingLetterData: rankingletter.data });
    } catch {
      this.setState({ rankingLetter: false });
    }
  }

  async deansListLetterExists() {
    const studentid = this.state.student.id;

    try {
      const url = `/reports/allstoredreports?report=deansletters&studentid=${studentid}`;

      const deanslist = await api.get(url);

      this.setState({ deansList: true });
      this.setState({ deansListData: deanslist.data });
    } catch {
      this.setState({ deansList: false });
    }
  }

  viewReport(e) {
    this.setState({ reportType: e.target.id });
    let data;

    if (e.target.id === "progressreport") {
      data = this.state.progressReportData;
    } else if (e.target.id === "classrank") {
      data = this.state.rankingLetterData;
    } else if (e.target.id === "deanslist") {
      data = this.state.deansListData;
    } else if (e.target.id === "honorsletter") {
      data = this.state.honorsLetterData;
    }

    this.setState({ targetData: data });
    this.toggleViewReportModal();
  }

  toggleViewReportModal() {
    this.setState({ viewReportModal: !this.state.viewReportModal });
  }

  async regenerateProgressReport() {
    let semester = releaseGradesGetCurrentSemester();
    let id = this.state.student.id;
    try {
      let report = await api.get(
        `/reports/progressreport?semester=${semester}&format=pdf&studentid=${id}`
      );
      window.open(report.data.url);
    } catch (err) {
      if (!axios.isCancel(err)) {
        console.error(err);
        this.setState({
          errorMessage: err.response?.data ? err.response.data : err.message,
        });
      }
    }
  }

  render() {
    return (
      <Container className="p-0 mt-4">
        {this.state.errorMessage && (
          <ErrorDisplay errorMessage={this.state.errorMessage} />
        )}
        {this.state.viewReportModal && (
          <ViewSemesterReportModal
            show={this.state.viewReportModal}
            onHide={this.toggleViewReportModal}
            data={this.state.targetData}
            reportType={this.state.reportType}
          />
        )}
        {(this.props.roles.admin || this.props.roles.registrar) && (
          <React.Fragment>
            <Row>
              <Col xs="auto">
                <h1 className="m-0">Reports And Utilities</h1>
              </Col>
              {this.state.loading && (
                <Col className="py-0 pt-2">
                  <Spinner animation="border" />
                </Col>
              )}
            </Row>
            {!this.state.loading && (
              <React.Fragment>
                <Row>
                  <h4 className="text-muted mt-2">
                    {this.state.student.first_name +
                      " " +
                      this.state.student.last_name}
                  </h4>
                </Row>
                <Row className="mt-3">
                  <Col xs="12" md="6">
                    <Card className="shadow mb-4">
                      <Card.Header as="h5">Select Report:</Card.Header>
                      <Card.Body>
                        <Col className="tools-card">
                          <Row>
                            <ToolButton
                              text="Dean's List Letter"
                              icon="pdf"
                              id="deanslist"
                              onClick={this.viewReport}
                              disabled={!this.state.deansList}
                            />
                          </Row>
                          <Row>
                            <ToolButton
                              text="Honors Letter"
                              icon="pdf"
                              id="honorsletter"
                              onClick={this.newOpenHonors}
                              disabled={!this.state.honorsLetter}
                            />
                          </Row>
                          <Row>
                            <ToolButton
                              text="Ranking Letter"
                              icon="pdf"
                              id="classrank"
                              onClick={this.viewReport}
                              disabled={!this.state.rankingLetter}
                            />
                          </Row>
                          <Row>
                            <ToolButton
                              text="Progress Report"
                              icon="pdf"
                              id="progressreport"
                              onClick={this.viewReport}
                              disabled={!this.state.progressReport}
                            />
                          </Row>
                        </Col>

                          {/* <li>
                            <FontAwesomeIcon
                              icon={faFilePdf}
                              style={{ color: "002e5d" }}
                            />{" "}
                            <Link to="#">Bar Graphs of GPA by Class</Link>{" "}
                            (under construction)
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faLink}
                              style={{ color: "002e5d" }}
                            />{" "}
                            <Link to="#">Course Grading Bar Graph</Link> (under
                            construction)
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faLink}
                              style={{ color: "002e5d" }}
                            />{" "}
                            <Link to="#">Course Roll</Link> (under construction)
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faLink}
                              style={{ color: "002e5d" }}
                            />{" "}
                            <Link to="#">Cover Sheet</Link> (under construction)
                          </li> */}
                          {/* {this.state.student.exam_number ? (
                            <li>
                              <FontAwesomeIcon
                                icon={faFilePdf}
                                style={{ color: "002e5d" }}
                              />{" "}
                              <Button
                                variant="link"
                                className="p-0 border-0"
                                onClick={this.openExamNumberLetter}
                              >
                                Exam Number Assignment Letter
                              </Button>
                            </li>
                          ) : (
                            <li>
                              <FontAwesomeIcon
                                icon={faTimes}
                                style={{ color: "002e5d" }}
                              />{" "}
                              Exam Number Assignment Letter not avaliable
                            </li>
                           )} */}
                          {/* <li>
                            <FontAwesomeIcon
                              icon={faLink}
                              style={{ color: "002e5d" }}
                            />{" "}
                            <Link to="#">GPA Calculator</Link> (under
                            construction)
                          </li>
                          <li>
                            <Link to="#">
                              <FontAwesomeIcon
                                icon={faFileExcel}
                                style={{ color: "002e5d" }}
                              />{" "}
                              <Button
                                variant="link"
                                className="p-0 border-0"
                                //onClick={/*fill in with correct document}
                              >
                                Graduation Evaluation Spreadsheet
                              </Button>
                            </Link>{" "}
                            (under construction)
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faFilePdf}
                              style={{ color: "002e5d" }}
                            />{" "}
                            <Link to="#">Graduation List</Link> (under
                            construction)
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faFilePdf}
                              style={{ color: "002e5d" }}
                            />{" "}
                            <Button
                              variant="link"
                              className="p-0 border-0"
                              //onClick={/*fill in with correct document}
                            >
                              List Alphabetical with GPA
                            </Button>
                            (under construction)
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faFilePdf}
                              style={{ color: "002e5d" }}
                            />{" "}
                            <Button
                              variant="link"
                              className="p-0 border-0"
                              //onClick={/*fill in with correct document}
                            >
                              List by Rank with GPA
                            </Button>
                            (under construction)
                          </li>
                            <FontAwesomeIcon
                              icon={faFilePdf}
                              style={{ color: "002e5d" }}
                            />{" "}
                            <Button
                              variant="link"
                              className="p-0 border-0"
                              //onClick={/*fill in with correct document}
                            >
                              Scholarship Eligibility Report
                            </Button>
                            (under construction)
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faFileExcel}
                              style={{ color: "002e5d" }}
                            />{" "}
                            <Button
                              variant="link"
                              className="p-0 border-0"
                              onClick={this.props.downloadStudentInfoCSV}
                            >
                              Student Information
                            </Button>
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faFilePdf}
                              style={{ color: "002e5d" }}
                            />{" "}
                            <Button
                              variant="link"
                              className="p-0 border-0"
                              //onClick={/*fill in with correct document}
                            >
                              Student Lists
                            </Button>
                            (under construction)
                          </li> */}
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col xs="12" md="6">
                    <Card className="shadow mb-4">
                      <Card.Header as="h5">Select Utility:</Card.Header>
                      <Card.Body>
                        <Col className="tools-card">
                          <Row>
                            <ToolButton
                              text={`Regenerate ${this.state.currentSemesterDisplay}${" "}Progress Report`}
                              icon="pdf"
                              onClick={this.regenerateProgressReport}
                            />
                          </Row>
                        </Col>
                        {/* <ul className="list-unstyled">
                          <li>
                            <FontAwesomeIcon
                              icon={faLink}
                              style={{ color: "002e5d" }}
                            />{" "}
                            <Button
                              variant="link"
                              className="p-0 border-0"
                              onClick={this.regenerateProgressReport}
                            >
                              Regenerate {this.state.currentSemesterDisplay}{" "}
                              Progress Report
                            </Button>{" "}
                          </li>
                        </ul> */}
                        {/*
                          <li>
                            <FontAwesomeIcon
                              icon={faLink}
                              style={{ color: "002e5d" }}
                            />{" "}
                            <Link to="#">Calculate Rankings by Class</Link>{" "}
                            (under construction)
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faLink}
                              style={{ color: "002e5d" }}
                            />{" "}
                            <Link to="#">Shift Courses</Link> (under
                            construction)
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faLink}
                              style={{ color: "002e5d" }}
                            />{" "}
                            <Link to="#">Sort and Space Courses</Link> (under
                            construction)
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faLink}
                              style={{ color: "002e5d" }}
                            />{" "}
                            <Link to="#">
                              Update Graduation Degree and Date
                            </Link>{" "}
                            (under construction)
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faLink}
                              style={{ color: "002e5d" }}
                            />{" "}
                            <Link to="#">
                              Update Stored Dean's List Letters
                            </Link>{" "}
                            (under construction)
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faLink}
                              style={{ color: "002e5d" }}
                            />{" "}
                            <Link to="#">Update Stored Honors Letters</Link>{" "}
                            (under construction)
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faLink}
                              style={{ color: "002e5d" }}
                            />{" "}
                            <Link to="#">Update Stored Progress Reports</Link>{" "}
                            (under construction)
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faLink}
                              style={{ color: "002e5d" }}
                            />{" "}
                            <Link to="#">Update Stored Ranking Letters</Link>{" "}
                            (under construction)
                          </li> 
                        */}
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </React.Fragment>
            )}
            {/* <GradeReportModal
                show={this.state.showGradeReportModal}
                onHide={this.hideGradeReportModal}
                validSemesters={this.state.validSemesters}
                loading={this.state.loading}
                student={this.state.student}
              /> */}
          </React.Fragment>
        )}
      </Container>
    );
  }
}

export default RepUtil;
