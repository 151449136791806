const CryptoJS = require("crypto-js");
const encryptionKey = "ThisCanBeAnything";

/**
 * Encrypts a string using the encryption key.
 *
 * @param {string} decryptedString
 * @return {string} encryptedString
 */
function encryptString(decryptedString) {
  return CryptoJS.AES.encrypt(decryptedString, encryptionKey).toString();
}

/**
 * Decrypts a string using the encryption key.
 *
 * @param {string} encryptedString
 * @return {string} decryptedString
 */
function decryptString(encryptedString) {
  const decryptedBytes = CryptoJS.AES.decrypt(encryptedString, encryptionKey);
  const decryptedString = decryptedBytes.toString(CryptoJS.enc.Utf8);
  return decryptedString;
}

// setting up a proxy allows us to automatically decrypt any property we want access to.
// When we want a property, all we have to do is reference secureUserToken.email, for example,
// and it will automatically retrieve, decrypt and return it.
const secureUserToken = new Proxy(
  {},
  {
    get: function (_, prop) {
      // if (sessionStorage.getItem("actastoken")) {
      //   const userData = JSON.parse(
      //     decryptString(sessionStorage.getItem("actastoken"))
      //   );

      //   if (prop === "toString") {
      //     return JSON.stringify(userData);
      //   }
      //   return userData[`${prop}`];
      // } else
      if (sessionStorage.getItem("userToken")) {
        const userData = JSON.parse(
          decryptString(sessionStorage.getItem("userToken"))
        );
        // console.log(`secureUserToken read as ${JSON.stringify(userData)}`);

        if (prop === "toString") {
          return JSON.stringify(userData);
        }
        return userData[`${prop}`];
      }
      return undefined;
    },
  }
);

module.exports = {
  secureUserToken,
  encryptString,
  decryptString,
};
