import React from "react";
import { Alert, Col, Row } from "react-bootstrap";

class ErrorDisplay extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: this.props.errorMessage,
      customMessage: this.props.customMessage,
      noHelpDesk: this.props.noHelpDesk,
      visible: true,
    };

    this.handleDismiss = this.handleDismiss.bind(this);
  }

  handleDismiss() {
    this.setState({ visible: false });
    if (this.props.errorMessageArray) {
      if (this.props.errorMessageArray.length > 1) {
        this.props.errorMessageArray.splice(this.props.index, 1);
      } else {
        this.props.errorMessageArray.pop();
      }
    }
  }

  render() {
    return (
      <Row className="justify-content-center">
        <Col className="m-0">
          {this.state.visible ? (
            <Alert
              className="text-center shadow"
              variant="danger"
              onClose={() => this.handleDismiss()}
            >
              {this.state.customMessage && (
                <>
                  {this.state.customMessage}
                  <br></br><br></br>
                </>
              )}
              {this.state.errorMessage}
              {!this.state.noHelpDesk && (
                <React.Fragment>
                  <br></br><br></br>
                  Contact the help desk if this error persists.
                </React.Fragment>
              )}
            </Alert>
          ) : (
            ""
          )}
        </Col>
      </Row>
    );
  }
}

export default ErrorDisplay;
