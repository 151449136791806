import React from "react";
import api from "../services/api";
import {
  Card,
  Container,
  Table,
  Button,
  Spinner,
  Form,
  Row,
  Col,
} from "react-bootstrap";
import { withRouter } from "react-router-dom";
import ErrorDisplay from "../components/ErrorDisplay.jsx";
import ToolsNav from "../components/ToolsNav.jsx";

class Departments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      errorMessage: null,
      departments: [],
      editableDepartments: [],
    };
    this.getDepartments = this.getDepartments.bind(this);
    this.buildDepartmentTable = this.buildDepartmentTable.bind(this);
    this.handleEditToggle = this.handleEditToggle.bind(this);
    this.handleSaveDepartment = this.handleSaveDepartment.bind(this);
    this.handleDeleteDepartment = this.handleDeleteDepartment.bind(this);
    this.getElementIndex = this.getElementIndex.bind(this);
    this.handleEditDepartmentTable = this.handleEditDepartmentTable.bind(this);
    this.addDepartment = this.addDepartment.bind(this);
  }

  componentDidMount() {
    this.getDepartments();
  }

  getElementIndex(e) {
    const [department_id, type] = e.target.id.split("-");
    const editableDepartments = this.state.editableDepartments;
    const departmentIndex = editableDepartments.findIndex(
      (department) =>
        parseInt(department.department_id) === parseInt(department_id)
    );
    return [departmentIndex, type];
  }

  async getDepartments() {
    this.setState({ errorMessage: null });
    try {
      const { data: departments } = await api.get("/departments");
      const editableDepartments = JSON.parse(JSON.stringify(departments));
      editableDepartments.forEach((department) => {
        department.disableEdit = true;
      });
      this.setState({
        departments: departments,
        editableDepartments: editableDepartments,
        loading: false,
      });
    } catch (err) {
      console.error(err);
      this.setState({
        errorMessage: err.response?.data ? err.response.data : err.message,
      });
    }
  }

  handleEditToggle(e) {
    this.setState({ errorMessage: null });
    const editableDepartments = this.state.editableDepartments;
    const departmentIndex = this.getElementIndex(e)[0];
    if (editableDepartments[departmentIndex].newCourse) {
      editableDepartments.splice(departmentIndex, 1);
    } else if (editableDepartments[departmentIndex].disableEdit) {
      editableDepartments[departmentIndex].disableEdit = false;
    } else {
      editableDepartments[departmentIndex] = {
        ...this.state.departments[departmentIndex],
      };
      editableDepartments[departmentIndex].disableEdit = true;
    }

    this.setState({ editableDepartments });
  }

  addDepartment() {
    let editableDepartments = this.state.editableDepartments;
    const highestOrder = Math.max.apply(
      Math,
      editableDepartments.map(function (course) {
        return course.department_id;
      })
    );

    const newDepartment = {
      title: "",
      room_number: "",
      disableEdit: "",
      newCourse: true,
      department_id: highestOrder + 1,
    };
    editableDepartments.push(newDepartment);
    this.setState({ editableDepartments });
  }

  async handleSaveDepartment(e) {
    this.setState({ disableApiButtons: true });
    const { editableDepartments, departments } = this.state;
    const departmentIndex = this.getElementIndex(e)[0];
    const payload = editableDepartments[departmentIndex];

    try {
      let department;
      if (payload.newCourse) {
        delete payload.disableEdit;
        delete payload.newCourse;
        let { data } = await api.post(`/departments/create`, payload);
        department = data;
      } else {
        let { data } = await api.post(
          `/departments/${payload.department_id}`,
          payload
        );
        department = data;
      }
      departments[departmentIndex] = {
        ...departments[departmentIndex],
        ...department,
      };
      department.disableEdit = true;
      editableDepartments[departmentIndex] = {
        ...editableDepartments[departmentIndex],
        ...department,
      };
      this.setState({
        departments,
        editableDepartments,
      });
    } catch (err) {
      console.error(err);
      this.setState({
        errorMessage: err.response?.data ? err.response.data : err.message,
      });
    }

    this.setState({ disableApiButtons: false });
  }

  handleEditDepartmentTable(e) {
    const editableDepartments = this.state.editableDepartments;
    const value = e.target.value;
    const [index, field] = this.getElementIndex(e);
    switch (field) {
      case "title":
        editableDepartments[index].title = value;
        break;
      case "room":
        editableDepartments[index].room_number = value;
        break;
      default:
        break;
    }
    this.setState({ editableDepartments });
  }

  async handleDeleteDepartment(e) {
    this.setState({ disableApiButtons: true });
    let { editableDepartments, departments } = this.state;
    const departmentIndex = this.getElementIndex(e)[0];
    const payload = editableDepartments[departmentIndex];

    try {
      await api.delete(`/departments/${payload.department_id}`, {
        data: payload,
      });
      departments.splice(departmentIndex, 1);
      editableDepartments.splice(departmentIndex, 1);

      this.setState({
        departments,
        editableDepartments,
      });
    } catch (err) {
      console.error(err);
      this.setState({
        errorMessage: err.response?.data ? err.response.data : err.message,
      });
    }

    this.setState({ disableApiButtons: false });
  }

  buildDepartmentTable(department) {
    const key = department.department_id;
    return (
      <tr>
        <td>
          <Form.Control
            id={`${key}-title`}
            value={department.title}
            disabled={department.disableEdit}
            onChange={this.handleEditDepartmentTable}
          />
        </td>
        <td>
          <Form.Control
            id={`${key}-room`}
            value={department.room_number}
            disabled={department.disableEdit}
            onChange={this.handleEditDepartmentTable}
          />
        </td>
        {department.disableEdit ? (
          <React.Fragment>
            <td width="5%">
              <Button
                id={`${key}-edit`}
                onClick={this.handleEditToggle}
                variant="outline-primary"
              >
                Edit
              </Button>
            </td>
            <td width="5%">
              <Button
                id={`${key}-delete`}
                disabled={this.state.disableApiButtons}
                onClick={this.handleDeleteDepartment}
                variant="outline-primary"
              >
                Delete
              </Button>
            </td>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <td width="5%">
              <Button
                id={`${key}-save`}
                onClick={this.handleSaveDepartment}
                variant="info"
                disabled={this.state.disableApiButtons}
              >
                Save
              </Button>
            </td>
            <td width="5%">
              <Button
                id={`${key}-cancel`}
                onClick={this.handleEditToggle}
                variant="outline-info"
              >
                Cancel
              </Button>
            </td>
          </React.Fragment>
        )}
      </tr>
    );
  }

  render() {
    if (this.state.loading) {
      return (
        <div className="loadingDiv">
          <h1 className="loadingText" style={{marginBottom: '1em', marginLeft: '0.5em'}}>{this.state.loadingMessage}</h1>
          <Spinner animation={"border"} />
        </div>
      );
    }

    return (
      <>
        <ToolsNav currentTab="admin-tools" roles={{admin: true}} isLink={true} />
        <Container className="p-3 bg-white">
          <Card className="my-4 shadow">
            <Card.Header as="h3">Departments</Card.Header>
            <Card.Body>
              <Table bordered striped responsive>
                <thead>
                  <tr>
                    <th>Department Title</th>
                    <th>Room Number</th>
                    <th colSpan="2">Options</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.editableDepartments.map((department) =>
                    this.buildDepartmentTable(department)
                  )}
                </tbody>
              </Table>
              <Row className="justify-content-center mt-2">
                <Col xs="auto">
                  <Button variant="outline-primary" onClick={this.addDepartment}>
                    + Department
                  </Button>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          {this.state.errorMessage && (
            <ErrorDisplay
              className="shadow"
              errorMessage={this.state.errorMessage}
            />
          )}
        </Container>
      </>
    );
  }
}

export default withRouter(Departments);
