import React from "react";
import { Button, Modal } from "react-bootstrap";

import axios from "axios";
import api from "../../services/api";
import ErrorDisplay from "../ErrorDisplay";

class UpdateFinalExamScheduleModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      document: "",
      errorMessage: "",
      customMessage: "",
    };

    this.fileReader = this.fileReader.bind(this);

    this.selectDocument = this.selectDocument.bind(this);
    this.submitDocument = this.submitDocument.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  onCancel() {
    this.setState({ document: "" });

    this.props.onHide();
  }

  async submitDocument() {
    let semester = this.props.currentSemester;
    try {
      await api.post(`/final-schedule/${semester}`, {
        file: this.state.document,
      });
    } catch (err) {
      if (!axios.isCancel(err)) {
        console.error(err);
        this.setState({
          errorMessage: err.response?.data ? err.response.data : err.message,
          customMessage: "Error downloading report",
        });
      }
    }

    if (this.state.errorMessage === ""){
      this.props.onHide();
    }
  }

  selectDocument(readerResult) {
    this.setState({ document: readerResult });
  }

  fileReader() {
    var fileInput = document.getElementById("image");
    var reader = new FileReader();
    reader.readAsDataURL(fileInput.files[0]);
    var onloader = function () {
      this.selectDocument(reader.result);
    };
    reader.onload = onloader.bind(this);

    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  }

  render() {
    let { show, onHide } = this.props;
    return (
      <Modal show={show} onHide={onHide}>
        <Modal.Header>
          <Modal.Title>Update Final exam Schedule</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{`This will update the Final Exam Schedule for  ${this.props.currentSemesterDisplay}`}</p>

          <div>
            {" "}
            <input
              type="file"
              id="image"
              name="ImageStyle"
              onChange={this.fileReader}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="info" onClick={this.submitDocument}>
            Submit
          </Button>
          <Button variant="outline-info" onClick={this.onCancel}>
            Cancel
          </Button>
        </Modal.Footer>
        {this.state.errorMessage && (
          <ErrorDisplay errorMessage={this.state.errorMessage} customMessage={this.state.customMessage} />
        )}
      </Modal>
    );
  }
}

export default UpdateFinalExamScheduleModal;
