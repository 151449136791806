import React from "react";
import api from "../../../services/api.js";
import { Button, Col, Form, Row } from "react-bootstrap";

export default class EditLanguages extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      languageOptions: [],
    };
    this.getLanguageOptions = this.getLanguageOptions.bind(this);
  }

  componentDidMount() {
    this.getLanguageOptions();
  }

  /**
   * Retrieves language options from the API and sets them in the component state.
   */
  async getLanguageOptions() {
    const { data: languages } = await api.get("/language");
    this.setState({ languageOptions: languages });
  }

  render() {
    const {
      languages,
      handleLanguageChange,
      addNewLanguage,
      roles,
      handleDeleteLanguage,
      handleDeleteNewLanguage,
      languagesToAdd,
    } = this.props;

    const isStudOrGrad = roles.student || roles.graduate;

    return (
      <>
        <Row className="p-0">
          <Col>
            <strong>Languages:</strong>
          </Col>
        </Row>
        {languages.map((item, index) => (
          <Row key={index} className="p-0 mt-1">
            <Col xs={"auto"}>
              <Form.Select
                id={`${index}-language`}
                className="mb-1 width-auto"
                value={item.language}
                onChange={handleLanguageChange}
                disabled={true}
              >
                {this.state.languageOptions.map((item) => (
                  <option value={item.language}>{item.language}</option>
                ))}
              </Form.Select>
            </Col>
            <Col xs={"auto"}>
              <Button
                id={`${index}-${item.language}-delete`}
                variant="outline-danger"
                onClick={handleDeleteLanguage}
              >
                Delete
              </Button>
            </Col>
          </Row>
        ))}
        {languagesToAdd.map((item, index) => (
          <Row key={index} className="p-0 mt-1">
            <Col xs={"auto"}>
              <Form.Select
                id={`${index}-language`}
                className="mb-1 width-auto"
                value={item.language}
                onChange={handleLanguageChange}
                disabled={isStudOrGrad}
              >
                {this.state.languageOptions.map((item) => (
                  <option value={item.language}>{item.language}</option>
                ))}
              </Form.Select>
            </Col>
            <Col xs={"auto"}>
              <Button
                id={`${index}-${item.language}-delete`}
                variant="outline-primary"
                onClick={handleDeleteNewLanguage}
              >
                Delete
              </Button>
            </Col>
          </Row>
        ))}
        {!isStudOrGrad && (
          <Row className="p-0 mt-2">
            <Col>
              <Button variant="outline-primary" onClick={addNewLanguage}>
                Add Language
              </Button>
            </Col>
          </Row>
        )}
      </>
    );
  }
}
