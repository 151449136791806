import { faDownload, faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import {
  Button,
  Card,
  Col,
  Form,
  OverlayTrigger,
  Row,
  Spinner,
  Tooltip,
} from "react-bootstrap";
import api from "../../../services/api";
import {
  getIndexOfAddressOfType,
  handleImageError,
  stateList,
} from "../../../utils/functions";
import ErrorDisplay from "../../ErrorDisplay";
import AddPhotoModal from "../../faculty_information/AddPhotoModal";
import AddSoundModal from "../../faculty_information/addSoundModal";
import History from "../../History";
import EditLanguages from "./EditLanguages";
import EditPreLawSchools from "./EditPreLawSchools";
import { saveAs } from "file-saver";
import EditPersonalInfoEntry from "./EditPersonalInfoEntry";
import { CleanImage } from "../../SafeImage";

export default class EditPersonalInfo extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      editedStudent: JSON.parse(JSON.stringify(this.props.student)),
      indexOfPermanentAddress: getIndexOfAddressOfType(
        this.props.student.address,
        "permanent"
      ),
      indexOfHomeAddress: getIndexOfAddressOfType(
        this.props.student.address,
        "home"
      ),
      state_other: "",
      loading: false,
      languages: [...this.props.student.languages],
      degrees: [...this.props.student.pre_law_degrees],
      languagesToDelete: [],
      languagesToAdd: [],
      degreesToAdd: [],
      degreesToDelete: [],
      errorMessage: null,
      studentEdited: false,
      addressEditedHome: false,
      addressEditedPermanent: false,
      otherFieldEdited: false,
      showHistory: false,
      studentId: this.props.studentId,
      pic: "",
      photoChanged: false,
      showPhotoModal: false,
      editablePhoto: "",
      showSoundModal: false,
      editableSound: "",
    };

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleGenderSelect = this.handleGenderSelect.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handlePreLawChange = this.handlePreLawChange.bind(this);
    this.addNewDegree = this.addNewDegree.bind(this);
    this.handleDeleteDegree = this.handleDeleteDegree.bind(this);
    this.handleLanguageChange = this.handleLanguageChange.bind(this);
    this.addNewLanguage = this.addNewLanguage.bind(this);
    this.handleDeleteLanguage = this.handleDeleteLanguage.bind(this);
    this.handleDeleteNewLanguage = this.handleDeleteNewLanguage.bind(this);
    this.handleDeleteNewDegree = this.handleDeleteNewDegree.bind(this);
    this.handleNewPreLawChange = this.handleNewPreLawChange.bind(this);
    this.toggleHistory = this.toggleHistory.bind(this);
    this.handleRestore = this.handleRestore.bind(this);
    this.togglePhotoModal = this.togglePhotoModal.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleSelectPhoto = this.handleSelectPhoto.bind(this);
    this.handleAddPhoto = this.handleAddPhoto.bind(this);
    this.displayPhoto = this.displayPhoto.bind(this);
    this.handleSoundCancel = this.handleSoundCancel.bind(this);
    this.toggleSoundModal = this.toggleSoundModal.bind(this);
    this.handleSelectSound = this.handleSelectSound.bind(this);
    this.handleAddSound = this.handleAddSound.bind(this);
  }

  /**
   * Display the photo.
   *
   * @return {string} The photo to display.
   */
  displayPhoto() {
    var base64Icon = this.state.editablePhoto;

    if (this.state.editablePhoto === "") {
      return (
        `${this.props.editablePerson.picture_link}?` + new Date().getTime()
      );
    } else {
      return base64Icon;
    }
  }

  componentDidMount() {
    const id = this.state.studentId;
    let link;
    if (process.env.REACT_APP_STAGE === "dev") {
      link =
        `https://s3.amazonaws.com/content.law-info.byu.edu-dev/student-pictures/${id}.jpg?` +
        new Date().getTime();
    } else {
      link =
        `https://s3-us-west-2.amazonaws.com/content.law-info.byu.edu/student-pictures/${id}.jpg?` +
        new Date().getTime();
    }

    const pic = link;

    let { indexOfHomeAddress, indexOfPermanentAddress } = this.state;
    let editedStudent = JSON.parse(JSON.stringify(this.state.editedStudent));
    let homeAddress = {};
    let permanentAddress = {};
    editedStudent.date_of_birth = editedStudent.date_of_birth
      ? new Date(this.state.editedStudent.date_of_birth)
          .toISOString()
          .split("T")[0]
      : "";
    if (indexOfHomeAddress === -1) {
      homeAddress.address_type = "home";
      homeAddress.city = "";
      homeAddress.country = "";
      homeAddress.id = `${editedStudent.id}`;
      homeAddress.phone = "";
      homeAddress.postal_code = "";
      homeAddress.state = "";
      homeAddress.street1 = "";
      homeAddress.street2 = "";
      editedStudent.address.push(homeAddress);
      indexOfHomeAddress = getIndexOfAddressOfType(
        editedStudent.address,
        "home"
      );
    }
    if (indexOfPermanentAddress === -1) {
      permanentAddress.address_type = "permanent";
      permanentAddress.city = "";
      permanentAddress.country = "";
      permanentAddress.id = `${editedStudent.id}`;
      permanentAddress.phone = "";
      permanentAddress.postal_code = "";
      permanentAddress.state = "";
      permanentAddress.street1 = "";
      permanentAddress.street2 = "";
      editedStudent.address.push(permanentAddress);
      indexOfPermanentAddress = getIndexOfAddressOfType(
        editedStudent.address,
        "permanent"
      );
    }
    if (!stateList.includes(editedStudent.address[indexOfHomeAddress]?.state)) {
      this.setState({
        state_other: editedStudent.address[indexOfHomeAddress]?.state
      })
      editedStudent.address[indexOfHomeAddress].state = "Other";
    }

    this.setState({
      editedStudent,
      indexOfPermanentAddress,
      indexOfHomeAddress,
      pic: pic,
    });
  }

  /**
   * A function to validate the save action.
   *
   * @return {boolean} true if save is disabled, false if save is allowed
   */
  saveValidator() {
    let isStudOrGradEdited =
      this.state.studentEdited ||
      this.state.addressEditedHome ||
      this.state.addressEditedPermanent ||
      this.state.otherFieldEdited;
    if (!this.props.roles.student) {
      if (!isStudOrGradEdited) {
        return true;
      }
    } else {
      // If the user is a student it checks to see if something has been edited
      // and then returns false so disabled save is off
      if (!isStudOrGradEdited) {
        return true;
      } else {
        return false;
      }
    }
  }

  /**
   * Adds new degree(s) to the list of degrees to add to the student.
   * Sets the state to show a field has been edited.
   */
  addNewDegree() {
    const { degrees, editedStudent, degreesToAdd } = this.state;

    if (!degrees.length && !degreesToAdd.length) {
      degreesToAdd.push({
        degree_num: 1,
        pre_law_gpa: "",
        grad_year: "",
        degree: "",
        school: "",
      });
    } else {
      let degreeNum;
      degreesToAdd.length
        ? (degreeNum = degreesToAdd[degreesToAdd.length - 1].degree_num + 1)
        : (degreeNum = degrees[degrees.length - 1].degree_num + 1);
      degreesToAdd.push({
        id: editedStudent.id,
        degree_num: degreeNum,
        pre_law_gpa: "",
        grad_year: "",
        degree: "",
        school: "",
      });
    }

    this.setState({ degreesToAdd, otherFieldEdited: true });
  }

  /**
   * Adds a new language to the state and sets otherFieldEdited to true.
   */
  addNewLanguage() {
    const { languagesToAdd } = this.state;

    languagesToAdd.push({
      language: "english",
    });

    this.setState({ languagesToAdd, otherFieldEdited: true });
  }

  downloadImage = () => {
    saveAs(this.state.pic, `${this.state.studentId}.jpg`);
  };

  /**
   * Update the gender of editedStudent and set studentEdited to true.
   *
   * @param {Event} e - the event object from the gender select
   */
  handleGenderSelect(e) {
    const { editedStudent } = this.state;
    editedStudent.gender = e.target.value;
    this.setState({ editedStudent, studentEdited: true });
  }

  /**
   * Toggles showHistory in the state.
   */
  toggleHistory() {
    let showHistory = !this.state.showHistory;
    this.setState({ showHistory });
  }

  /**
   * Handle the cancel action by resetting the editable photo and toggling the photo modal.
   */
  handleCancel() {
    this.setState({ editablePhoto: "" });
    this.togglePhotoModal();
  }

  /**
   * Sets the editablePhoto in the state with the selected photo.
   * 
   * @param {Event} event - the event object with the selected photo
   */
  handleSelectPhoto(event) {
    this.setState({ editablePhoto: event });
  }

  /**
   * Closes the photo modal and sets photoChanged to true.
   */
  async handleAddPhoto() {
    this.togglePhotoModal();
    this.setState({ photoChanged: true, otherFieldEdited: true });
  }

  /**
   * Toggles showPhotoModal in the state.
   */
  togglePhotoModal() {
    this.setState({ showPhotoModal: !this.state.showPhotoModal });
  }

  /**
   * Toggles showSoundModal in the state.
   */
  toggleSoundModal() {
    this.setState({ showSoundModal: !this.state.showSoundModal });
  }

  /**
   * Resets the editableSound in the state and closes the sound modal.
   */
  handleSoundCancel() {
    this.setState({ editableSound: "" });
    this.toggleSoundModal();
  }

  /**
   * Save the selected sound and file name in the state.
   *
   * @param {Object} event - the event object with the selected sound
   * @param {string} filename - the name of the selected file
   */
  handleSelectSound(event, filename) {
    console.log("event", event);
    this.setState({ editableSound: event });

    if (filename.length > 30) {
      this.setState({
        soundFileName:
          filename.slice(0, 30) +
          "(...)" +
          filename.slice(filename.length - 4, filename.length),
      });
    } else {
      this.setState({ soundFileName: filename });
    }
  }

  /**
   * Sets soundChanged to true and closes the sound modal.
   */
  async handleAddSound() {
    this.toggleSoundModal();
    this.setState({ soundChanged: true, otherFieldEdited: true });
  }

  /**
   * Handles the change event from an input field and updates the state accordingly.
   *
   * @param {Object} e - the event object
   */
  handleChange(e) {
    const { id, value } = e.target;

    const { editedStudent, indexOfHomeAddress, indexOfPermanentAddress } =
      this.state;
    let addressChangedHome = false;
    let addressChangedPermanent = false;
    let studentChanged = false;

    switch (id) {
      case "restrict":
        editedStudent.restricted_contact_information =
          e.target.checked === true ? true : false;
        studentChanged = true;
        break;
      case "preferred":
        editedStudent.known_by = value;
        studentChanged = true;
        break;
      case "title-name":
        editedStudent.title = value;
        studentChanged = true;
        break;
      case "first-name":
        editedStudent.first_name = value;
        studentChanged = true;
        break;
      case "middle-name":
        editedStudent.middle_name = value;
        studentChanged = true;
        break;
      case "last-name":
        editedStudent.last_name = value;
        studentChanged = true;
        break;
      case "dob":
        editedStudent.date_of_birth = value;
        studentChanged = true;
        break;
      case "ethnicity":
        editedStudent.ethnicity = value;
        studentChanged = true;
        break;
      case "email":
        editedStudent.email = value;
        studentChanged = true;
        break;
      case "address-home-line1":
        editedStudent.address[indexOfHomeAddress].street1 = value;
        addressChangedHome = true;
        break;
      case "address-home-line2":
        editedStudent.address[indexOfHomeAddress].street2 = value;
        addressChangedHome = true;
        break;
      case "address-home-city":
        editedStudent.address[indexOfHomeAddress].city = value;
        addressChangedHome = true;
        break;
      case "address-home-zip":
        editedStudent.address[indexOfHomeAddress].postal_code = value;
        addressChangedHome = true;
        break;
      case "address-home-state":
        editedStudent.address[indexOfHomeAddress].state = value;
        if (value !== "Other") {
          this.setState({ state_other: "" });
        }
        addressChangedHome = true;
        break;
      case "address-home-state-other":
        this.setState({ state_other: value });
        addressChangedHome = true;
        break;
      case "address-home-country":
        editedStudent.address[indexOfHomeAddress].country = value;
        addressChangedHome = true;
        break;
      case "address-home-phone":
        editedStudent.address[indexOfHomeAddress].phone = value;
        addressChangedHome = true;
        break;
      case "address-permanent-line1":
        editedStudent.address[indexOfPermanentAddress].street1 = value;
        addressChangedPermanent = true;
        break;
      case "address-permanent-line2":
        editedStudent.address[indexOfPermanentAddress].street2 = value;
        addressChangedPermanent = true;
        break;
      case "address-permanent-city":
        editedStudent.address[indexOfPermanentAddress].city = value;
        addressChangedPermanent = true;
        break;
      case "address-permanent-zip":
        editedStudent.address[indexOfPermanentAddress].postal_code = value;
        addressChangedPermanent = true;
        break;
      case "address-permanent-state":
        editedStudent.address[indexOfPermanentAddress].state = value;
        addressChangedPermanent = true;
        break;
      case "address-permanent-country":
        editedStudent.address[indexOfPermanentAddress].country = value;
        addressChangedPermanent = true;
        break;
      case "address-permanent-phone":
        editedStudent.address[indexOfPermanentAddress].phone = value;
        addressChangedPermanent = true;
        break;
      case "spouse":
        editedStudent.spouse = value;
        studentChanged = true;
        break;
      case "carrel":
        editedStudent.carrel = value;
        studentChanged = true;
        break;
      case "class-year":
        editedStudent.year_in_program = value;
        studentChanged = true;
        break;
      case "exp-grad-year":
        editedStudent.expected_grad_year = value;
        studentChanged = true;
        break;
      case "mission":
        editedStudent.mission = value;
        studentChanged = true;
        break;
      case "interests":
        editedStudent.interests = value;
        studentChanged = true;
        break;
      case "misc-language":
        editedStudent.language = value;
        studentChanged = true;
        break;
      case "organizations":
        editedStudent.organizations = value;
        studentChanged = true;
        break;
      case "religion":
        editedStudent.religion = value;
        studentChanged = true;
        break;
      case "gender":
        editedStudent.gender = value;
        studentChanged = true;
        break;
      default:
        return;
    }

    if (studentChanged) this.setState({ studentEdited: studentChanged });
    if (addressChangedHome)
      this.setState({ addressEditedHome: addressChangedHome });
    if (addressChangedPermanent)
      this.setState({ addressEditedPermanent: addressChangedPermanent });
    this.setState({ editedStudent });
  }

  handleDeleteDegree(e) {
    const { id: elementID } = e.target;
    let { degrees, degreesToDelete, degreesToAdd } = this.state;

    const degreeIndex = elementID.split("-")[0];
    const degreeNum = Number(degreeIndex);
    const degreeToDelete = degrees.find(
      (degree) => degree.degree_num === degreeNum
    );

    degrees = degrees.filter((degree) => degree.degree_num !== degreeNum);

    degreesToDelete = degreesToDelete.filter(
      (degree) => degree.degree_num !== degreeNum
    );

    degreesToAdd = degreesToAdd.filter(
      (degree) => degree.degree_num !== degreeNum
    );

    degreesToDelete.push(degreeToDelete);

    this.setState({
      degrees,
      degreesToDelete,
      degreesToAdd,
      otherFieldEdited: true,
    });
  }

  handleDeleteNewDegree(e) {
    const { id: elementID } = e.target;
    let { degreesToAdd } = this.state;

    const index = elementID.split("-")[0];
    const degreeNum = Number(index);
    degreesToAdd = degreesToAdd.filter(
      (degree) => degree.degree_num !== degreeNum
    );

    this.setState({ degreesToAdd, otherFieldEdited: true });
  }

  async handleDeleteLanguage(e) {
    const { id: elementID } = e.target;
    let { languages, languagesToDelete } = this.state;
    const index = elementID.split("-")[0];
    const languageToDelete = languages.splice(index, 1);

    languagesToDelete.push(languageToDelete[0]);

    this.setState({ languages, languagesToDelete, otherFieldEdited: true });
  }

  async handleDeleteNewLanguage(e) {
    const { id: elementID } = e.target;
    let { languagesToAdd } = this.state;

    const index = elementID.split("-")[0];
    languagesToAdd.splice(index, 1);

    this.setState({ languagesToAdd, otherFieldEdited: true });
  }

  handleLanguageChange(e) {
    const { id: elementID, value } = e.target;
    const { languagesToAdd } = this.state;

    const indexOfLanguage = elementID.split("-")[0];

    languagesToAdd[indexOfLanguage].language = value;

    this.setState({ languagesToAdd, otherFieldEdited: true });
  }

  handlePreLawChange(e) {
    const { id: elementID, value } = e.target;
    let { degrees, degreesToAdd, degreesToDelete } = this.state;

    const [index, field] = elementID.split("-");
    const degreeNum = Number(index);

    let degree = degrees.find((degree) => degree.degree_num === degreeNum);

    let degreeCopy = JSON.parse(JSON.stringify(degree));

    if (!degreesToDelete.find((x) => x.degree_num === degree.degree_num)) {
      degreesToDelete.push(degreeCopy);
    }

    switch (field) {
      case "school":
        degree.school = value;
        break;
      case "deg":
        degree.degree = value;
        break;
      case "gpa":
        degree.pre_law_gpa = value;
        break;
      case "year":
        degree.grad_year = value;
        break;
      default:
        return;
    }
    let degreeCopy2 = JSON.parse(JSON.stringify(degree));

    if (degreesToAdd.find((x) => x.degree_num === degree.degree_num)) {
      degreesToAdd = degreesToAdd.filter(
        (degree) => degree.degree_num !== degreeNum
      );
    }

    degreesToAdd.push(degreeCopy2);

    this.setState({
      degreesToAdd,
      degreesToDelete,
      otherFieldEdited: true,
    });
  }

  handleNewPreLawChange(e) {
    const { id: elementID, value } = e.target;
    let { degreesToAdd } = this.state;

    const [index, field] = elementID.split("-");
    const degreeNum = Number(index);

    const degree = degreesToAdd.find(
      (degree) => degree.degree_num === degreeNum
    );

    switch (field) {
      case "school":
        degree.school = value;
        break;
      case "deg":
        degree.degree = value;
        break;
      case "gpa":
        degree.pre_law_gpa = value;
        break;
      case "year":
        degree.grad_year = value;
        break;
      default:
        return;
    }

    this.setState({ degreesToAdd, otherFieldEdited: true });
  }

  handleRestore() {
    this.props.updateStudent();
    this.props.onClose();
  }

  async handleFormSubmit(e) {
    e.preventDefault();
    this.setState({ loading: true });

    const { student, roles } = this.props;
    let { degreesToAdd, degrees, degreesToDelete } = this.state;
    const { editedStudent, languagesToAdd, languagesToDelete } = this.state;
    const route = this.props.isGraduate ? "graduates" : "students";
    if (this.state.state_other) {
      editedStudent.address[this.state.indexOfHomeAddress].state = this.state.state_other;
    }

    //The following lines delete the completely empty degrees when you save
    let emptyDegrees = degrees.filter(
      (item) =>
        item.school === "" && item.degree === "" && item.grad_year === ""
    );

    degreesToAdd = degreesToAdd.filter(
      (item) =>
        item.school !== "" || item.degree !== "" || item.grad_year !== ""
    );

    emptyDegrees.forEach((item) => {
      if (
        !degreesToDelete.find((degree) => degree.degree_num === item.degree_num)
      ) {
        degreesToDelete.push(item);
      }
    });
    editedStudent.type = student.type;
    editedStudent.full_name = editedStudent.middle_name
      ? `${editedStudent.first_name} ${editedStudent.middle_name} ${editedStudent.last_name}`
      : `${editedStudent.first_name} ${editedStudent.last_name}`;

    editedStudent.date_of_birth = editedStudent.date_of_birth
      ? new Date(editedStudent.date_of_birth).toISOString()
      : null;
    const editedAddress = editedStudent.address;
    const postLanguages = async () => {
      await api.delete(`/language/${student.id}`, { data: languagesToDelete });
      await api.post(`/language/${student.id}`, languagesToAdd);
    };

    for (let i = 0; i < degreesToDelete.length; i++) {
      if (
        degreesToAdd.find(
          (degree) => degree.degree_num === degreesToDelete[i].degree_num
        )
      ) {
        degreesToDelete.splice(i, 1);
      }
    }

    const postDegrees = async () => {
      if (roles.admin || roles.registrar) {
        await api.delete(`/pre-law-degree/${student.id}`, {
          data: degreesToDelete,
        });
        await api.post(`/pre-law-degree/${student.id}`, degreesToAdd);
      }
    };

    try {
      const promiseArray = [postDegrees(), postLanguages()];
      if (this.state.studentEdited)
        promiseArray.push(api.post(`/${route}/${student.id}`, editedStudent));
      if (this.state.photoChanged) {
        promiseArray.push(
          api.post(`images/${this.state.editedStudent.id}`, {
            file: this.state.editablePhoto,
            person_type: "student",
          })
        );
      }
      if (this.state.soundChanged) {
        promiseArray.push(
          api.post(`audio/${this.state.editedStudent.id}`, {
            file: this.state.editableSound,
            person_type: "student",
          })
        );
      }
      if (this.state.addressEditedHome && !this.state.addressEditedPermanent) {
        promiseArray.push(
          api.post(
            `/address/${student.id}`,
            editedAddress[this.state.indexOfHomeAddress]
          )
        );
      } else if (
        this.state.addressEditedPermanent &&
        !this.state.addressEditedHome
      ) {
        promiseArray.push(
          api.post(
            `/address/${student.id}`,
            editedAddress[this.state.indexOfPermanentAddress]
          )
        );
      } else if (
        this.state.addressEditedPermanent &&
        this.state.addressEditedHome
      ) {
        promiseArray.push(api.post(`/address/${student.id}`, editedAddress));
      }
      await Promise.all(promiseArray);

      await this.props.updateStudent();
      this.setState({ loading: false });
      this.props.onClose();
    } catch (err) {
      console.error(err.message);
      this.setState({
        errorMessage: err.response?.data ? err.response.data : err.message,
        loading: false,
      });
    }
  }

  render() {
    const { editedStudent, indexOfPermanentAddress, indexOfHomeAddress } =
      this.state;
    const { student, roles } = this.props;
    const isStudOrGrad = roles.student || roles.graduate;
    const isOperator = roles.operator;
    const columnSize = roles.admin || roles.registrar ? 4 : 6;

    return (
      <Form onSubmit={this.handleFormSubmit}>
        <React.Fragment>
          {this.state.errorMessage && (
            <ErrorDisplay errorMessage={this.state.errorMessage} />
          )}
          <Row>
            <h1>Personal Info</h1>
          </Row>
          <Row>
            <Col>
              <Card className="shadow personal-info-card">
                <Card.Body>
                  <Row className="m-1 justify-content-center">
                    <Col xs="6" sm="4" md="2" className="mb-1">
                      <div
                        style={{ display: "inline-flex", position: "relative" }}
                      >
                        <div
                          style={{
                            position: "absolute",
                            bottom: 0,
                            left: 0,
                            zIndex: 1,
                          }}
                        >
                          <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip>Edit Photo</Tooltip>}
                          >
                            <Button
                              className="m-2 p-0"
                              variant="success"
                              onClick={this.togglePhotoModal}
                              aria-label={"Edit Student Photo"}
                            >
                              <FontAwesomeIcon
                                icon={faEdit}
                                style={{ color: "ffffff" }}
                              />
                            </Button>
                          </OverlayTrigger>
                        </div>
                        <div
                          style={{
                            position: "absolute",
                            bottom: 0,
                            right: 0,
                            zIndex: 1,
                          }}
                        >
                          <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip>Download Photo</Tooltip>}
                          >
                            <Button
                              className="m-2 p-0"
                              variant="success"
                              onClick={this.downloadImage}
                              aria-label={"Download Student Photo"}
                            >
                              <FontAwesomeIcon
                                icon={faDownload}
                                style={{ color: "ffffff" }}
                              />
                            </Button>
                          </OverlayTrigger>
                        </div>
                        <div style={{ postion: "relative" }}>
                          <CleanImage
                            src={
                              this.state.editablePhoto === ""
                                ? `${this.state.pic + new Date().getTime()}`
                                : this.displayPhoto()
                            } //Update photo image and button
                            alt="student profile picture"
                            style={{
                              width: "100%",
                              maxHeight: "139px",
                              maxWidth: "139px",
                              borderBottomLeftRadius: "100%",
                            }}
                            onError={(e) =>
                              handleImageError(e, {
                                first_name: student.first_name,
                                last_name: student.last_name,
                              })
                            }
                            person={student}
                            fluid
                            rounded
                            className="shadow"
                          />
                        </div>
                      </div>
                      <AddPhotoModal
                        show={this.state.showPhotoModal}
                        onHide={this.handleCancel}
                        addPhoto={this.handleAddPhoto}
                        selectPhoto={this.handleSelectPhoto}
                      />
                      <AddSoundModal
                        show={this.state.showSoundModal}
                        onHide={this.handleSoundCancel}
                        addSound={this.handleAddSound}
                        selectSound={this.handleSelectSound}
                      />
                      <Row className="mt-2 ">
                        <strong className="p-0 m-0">
                          Pronunciation{" "}
                          <OverlayTrigger
                            placement="top"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip>Edit Pronunciation</Tooltip>}
                          >
                            <Button
                              className="m-0 p-0"
                              variant="success"
                              onClick={this.toggleSoundModal}
                              aria-label={"Edit Student Pronunciation"}
                            >
                              <FontAwesomeIcon icon={faEdit} />
                            </Button>
                          </OverlayTrigger>
                        </strong>
                      </Row>

                      {this.state.editableSound && (
                        <p>{this.state.soundFileName}</p>
                      )}
                      {!this.props.roles.student && (
                        <Row className="mt-3 m-1">
                          <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 250, hide: 400 }}
                            overlay={
                              <Tooltip>
                                {"Click to Restict or Unrestrict info"}
                              </Tooltip>
                            }
                          >
                            <Form.Check
                              type="switch"
                              id="restrict"
                              label={"Restricted"}
                              checked={
                                editedStudent.restricted_contact_information
                              }
                              onChange={this.handleChange}
                            />
                          </OverlayTrigger>
                        </Row>
                      )}
                    </Col>
                    {/* Name and Info Section */}
                    <Col md="10">
                      <Row className="p-0 m-0 mb-1">
                        <Col md="6" lg={columnSize} className="personal-info-col">
                          <EditPersonalInfoEntry
                            label={"First Name:"}
                            id="first-name"
                            value={editedStudent.first_name}
                            onChange={this.handleChange}
                            disabled={isStudOrGrad}
                          />
                          <EditPersonalInfoEntry
                            label={"Middle Name:"}
                            id="middle-name"
                            value={editedStudent.middle_name}
                            onChange={this.handleChange}
                            disabled={isStudOrGrad}
                          />                        
                          <EditPersonalInfoEntry
                            label={"Last Name:"}
                            id="last-name"
                            value={editedStudent.last_name}
                            onChange={this.handleChange}
                            disabled={isStudOrGrad}
                          />
                          <EditPersonalInfoEntry
                            label={"Preferred Name:"}
                            id="preferred"
                            value={editedStudent.known_by}
                            onChange={this.handleChange}
                          />
                          <div className="entry">
                            <Col xs="auto">
                              <Form.Label>
                                <strong className="p-0">Gender:</strong>
                              </Form.Label>
                            </Col>
                            <Col className="entry-select">
                              <Form.Select
                                id="gender"
                                value={editedStudent.gender}
                                onChange={this.handleChange}
                                disabled={isStudOrGrad || isOperator}
                              >
                                <option value="male">Male</option>
                                <option value="female">Female</option>
                              </Form.Select>
                            </Col>
                          </div>
                          <EditPersonalInfoEntry
                            label={"Date of Birth:"}
                            id="dob"
                            value={editedStudent.date_of_birth}
                            onChange={this.handleChange}
                            disabled={isStudOrGrad || isOperator}
                          />
                          <EditPersonalInfoEntry
                            label={"SID:"}
                            disabled
                            value={editedStudent.id}
                            onChange={this.handleChange}
                          />
                          <EditPersonalInfoEntry
                            label={"NetID:"}
                            disabled
                            value={editedStudent.net_id}
                            onChange={this.handleChange}
                          />
                          {roles.student ? (
                            <EditPersonalInfoEntry
                              label="Exp. Graduation:"
                              id="exp-grad-year"
                              disabled
                              value={editedStudent.expected_grad_year}
                              onChange={this.handleChange}
                              type="number"
                            />
                          ) : (
                            <>
                              {editedStudent.expected_grad_year?.JD && (
                                <EditPersonalInfoEntry
                                  label="Graduation JD:"
                                  id="exp-grad-year"
                                  disabled
                                  value={editedStudent.expected_grad_year.JD}
                                  onChange={this.handleChange}
                                  type="number"
                                />
                              )}
                              {editedStudent.expected_grad_year?.LLM && (
                                <EditPersonalInfoEntry
                                  label="Graduation LLM:"
                                  id="exp-grad-year"
                                  disabled
                                  value={editedStudent.expected_grad_year.LLM}
                                  onChange={this.handleChange}
                                  type="number"
                                />
                              )}                              
                            </>
                          )}
                        </Col>

                        <Col md="6" lg={columnSize} className="personal-info-col">
                          <EditPersonalInfoEntry
                            label={"Address:"}
                            id="address-home-line1"
                            placeholder="Street Address"
                            value={
                              editedStudent.address[indexOfHomeAddress]?.street1
                            }
                            onChange={this.handleChange}
                          />
                          <EditPersonalInfoEntry
                            label={"City:"}
                            id="address-home-city"
                            value={editedStudent.address[indexOfHomeAddress]?.city}
                            onChange={this.handleChange}
                          />
                          <div className="entry">
                            <Col xs="auto">
                              <Form.Label>
                                <strong className="p-0">State:</strong>
                              </Form.Label>
                            </Col>
                            <Col className="entry-select">
                              <Form.Select
                                id="address-home-state"
                                value={editedStudent.address[indexOfHomeAddress]?.state}
                                onChange={this.handleChange}
                              >
                                {stateList.map((state) => (
                                  <option key={state} value={state}>
                                    {state}
                                  </option>
                                ))}
                              </Form.Select>
                            </Col>
                          </div>
                          {editedStudent.address[indexOfHomeAddress]?.state === "Other" && (
                            <EditPersonalInfoEntry
                              label={"Other:"}
                              id="address-home-state-other"
                              value={this.state.state_other}
                              onChange={this.handleChange}
                            />
                          )}
                          <EditPersonalInfoEntry
                            label={"ZIP Code:"}
                            type="number"
                            id="address-home-zip"
                            value={
                              editedStudent.address[indexOfHomeAddress]?.postal_code
                            }
                            onChange={this.handleChange}
                          />
                          <EditPersonalInfoEntry
                            label={"Phone:"}
                            id="address-home-phone"
                            value={editedStudent.address[indexOfHomeAddress]?.phone}
                            onChange={this.handleChange}
                          />
                          <EditPersonalInfoEntry
                            label={"Email:"}
                            type="email"
                            id="email"
                            value={editedStudent.email}
                            onChange={this.handleChange}
                            disabled={isStudOrGrad}
                          />
                          <EditPersonalInfoEntry
                            label={"Carrel:"}
                            type="number"
                            id="carrel"
                            value={editedStudent.carrel}
                            onChange={this.handleChange}
                            disabled={isStudOrGrad}
                          />
                          <EditPersonalInfoEntry
                            label={"Exam #:"}
                            id="exam-number"
                            value={editedStudent.exam_number}
                            onChange={this.handleChange}
                            disabled={true}
                          />
                          <div className="entry">
                            <Col xs="auto">
                              <Form.Label className="p-0 m-0 mb-1">
                                <strong>Class</strong>
                              </Form.Label>
                            </Col>
                            <Col className="entry-select">
                              <Form.Select
                                id="class-year"
                                value={editedStudent.year_in_program}
                                onChange={this.handleChange}
                                disabled={isStudOrGrad || isOperator}
                              >
                                <option value="A">A</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="LLM">LLM</option>
                                <option value="G">G</option>
                              </Form.Select>
                            </Col>
                          </div>
                        </Col>
                        { (roles.admin || roles.registrar) && (
                          <Col md="6" lg={columnSize} className="personal-info-col">
                            <EditPersonalInfoEntry
                              label={"Ethnicity:"}
                              id="ethnicity"
                              value={editedStudent.ethnicity}
                              onChange={this.handleChange}
                            />
                            <EditPersonalInfoEntry
                              label={"Religion:"}
                              id="religion"
                              value={editedStudent.religion}
                              onChange={this.handleChange}
                            />
                            <EditPersonalInfoEntry
                              label={"Spouse:"}
                              id="spouse"
                              value={editedStudent.spouse}
                              onChange={this.handleChange}
                            />
                            {(this.props.roles.admin || this.props.roles.registrar) && ( //|| this.props.roles.operator) && ( // maybe
                              <EditLanguages
                                languages={this.state.languages}
                                handleLanguageChange={this.handleLanguageChange}
                                addNewLanguage={this.addNewLanguage}
                                handleDeleteLanguage={this.handleDeleteLanguage}
                                roles={roles}
                                languagesToAdd={this.state.languagesToAdd}
                                handleDeleteNewLanguage={this.handleDeleteNewLanguage}
                              />
                            )}
                            <EditPersonalInfoEntry
                              label={"Mission:"}
                              id="mission"
                              value={editedStudent.mission}
                              onChange={this.handleChange}
                            />
                            <EditPersonalInfoEntry
                              label={"Interests:"}
                              id="interests"
                              value={editedStudent.interests}
                              onChange={this.handleChange}
                            />
                          </Col>
                        )}
                      </Row>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            
            </Col>
            
          </Row>
          <EditPreLawSchools
            degrees={this.state.degrees}
            handlePreLawChange={this.handlePreLawChange}
            addNewDegree={this.addNewDegree}
            handleDeleteDegree={this.handleDeleteDegree}
            roles={roles}
            degreesToAdd={this.state.degreesToAdd}
            handleDeleteNewDegree={this.handleDeleteNewDegree}
            handleNewPreLawChange={this.handleNewPreLawChange}
          />
        </React.Fragment>
        <Row className="mt-4 mb-2">
          {(this.props.roles.admin || this.props.roles.registrar) && (
            <Col className="d-flex justify-content-begining" xs="5">
              {this.state.showHistory ? (
                <Button
                  className="mb-4 shadow-button"
                  onClick={this.toggleHistory}
                >
                  Hide History
                </Button>
              ) : (
                <Button
                  className="mb-4 shadow-button"
                  onClick={this.toggleHistory}
                >
                  Show History
                </Button>
              )}
            </Col>
          )}
          <Col className="d-flex justify-content-end" xs="7">
            <Button
              className="mb-4 shadow-button"
              style={{ marginRight: ".5rem" }}
              variant="outline-info"
              type=""
              id="cancel-button"
              onClick={this.props.onClose}
            >
              Cancel
            </Button>
            <Button
              className="mb-4 shadow-button"
              variant="info"
              type="submit"
              disabled={this.saveValidator()}
            >
              Save Changes
              {this.state.loading && (
                <Spinner animation="border" size="sm" style={{ marginLeft:".5rem" }} />
              )}
            </Button>
          </Col>
        </Row>
        {(this.props.roles.admin || this.props.roles.registrar) &&
          this.state.showHistory && (
            <Card className="shadow mb-4">
              <Card.Header as="h5">Change history: </Card.Header>
              <Card.Body style={{ height: "85vh" }}>
                <p className="m-0">{editedStudent.history}</p>
                <History
                  apis={[
                    `/${this.props.isGraduate ? "graduates" : "students"}/${
                      this.state.editedStudent.id
                    }`
                  ]}
                  onRestore={this.handleRestore}
                  enableHighlight={[true, false, true, false]}
                  displayNames={[
                    "Student Data",
                    "Pre-law Degrees",
                    "Addresses",
                    "Languages",
                  ]}
                />
              </Card.Body>
            </Card>
          )}
      </Form>
    );
  }
}
