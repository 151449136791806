import React from "react";
import { Modal, Button } from "react-bootstrap";

class GradessubmitModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.submit = this.submit.bind(this);
  }

  /**
   * Submits the form by calling the handleSaveGrade function and hiding the component.
   *
   * @return {void} None - no return value.
   */
  submit() {
    this.props.handleSaveGrade();
    this.props.onHide();
  }

  render() {
    let { show, onHide } = this.props;
    // let file ;
    return (
      <Modal show={show} onHide={this.onHide}>
        <Modal.Header>
          <Modal.Title>Submission</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* {message} */}

          <p>Are you sure you want to submit and not Save?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-info" onClick={onHide}>
            Cancel
          </Button>
          <Button onClick={this.submit}>Submit</Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default GradessubmitModal;
