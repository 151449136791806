import React from 'react';
import { Nav, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

class NavBarItem extends React.Component {
  render() {
    const {
      title,
      linkRef,
      isFromPage,
      iconDark,
      iconLight,
      alt,
      isLink,
      isFunction,
      handlerFunction,
      functionArgs,
    } = this.props;
    let LinkItem;
    let FunctionItem;

    if (isLink) {
      LinkItem = (
        <Nav.Item className="nav-bar-item">
          {isFromPage ? (
            <Link className="text-dark" to={linkRef}>
              <Button 
                className="align-items-center px-3 py-2 shadow"
                variant="primary-gray"
                disabled={this.props.disabled}
              >
                <img
                  src={iconDark}
                  className="pe-2"
                  alt={alt}
                  style={{ maxHeight: "30px" }}
                />
                {title}
              </Button>
            </Link>
          ) : (
            <Link className="text-light" to={linkRef}>
              <Button 
                className="align-items-center px-3 py-2 shadow"
                disabled={this.props.disabled}
              >
                <img
                  src={iconLight}
                  className="pe-2"
                  alt={alt}
                  style={{ maxHeight: "30px" }}
                />
                {title}
              </Button>
            </Link>
          )}
        </Nav.Item>
      );
    }

    if (isFunction) {
      FunctionItem = (
        <Nav.Item className="nav-bar-item">
          {isFromPage ? (
            <Button 
              className="align-items-center px-3 py-2 shadow"
              variant="primary-gray"
              onClick={() => handlerFunction(functionArgs.join())}
              disabled={this.props.disabled}
            >
              <img
                src={iconDark}
                className="pe-2"
                alt={alt}
                style={{ maxHeight: "30px" }}
              />
              {title}
            </Button>
          ) : (
            <Button 
              className="align-items-center px-3 py-2 shadow"
              onClick={() => handlerFunction(functionArgs.join())}
              disabled={this.props.disabled}
            >
              <img
                src={iconLight}
                className="pe-2"
                alt={alt}
                style={{ maxHeight: "30px" }}
              />
              {title}
            </Button>
          )}
        </Nav.Item>
      )
    }

    return (
      <>
        {isLink && (
          <>
            {LinkItem}
          </>
        )}

        {isFunction && (
          <>
            {FunctionItem}
          </>
        )}
      </>
    )
  }
}

export default NavBarItem;