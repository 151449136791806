import axios from "axios";
import { getToken } from "../utils/refreshtoken";

function createAxiosInstance() {
  //const actastoken = getToken();
  const idtoken = getToken();
  //const token2 = `Bearer ${actastoken}`;
  const token = `Bearer ${idtoken}`;
  const params = {
    baseURL: process.env.REACT_APP_BASE_URL,
  };
  // if (actastoken) {
  //   params.headers = {
  //     Authorization: token2,
  //   };
  // } else
  if (idtoken) {
    params.headers = {
      Authorization: token,
    };
  }
  const instance = axios.create(params);
  // if (sessionStorage.getItem("impersonationToken")) {
  //   instance.interceptors.request.use((config) => {
  //     const impersonationDetails = JSON.parse(
  //       sessionStorage.getItem("impersonationToken")
  //     );
  //     impersonationDetails.roles = impersonationDetails.roles?.join(",");
  //     config.params = {
  //       impersonate: true,
  //       ...impersonationDetails,
  //       ...config.params,
  //     };
  //     return config;
  //   });
  // }
  return instance;
}

const api = new Proxy(
  {},
  {
    get: function (_, prop) {
      const instance = createAxiosInstance();
      return instance[prop];
    },
  }
);

export default api;
