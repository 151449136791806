import React from "react";
import { Card } from "react-bootstrap";
import History from "../components/History";

class DeletedRecords extends React.Component {
  constructor(props) {
    super(props);
    this.onRestore = this.onRestore.bind(this);
  }

  onRestore(student) {
    window.location.assign(`${this.props.redirectURL}${student.id}`);
  }

  render() {
    const { title, api, postApi } = this.props;
    return (
      <Card className="mb-4 shadow">
        <Card.Header as="h3" className="text-center">
          {title}
        </Card.Header>
        <Card.Body>
          {/* style={{ height: "80vh" }}> */}
          <History
            apis={[`${api}`]}
            postApi={postApi}
            onRestore={this.onRestore}
            enableHighlight={[false]}
            isTableOfDeletedItems
            displayNames={[`${title}`]}
          />
        </Card.Body>
      </Card>
    );
  }
}

export default DeletedRecords;
