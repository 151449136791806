import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChartBar,
  faCheck,
  faCheckCircle,
  faColumns,
  faMinusCircle,
  faTimes,
  faMask,
} from "@fortawesome/free-solid-svg-icons";
import {
  Button,
  OverlayTrigger,
  Spinner,
  Tooltip,
} from "react-bootstrap";
import CopyIcon from "./CopyIcon";
import Impersonate from "./Impersonate";

class TextOnlyRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showImpersonateModal: false,
    };
    this.toggleImpersonateModal = this.toggleImpersonateModal.bind(this);
  }

  /**
   * Toggles the impersonate modal and stops the propagation of the event.
   *
   * @param {Event} event - The event that triggered the toggle.
   * @return {none} - This function does not return a value.
   */
  toggleImpersonateModal(event) {
    this.setState({ showImpersonateModal: !this.state.showImpersonateModal });
    event.stopPropagation();
  }

  render() {
    const {
      element,
      fieldsToShow,
      handleDisplayButton,
      handleDisplayClick,
      dataSet,
      roles,
      impersonateField
    } = this.props;
      
    return (
      <tr
        tabIndex="0"
        aria-roledescription="button"
        onKeyDown={(e) => handleDisplayButton(e, element, false)}
      >
        {element.professors === "[object Object]" &&
          (element.professors = "")}
        {fieldsToShow.map((field) =>
          field.display_name === "Actions" ? (
            <React.Fragment key={field.display_name}>
              <td>
                {roles.registrar && (
                  <React.Fragment>
                    {element.loading ? (
                      <Spinner
                        animation="border"
                        size="sm"
                      />
                    ) : (
                      <OverlayTrigger
                        id
                        overlay={<Tooltip>Test Grades</Tooltip>}
                      >
                        <Button
                          variant="success"
                          size="sm"
                          onClick={() => this.props.getCourseStats(element)}
                        >
                          <FontAwesomeIcon
                            icon={faChartBar}
                          />
                        </Button>
                      </OverlayTrigger>
                    )}
  
                    <OverlayTrigger
                      overlay={<Tooltip>Approve</Tooltip>}
                    >
                      <span>
                        <Button
                          size="sm"
                          variant="success"
                          className="border-0"
                          value={JSON.parse(JSON.stringify(element))}
                          disabled={
                            element.grade_status === "rejected" ||
                            element.grade_status === "unsubmitted" ||
                            element.grade_status === "submitted"
                          }
                          onClick={() =>
                            this.props.submitStatus(element, "approved")
                          }
                        >
                          {element.grade_status === "rejected" ||
                          element.grade_status === "submitted" ||
                          element.grade_status === "unsubmitted" ? (
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              style={{ color: "5A5A5A" }}
                            />
                          ) : (
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              style={{ color: "34b233" }}
                            />
                          )}
                        </Button>
                      </span>
                    </OverlayTrigger>
  
                    <OverlayTrigger
                      overlay={<Tooltip>Reject </Tooltip>}
                    >
                      <span>
                        <Button
                          size="sm"
                          variant="success"
                          className="border-0"
                          value={JSON.parse(JSON.stringify(element))}
                          disabled={
                            element.grade_status === "rejected" ||
                            element.grade_status === "unsubmitted" ||
                            element.grade_status === "submitted"
                          }
                          onClick={() =>
                            this.props.submitStatus(element, "rejected")
                          }
                        >
                          {element.grade_status === "rejected" ||
                          element.grade_status === "unsubmitted" ||
                          element.grade_status === "submitted" ? (
                            <FontAwesomeIcon
                              icon={faMinusCircle}
                              style={{ color: "5A5A5A" }}
                            />
                          ) : (
                            <FontAwesomeIcon
                              icon={faMinusCircle}
                              style={{ color: "FF0000" }}
                            />
                          )}
                        </Button>
                      </span>
                    </OverlayTrigger>
  
                    <OverlayTrigger
                      id
                      overlay={<Tooltip>View Grades</Tooltip>}
                    >
                      <Button
                        size="sm"
                        className="me-1"
                        variant="success"
                        onClick={() =>
                          handleDisplayClick(element, true)
                        }
                      >
                        <FontAwesomeIcon
                          icon={faColumns}
                          style={{ color: "5bb0e2" }}
                        />
                      </Button>
                    </OverlayTrigger>
                  </React.Fragment>
                )}
                {roles.faculty &&
                  !roles.registrar &&
                  this.props.fromCourse &&
                  element.can_edit && (
                    <OverlayTrigger
                      id
                      overlay={<Tooltip>View Grades</Tooltip>}
                    >
                      <Button
                        size="sm"
                        className="me-1"
                        variant="success"
                        onClick={() =>
                          handleDisplayClick(element, true)
                        }
                      >
                        <FontAwesomeIcon
                          icon={faColumns}
                          style={{ color: "5bb0e2" }}
                        />
                      </Button>
                    </OverlayTrigger>
                  )}
              </td>
            </React.Fragment>
          ) : field.display_name === "Grade Status" &&
            (element.grade_status === "approved" ||
              element.grade_status === "registrar") ? (
            <td key={field.display_name}>
              <p>
                {element.grade_status.charAt(0).toUpperCase() +
                  element.grade_status.slice(1)}
              </p>
            </td>
          ) : field.display_name === "Reqs Met" ? (
            <td key={field.display_name}>
              <p>
                {element.meets_grade_reqs ? "Yes" : "No"}
              </p>
            </td>
          ) : (
            <td
              onClick={() => handleDisplayClick(element, false)}
              className="clickable"
              key={field.display_name}
            >
              {/* This is what displays the data that is passed in */}
              {field.display_name === "Course" ? (
                element[field.field_name[0]] +
                "-" +
                element[field.field_name[1]]
              ) : field.display_name === "Substantial Writing" ||
                field.display_name === "Library Skills" ? (
                <React.Fragment>
                  {String(element[field.field_name])
                    .charAt(0)
                    .toUpperCase() +
                    String(element[field.field_name]).slice(1) ===
                  "Complete" ? (
                    <FontAwesomeIcon
                      style={{ color: "#228c22" }}
                      icon={faCheck}
                    />
                  ) : (
                    <FontAwesomeIcon
                      style={{ color: "bb1919" }}
                      icon={faTimes}
                    />
                  )}
                </React.Fragment>
              ) : field.display_name === "Birthday" ? (
                <td>
                  {" "}
                  {new Date(element.date_of_birth).toLocaleDateString(
                    "en-US",
                    {
                      timeZone: "UTC",
                      month: "long",
                      day: "numeric",
                    }
                  )}
                </td>
              ) : field.display_name === "Email" &&
                element[field.field_name] ? (
                <div>
                  <a
                    href={`mailto:${element[field.field_name]}`}
                    style={{ color: "black" }}
                    onClick={(e) => e.stopPropagation()}
                  >
                    {String(
                      element[field.field_name]
                        ? element[field.field_name]
                        : ""
                    )
                      .charAt(0)
                      .toUpperCase() +
                      String(
                        element[field.field_name]
                          ? element[field.field_name]
                          : " "
                      ).slice(1)}
                    </a>
                  <CopyIcon item={element[field.field_name]} />
                </div>
              ) : field.display_name === "Phone" &&
                element[field.field_name] ? (
                  <a
                    href={`tel:${element[field.field_name]}`}
                    style={{ color: "black" }}
                    onClick={(e) => e.stopPropagation()}
                  >
                    {element[field.field_name]}
                  </a>
              ) : (
                <>
                  {String(
                    element[field.field_name]
                      ? element[field.field_name]
                      : ""
                  )
                    .charAt(0)
                    .toUpperCase() +
                  String(
                    element[field.field_name]
                      ? element[field.field_name]
                      : " "
                  ).slice(1)}
                  {roles.registrar && field.field_name === impersonateField && (
                    <>
                      <FontAwesomeIcon
                        icon={faMask}
                        style={{ color: "#e6ac00", marginLeft: 5 }}
                        onClick={(event) => {
                          this.toggleImpersonateModal(event);
                        }}
                      />
                      <Impersonate
                        dataset={dataSet}
                        show={this.state.showImpersonateModal}
                        onHide={this.toggleImpersonateModal}
                        person={element}
                      />
                    </>
                  )}
                </>
              )}
            </td>
          )
        )}
      </tr>
    )
  }
}

export default TextOnlyRow;