import React from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";

class RequestRecording extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      requestRecordingObject: {},
      name: "",
      email: "",
      partnerName: "",
      professorName: "",
      courseType: "",
      date: "",
      time: null,
      recordingLength: "",
      room: "",
      otherRoom: "",
      specialInstructions: "",
    };
    this.submitRequest = this.submitRequest.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  /*MBNote 05/19/2022: made this page but may not need this as it 
could be similar to request final exam change in which the request 
is handled with a qualtrics survey. If it is used that way we will 
not need this page. However, if we have a way to send emails from the code 
then this page will become applicable */

  handleChange(e) {
    let value = e.target.value;
    let id = e.target.id;

    switch (id) {
      case "name":
        this.setState({ name: value });
        break;
      case "email":
        this.setState({ email: value });
        break;
      case "partner-name":
        this.setState({ partnerName: value });
        break;
      case "professor-name":
        this.setState({ professorName: value });
        break;
      case "coruse-type":
        this.setState({ courseType: value });
        break;
      case "date":
        this.setState({ date: value });
        break;
      case "time":
        this.setState({ time: value });
        break;
      case "recording-length":
        this.setState({ recordingLength: value });
        break;
      case "room":
        this.setState({ room: value });
        break;
      case "other-room":
        this.setState({ otherRoom: value });
        break;
      case "special-instructions":
        this.setState({ specialInstructions: value });
        break;

      default:
        break;
    }
  }

  /*MBNote:this submit will need to send an eamil to media services so 
  they can get the notification about someone wanting a special recording
   */
  submitRequest() {
    let tempObject = this.state.requestRecordingObject;
    tempObject.name = this.state.name;
    tempObject.email = this.state.email;
    tempObject.partnerName = this.state.partnerName;
    tempObject.professorName = this.state.professorName;
    tempObject.courseType = this.state.courseType;
    tempObject.date = this.state.date;
    tempObject.time = this.state.time;
    tempObject.recordingLength = this.state.recordingLength;
    tempObject.room = this.state.room;
    tempObject.otherRoom = this.state.otherRoom;
    tempObject.specialInstructions = this.state.specialInstructions;
    this.setState({ requestRecordingObject: tempObject });
  }

  render() {
    let { handleBack } = this.props;
    return (
      <React.Fragment>
        <Card className="shadow my-4">
          <Card.Header className="text-center" as="h2">
            Request Special Recording
          </Card.Header>
          <Card.Body>
            <Row className="justify-content-center mt-1">
              <Col xs="auto">
                <h5>Instructions</h5>
              </Col>
            </Row>
            <Row className="justify-content-center mt-2 mb-3">
              <Col xs="auto" xl="10">
                Use this form to request a recording for mediation or
                negotiation courses, or other course projects. Before scheduling
                a recording, please schedule a room. To schedule a room now,
                click on "
                <a
                  href="https://jrcb-ems.byu.edu/emswebapp/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Law School Room Schedule
                </a>
                ." Rooms that have cameras in them are: 205, 206, 208, 275, 276,
                303, 306, 314, 320. Fields marked with an * are required and
                must be filled in before your request can be processed. Click on{" "}
                <strong> Submit</strong> button to schedule your request with{" "}
                <storng>Media Services</storng>. You should receive an e-email
                response once the request has been processed.
              </Col>
            </Row>
            <Row>
              <Col xs={2}></Col>
              <Col>
                <Row>
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={2}>
                      Name *
                    </Form.Label>
                    <Col sm={8}>
                      <Form.Control
                        id="name"
                        type="text"
                        value={this.state.name}
                        onChange={this.handleChange}
                        placeholder="Name"
                        isInvalid={this.state.name.length === 0}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={2}>
                      Email *
                    </Form.Label>
                    <Col sm={8}>
                      <Form.Control
                        id="email"
                        type="email"
                        value={this.state.email}
                        onChange={this.handleChange}
                        placeholder="Email"
                        isInvalid={this.state.email.length === 0}
                      />
                    </Col>
                  </Form.Group>

                  <Row className="mb-3">
                    <Form.Group as={Col} xs={5}>
                      <Form.Label> Partner Name</Form.Label>
                      <Form.Control
                        type="text"
                        id="partner-name"
                        onChange={this.handleChange}
                        value={this.state.partnerName}
                        placeholder="Name"
                      />
                    </Form.Group>

                    <Form.Group as={Col} xs={5}>
                      <Form.Label> Professor Name</Form.Label>
                      <Form.Control
                        type="text"
                        id="professor-name"
                        onChange={this.handleChange}
                        value={this.state.professorName}
                        placeholder="Name"
                      />
                    </Form.Group>
                  </Row>

                  <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={2}>
                      Course Type*
                    </Form.Label>
                    <Col sm={8}>
                      <Form.Select
                        defaultValue={"0"}
                        id="coruse-type"
                        onChange={this.handleChange}
                        value={this.state.courseType}
                        isInvalid={this.state.courseType === ""}
                      >
                        <option value="">Select course Type</option>
                        <option value="1">course 1</option>
                        <option value="2">course 2</option>
                        <option value="3">course 3</option>
                      </Form.Select>
                    </Col>
                  </Form.Group>

                  <Row className="mb-3">
                    <Form.Group as={Col} xs={3}>
                      <Form.Label>Date*</Form.Label>
                      <Form.Control
                        type="date"
                        id="date"
                        onChange={this.handleChange}
                        value={this.state.date}
                        placeholder="date"
                        isInvalid={this.state.date === ""}
                      />
                    </Form.Group>

                    <Form.Group as={Col} xs={3} id="time">
                      <Form.Label>Time*</Form.Label>
                      <Form.Control
                        type="time"
                        id="time"
                        onChange={this.handleChange}
                        value={this.state.time}
                        placeholder="time"
                        isInvalid={this.state.time === null}
                      />
                    </Form.Group>

                    <Form.Group as={Col} xs={4}>
                      <Form.Label> Length of Recording*</Form.Label>
                      <Form.Select
                        id="recording-length"
                        onChange={this.handleChange}
                        value={this.state.recordingLength}
                        isInvalid={this.state.recordingLength === ""}
                      >
                        <option value="">Select Time Duration (min)</option>
                        <option value="15">15</option>
                        <option value="30">30</option>
                        <option value="60">60</option>
                        <option value="75">75</option>
                        <option value="90">90</option>
                        <option value="105">105</option>
                        <option value="120">120</option>
                      </Form.Select>
                    </Form.Group>
                  </Row>

                  <Row className="mb-3">
                    <Form.Group as={Col} xs={5}>
                      <Form.Label>Room*</Form.Label>
                      <Form.Select
                        id="room"
                        onChange={this.handleChange}
                        value={this.state.room}
                        isInvalid={
                          this.state.otherRoom.length === 0 &&
                          this.state.room === ""
                        }
                      >
                        <option value="">Select Room (JCRB)</option>
                        <option value="205">205 (Trial Court Room)</option>
                        <option value="206">206</option>
                        <option value="208">208</option>
                        <option value="275">275</option>
                        <option value="276">276</option>
                        <option value="276">276</option>
                        <option value="303">
                          303 (Anderson Moot Court Room){" "}
                        </option>
                        <option value="306">306</option>
                        <option value="314">314</option>
                        <option value="320">320</option>

                        {/*MBNote this is a list of all the rooms I found on 
                        the link to request a room. If we want to add these
                        to the list later we can otherwise we can take it out 
                        when this is not under construction

                         <option value="">Select Room (JCRB)</option>
                        <option value="144">144</option>
                        <option value="150">150</option>
                        <option value="205">205 (Trial Court Room)</option>
                        <option value="206">206</option>
                        <option value="208">208</option>
                        <option value="274A">274A</option>
                        <option value="274B">274B</option>
                        <option value="274D">274D</option>
                        <option value="274F">274F</option>
                        <option value="274H">274H</option>
                        <option value="275">275</option>
                        <option value="276">276</option>
                        <option value="276">276</option>
                        <option value="303">
                          303 (Anderson Moot Court Room){" "}
                        </option>
                        <option value="306">306</option>
                        <option value="314">314</option>
                        <option value="320">320</option>
                        <option value="380B">380B</option>
                        <option value="380C">380C</option>
                        <option value="380D">380D</option>
                        <option value="380E">380E</option> */}
                      </Form.Select>
                    </Form.Group>
                    <Form.Group as={Col} xs={5}>
                      <Form.Label>Other*</Form.Label>
                      <Form.Control
                        id="other-room"
                        onChange={this.handleChange}
                        type="text"
                        placeholder="Enter Room number"
                        value={this.state.otherRoom}
                      />
                    </Form.Group>
                  </Row>
                  <Row className="mb-3">
                    <Form.Group as={Col} xs={10}>
                      <Form.Label>Special Instructions</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={5}
                        size="sm"
                        id="special-instructions"
                        onChange={this.handleChange}
                        value={this.state.specialInstructions}
                      />
                    </Form.Group>
                  </Row>
                </Row>
              </Col>
              <Col xs={1}></Col>
            </Row>
            <Row className="justify-content-center mt-2">
              <Col xs="auto" xl="10">
                Please drop by the <strong>Media Services</strong> office (318
                JRCB) 5-10 minutes before your recording begins to view camera
                placement. Recording will begin and end exactly as requestd. So,
                if you are late starting or go over the scheduled recording
                time, it is your responsibility to contact{" "}
                <strong>Media Services</strong> (801-422-2104) to adjust the
                schedule.
              </Col>
            </Row>

            <Row className="justify-content-center mt-1">
              <Col xs="auto">
                <Button className="mx-1" onClick={handleBack}>
                  Back
                </Button>
                <Button
                  className="mx-1"
                  onClick={this.submitRequest}
                  disabled={
                    this.state.name.length === 0 ||
                    this.state.email.length === 0 ||
                    this.state.courseType === "" ||
                    this.state.date === "" ||
                    this.state.time === null ||
                    this.state.recordingLength === "" ||
                    (this.state.otherRoom.length === 0 &&
                      this.state.room === "")
                  }
                >
                  Submit
                </Button>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </React.Fragment>
    );
  }
}

export default RequestRecording;
