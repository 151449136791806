import React from "react";
import { Col, Row, Card } from "react-bootstrap";
import { handleImageError } from "../../utils/functions";
import { CleanCardImage } from "../SafeImage";

class PublicPicture extends React.Component {
  render() {
    const { dataSet, handleDisplayClick } = this.props;
    return (
      <React.Fragment>
        <Row className="no-margin">
          {dataSet.map((person, index) => (
            <Col
              xs={6}
              sm={4}
              lg={3}
              xl={2}
              className="text-picture-compensation"
            >
              <Card
                onClick={() => handleDisplayClick(index)}
                className="directory-shadow fill-parent clickable"
              >
                <div className="image-display-container">
                  <CleanCardImage
                    className="image-display-content"
                    variant="top"
                    src={dataSet[index].picture_link}
                    alt={`${person.first_name} ${person.last_name} profile picture`}
                    placeholder="person_img"
                    onError={(e) => {
                      handleImageError(e, person);
                    }}
                    person={person}
                  />
                </div>
                <Row className="mx-2 mb-1">
                  <ul className="list-unstyled no-margin">
                    <li>
                      <strong>
                        {person.first_name + " " + person.last_name}
                      </strong>
                    </li>
                    <li>
                        {person.length > 0
                          ? person.category[0]
                          : "Full Time Faculty"}
                    </li>
                  </ul>
                </Row>
              </Card>
            </Col>
          ))}
        </Row>
      </React.Fragment>
    );
  }
}

export default PublicPicture;
