import React from "react";
import { Col, Row, Button, Card } from "react-bootstrap";
import History from "../../History";
import { handleImageError, getAddressOfType } from "../../../utils/functions";
import PersonalInfoEntry from "./PersonalInfoEntry";

import PreLawSchools from "./PreLawSchools";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faPlayCircle, faStopCircle } from "@fortawesome/free-solid-svg-icons";
import { CleanImage } from "../../SafeImage";

export default class DisplayPersonalInfo extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pic: "",
      showHistory: false,
      play: false,
      student: this.props,
    };
    this.toggleHistory = this.toggleHistory.bind(this);
    this.getLanguages = this.getLanguages.bind(this);
  }

  audio = new Audio(
    `https://s3.amazonaws.com/content.law-info.byu.edu-dev/student-audio/${this.props.student.id}.mp3?` +
      new Date().getTime()
    //`aws-address/file-name-${this.state.student.id}`
    // "https://www.learningcontainer.com/wp-content/uploads/2020/02/Kalimba.mp3"
  );

  /**
   * Component lifecycle method called after the component is mounted. 
   * Retrieves the student id from props, constructs a link to the student's
   * picture based on the environment, sets the picture in the component state,
   * and adds an event listener to the audio element to update state when the
   * audio ends.
   *
   */
  componentDidMount() {
    const { id } = this.props.student;

    let link;
    if (process.env.REACT_APP_STAGE === "dev") {
      link =
        `https://s3.amazonaws.com/content.law-info.byu.edu-dev/student-pictures/${id}.jpg?` +
        new Date().getTime();
    } else {
      link =
        `https://s3-us-west-2.amazonaws.com/content.law-info.byu.edu/student-pictures/${id}.jpg?` +
        new Date().getTime();
    }
    const pic = link;

    this.setState({ pic });

    this.audio.addEventListener("ended", () => this.setState({ play: false }));
  }

  /**
   * Stops the audio when the component unmounts.
   */
  componentWillUnmount() {
    this.audio.removeEventListener("ended", () =>
      this.setState({ play: false })
    );
  }

  playAudioFile = () => {
    this.setState({ play: !this.state.play }, () => {
      this.state.play ? this.audio.play() : this.audio.pause();
    });
  };

  toggleHistory() {
    this.setState({ showHistory: !this.state.showHistory });
  }

  /**
   * Retrieves the languages from each object in the given array of objects.
   *
   * @param {array} array - the array of objects
   * @return {string} a comma-separated string of languages
   */
  getLanguages(array) {
    let languages = [];
    for (const i in array) {
      languages.push(array[i].language);
    }
    return languages.join(", ");
  }

  render() {
    const { student, roles } = this.props;

    const isStudOrGrad = roles.student || roles.graduate;
    const isOperator = roles.operator;

    const homeAddress = getAddressOfType(student.address, "home");
    const permanentAddress = getAddressOfType(student.address, "permanent");
    const columnSize = roles.admin || roles.registrar ? 4 : 6;

    return (
      <React.Fragment>
        <Row>
          <Col xs={12} md={6}>
            <h1>Personal Info</h1>
          </Col>
          {(roles.student || roles.graduate) && (
            <Col xs={12} md={6} className="d-flex justify-content-end align-content-center flex-wrap">
              <Button
                variant="primary"
                onClick={() => this.props.handleClickEdit()}
                style={{ width: "auto", height: "35px"}}
                className="shadow"
              >
                <FontAwesomeIcon icon={faPen} className="edit-icon" />
                Edit
              </Button>
            </Col>
          )}
        </Row>
        <Row className="justify-content-center mt-4">
          <Col>
            <Card className="shadow personal-info-card">
              <Card.Body>
                <Row>
                  <Col className="text-center mb-2 mb-lg-0" xs="12" lg="2">
                    <div className="avatar">
                      <CleanImage
                        src={`${this.state.pic}?` + new Date().getTime()}
                        alt="student profile picture"
                        onError={(e) =>
                          handleImageError(e, {
                            first_name: student.first_name,
                            last_name: student.last_name,
                          })
                        }
                        person={student}
                        className="shadow"
                      />
                    </div>
                    <Row className="mt-2">
                      <strong>
                        Pronunciation{" "}
                        {this.state.play ? (
                          <Button
                            className="m-0 p-0"
                            variant="success"
                            onClick={this.playAudioFile}
                            aria-label={"play-audio"}
                          >
                            <FontAwesomeIcon
                              icon={faStopCircle}
                              // style={{ color: primary }}
                            />
                          </Button>
                        ) : (
                          <Button
                            className="m-0 p-0"
                            variant="success"
                            onClick={this.playAudioFile}
                            aria-label={"play-audio"}
                          >
                            <FontAwesomeIcon
                              icon={faPlayCircle}
                              // style={{ color: primary }}
                            />
                          </Button>
                        )}
                      </strong>
                    </Row>
                  </Col>
                  <Col md="10">
                    <Row className="mb-3">
                      <Col md="6" lg={columnSize} className="personal-info-col">
                        <PersonalInfoEntry
                          label="First Name:"
                          data={student.first_name ? student.first_name : "-"}
                        />
                        <PersonalInfoEntry
                          label="Middle Name:"
                          data={student.middle_name ? student.middle_name : "-"}
                        />
                        <PersonalInfoEntry
                          label="Last Name:"
                          data={student.last_name ? student.last_name : "-"}
                        />
                        <PersonalInfoEntry
                          label="Preferred Name:"
                          data={student.known_by ? student.known_by : "-"}
                        />
                        <PersonalInfoEntry
                          label="Gender:"
                          data={student.gender ? student.gender : "-"}
                        />
                        <PersonalInfoEntry
                          label="Date of birth:"
                          data={student.date_of_birth
                            ? new Date(student.date_of_birth).toLocaleDateString(
                                "en-US",
                                {
                                  timeZone: "Etc/UTC",
                                }
                              )
                            : "-"}
                        />
                        <PersonalInfoEntry
                          label="SID:"
                          data={student.id ? student.id : "-"}
                        />
                        <PersonalInfoEntry
                          label="NetID:"
                          data={student.net_id ? student.net_id : "-"}
                        />
                      </Col>

                      <Col md="6" lg={columnSize} className="personal-info-col">
                        <PersonalInfoEntry
                          label="Address:"
                          data={
                            homeAddress ?
                            <>
                              {homeAddress?.street1}
                              {/* {homeAddress?.street2 && `\n` + homeAddress?.street2} */}
                              {homeAddress?.street2 &&
                              `\n` + homeAddress.street2 === ("" || undefined)
                                ? homeAddress.street2
                                : " "}
                              <br />
                              {homeAddress?.city +
                                (homeAddress?.city && homeAddress?.state ? ", " : "") +
                                homeAddress?.state +
                                (homeAddress?.postal_code &&
                                (homeAddress?.state || homeAddress?.city)
                                  ? ", "
                                  : "")}
                              {homeAddress?.postal_code}
                              <br />
                              {homeAddress?.country}
                            </>
                            : "-"
                          }
                        />
                        <PersonalInfoEntry
                          label="Phone:"
                          data={homeAddress?.phone ? homeAddress.phone : "-"}
                          isPhone={true}
                        />
                        <PersonalInfoEntry
                          label="Email:"
                          data={student.email ? student.email : "-"}
                          isEmail={true}
                        />
                        <PersonalInfoEntry
                          label="Carrel:"
                          data={student.carrel ? student.carrel : "-"}
                        />
                        <PersonalInfoEntry
                          label="Class:"
                          data={student.year_in_program ? student.year_in_program : "-"}
                        />
                        <PersonalInfoEntry
                          label="Exam #:"
                          data={student.exam_number ? student.exam_number : "-"}
                        />
                        {!this.props.isGraduate ? (
                          <PersonalInfoEntry
                            label="Exp. Graduation:"
                            data={student.expected_grad_year ? student.expected_grad_year : "-"}
                          />
                        ) : (
                          <>
                            {student?.expected_grad_year?.JD && (
                              <PersonalInfoEntry
                                label="Graduated JD:"
                                data={student?.expected_grad_year?.JD ? student.expected_grad_year.JD : "-"}
                              />
                            )}
                            {student?.expected_grad_year?.LLM && (
                              <PersonalInfoEntry
                                label="Graduated LLM:"
                                data={student?.expected_grad_year?.LLM ? student.expected_grad_year.LLM : "-"}
                              />
                            )}
                          </>
                        )}
                      </Col>
                      { (roles.admin || roles.registrar) && (
                        <Col md="6" lg={columnSize} className="personal-info-col">
                          <PersonalInfoEntry
                            label="Ethnicity:"
                            data={student.ethnicity ? student.ethnicity : "-"}
                          />
                          <PersonalInfoEntry
                            label="Religion:"
                            data={student.religion ? student.religion : "-"}
                          />
                          <PersonalInfoEntry
                            label="Spouse:"
                            data={student.spouse ? student.spouse : "-"}
                          />
                          <PersonalInfoEntry
                            label="Language:"
                            data={student.languages ? this.getLanguages(student.languages) : "-"}
                          />
                          <PersonalInfoEntry
                            label="Mission:"
                            data={student.mission ? student.mission : "-"}
                          />
                          <PersonalInfoEntry
                            label="Interests:"
                            data={student.interests ? student.interests : "-"}
                          />
                        </Col>
                      )}
                    </Row>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <PreLawSchools degrees={student.pre_law_degrees} />

        {this.props.isGraduate && (
          <React.Fragment>
            <Row className="mt-4 mb-2">
              <Col
                className="d-flex justify-content-beginning"
                md={{ span: 2 }}
              >
                {(this.props.roles.admin || this.props.registrar) &&
                  (this.state.showHistory ? (
                    <Button
                      className="shadow-button"
                      onClick={this.toggleHistory}
                    >
                      Hide History
                    </Button>
                  ) : (
                    <Button
                      className="shadow-button"
                      onClick={this.toggleHistory}
                    >
                      Show History
                    </Button>
                  ))}
              </Col>
            </Row>
            {(this.props.roles.admin || this.props.roles.registrar) &&
              this.state.showHistory && (
                <Card className="shadow mb-4 personal-info-card" style={{ height: "90vh" }}>
                  <Card.Header as="h5">Change history: </Card.Header>
                  <Card.Body>
                    <History
                      apis={[
                        `/${this.props.isGraduate ? "graduates" : "students"}/${
                          student.id
                        }`
                      ]}
                      disableRestore={[true, true, true, true]}
                      enableHighlight={[true, false, true, false]}
                      displayNames={[
                        "Student Data",
                        "Pre-law Degrees",
                        "Addresses",
                        "Languages",
                      ]}
                    />
                  </Card.Body>
                </Card>
              )}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}
