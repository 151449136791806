import React from 'react';
import { Card, Col, Row, Button, Form, Modal } from 'react-bootstrap';
import ToolButton from './ToolButton.jsx';
import api from "../../services/api";
import ReleaseGradesModal from "../ReleaseGradesModal.jsx";
import ErrorDisplay from "../ErrorDisplay.jsx";
import ViewSemesterReportModal from "../../pages/ViewSemesterReportModal.jsx";
import UpdateFinalExamScheduleModal from '../reps_utils/UpdateFinalExamScheduleModal.jsx';
import {
  displaySemester,
  releaseGradesGetCurrentSemester,
} from "../../utils/functions";

class RegistrarTools extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showExamNumberModal: false,
      showViewExamNumberModal: false,
      showReleaseGradesModal: false,
      errorMessage: null,
      apiResponse: "",
      showReleaseGradesModalSuccess: false,
      showGraduateReportModal: false,
      showUpdateFinalScheduleModal: false,
      semesterDropdownValueMap: this.createPastFourSemestersObject(),
      currentSemester: releaseGradesGetCurrentSemester(),
      currentSemesterDisplay: 
        displaySemester(releaseGradesGetCurrentSemester())[1] +
        " " +
        displaySemester(releaseGradesGetCurrentSemester())[0],
    }

    this.handleReleaseOneLGrades = this.handleReleaseOneLGrades.bind(this);
    this.handleReleaseGrades = this.handleReleaseGrades.bind(this);
    this.toggleReleaseGradesModal = this.toggleReleaseGradesModal.bind(this);
    this.toggleUpdateFinalScheduleModal = this.toggleUpdateFinalScheduleModal.bind(this);
    this.handleSemesterSelect = this.handleSemesterSelect.bind(this);
    this.closeReleaseGradesModalSuccess = this.closeReleaseGradesModalSuccess.bind(this);
  }

  createPastFourSemestersObject() {
    let obj = {};
    let prev = releaseGradesGetCurrentSemester();
    for (let i = 0; i < 5; i++) {
      if (prev[4] !== "2") obj[i] = prev;
      prev =
        prev.toString()[4] === "1"
          ? (parseInt(prev.toString().substring(0, 4)) - 1).toString() + "5"
          : (parseInt(prev) - 1).toString();
    }
    return obj;
  }

  handleSemesterSelect(e) {
    this.setState({
      currentSemester: e.target.value,
      currentSemesterDisplay:
        displaySemester(e.target.value)[1] +
        " " +
        displaySemester(e.target.value)[0],
    });
  }

  async handleReleaseOneLGrades() {
    this.setState({ loading: true });
    try {
      const { data } = await api.post(
        `/grades/actions/one-l-release?semester=${this.state.currentSemester}`
      );
      this.setState({
        showReleaseGradesModalSuccess: true,
        apiResponse: data,
      });
    } catch (err) {
      console.log(err);
      this.setState({
        errorMessage: err.response?.data ? err.response.data : err.message,
        showReleaseGradesModal: true,
      });
    }
    this.setState({ loading: false });
  }

  async handleReleaseGrades() {
    this.setState({ loading: true });
    try {
      const { data } = await api.post(
        `/grades/actions/all-release?semester=${this.state.currentSemester}`
      );
      this.setState({
        showReleaseGradesModalSuccess: true,
        apiResponse: data,
      });
    } catch (err) {
      console.log(err);
      this.setState({
        errorMessage: err.response?.data ? err.response.data : err.message,
        showReleaseGradesModal: true,
      });
    }
    this.setState({ loading: false });
  }

  toggleUpdateFinalScheduleModal() {
    this.setState({
      showUpdateFinalScheduleModal: !this.state.showUpdateFinalScheduleModal,
    });
  }

  toggleReleaseGradesModal() {
    this.setState({
      showReleaseGradesModal: !this.state.showReleaseGradesModal,
    });
  }

  closeReleaseGradesModalSuccess() {
    this.setState({ showReleaseGradesModalSuccess: false });
  }

  render() {
    return (
      <Card className='bg-primary shadow' style={{ color: "white" }}>
        {this.state.errorMessage && (
          <ErrorDisplay errorMessage={this.state.errorMessage} />
        )}
        {this.state.viewReportModal && (
          <ViewSemesterReportModal
            show={this.state.viewReportModal}
            onHide={this.toggleViewReportModal}
            data={this.state.targetData}
            reportType={this.state.reportType}
          />
        )}
        <ReleaseGradesModal
          show={this.state.showReleaseGradesModal}
          toggleReleaseGradesModal={this.toggleReleaseGradesModal}
          errorMessage={this.state.errorMessage}
        />
        <Modal
          show={this.state.showReleaseGradesModalSuccess}
          onHide={this.closeReleaseGradesModalSuccess}
        >
          <Modal.Header closeButton>
            <Modal.Title>Grade Release Feedback</Modal.Title>
          </Modal.Header>
          <Modal.Body>{this.state.apiResponse}</Modal.Body>
        </Modal>
        <UpdateFinalExamScheduleModal
          show={this.state.showUpdateFinalScheduleModal}
          onHide={this.toggleUpdateFinalScheduleModal}
          currentSemester={this.state.currentSemester}
          currentSemesterDisplay={this.state.currentSemesterDisplay}
        />
        <Card.Body>
          <h3>Registrar Tools</h3>
          <Row>
            <Col xs={12} lg={6} className='tools-card'>
              <h5 style={{ textDecoration: "underline" }}>Semester Specific</h5>
              <Row>
                <Col xs={5} style={{ padding: 0 }}>
                  Select Semester:
                </Col>
                <Col xs={7} style={{ padding: 0 }}>
                  <Button
                    variant="link"
                    className="p-0 border-0"
                    style={{ marginLeft: 10, float: "right" }}
                  >
                    <Form.Select
                      aria-label="Default select example"
                      value={this.state.currentSemester}
                      onChange={this.handleSemesterSelect}
                      size="sm"
                    >
                      {Object.keys(
                        this.state.semesterDropdownValueMap
                      ).map((value) => {
                        return (
                          <option
                            value={
                              this.state.semesterDropdownValueMap[
                                value
                              ]
                            }
                            key={value}
                          >
                            {displaySemester(
                              this.state.semesterDropdownValueMap[
                                value
                              ]
                            )[1] +
                              " " +
                              displaySemester(
                                this.state.semesterDropdownValueMap[
                                  value
                                ]
                              )[0]}
                          </option>
                        );
                      })}
                    </Form.Select>
                    {"  "}
                  </Button>
                </Col>
              </Row>
              <Row>
                <ToolButton
                  text={`Release 1L Grades for:  ${this.state.currentSemesterDisplay}`}
                  icon="utility"
                  onClick={this.handleReleaseOneLGrades}
                />
              </Row>
              <Row>
                <ToolButton
                  text={`Release All Grades for:  ${this.state.currentSemesterDisplay}`}
                  icon="utility"
                  onClick={this.handleReleaseGrades}
                />
              </Row>
              <Row>
                <ToolButton
                  text={`Update Final Exam Schedule for:  ${this.state.currentSemesterDisplay}`}
                  icon="utility"
                  onClick={this.toggleUpdateFinalScheduleModal}
                />
              </Row>
            </Col>
            <Col xs={12} lg={6} className='tools-card'>
              <h5 style={{ textDecoration: "underline" }}>Other Tools:</h5>
              <Row>
                <ToolButton text="Mass Advance" icon="link" to="/mass-advance"/>
              </Row>
              <Row>
                <ToolButton text="Mass Graduate" icon="link" to="/mass-graduate"/>
              </Row>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    )
  }
  
}

export default RegistrarTools;