import { Button, Spinner } from "react-bootstrap";
import Footer from "../components/Footer"
import Header from "../components/Header"
import "../styles/App.scss";
import { RedirectToLogin } from "../components/auth/login";
import { useState } from "react";

function NewUserPage() {
  const [loading, setLoading] = useState(false);
  function handleClick() {
    setLoading(true);
    RedirectToLogin();
  }

  return (
    <>
      <div className="page-wrapper" style={{display: "flex", flexDirection: "column"}}>
        <Header noButtons roles={[]} />
        <div style={{display: "flex", flex: 1, flexDirection: "column", justifyContent: "center", alignContent: "center", alignItems: "center"}}>
          <h2 style={{marginBottom: '1em', marginLeft: '0.5em'}}>Are you new here?</h2>
          <p>It doesn't look like you have an account yet, please contact BYU Law's Helpdesk to get started.</p>
          <Button onClick={handleClick} style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", textAlign: "center"}}>
            <p style={{margin: 0}}>{loading ? "Loading..." : "Retry"}</p>
            {loading && <Spinner size="sm" style={{marginLeft: "0.5em"}} animation={"border"} />}
            </Button>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default NewUserPage;