import React from "react";
import { Container } from "react-bootstrap";
import CreatePerson from "../components/CreatePerson.jsx";
import ToolsNav from "../components/ToolsNav.jsx";

class CreateUserPage extends React.Component {
  render() {
    return (
      <>
        <ToolsNav currentTab="admin-tools" roles={this.props.roles} isLink={true} />
        <Container className="bg-white">
          <CreatePerson {...this.props} />
        </Container>
      </>
    );
  }
}

export default CreateUserPage;
