import React from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Nav,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import api from "../../services/api";
import ErrorDisplay from "../ErrorDisplay";
import Footer from "../Footer";
import Header from "../Header";
import DisplayPictureInformation from "./DisplayPictureInformation";
import PublicDisplayFacultyInformation from "./PublicDisplayFacultyInformation";

class PublicFacultyInformation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      person: [],
      filteredPersons: [],
      loadingMessage: "Loading...",
      loading: true,
      errorMessage: null,
      isLoadingError: false,
      showIndividual: false,
      individualPerson: "",
      showPictures: false,
      page: "public-faculty-information",
      params: new URLSearchParams(""),
      currentPageArray: [],
      currentPageIndex: 0,
      nameSearch: "",
      numberOfRecords: 0,
    };
    this.getFaculty = this.getFaculty.bind(this);
    this.handleRowClick = this.handleRowClick.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handleSearchName = this.handleSearchName.bind(this);
    this.filterName = this.filterName.bind(this);
    this.searchName = this.searchName.bind(this);
  }

  /**
   * Search functionality, will allow a user to search, and on button submit, return filtered results
   * 
   * @param {event} e - event
   */
  async handleSearchName(e) {
    e.preventDefault();
    await this.setState({ nameSearch: e.target.value });
    this.searchName();
    if (this.state.nameSearch === "") {
      this.setPaginationArray(this.state.person);
    } else {
      this.setPaginationArray(this.state.filteredFaculty);
    }
  }

  /**
   * Returns a boolean whether the item matches the search name or not.
   *
   * @param {Object} item - The item to filter.
   * @return {boolean} Returns true if the item matches the search name, otherwise false.
   */
  filterName(item) {
    let displayName = item.first_name + " " + item.last_name;
    if (
      item.first_name
        .substring(0, this.state.nameSearch.length)
        .toLowerCase() === this.state.nameSearch.toLowerCase()
    ) {
      return true;
    }
    if (
      item.last_name
        .substring(0, this.state.nameSearch.length)
        .toLowerCase() === this.state.nameSearch.toLowerCase()
    ) {
      return true;
    }
    if (displayName.toLowerCase() === this.state.nameSearch.toLowerCase()) {
      return true;
    }
    return false;
  }

  /**
   * Filters the person array based on the search name and
   * sets the filtered array in filteredFaculty state.
   *
   */
  searchName() {
    let arr = [];
    this.state.person.forEach((item) => {
      if (this.filterName(item) === true) {
        arr.push(item);
      }
    });
    this.setState({ filteredFaculty: arr });
  }
  /*----------------------------------------------------------------------*/

  /**
   * Will allow for pagination of items in the directory
   * @param {*} b
   */
  handlePagination(b) {
    switch (b) {
      case "next":
        if (
          this.state.currentPageIndex <
          this.state.currentPageArray.length - 1
        ) {
          this.setState({
            currentPageIndex: this.state.currentPageIndex + 1,
          });
        }
        break;
      case "prev":
        if (this.state.currentPageIndex > 0) {
          this.setState({
            currentPageIndex: this.state.currentPageIndex - 1,
          });
        }
        break;
      case "first":
        this.setState({
          currentPageIndex: 0,
        });
        break;
      case "last":
        this.setState({
          currentPageIndex: this.state.currentPageArray.length - 1,
        });
        break;
      default:
        break;
    }
  }

  /**
   * Sets the pagination array based on the input array and updates the state
   * accordingly.
   *
   * @param {array} array - The input array for pagination
   * @return {void} 
   */
  async setPaginationArray(array) {
    this.setState({ numberOfRecords: 0 });
    await this.setState({ loading: true });
    let arr = array;
    await this.setState({
      currentPageArray: [],
      currentPageIndex: 0,
      loading: true,
    });
    for (var i = 0; i < arr.length; i += 18) {
      this.state.currentPageArray.push(arr.slice(i, i + 18));
    }
    await this.setState({ currentPage: this.state.currentPageArray });
    let sum = 0;
    for (var j = 0; j < this.state.currentPageArray.length; j++) {
      sum += this.state.currentPageArray[j].length;
    }
    this.setState({ numberOfRecords: sum });
    this.setState({ loading: false });
  }
  /*----------------------------------------------------------------------*/

  /**
   *   Will allow for clicking individual items and url accessibility of items of items in the directory
   * @param {*} index
   */
  async handleRowClick(index) {
    await this.setState({
      individualPerson:
        this.state.currentPageArray[this.state.currentPageIndex][index],
      showIndividual: !this.state.showIndividual,
    });
    this.updateURL(
      this.state.individualPerson.first_name +
        "-" +
        this.state.individualPerson.last_name
    );
  }
  /**
   * Handles the cancel action by toggling the showIndividual state and updating the URL.
   */
  handleCancel() {
    this.setState({ showIndividual: !this.state.showIndividual });
    this.updateURL("");
  }
  
  /**
   * Updates the URL with the next page name without reloading the page.
   *
   * @param {string} nextName - the name of the next page
   * @return {void} 
   */
  updateURL(nextName) {
    let nextURL = "";
    nextURL = `${window.location.protocol}//${window.location.host}/${this.state.page}/${nextName}`;
    const nextTitle = document.title;
    // This will replace the current entry in the browser's history, without reloading
    window.history.replaceState({}, nextTitle, nextURL);
  }
  /*----------------------------------------------------------------------*/

  /**
   * Toggles the showPictures state between true and false.
   */
  handleFilterChange() {
    this.setState({ showPictures: !this.state.showPictures });
  }

  /**
   * Asynchronously retrieves faculty data from the API,
   * adjusts the name to be URL friendly,
   * and updates the component state.
   */
  async componentDidMount() {
    this.getFaculty();

    this.setState({ errorMessage: null });
    await Promise.all([this.getFaculty()]);
    let slug = window.location.href.split("/").pop();

    //make faculty members url accessible
    if (slug !== "") {
      let findLastName = slug.split("-").pop();
      let findFirstName = slug.split("-")[0];
      this.state.person.forEach((facultyMember) => {
        if (facultyMember.first_name === findFirstName) {
          if (facultyMember.last_name === findLastName) {
            this.setState({
              individualPerson: facultyMember,
              showIndividual: true,
            });
          }
        }
      });
    }
    this.setPaginationArray(this.state.person);
    this.setState({ numberOfRecords: this.state.person.length });
  }

  /**
   * Asynchronously retrieves faculty data from the API and updates the component state.
   */
  async getFaculty() {
    if (this.props.fromDirectory) {
      this.setState({ person: this.props.person });
      return;
    }
    try {
      const params = {
        edit: 1,
      };
      const { data: person } = await api.get(
        "/public/faculty-staff-directory",
        { params }
      );
      this.setState({ person });
    } catch (err) {
      this.setState({
        errorMessage: err.response?.data ? err.response.data : err.message,
        isLoadingError: true,
      });
    }
  }

  render() {
    const { handleCancel, fromDirectory } = this.props;

    if (this.state.loading) {
      return (
        <>
          <div className="page-wrapper">
            <Header noButtons roles={"unauthorized"} />
            {fromDirectory && (
              <Row className="justify-content-center no-margin">
                <Col xs={"auto"} className="mt-2">
                  <Button
                    style={{ margin: "auto" }}
                    onClick={handleCancel}
                    className="shadow"
                  >
                    Return to Directory
                  </Button>
                </Col>
              </Row>
            )}
            <div className="loadingDiv">
              <h1 className="loadingText" style={{marginBottom: '1em', marginLeft: '0.5em'}}>{this.state.loadingMessage}</h1>
              <Spinner animation={"border"} />
            </div>
          </div>
        </>
      );
    }
    if (this.state.errorMessage && this.state.isLoadingError) {
      return <ErrorDisplay errorMessage={this.state.errorMessage} />;
    }

    return (
      <React.Fragment>
        <Header noButtons roles={"unauthorized"} />
        {fromDirectory && (
          <Row className="justify-content-center no-margin">
            <Col xs={"auto"} className="mt-2">
              <Button
                style={{ margin: "auto" }}
                onClick={handleCancel}
                className="shadow"
              >
                Return to Directory
              </Button>
            </Col>
          </Row>
        )}
        <React.Fragment>
          <Row className="no-margin">
            <Col xs={12} md={5} lg={4} xl={3} className="sidebar">
              <Nav>
                <Container className="p-0">
                  <Card className="shadow">
                    <Card.Header as={"h4"}>
                      Faculty and Staff Directory{" "}
                    </Card.Header>
                    <Card.Body>
                      <Row className="py-1">
                        <Col>
                          <h5 className="p-0 m-0">Search:</h5>
                        </Col>
                        <Col>
                          <Form.Group>
                            <div className="text-photo">
                              <Form.Check
                                className="text-photo-item item-switch"
                                type="switch"
                                checked={this.state.showPictures}
                                onChange={this.handleFilterChange}
                              />
                              <Form.Label className="text-photo-item">
                                Photo
                              </Form.Label>
                            </div>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row className="py-1 px-0 align-items-center">
                        <Col>
                          <Form.Control
                            placeholder="Search by Name"
                            value={this.state.nameSearch}
                            onChange={this.handleSearchName}
                            autoFocus="autoFocus"
                          />
                        </Col>
                      </Row>{" "}
                      <Row
                        className="py-1 px-0 justify-content-center"
                        style={{ marginTop: 20 }}
                      >
                        <Col className="m-1" xs="5">
                          <Button
                            className="small-font fill-parent"
                            variant="secondary"
                            onClick={() => this.handlePagination("prev")}
                          >
                            ← Prev Page
                          </Button>
                        </Col>
                        <Col className="m-1" xs="5">
                          <Button
                            className="small-font fill-parent"
                            variant="secondary"
                            onClick={() => this.handlePagination("next")}
                          >
                            Next Page →
                          </Button>
                        </Col>
                        <Col className="m-1" xs="5">
                          <Button
                            className="small-font fill-parent"
                            variant="secondary"
                            onClick={() => this.handlePagination("first")}
                          >
                            ⇤ First
                          </Button>
                        </Col>
                        <Col className="m-1" xs="5">
                          <Button
                            className="small-font fill-parent"
                            variant="secondary"
                            onClick={() => this.handlePagination("last")}
                          >
                            Last ⇥
                          </Button>
                        </Col>
                      </Row>
                      <Row className="justify-content-center">
                        <Col xs="auto">
                          Page {this.state.currentPageIndex + 1} /{" "}
                          {this.state.currentPage.length}
                        </Col>
                      </Row>
                      <Row className="justify-content-center">
                        <Col xs="auto">
                          {this.state.numberOfRecords} Records
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Container>
              </Nav>
            </Col>
            {!this.state.showIndividual && !this.state.showPictures ? (
              <Col
                xs={12}
                md={7}
                lg={8}
                xl={9}
                className="no-margin-but-padding directory-display-wrapper"
              >
                {this.state.currentPage.length > 0 ? (
                  <Card className="directory-shadow">
                    <Table striped bordered hover responsive className="mb-0">
                      <thead>
                        <tr>
                          <th>First Name</th>
                          <th>Last Name</th>
                          {/* <th>Status</th> */}
                          <th>Email</th>
                          <th>Phone</th>
                          <th>Bio Link</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.currentPage[
                          this.state.currentPageIndex
                        ]?.map((element, index) => (
                          <tr>
                            <td
                              className="clickable"
                              onClick={() => this.handleRowClick(index)}
                            >
                              {element.first_name}
                            </td>
                            <td
                              className="clickable"
                              onClick={() => this.handleRowClick(index)}
                            >
                              {element.last_name}
                            </td>
                            {/* <td
                              className="clickable"
                              onClick={() => this.handleRowClick(index)}
                            >
                              {element.length > 0
                                ? element.category[0]
                                : "Full Time Faculty"}
                            </td> */}
                            <td
                              className="clickable"
                              onClick={() => this.handleRowClick(index)}
                            >
                              {element.email}
                            </td>
                            <td
                              className="clickable"
                              onClick={() => this.handleRowClick(index)}
                            >
                              {element.phone}
                            </td>
                            {element.bio_link === null ? (
                              <td>None</td>
                            ) : (
                              <td>
                                <a href={element.bio_link}>
                                  {element.first_name} {element.last_name} Info
                                </a>
                              </td>
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Card>
                ) : (
                  <Card className="directory-shadow">
                    <Card.Body>No results</Card.Body>
                  </Card>
                )}
              </Col>
            ) : this.state.showPictures && !this.state.showIndividual ? (
              <Col
                xs={12}
                md={7}
                lg={8}
                xl={9}
                className="no-margin-but-padding directory-display-wrapper"
              >
                <DisplayPictureInformation
                  dataSet={
                    this.state.currentPageArray[this.state.currentPageIndex]
                  }
                  handleDisplayClick={this.handleRowClick}
                ></DisplayPictureInformation>
              </Col>
            ) : (
              <>
                <Col
                  xs={12}
                  md={7}
                  lg={8}
                  xl={9}
                  className="no-margin-but-padding directory-display-wrapper"
                >
                  <Row>
                    <PublicDisplayFacultyInformation
                      person={this.state.individualPerson}
                      returnToDirectory={this.handleCancel}
                    />
                  </Row>
                  <Row></Row>
                </Col>
              </>
            )}
          </Row>
        </React.Fragment>
        <Footer />
      </React.Fragment>
    );
  }
}

export default PublicFacultyInformation;
