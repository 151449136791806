import React, { useEffect } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import DuckLawyer from "../images/aspiring_duck_lawyer.jpg";
import ToolButton from "../components/tools/ToolButton";
import adminToolsDark from "../images/adminToolsDark.png";
import courseDark from "../images/courseDark.png";
import documentsDark from "../images/documentsDark.png";
import gradDark from "../images/gradDark.png";
import myInfoDark from "../images/myInfoDark.png";
import reportCardDark from "../images/reportCardDark.png";
import staffDark from "../images/staffDark.png";
import studentDark from "../images/studentDark.png";
import { getMyInfoLink, getMyDocumentsLink } from "../components/Header";
import UpdateNotification from "../components/UpdateNotification";
import { RedirectToLogin } from "../components/auth/login.jsx";


const Home = ({ roles }) => {
  const getWelcomeText = () => {
    return roles.admin ? (
      <p className="welcome-text">
        Here you can manage information within the Law School. You also
        have access to a variety of informational tools like the
        directories.
      </p>
    ) : roles.registrar ? (
      <p className="welcome-text">
        Here you can manage students at the Law School, as well as
        editing your own information. You also have access to a variety
        of informational tools like the directories.
      </p>
    ) : roles.operator ? (
      <p className="welcome-text">
        Here you can manage information within the Law School. You also
        have access to a variety of informational tools like the
        directories.
      </p>
    ) : roles.faculty ? (
      <p className="welcome-text">
        Here you can see and edit your information at the Law School.
        You also have access to a variety of informational tools like
        the directories.
      </p>
    ) : roles.secretary ? (
      <p className="welcome-text">
        Here you can look up information on Faculty and Staff. You also
        have access to Exam number of Students.
      </p>
    ) : roles.graduate ? (
      <p className="welcome-text">
        Here you can see and download your records at the Law School. If
        you have any questions, you can email the registrar, GaeLynn
        Smith, at smithgl@law.byu.edu
      </p>
    ) : roles.student ? (
      <p className="welcome-text">
        Here you can see your records and progress at the Law School, as
        well as edit some contact information. You also have access to a
        variety of informational tools like the directories.
      </p>
    ) : (
      <p className="welcome-text">
        Unauthorized.
      </p>      
    )
  }
  
  useEffect(() => {
    if (!sessionStorage.getItem('idtoken')) {
      RedirectToLogin();
    }
  }, []);

  const myInfoLink = getMyInfoLink(roles);
  const myDocumentsLink = getMyDocumentsLink(roles);

  return (
    <Container className="pt-2 bg-white">
      <UpdateNotification />
      <Card className="shadow">
        <Card.Img
          src={DuckLawyer}
          alt={
            ""
            /*left blank because the background image does not have any semantic meaning for the site*/
          }
          className="home-image"
        />
        <Card className="welcome-card" style={{border: "0"}}>
          <Card.Body className="welcome-card-body">
            <h2 className="welcome-header">Welcome to Law Info!</h2>
            <br />
            {getWelcomeText()}
          </Card.Body>
        </Card>
      </Card>
      <Row className="pb-3 pt-4 justify-content-center">
        {(roles.admin || roles.registrar || roles.faculty || roles.operator || roles.secretary || roles.student) && (
          <Col xs="12" md="6" lg="4" className="text-center">
            <Card className="mt-3 shadow bg-primary" style={{ color:"white" }}>
              <Card.Header as="h3">Directories</Card.Header>
              <Card.Body className="tools-card">
                <Row>
                  <ToolButton text="Faculty and Staff Directory" image={staffDark} to="/faculty-directory" />
                </Row>
                <Row>
                  <ToolButton text="Course Directory" image={courseDark} to="/course-directory" />
                </Row>
                {(roles.admin ||
                  roles.registrar ||
                  roles.faculty ||
                  roles.operator ||
                  roles.secretary) && (
                  <Row>
                    <ToolButton text="Graduate Directory" image={gradDark} to="/graduate-directory" />
                  </Row>
                )}
                <Row>
                  <ToolButton text="Student Directory" image={studentDark} to="/student-directory" />
                </Row>
              </Card.Body>
            </Card>
          </Col>
        )}

        <Col xs="12" md="6" lg="4" className="text-center">
          <Card className="mt-3 shadow bg-primary" style={{ color:"white" }}>
            <Card.Header as="h3">
              My Tools
            </Card.Header>
            <Card.Body className="tools-card">
              <Row>
                <ToolButton
                  text="My Info"
                  image={myInfoDark}
                  to={myInfoLink}
                />
              </Row>
              {(roles.operator || roles.registrar || roles.admin) && (
                <Row>
                  <ToolButton
                    text={
                      roles.admin ? "Admin Tools" :
                      roles.registrar ? "Registrar Tools" :
                      "Operator Tools"
                    }
                    image={adminToolsDark}
                    to="/faculty/admin-tools"
                    />
                </Row>
              )}
              {roles.faculty && (
                <Row>
                  <ToolButton
                    text="Faculty Tools"
                    image={adminToolsDark}
                    to="/faculty/tools"
                  />
                </Row>
              )}
              {(roles.student || roles.graduate) && (
                <Row>
                  <ToolButton
                    text="Academic Progress"
                    image={reportCardDark}
                    to={
                      roles.graduate
                        ? `/graduate-archive/progress-report`
                        : "/enrollment/progress-report"
                    }
                  />
                </Row>
              )}
              {(roles.operator || roles.registrar || roles.admin || roles.secretary) && (
                <Row>
                  <ToolButton
                    text="Documents"
                    image={documentsDark}
                    to={myDocumentsLink}
                  />
                </Row>
              )}
              {(roles.student || roles.graduate) && (
                <Row>
                  <ToolButton
                    text="My Student Records"
                    image={documentsDark}
                    to={roles.graduate ? "/graduate-archive/student-record" : "/enrollment/student-record"}
                    />
                </Row>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Home;
