import React from "react";
import { Modal, Button } from "react-bootstrap";

class ConfirmDeleteModal extends React.Component {
  render() {
    let { show, onHide, deleteRole, message } = this.props;
    return (
      <Modal show={show} onHide={this.onHide}>
        <Modal.Header>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>{message}</Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={deleteRole}>
            Delete
          </Button>
          <Button variant="outline-info" onClick={onHide}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default ConfirmDeleteModal;
