import React from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { handleImageError } from "../../../utils/functions";
import api from "../../../services/api";

import EditCourseTable from "./EditCourseTable.jsx";
import History from "../../History.jsx";
import ErrorDisplay from "../../ErrorDisplay.jsx";
import GraduateStudentModal from "./GraduateStudentModal.jsx";
import ForceGraduationModal from "./ForceGraduationModal.jsx";
import ArchiveStudentModal from "./ArchiveStudentModal.jsx";
import ProgressReportEntry from "./ProgressReportEntry.jsx";
import BarAttempts from "./BarAttempts.jsx";
import EditProgressReportEntry from "./EditProgressReportEntry";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { CleanImage } from "../../SafeImage";

export default class EditProgressReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editedStudent: JSON.parse(JSON.stringify(this.props.student)),
      lsat_score: this.props.student.lsatScore,
      showHistory: false,
      showCourseHistory: false,
      errorMessage: null,
      scrollToCourseHistory: false,
      hasStudentBeenEdited: false,
      manualGraduationInfo: {
        degree: "JD",
        graduation_date: new Date().toISOString().split("T")[0],
        honors: this.props.student.expected_honors
          ? this.props.student.expected_honors
          : "",
        honors_exception: false,
        joint_degree:
          this.props.student.year_in_program === "G"
            ? this.props.student.law_degrees[0]?.joint_degree
              ? this.props.student.law_degrees[0]?.joint_degree
              : "None"
            : this.props.student?.joint_degree
            ? this.props.student?.joint_degree
            : "None",
      },
      hasGraduationInfoChanged: false,
      showGraduateModal: false,
      showForceGradModal: false,
      incompleteGradInfo: "",
      showGradErrorMessage: false,
      archiveModal: false,
      archive: false,
      pic: "",
    };
    this.courseScrollRef = React.createRef();

    this.onClose = this.props.onClose;
    this.updateStudent = this.props.updateStudent;

    this.onCancel = this.onCancel.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.toggleHistory = this.toggleHistory.bind(this);
    this.toggleCourseHistory = this.toggleCourseHistory.bind(this);
    this.handleRestore = this.handleRestore.bind(this);
    this.graduateStudent = this.graduateStudent.bind(this);
    this.toggleGraduateModal = this.toggleGraduateModal.bind(this);
    this.toggleForceGradModal = this.toggleForceGradModal.bind(this);
    this.onGraduate = this.onGraduate.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.deleteDateReceived = this.deleteDateReceived.bind(this);
    this.addNewDateReceived = this.addNewDateReceived.bind(this);
    this.toggleArchiveModal = this.toggleArchiveModal.bind(this);
    this.archiveApi = this.archiveApi.bind(this);

    this.saveArchiveValues = this.saveArchiveValues.bind(this);
  }

  componentDidMount() {
    const { id } = this.props.student;

    let link;
    if (process.env.REACT_APP_STAGE === "dev") {
      link =
        `https://s3.amazonaws.com/content.law-info.byu.edu-dev/student-pictures/${id}.jpg?` +
        new Date().getTime();
    } else {
      link =
        `https://s3-us-west-2.amazonaws.com/content.law-info.byu.edu/student-pictures/${id}.jpg?` +
        new Date().getTime();
    }

    const pic = link;

    this.setState({ pic });
  }

  componentDidUpdate() {
    if (this.state.scrollToCourseHistory) {
      this.courseScrollRef.current?.scrollIntoView();
    }
  }

  toggleArchiveModal() {
    const { editedStudent } = this.state;
    this.setState({ archiveModal: !this.state.archiveModal });
    if (editedStudent.year_in_program === "A") {
      this.setState({ archive: true });
    } else {
      this.setState({ archive: false });
    }
  }

  saveArchiveValues(yearInProgram, status, comments) {
    const { editedStudent } = this.state;
    if (yearInProgram) {
      editedStudent.year_in_program = yearInProgram;
    }
    editedStudent.enrollment_status = status;
    editedStudent.comments = comments;

    this.setState({ editedStudent });
  }

  async archiveApi() {
    const { editedStudent } = this.state;

    editedStudent.type = this.props.student.type;

    try {
      if (this.state.editedStudent.type === "student") {
        await api.post(
          `/students/${this.props.student.id}?archive=true`,
          editedStudent
        );
      }
      await this.updateStudent();
      this.onClose();
    } catch (err) {
      console.error(err.message);
      this.setState({
        errorMessage: err.message,
      });
    }
  }

  toggleHistory() {
    this.setState({
      showHistory: !this.state.showHistory,
      scrollToCourseHistory: false,
    });
  }

  toggleCourseHistory() {
    this.setState({
      showCourseHistory: !this.state.showCourseHistory,
      scrollToCourseHistory: true,
    });
  }

  toggleGraduateModal() {
    this.setState({ showGraduateModal: !this.state.showGraduateModal });
  }

  toggleForceGradModal() {
    this.setState({
      showForceGradModal: !this.state.showForceGradModal,
    });
  }

  onGraduate() {
    window.location.assign(
      `${window.location.protocol}//${window.location.host}/graduate-directory/progress-report/${this.props.student.id}`
    );
  }

  async onCancel() {
    await this.props.updateStudent();
    this.onClose();
  }

  gradYearOptions() {
    const currentDate = new Date();
    var years = [];
    for (var i = 1970; i <= currentDate.getFullYear() + 10; i++) {
      years.push(i);
    }
    var gradYears = years.map((year) => (
      <option key={year} value={year}>
        {year}
      </option>
    ));
    return gradYears;
  }

  handleRestore() {
    this.onCancel();
  }

  handleChange(e) {
    let { id, value, checked } = e.target;
    const { editedStudent, manualGraduationInfo } = this.state;
    checked = checked ? true : false;
    let studentChanged = false;
    let gradInfoChanged = false;

    switch (id) {
      case "enrollment-status":
        editedStudent.enrollment_status = value;
        if (
          (value === "T" || value === "W" || value === "D") &&
          this.state.archiveModal === false
        ) {
          this.toggleArchiveModal();
        }
        studentChanged = true;
        break;
      case "class-year":
        editedStudent.year_in_program = value;
        studentChanged = true;
        break;
      case "print-percentile":
        editedStudent.print_percentile = checked;
        studentChanged = true;
        break;
      case "library-skills":
        editedStudent.library_skills = checked;
        studentChanged = true;
        break;
      case "substantial-writing":
        editedStudent.substantial_writing = checked;
        studentChanged = true;
        break;
      case "exp-graduation":
        editedStudent.expected_grad_year = value;
        studentChanged = true;
        break;
      case "exception":
        editedStudent.honors_exception = checked;
        studentChanged = true;
        break;
      case "honors-edited":
        editedStudent.expected_honors = value;
        studentChanged = true;
        break;
      case "exam-number":
        editedStudent.exam_number = value;
        studentChanged = true;
        break;
      case "disciplinary-action":
        editedStudent.disciplinary = value;
        studentChanged = true;
        break;
      case "comments":
        editedStudent.comments = value;
        studentChanged = true;
        break;
      case "edit-class-rank":
        editedStudent.rank = value;
        studentChanged = true;
        break;
      case "edit-class-percentile":
        editedStudent.percentile = value;
        studentChanged = true;
        break;
      case "joint-degree":
        editedStudent.joint_degree = value;
        studentChanged = true;
        break;
      case "joint-degree-grad":
        manualGraduationInfo.joint_degree = value;
        studentChanged = true;
        break;
      case "graduation-date":
        manualGraduationInfo.graduation_date = value;
        gradInfoChanged = true;
        break;
      case "date-received":
        if (editedStudent.law_degrees.length === 0) {
          editedStudent.law_degrees.push({ date_degree_received: value });
        } else {
          editedStudent.law_degrees[0].date_degree_received = value;
        }
        studentChanged = true;
        break;
      case "degree":
        manualGraduationInfo.degree = value;
        gradInfoChanged = true;
        break;
      case "honors":
        manualGraduationInfo.honors = value;
        gradInfoChanged = true;
        break;
      case "honors-exception":
        manualGraduationInfo.honors_exception = value;
        gradInfoChanged = true;
        break;
      case "archive":
        this.setState({ archive: checked });
        this.toggleArchiveModal();

        break;
      default:
        return;
    }

    if (studentChanged) {
      this.setState({ hasStudentBeenEdited: true });
    }
    if (gradInfoChanged) {
      this.setState({ hasGraduationInfoChanged: true });
    }
    this.setState({
      editedStudent,
      manualGraduationInfo,
      scrollToCourseHistory: false,
    });
  }

  async handleFormSubmit(e) {
    e.preventDefault();

    const { editedStudent } = this.state;

    editedStudent.type = this.props.student.type;
    // editedStudent.gender= editedStudent.gender === "F" ? "female" : "male"

    try {
      // if (this.state.editedStudent.law_degrees) {
      if (this.state.editedStudent.type === "student") {
        await api.post(`/students/${this.props.student.id}`, editedStudent);
      } else {
        await api.post(`/graduates/${this.props.student.id}`, editedStudent);
      }
      await this.updateStudent();
      this.onClose();
    } catch (err) {
      console.error(err.message);
      this.setState({
        errorMessage: err.message,
        //errorMessage: err.response?.data ? err.response.data : err.message,
      });
    }
  }

  addNewDateReceived() {
    const { editedStudent } = this.state;
    editedStudent.law_degrees?.push({
      date_degree_received: "",
    });

    this.setState({ editedStudent });
  }

  handleDateChange(e) {
    let { id, value } = e.target;
    let field = "";
    const { editedStudent } = this.state;
    [id, field] = id.split("-");
    const index = Number(id);

    switch (field) {
      case "id":
        editedStudent.law_degrees[index].date_degree_received = value;
        break;
      default:
        return;
    }
    this.setState({ editedStudent, hasStudentBeenEdited: true });
  }

  deleteDateReceived(e) {
    let { id: index } = e.target;
    const { editedStudent } = this.state;
    index = Number(index.split("-")[0]);
    if (editedStudent.law_degrees.length === 1) {
      editedStudent.law_degrees = [];
    } else {
      editedStudent.law_degrees.splice(index, 1);
    }
    this.setState({ editedStudent, hasStudentBeenEdited: true });
  }

  async graduateStudent(e) {
    e.preventDefault();
    const { manualGraduationInfo } = this.state;

    manualGraduationInfo.graduation_date = manualGraduationInfo.graduation_date
      ? new Date(manualGraduationInfo.graduation_date).toISOString()
      : null;
    if (manualGraduationInfo.degree === "JD") {
      manualGraduationInfo.degree = "Juris Doctor";
    }
    if (manualGraduationInfo.degree === "LLM") {
      manualGraduationInfo.degree = "Master of Law";
    }
    if (typeof manualGraduationInfo.honors_exception === "string") {
      if (manualGraduationInfo.honors_exception === "true") {
        manualGraduationInfo.honors_exception = true;
      } else {
        manualGraduationInfo.honors_exception = false;
      }
    }
    try {
      if (e.target.id === "forceGraduate") {
        await api.post(
          `/students/graduation/${this.props.student.id}`,
          manualGraduationInfo,
          {
            params: { force_graduation: 1 },
          }
        );
        this.onGraduate();
      } else {
        await api.post(
          `/students/graduation/${this.props.student.id}`,
          manualGraduationInfo
        );
        this.onGraduate();
      }
    } catch (err) {
      let manualGraduationInfo = JSON.parse(
        JSON.stringify(this.state.manualGraduationInfo)
      );
      manualGraduationInfo.graduation_date = new Date(
        manualGraduationInfo.graduation_date
      )
        .toISOString()
        .split("T")[0];
      if (typeof err?.response?.data === "object") {
        this.toggleGraduateModal();
        this.toggleForceGradModal();
        this.setState({
          incompleteGradInfo: err.response.data,
          manualGraduationInfo,
        });
      } else {
        console.error(err.message);
        this.setState({
          showGradErrorMessage: true,
          errorMessage: err.message,
        });
      }
    }
  }

  render() {
    const { editedStudent } = this.state;
    const isAdminOrRegistrar =
      this.props.roles.admin || this.props.roles.registrar;
    const isOperator = this.props.roles.operator;
    const isStudOrGrad = this.props.roles.student || this.props.roles.graduate;
    return (
      <React.Fragment>
        <GraduateStudentModal
          show={this.state.showGraduateModal}
          toggleGraduateModal={this.toggleGraduateModal}
          handleChange={this.handleChange}
          graduateStudent={this.graduateStudent}
          manualGraduationInfo={this.state.manualGraduationInfo}
          showForceGradModal={this.state.showForceGradModal}
          incompleteGradInfo={this.state.incompleteGradInfo}
          showGradErrorMessage={this.state.showGradErrorMessage}
          errorMessage={this.state.errorMessage}
        />
        <ArchiveStudentModal
          show={this.state.archiveModal}
          toggleArchiveModal={this.toggleArchiveModal}
          comments={editedStudent.comments}
          archiveApi={this.archiveApi}
          year_in_program={editedStudent.year_in_program}
          handleFormSubmit={this.handleFormSubmit}
          archive={this.state.archive}
          saveArchiveValues={this.saveArchiveValues}
          errorMessage={this.state.errorMessage}
        />
        <ForceGraduationModal
          show={this.state.showForceGradModal}
          toggleForceGradModal={this.toggleForceGradModal}
          handleChange={this.handleChange}
          graduateStudent={this.graduateStudent}
          manualGraduationInfo={this.state.manualGraduationInfo}
          incompleteGradInfo={this.state.incompleteGradInfo}
          showGradErrorMessage={this.state.showGradErrorMessage}
          errorMessage={this.state.errorMessage}
        />
        <Form onSubmit={this.handleFormSubmit}>
          <Container className="mt-4 mb-5 p-0">
            {this.state.errorMessage && !this.state.showGradErrorMessage && (
              <ErrorDisplay errorMessage={this.state.errorMessage} />
            )}
            <Row>
              <h1>Progress Report</h1>
            </Row>
            <Row>
              <Col lg="6">
                <Card className="shadow academic-progress-card mb-3">
                  <Card.Body>
                    <Row>
                      <Col lg={6} className="academic-progress-col">
                        <CleanImage
                          src={this.state.pic}
                          alt={`student headshot`}
                          placeholder="student_img"
                          style={{
                            display: "block",
                            margin: "auto",
                            maxWidth: "275px",
                          }}
                          className="shadow card-img"
                          onError={(e) => {
                            handleImageError(e, editedStudent);
                          }}
                          person={editedStudent}
                          fluid
                          rounded
                        />
                      </Col>
                      <Col lg={6} className="academic-progress-col">
                        <ProgressReportEntry
                          label="Name:"
                          data={
                            editedStudent.known_by &&
                            editedStudent.known_by !== editedStudent.last_name
                              ? editedStudent.known_by +
                                " " +
                                editedStudent.last_name
                              : editedStudent.first_name +
                                " " +
                                editedStudent.last_name
                          }
                        />
                        <ProgressReportEntry
                          label="ID:"
                          data={editedStudent.id}
                        />
                        <div className="entry">
                          <Col xs="auto">
                            <Form.Label>
                              <strong className="p-0">
                                Enrollment Status:
                              </strong>
                            </Form.Label>
                          </Col>
                          <Col className="entry-select">
                            <Form.Select
                              id="enrollment-status"
                              size="sm"
                              value={editedStudent.enrollment_status}
                              onChange={this.handleChange}
                              disabled={
                                isOperator ||
                                this.props.isGraduate ||
                                editedStudent.year_in_program === "A"
                              }
                            >
                              <option>JD</option>
                              <option>LLM</option>
                              <option value="T" key="T">
                                Transfer
                              </option>
                              <option value="V" key="V">
                                Visiting
                              </option>
                              <option value="NL" key="NL">
                                Non-Law
                              </option>
                              <option value="G" key="G">
                                Graduated
                              </option>
                              <option value="W" key="W">
                                Withdrawn
                              </option>
                              <option value="D" key="D">
                                Dismissed
                              </option>
                            </Form.Select>
                          </Col>
                        </div>
                        <div className="entry">
                          <Col xs="auto">
                            <Form.Label>
                              <strong className="p-0">Class:</strong>
                            </Form.Label>
                          </Col>
                          {editedStudent.year_in_program === "A" ? (
                            <Col className="entry-select">
                              <Form.Select
                                id="class-year"
                                size="sm"
                                value={editedStudent.year_in_program}
                                onChange={this.handleChange}
                                disabled={
                                  isOperator ||
                                  this.props.isGraduate ||
                                  editedStudent.year_in_program === "A"
                                }
                              >
                                <option value="A">A</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="LLM">LLM</option>
                                <option value="NL">NL</option>
                              </Form.Select>
                            </Col>
                          ) : (
                            <Col className="entry-select">
                              <Form.Select
                                id="class-year"
                                size="sm"
                                value={editedStudent.year_in_program}
                                onChange={this.handleChange}
                                disabled={
                                  isOperator ||
                                  this.props.isGraduate ||
                                  editedStudent.year_in_program === "A"
                                }
                              >
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="LLM">LLM</option>
                                <option value="NL">NL</option>
                              </Form.Select>
                            </Col>
                          )}
                        </div>
                        {!this.props.isGraduate ? (
                          <div className="entry">
                            <Col xs="auto">
                              <Form.Label>
                                <strong className="p-0">
                                  Expected Graduation:
                                </strong>
                              </Form.Label>
                            </Col>
                            <Col className="entry-select">
                              <Form.Select
                                id="exp-graduation"
                                size="sm"
                                value={editedStudent.expected_grad_year}
                                onChange={this.handleChange}
                                disabled={
                                  isOperator ||
                                  editedStudent.year_in_program === "G"
                                }
                              >
                                <this.gradYearOptions />
                              </Form.Select>
                            </Col>
                          </div>
                        ) : (
                          <>
                            {editedStudent.expected_grad_year.JD && (
                              <div className="entry">
                                <Col xs="auto">
                                  <Form.Label>
                                    <strong className="p-0">
                                      Graduated JD:
                                    </strong>
                                  </Form.Label>
                                </Col>
                                <Col className="entry-select">
                                  <Form.Select
                                    id="exp-graduation"
                                    size="sm"
                                    value={editedStudent.expected_grad_year.JD}
                                    onChange={this.handleChange}
                                    disabled={
                                      isOperator ||
                                      editedStudent.year_in_program === "G"
                                    }
                                  >
                                    <this.gradYearOptions />
                                  </Form.Select>
                                </Col>
                              </div>
                            )}
                            {editedStudent.expected_grad_year.LLM && (
                              <div className="entry">
                                <Col xs="auto">
                                  <Form.Label>
                                    <strong className="p-0">
                                      Graduated LLM:
                                    </strong>
                                  </Form.Label>
                                </Col>
                                <Col className="entry-select">
                                  <Form.Select
                                    id="exp-graduation"
                                    size="sm"
                                    value={editedStudent.expected_grad_year.LLM}
                                    onChange={this.handleChange}
                                    disabled={
                                      isOperator ||
                                      editedStudent.year_in_program === "G"
                                    }
                                  >
                                    <this.gradYearOptions />
                                  </Form.Select>
                                </Col>
                              </div>
                            )}
                          </>
                        )}
                        <Col md="12">
                          <Form.Group as={Row} className="align-items-center">
                            <Form.Label column md="auto">
                              <strong>Skills:</strong>
                            </Form.Label>
                            <Col>
                              <Form.Check
                                type="switch"
                                id="library-skills"
                                checked={editedStudent.library_skills}
                                onChange={this.handleChange}
                                disabled={isOperator}
                              />
                            </Col>
                          </Form.Group>
                        </Col>
                        <Col md="12">
                          <Form.Group as={Row} className="align-items-center">
                            <Form.Label column md="auto">
                              <strong>Subst. Writ.:</strong>
                            </Form.Label>
                            <Col>
                              <Form.Check
                                type="switch"
                                id="substantial-writing"
                                checked={editedStudent.substantial_writing}
                                onChange={this.handleChange}
                                disabled={isOperator}
                              />
                            </Col>
                          </Form.Group>
                        </Col>
                        <Col md="12">
                          {this.state.editedStudent.year_in_program !== "A" ? (
                            <Form.Group
                              as={Row}
                              className="mb-1 align-items-center"
                            >
                              <Form.Label column xs="auto">
                                <strong>Archive:</strong>
                              </Form.Label>
                              <Col>
                                <Form.Check
                                  type="switch"
                                  id="archive"
                                  checked={
                                    this.state.editedStudent.year_in_program ===
                                    "A"
                                  }
                                  onChange={this.handleChange}
                                  disabled={!isAdminOrRegistrar}
                                />
                              </Col>
                            </Form.Group>
                          ) : (
                            <Form.Group
                              as={Row}
                              className="m-0 align-items-center"
                            >
                              <Form.Label column xs="auto">
                                <strong>Archive:</strong>
                              </Form.Label>
                              <Col>
                                <Form.Check
                                  type="switch"
                                  id="archive"
                                  checked={
                                    this.state.editedStudent.year_in_program ===
                                    "A"
                                  }
                                  onChange={this.handleChange}
                                  disabled={!isAdminOrRegistrar}
                                />
                              </Col>
                            </Form.Group>
                          )}
                        </Col>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
                {this.props.isGraduate && (
                  <BarAttempts
                    student={editedStudent}
                    roles={this.props.roles}
                  />
                )}
              </Col>
              <Col lg="6">
                <Card className="shadow academic-progress-card mb-3">
                  <Card.Body>
                    <Row className="mb-3">
                      <Col lg="6" className="academic-progress-col">
                        <EditProgressReportEntry
                          label="Class Rank"
                          id="edit-class-rank"
                          value={editedStudent.rank}
                          onChange={this.handleChange}
                          disabled={!isAdminOrRegistrar}
                        />
                        <div className="entry">
                          <Col xs="auto">
                            <Form.Label>
                              <strong className="p-0">Percentile</strong>
                            </Form.Label>
                          </Col>
                          <Col className="entry-select">
                            <Form.Select
                              id="edit-class-percentile"
                              type="number"
                              rows={1}
                              size="sm"
                              value={editedStudent.percentile}
                              onChange={this.handleChange}
                              disabled={!isAdminOrRegistrar}
                            >
                              <option label="N/A"></option>
                              <option>T10</option>
                              <option>T15</option>
                              <option>T20</option>
                              <option>T25</option>
                              <option>T30</option>
                              <option>T33</option>
                              <option>T40</option>
                              <option>T50</option>
                              <option>L50</option>
                            </Form.Select>
                          </Col>
                        </div>
                        <ProgressReportEntry
                          label="CR-Hours"
                          data={editedStudent.credits}
                        />
                        <ProgressReportEntry
                          label="GD-Points"
                          data={
                            editedStudent.grade_point
                              ? editedStudent.grade_point
                              : ""
                          }
                        />
                        <ProgressReportEntry
                          label="GPA"
                          data={editedStudent.gpa}
                        />
                        {!isStudOrGrad && (
                          <ProgressReportEntry
                            label="LGPA"
                            data={editedStudent.long_gpa}
                          />
                        )}
                        <ProgressReportEntry
                          label="Pre-law GPA:"
                          data={editedStudent.pre_law_degrees[0]?.pre_law_gpa}
                        />
                        <ProgressReportEntry
                          label="LSAT Score:"
                          data={editedStudent.lsat_score}
                        />
                        <ProgressReportEntry
                          label="LSAT Index:"
                          data={editedStudent.lsat_index}
                        />
                      </Col>
                      <Col lg="6" className="academic-progress-col">
                        <Col md="12">
                          <Form.Group
                            as={Row}
                            className="mb-1 align-items-center"
                          >
                            <Form.Label column xs="auto">
                              <strong>Print Percentile:</strong>
                            </Form.Label>
                            <Col>
                              <Form.Check
                                type="switch"
                                id="print-percentile"
                                checked={editedStudent.print_percentile}
                                onChange={this.handleChange}
                                disabled={isOperator}
                              />
                            </Col>
                          </Form.Group>
                        </Col>
                        {!this.props.isGraduate ? (
                          <Col md="12">
                            <Form.Label className="pe-3" column xs="auto">
                              <strong>Degree: </strong>
                              <Button
                                className="m-0 p-0"
                                size="sm"
                                variant="success"
                                onClick={this.toggleGraduateModal}
                                disabled={!isAdminOrRegistrar}
                              >
                                <FontAwesomeIcon
                                  icon={faInfoCircle}
                                  style={{ color: "002e5d" }}
                                />
                              </Button>
                            </Form.Label>
                          </Col>
                        ) : (
                          <ProgressReportEntry
                            label="Degree:"
                            data={
                              editedStudent.law_degrees === ""
                                ? "None"
                                : editedStudent.law_degrees
                                    .map((degreeNum) => degreeNum.degree)
                                    .join(", ")
                            }
                          />
                        )}
                        <Col md="6">
                          <Form.Label className="m-0" row xs="auto">
                            <strong>Joint Degree: </strong>
                          </Form.Label>
                        </Col>
                        {/* You can only edit a joint degree if the student has not graduated, or in the graduate student modal, also only one joint degree will be shown */}
                        {editedStudent.year_in_program === "G" ? (
                          <Col md="6" style={{ textAlign: "right" }}>
                            {editedStudent?.law_degrees[0]?.joint_degree
                              ? editedStudent.law_degrees[0].joint_degree
                              : "None"}
                          </Col>
                        ) : (
                          <Col md="6">
                            <Form.Select
                              id="joint-degree"
                              size="sm"
                              value={editedStudent?.joint_degree}
                              onChange={this.handleChange}
                              disabled={!isAdminOrRegistrar} //{this.props.roles.operator}
                            >
                              <option value="" key="null">
                                None
                              </option>
                              <option value="MBA" key="MBA">
                                MBA
                              </option>
                              <option value="MPA" key="MPA">
                                MPA
                              </option>
                              <option value="MAcc" key="MAcc">
                                MAcc
                              </option>
                              <option value="MEd" key="MEd">
                                {" "}
                                MEd
                              </option>
                              <option value="MPP" key="MPP">
                                {" "}
                                MPP
                              </option>
                              {/* //These joint degrees are currently invalid in the backend. */}
                              {/* <option value="PHDEd" key="PHDEd">
                                {" "}
                                PHDEd
                              </option>
                              <option value="PLSC" key="PLSC">
                                {" "}
                                PLSC
                              </option>
                              <option value="ISM" key="ISM">
                                {" "}
                                ISM
                              </option>
                              <option value="LibSci" key="LibSci">
                                {" "}
                                LibSci
                              </option>
                              <option value="MOB" key="MOB">
                                {" "}
                                MOB
                              </option>
                              <option value="MSW" key="MSW">
                                MSW
                              </option>
                              <option value="Classics" key="Classics">
                                Classics
                              </option>
                              <option value="H.Adm" key="H.Adm">
                                H.Adm
                              </option>
                              <option value="MPH" key="MPH">
                                MPH
                              </option> */}
                            </Form.Select>
                          </Col>
                        )}
                        <Col md="6">
                          <Form.Label column xs="auto">
                            <strong>Date Received: </strong>
                          </Form.Label>
                        </Col>
                        <Col md="6">
                          {editedStudent.law_degrees?.map(
                            (item, index) =>
                              (this.props.roles.admin ||
                                this.props.roles.registrar) && (
                                <Row key={index + "row"}>
                                  <Col className="mt-1" key={index + "col"}>
                                    <Form.Control
                                      id={`${index}-id`}
                                      size="sm"
                                      value={item.date_degree_received?.substring(
                                        0,
                                        10
                                      )}
                                      placeholder="YYYY-MM-DD"
                                      onChange={this.handleDateChange}
                                      key={index + "fc"}
                                    />
                                  </Col>
                                  {/* <Col className="col-2 px-0 mt-1">
                                    <Button
                                      id={`${index}-delete`}
                                      size="sm"
                                      variant="outline-danger"
                                      onClick={this.deleteDateReceived}
                                    >
                                      X
                                    </Button>
                                  </Col> */}
                                </Row>
                              )
                          )}
                        </Col>
                        <Col md="6">
                          <Form.Label column xs="auto" md="6">
                            <strong>Honors: </strong>
                          </Form.Label>
                        </Col>
                        <Col md="6">
                          <Form.Select
                            id="honors-edited"
                            size="sm"
                            value={editedStudent.expected_honors}
                            onChange={this.handleChange}
                            disabled={
                              isOperator ||
                              this.props.isGraduate ||
                              editedStudent.year_in_program === "A"
                            }
                          >
                            <option value="">None</option>
                            <option value="Cum Laude">Cum Laude</option>
                            <option value="Summa Cum Laude">
                              Summa Cum Laude
                            </option>
                            <option value="Magna Cum Laude">
                              Magna Cum Laude
                            </option>
                          </Form.Select>
                        </Col>
                        <Col md="6">
                          <Form.Label column xs="auto">
                            <strong>Exam#: </strong>
                          </Form.Label>
                        </Col>
                        <Col md="6">
                          <Form.Control
                            id="exam-number"
                            type="text"
                            size="sm"
                            placeholder=""
                            value={editedStudent.exam_number}
                            onChange={this.handleChange}
                            disabled={!isAdminOrRegistrar}
                          />
                        </Col>
                        <Col md="12">
                          <Form.Group
                            as={Row}
                            className="mb-1 align-items-center"
                          >
                            <Form.Label column xs="auto">
                              <strong>Exception: </strong>
                              {editedStudent.law_degrees === ""
                                ? "N/A"
                                : editedStudent.law_degrees
                                    .map((degreeNum) =>
                                      degreeNum.honors_exception === 1
                                        ? "Yes"
                                        : "N/A"
                                    )
                                    .join(", ")}
                            </Form.Label>
                            <Col>
                              <Form.Check
                                type="switch"
                                id="exception"
                                checked={editedStudent.honors_exception}
                                onChange={this.handleChange}
                                disabled={isOperator}
                                style={{ width: "100px" }}
                              />
                            </Col>
                          </Form.Group>
                        </Col>
                        <Col md="12">
                          <Form.Group>
                            <Form.Label>
                              <strong>Disciplinary Action:</strong>
                            </Form.Label>
                            <Form.Control
                              id="disciplinary-action"
                              as="textarea"
                              rows={1}
                              size="sm"
                              value={editedStudent.disciplinary || ""}
                              onChange={this.handleChange}
                              disabled={isOperator}
                            />
                          </Form.Group>
                        </Col>
                        <Col md="12">
                          <Form.Group>
                            <Form.Label>
                              <strong>Comments:</strong>
                            </Form.Label>
                            <Form.Control
                              id="comments"
                              as="textarea"
                              rows={3}
                              size="sm"
                              value={editedStudent.comments}
                              onChange={this.handleChange}
                              disabled={!isAdminOrRegistrar}
                            />
                          </Form.Group>
                        </Col>
                        <ProgressReportEntry
                          label="Date of Birth:"
                          data={
                            editedStudent.date_of_birth
                              ? new Date(
                                  editedStudent.date_of_birth
                                ).toLocaleDateString("en-US", {
                                  timeZone: "Etc/UTC",
                                })
                              : ""
                          }
                        />
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg="5"></Col>
            </Row>

            <Row className="mb-4 mt-2">
              {(this.props.roles.admin || this.props.roles.registrar) && (
                <React.Fragment>
                  <Col className="d-flex" md={{ span: 6 }}>
                    {this.state.showHistory ? (
                      <Button
                        className="my-1 me-1 shadow-button"
                        onClick={this.toggleHistory}
                      >
                        Hide Progress Report History
                      </Button>
                    ) : (
                      <Button
                        className="my-1 me-1 shadow-button"
                        onClick={this.toggleHistory}
                      >
                        Show Progress Report History
                      </Button>
                    )}
                    {this.state.showCourseHistory ? (
                      <Button
                        className="m-1 shadow-button"
                        onClick={this.toggleCourseHistory}
                      >
                        Hide Course History
                      </Button>
                    ) : (
                      <Button
                        className="m-1 shadow-button"
                        onClick={this.toggleCourseHistory}
                      >
                        Show Course History
                      </Button>
                    )}
                  </Col>
                </React.Fragment>
              )}
              <Col className="d-flex justify-content-end" md={{ span: 6 }}>
                <Button
                  className="m-1 shadow-button"
                  style={{ marginRight: ".5rem" }}
                  variant="outline-info"
                  type=""
                  id="cancel-button"
                  onClick={this.onCancel}
                >
                  Cancel
                </Button>
                <Button
                  className="m-1 shadow-button"
                  variant="info"
                  type="submit"
                  disabled={!this.state.hasStudentBeenEdited}
                >
                  Save Changes
                </Button>
              </Col>
            </Row>
            {(this.props.roles.admin || this.props.roles.registrar) &&
              this.state.showHistory && (
                <React.Fragment>
                  <Row>
                    <Col>
                      <Card className="shadow">
                        <Card.Header as="h5">Change history: </Card.Header>
                        <Card.Body>
                          <p className="m-0">{editedStudent.history}</p>
                          <Row>
                            <Col style={{ maxHeight: "70vh" }}>
                              <History
                                apis={[
                                  `/${
                                    this.props.isGraduate
                                      ? "graduates"
                                      : "students"
                                  }/${this.props.student.id}`,
                                ]}
                                onRestore={this.handleRestore}
                                enableHighlight={[true]}
                                displayNames={["Student Data"]}
                              />
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                  <br />
                </React.Fragment>
              )}
            {(this.props.roles.admin || this.props.roles.registrar) &&
              this.state.showCourseHistory && (
                <Row ref={this.courseScrollRef}>
                  <React.Fragment>
                    <Col>
                      <Card className="shadow">
                        <Card.Header as="h5">Change history: </Card.Header>
                        <Card.Body style={{ height: "80vh" }}>
                          <p className="m-0">{editedStudent.history}</p>
                          <History
                            apis={[`/student-course/${this.props.student.id}`]}
                            onRestore={this.handleRestore}
                            enableHighlight={[false]}
                            displayNames={["Student Courses"]}
                          />
                        </Card.Body>
                      </Card>
                    </Col>
                  </React.Fragment>
                </Row>
              )}
            <Row>
              <Col>
                <EditCourseTable
                  courses={editedStudent.courses}
                  studentID={editedStudent.id}
                  roles={this.props.roles}
                  onCancelChanges={this.onCancel}
                  onSaveChanges={this.handleFormSubmit}
                  hasStudentBeenEdited={this.state.hasStudentBeenEdited}
                />
              </Col>
            </Row>
          </Container>
        </Form>
      </React.Fragment>
    );
  }
}
