import { faInfoCircle, faSort } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import {
  Button,
  ButtonGroup,
  Card,
  Col,
  Container,
  Form,
  Nav,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import ReactToPrint from "react-to-print";
import api from "../../services/api";
import StudentInformation from "../records/StudentInformation";
import MassGraduationModal from "./MassGraduationInfoModal";
import MassGraduationSubmitModal from "./MassGraduationSubmitModal";

/**
 * Renders a tooltip component with the given props.
 *
 * @param {object} props - The props for the tooltip component
 * @return {JSX.Element} The rendered tooltip component
 */
const renderTooltip = (props) => (
  <Tooltip id="button-tooltip" {...props}>
    Hold shift to multi-select
  </Tooltip>
);

class MassGraduationEvaluation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fieldsToShow: [
        "Graduate",
        "Student ID",
        "Degree",
        "Name",
        "Name on Degree",
        "Honors",
        "Honors Exception",
        "Joint Degree",
        "Info",
        // "Edit",
      ],
      gradCheck: false,
      allData: [],
      isLoaded: false,
      currentStudentModal: "",
      currentSelectedStudent: "",
      showRequirementsModal: false,
      yearinprogramfilter: "3",
      showGraduationSubmitModal: false,
      studentsToGraduate: [],
      editAll: false,
      editableStudents: [],
      showChecked: false,
      graduateSortAscending: true,
      nameSortAscending: true,
      nameOnDegreeSortAscending: true,
      honorsSortAscending: true,
      honorsExceptionSortAscending: true,
      jointDegreeSortAscending: true,
      togglePrintReport: false,
      graduationResponse: [],
      showIndividualStudent: false,
      page: "mass-graduate",
      messagedResponses: "",
      prevFilters: [],
    };
    this.prevuniversityreport = React.createRef();
    this.prevdetailedreport = React.createRef();
    this.populateData = this.populateData.bind(this);
    this.handleclick = this.handleclick.bind(this);
    this.toggleRequirementsModal = this.toggleRequirementsModal.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handleGraduationCheck = this.handleGraduationCheck.bind(this);
    this.handleGraduateSubmitClick = this.handleGraduateSubmitClick.bind(this);
    this.toggleGraduateSubmitModal = this.toggleGraduateSubmitModal.bind(this);
    this.getCheckedStudentsToGraduate =
      this.getCheckedStudentsToGraduate.bind(this);
    this.handleEditChange = this.handleEditChange.bind(this);
    this.handleSingleEdit = this.handleSingleEdit.bind(this);
    this.handleCheckDeselectAll = this.handleCheckDeselectAll.bind(this);
    this.handleSort = this.handleSort.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.togglePrintReport = this.togglePrintReport.bind(this);
    this.setGraduationResponse = this.setGraduationResponse.bind(this);
    this.getStudentFromEditableStudents =
      this.getStudentFromEditableStudents.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.updateURL = this.updateURL.bind(this);
    this.handleDisplayClick = this.handleDisplayClick.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.disablePageNav = this.disablePageNav.bind(this);
    this.enablePageNav = this.enablePageNav.bind(this);
    this.setMessagedReponses = this.setMessagedReponses.bind(this);
    this.toggleLoading = this.toggleLoading.bind(this);
    this.endLoading = this.endLoading.bind(this);
    this.saveChecks = this.saveChecks.bind(this);
    this.handleCheckSelectAll = this.handleCheckSelectAll.bind(this);
  }

  setMessagedReponses(response) {
    this.setState({ messagedResponses: response });
  }

  disablePageNav() {
    this.setState({ disablePageNav: true });
  }
  toggleLoading() {
    this.setState({ isLoaded: false });
  }

  endLoading() {
    this.setState({ isLoaded: true });
  }

  enablePageNav() {
    this.setState({ disablePageNav: false });
  }

  /**
   * Takes the user to see the individual student info when clicked.
   *
   * @param {object} element - The student that was clicked
   */
  handleDisplayClick(element) {
    this.saveChecks();
    this.updateURL(element.id);

    this.setState({
      storeFormat: this.state.currentFormat,
      currentFormat: "Display",
      selectedData: element,
      disablePaginator: true,
      showPopUpWarning: false,
    });
    this.setState({ showIndividualStudent: !this.state.showIndividualStudent });
  }

  /**
   * Takes the user back to the mass graduation page from an individual student info.
   */
  handleCancel() {
    this.updateURL(false);
    this.setState({
      currentFormat: this.state.storeFormat,
      storeFormat: "",
      selectedData: {},
      disablePaginator: false,
    });

    this.populateData();

    this.setState({ showIndividualStudent: !this.state.showIndividualStudent });
  }

  /**
   * Updates the URL based on the next ID given, if applicable, and the user's roles.
   * Does not reload the page.
   *
   * @param {type} nextId - the next ID to be used in the URL
   */
  updateURL(nextId) {
    if (!this.props.fromFaculty) {
      nextId = false;
    }
    let nextURL = "";
    if (
      nextId &&
      (this.props.roles.admin ||
        this.props.roles.registrar ||
        this.props.roles.operator)
    ) {
      nextURL = `${window.location.protocol}//${window.location.host}/${this.state.page}/${nextId}`;
    } else {
      nextURL = `${window.location.protocol}//${window.location.host}/${this.state.page}`;
    }
    const nextTitle = document.title;
    // This will replace the current entry in the browser's history, without reloading
    window.history.replaceState({}, nextTitle, nextURL);
  }

  handleSelect(e) {
    let id = e.target.name;
    let field = e.target.id;
    let editStudent = this.state.editableStudents;
    switch (field) {
      case "edit-degree-name":
        editStudent.forEach((student) => {
          if (student.id === id) {
            student.name_on_diploma = e.target.value;
          }
        });
        break;
      case "edit-honors":
        editStudent.forEach((student) => {
          if (student.id === id) {
            student.honors = e.target.value;
          }
        });
        break;
      case "edit-honors-exception":
        editStudent.forEach((student) => {
          if (student.id === id) {
            if (e.target.value === "true") {
              student.honors_exception = true;
            } else {
              student.honors_exception = false;
            }
          }
        });
        break;
      case "edit-joint-degree":
        editStudent.forEach((student) => {
          if (student.id === id) {
            if (e.target.value === "true") {
              student.joint_degree = e.target.value;
            }
          }
        });
        break;
      default:
        break;
    }

    this.setState({ editableStudents: editStudent });
  }

  getStudentFromEditableStudents(id) {
    let editStudent = this.state.editableStudents;
    let studentToFind = {};
    editStudent.forEach((student) => {
      if (student.id === id) {
        studentToFind = student;
      }
    });
    return studentToFind;
  }

  togglePrintReport() {
    this.setState({ togglePrintReport: true });
  }

  setGraduationResponse(value) {
    this.setState({ graduationResponse: value });
  }

  handleSort(field) {
    switch (field) {
      case "Graduate":
        if (this.state.graduateSortAscending === true) {
          this.sortAscending("checkedForGrad");
        } else {
          this.sortDecending("checkedForGrad");
        }
        this.setState({
          graduateSortAscending: !this.state.graduateSortAscending,
        });
        break;
      case "Name":
        if (this.state.nameSortAscending === true) {
          this.sortAscending("last_name");
        } else {
          this.sortDecending("last_name");
        }
        this.setState({ nameSortAscending: !this.state.nameSortAscending });
        break;
      case "Name on Degree":
        if (this.state.nameOnDegreeSortAscending === true) {
          this.sortAscending("display_name");
        } else {
          this.sortDecending("display_name");
        }
        this.setState({
          nameOnDegreeSortAscending: !this.state.nameOnDegreeSortAscending,
        });
        break;
      case "Honors":
        if (this.state.honorsSortAscending === true) {
          this.sortAscending("honors");
        } else {
          this.sortDecending("honors");
        }
        this.setState({
          honorsSortAscending: !this.state.honorsSortAscending,
        });
        break;
      case "Honors Exception":
        if (this.state.honorsExceptionSortAscending === true) {
          this.sortAscending("honors_exception");
        } else {
          this.sortDecending("honors_exception");
        }
        this.setState({
          honorsExceptionSortAscending:
            !this.state.honorsExceptionSortAscending,
        });
        break;
      case "Joint Degree":
        if (this.state.jointDegreeSortAscending === true) {
          this.sortAscending("joint_degree");
        } else {
          this.sortDecending("joint_degree");
        }
        this.setState({
          jointDegreeSortAscending: !this.state.jointDegreeSortAscending,
        });
        break;
      default:
        break;
    }
    let arr = this.state.prevFilters;
    arr.push(field);
    this.setState({ allData: this.state.allData, prevFilters: arr });
  }

  sortAscending(key) {
    this.state.allData.sort(function (a, b) {
      var x = a[key];
      var y = b[key];
      return x === null ? 1 : y === null ? -1 : x > y ? -1 : x < y ? 1 : 0;
    });
  }

  sortDecending(key) {
    this.state.allData.sort(function (a, b) {
      var x = a[key];
      var y = b[key];
      return x === null ? 1 : y === null ? -1 : x < y ? -1 : x > y ? 1 : 0;
    });
  }

  handleCheckDeselectAll() {
    this.state.allData.forEach((student) => {
      if (student.checkedForGrad) {
        student.checkedForGrad = false;
      }
    });
    this.setState({ allData: this.state.allData });
  }
  handleCheckSelectAll() {
    this.state.allData.forEach((student) => {
      if (student.year_in_program === this.state.yearinprogramfilter)
        student.checkedForGrad = true;
    });
    this.setState({ allData: this.state.allData });
  }

  async componentDidMount() {
    await this.populateData();

    if (this.props.match.params.byuId && this.props.match.params.page) {
      const selectedData = this.state.allData.find(
        (data) => parseInt(data.id) === parseInt(this.props.match.params.byuId)
      );
      if (
        selectedData &&
        (this.props.roles.admin ||
          this.props.roles.registrar ||
          this.props.roles.operator)
      ) {
        this.handleDisplayClick(selectedData);
      } else {
        this.updateURL(false);
      }
    }
  }

  handleKeyPress(e) {
    if (e.shiftKey === true) {
      let editStudent = this.state.allData;
      let foundFirstCheck = false;
      let done = false;
      editStudent.forEach((item) => {
        if (
          foundFirstCheck === true &&
          done === false &&
          item.id !== e.target.value &&
          item.id !== this.state.lastIDChecked &&
          (item.year_in_program === this.state.yearinprogramfilter ||
            this.state.yearinprogramfilter === "")
        ) {
          item.checkedForGrad = !item.checkedForGrad;
        }

        if (
          (item.id === this.state.lastIDChecked ||
            item.id === e.target.value) &&
          foundFirstCheck === true
        ) {
          done = true;
        }

        if (
          item.id === this.state.lastIDChecked &&
          foundFirstCheck === false &&
          foundFirstCheck === false
        ) {
          foundFirstCheck = true;
        } else if (item.id === e.target.value && foundFirstCheck === false) {
          foundFirstCheck = true;
        }
      });
      this.setState({ allData: editStudent, lastIDChecked: e.target.value });
    }
  }

  handleEditChange(student) {
    let students = this.state.editableStudents;
    students.forEach((item) => {
      if (item.id === student.id) {
        student = item;
      }
    });
    let allStudents = this.state.allData;
    allStudents.forEach((item) => {
      if (item.id === student.id) {
        item = student;
        item.singleEdit = !item.singleEdit;
      }
    });
    this.setState({ allData: allStudents, editableStudents: students });
  }

  async handleGraduationCheck(e) {
    let editStudent = this.state.allData;
    editStudent.forEach((item) => {
      if (item.id === e.target.value) {
        item.checkedForGrad = !item.checkedForGrad;
      }
    });
    await this.setState({
      allData: editStudent,
      lastIDChecked: e.target.value,
    });
  }

  handleFilterChange(e) {
    let [filter] = e.target.id.split("-");
    let value = e.target.value;
    switch (filter) {
      case "year":
        this.setState({ yearinprogramfilter: value });
        break;
      case "checked":
        this.setState({ showChecked: !this.state.showChecked });
        break;
      default:
        break;
    }
  }

  async getStudent(id) {
    try {
      let studentid = id.toString();
      let { data: elementDataArray } = await api.get(
        `/students/graduation-eval/` + studentid,
        {}
      );
      this.setState({ currentStudentModal: elementDataArray });
      this.toggleRequirementsModal();
    } catch (err) {
      console.error(err.message);
    }
  }

  async handleclick(id, student) {
    await this.getStudent(id);
    await this.setState({ currentSelectedStudent: student });
  }

  handleCheck() {}

  handleSingleEdit(e) {
    let id = e.target.id;
    let array = this.state.editableStudents;

    let editStudent = this.state.allData;
    editStudent.forEach((item) => {
      if (item.id === id) {
        item.singleEdit = !item.singleEdit;
        array.push(item);
      }
    });

    this.setState({ allData: editStudent });
    this.setState({ editableStudents: array });
  }

  async populateData() {
    let prevOrder = [];
    if (this.state.allData.length > 0) {
      this.state.allData.forEach((student) => {
        prevOrder.push(student.id);
      });
    }

    let { data: elementDataArray } = await api.get(`/students/directory`, {});
    elementDataArray.forEach((data) => {
      if (data.known_by && data.known_by !== data.last_name) {
        data.display_name = data.known_by + " " + data.last_name;
      } else {
        data.display_name = data.first_name + " " + data.last_name;
      }
      data.singleEdit = false;
      data.honors = data.expected_honors;
      data.checkedForGrad = false;
      if (!data.name_on_diploma) {
        data.name_on_diploma = data.display_name;
      }
    });

    let date = new Date();
    let year = date.getFullYear();
    try {
      let { data: checkArray } = await api.get(`/graduation/draft/${year}`);
      if (checkArray.length > 0) {
        elementDataArray.forEach((data) => {
          let obj = checkArray.find(({ id }) => id === data.id);
          if (obj) {
            data.checkedForGrad = obj.checkedForGrad;
            //Apart of editing comment from line 675 regarding editing fields.
            // data.name_on_diploma = obj.name_on_diploma;
            // data.honors = obj.honors;
            // data.honors_exception = obj.honors_exception;
          } else {
            data.checkedForGrad = false;
          }
        });
      }
    } catch (err) {
      console.error(err);
      this.setState({
        errorMessage: err.response?.data ? err.response.data : err.message,
      });
    }

    let stateArray = [];
    //delete all the not wanted data from all data
    await elementDataArray.forEach((data) => {
      if (data.year_in_program === "3" || data.year_in_program === "LLM") {
        stateArray.push(data);
      }
    });

    const sortedData = stateArray.sort((a, b) => {
      return prevOrder.indexOf(a.id) - prevOrder.indexOf(b.id);
    });

    this.setState({ allData: sortedData });
    this.setState({ apiCopyData: stateArray });

    this.setState({ isLoaded: true });
  }

  async saveChecks() {
    let students = this.state.allData;
    let array = [];
    students.forEach((student) => {
      let obj = {};
      obj.checkedForGrad = student.checkedForGrad;
      obj.id = student.id;
      array.push(obj);
    });

    try {
      let date = new Date();
      let year = date.getFullYear();
      await api.post(`/graduation/draft/${year}`, array);
    } catch (err) {
      console.error(err);
      this.setState({
        errorMessage: err.response?.data ? err.response.data : err.message,
      });
    }
  }

  toggleRequirementsModal() {
    this.setState({
      showRequirementsModal: !this.state.showRequirementsModal,
    });
  }

  toggleGraduateSubmitModal() {
    this.setState({
      showGraduationSubmitModal: !this.state.showGraduationSubmitModal,
    });
  }

  async handleGraduateSubmitClick() {
    await this.saveChecks();
    await this.getCheckedStudentsToGraduate();
    await this.toggleGraduateSubmitModal();
  }

  getCheckedStudentsToGraduate() {
    this.setState({ studentsToGraduate: [] });
    let arr = [];
    this.state.allData.forEach((student) => {
      if (student.checkedForGrad) {
        arr.push(student);
      }
    });
    this.setState({ studentsToGraduate: arr });
  }

  render() {
    const DisplayData = this.state.allData.map((student) => {
      return (this.state.showChecked === true &&
        student.checkedForGrad === true) ||
        (this.state.showChecked === false &&
          student.year_in_program === this.state.yearinprogramfilter) ? (
        <tr>
          <td>
            <OverlayTrigger
              placement="bottom"
              delay={{ show: 250, hide: 400 }}
              overlay={renderTooltip}
            >
              <Form.Check
                type="checkbox"
                value={student.id}
                onChange={this.handleGraduationCheck}
                checked={student.checkedForGrad === true}
                onMouseUp={this.handleKeyPress}
                role="button"
              ></Form.Check>
            </OverlayTrigger>
          </td>
          <td
            onClick={() => this.handleDisplayClick(student)}
            className="clickable"
          >
            {student.id}
          </td>
          <td
            onClick={() => this.handleDisplayClick(student)}
            className="clickable"
          >
            {student.year_in_program === "LLM" ? "LLM" : "JD"}
          </td>
          <td
            onClick={() => this.handleDisplayClick(student)}
            className="clickable"
          >
            {student.display_name}
          </td>
          {student.singleEdit === true ? (
            <>
              <td>
                <Form.Control
                  id="edit-degree-name"
                  name={student.id}
                  defaultValue={
                    this.getStudentFromEditableStudents(student.id)
                      .name_on_diploma
                  }
                  onChange={this.handleSelect}
                ></Form.Control>
              </td>
              <td>
                <Form.Select
                  id="edit-honors"
                  name={student.id}
                  defaultValue={
                    this.getStudentFromEditableStudents(student.id).honors
                  }
                  onChange={this.handleSelect}
                >
                  <option value="">None</option>
                  <option value="Cum Laude">Cum Laude</option>
                  <option value="Summa Cum Laude">Summa Cum Laude</option>
                  <option value="Magna Cum Laude">Magna Cum Laude</option>
                </Form.Select>
              </td>
              <td>
                <Form.Select
                  id="edit-honors-exception"
                  defaultValue={
                    this.getStudentFromEditableStudents(student.id)
                      .honors_exception
                  }
                  name={student.id}
                  onChange={this.handleSelect}
                >
                  <option value="true">Yes</option>
                  <option value="false">No</option>
                </Form.Select>
              </td>
              <td>
                <Form.Select
                  id="edit-joint-degree"
                  name={student.id}
                  defaultValue={
                    this.getStudentFromEditableStudents(student.id).joint_degree
                  }
                  onChange={this.handleSelect}
                >
                  <option value="" key="">
                    None
                  </option>
                  <option value="MBA" key="MBA">
                    MBA
                  </option>
                  <option value="MPA" key="MPA">
                    MPA
                  </option>
                  <option value="MAcc" key="MAcc">
                    MAcc
                  </option>
                  <option value="MEd" key="MEd">
                    {" "}
                    MEd
                  </option>
                  <option value="MPP" key="MPP">
                    {" "}
                    MPP
                  </option>
                </Form.Select>
              </td>
            </>
          ) : (
            <>
              <td
                onClick={() => this.handleDisplayClick(student)}
                className="clickable"
              >
                {student.name_on_diploma}
              </td>
              <td
                onClick={() => this.handleDisplayClick(student)}
                className="clickable"
              >
                {student.honors}
              </td>
              <td
                onClick={() => this.handleDisplayClick(student)}
                className="clickable"
              >
                {student.honors_exception ? "Yes" : "No"}
              </td>
              <td
                onClick={() => this.handleDisplayClick(student)}
                className="clickable"
              >
                {student.joint_degree}
              </td>
            </>
          )}
          <td>
            <Button
              className="m-0 p-0"
              size="m"
              variant="success"
              value={student.id}
              onClick={this.handleclick.bind(this, student.id, student)}
            >
              <FontAwesomeIcon
                icon={faInfoCircle}
                style={{ color: "002e5d" }}
              />
            </Button>
          </td>
          {/*
            On 4/4/2022, we decided to not allow Gaelynn to edit fields on the mass graduation page due to conflicting drafts, 
            and database data. She will have to go to the students progress report page to do so.
            
            If you are wanting to reinstate the ability to edit, undo this comment, the comment in the 
            populate data function, and the table field in the component constructor. This will allow Gaelynn to save a draft 
            of edits that is not posted to the database, but will overwrite any incoming database changes after the draft is 
            posted for the first time in the year.       
          */}
          {/* <td>
            {student.singleEdit === false ? (
              <Button
                variant="outline-primary"
                checked={student.singleEdit}
                onClick={this.handleSingleEdit}
                type="checkbox"
                size="sm"
                id={student.id}
              >
                Edit
              </Button>
            ) : (
              ""
            )}
            {student.singleEdit === true ? (
              <ButtonGroup>
                <Button
                  variant="outline-primary"
                  onClick={() => this.handleEditChange(student)}
                  type="checkbox"
                  size="sm"
                  id={student.id}
                >
                  Save
                </Button>
              </ButtonGroup>
            ) : (
              ""
            )}
          </td> */}
        </tr>
      ) : (
        ""
      );
    });

    return this.state.isLoaded ? (
      <React.Fragment>
        <MassGraduationModal
          show={this.state.showRequirementsModal}
          toggleForceGradModal={this.toggleRequirementsModal}
          manualGraduationInfo={this.state.currentStudentModal}
          incompleteGradInfo={this.state.currentStudentModal}
          student={this.state.currentSelectedStudent}
          fromMassAdvance={false}
        />
        <MassGraduationSubmitModal
          show={this.state.showGraduationSubmitModal}
          manualGraduationInfo={this.state.allData}
          studentsToGraduate={this.state.studentsToGraduate}
          toggleGraduateModal={this.toggleGraduateSubmitModal}
          showPrintReport={this.togglePrintReport}
          setGraduationResponse={this.setGraduationResponse}
          setMessagedResponses={this.setMessagedReponses}
          populateData={this.populateData}
          saveChecks={this.saveChecks}
          toggleLoading={this.toggleLoading}
          endLoading={this.endLoading}
        />
        <Row className="no-margin">
          <Col mxs={12} md={5} lg={4} xl={3} className="sidebar">
            <Nav>
              <Container className="p-0">
                <Card className="shadow">
                  <Card.Header as={"h4"}>Mass Graduation</Card.Header>
                  <Card.Body>
                    <p>
                      To select or deselect multiple students at once for
                      graduation, select one student, and hold shift when
                      selecting the next student to select all students
                      inbetween.
                    </p>
                    <Row className="py-1">
                      <Col>
                        <h5 className="p-0 m-0">Select Degrees:</h5>

                        <Form.Select
                          id="year"
                          onChange={this.handleFilterChange}
                          defaultValue={this.state.yearinprogramfilter}
                          value={this.state.yearinprogramfilter}
                          className="mt-2"
                        >
                          <option value="3">JD</option>
                          <option value="LLM">LLM</option>
                        </Form.Select>
                      </Col>
                      <Col>
                        {/* Debating adding a full edit all option here */}
                        <Form.Group>
                          <div
                            className="float-beginning mt-4"
                            style={{ display: "flex" }}
                          >
                            <Form.Check
                              className="text-photo-item item-switch"
                              type="switch"
                              id="checked"
                              onChange={this.handleFilterChange}
                            />
                            <Form.Label className="text-photo-item">
                              Show All Selections
                            </Form.Label>
                          </div>
                        </Form.Group>
                      </Col>
                    </Row>
                    <p></p>
                    <Row className="py-1 px-0 justify-content-center">
                      <Col xs={5}>
                        <div
                          style={{
                            justifyContent: "center",
                            display: "flex",
                            flexDirection: "column",
                            marginBottom: 3,
                          }}
                        >
                          <Button
                            variant="info"
                            onClick={this.handleGraduateSubmitClick.bind(this)}
                          >
                            Graduate
                          </Button>
                          <Button
                            variant="primary"
                            onClick={this.saveChecks}
                            disabled={!this.props.roles.registrar}
                            style={{ marginTop: 3 }}
                          >
                            Save Checks
                          </Button>{" "}
                        </div>
                      </Col>
                      <Col>
                        <div
                          style={{
                            justifyContent: "center",
                            display: "flex",
                          }}
                        >
                          <Button
                            variant="danger"
                            onClick={this.handleCheckDeselectAll}
                            style={{ marginLeft: 3 }}
                          >
                            Deselect All
                          </Button>
                          <Button
                            variant="primary"
                            onClick={this.handleCheckSelectAll}
                            disabled={!this.props.roles.registrar}
                            style={{ marginLeft: 3 }}
                          >
                            Select All
                          </Button>{" "}
                        </div>
                      </Col>
                    </Row>
                    <Row className="py-1 px-0 justify-content-center">
                      <Col xs={5}></Col>
                      <Col>
                        {this.state.togglePrintReport ? (
                          <div>
                            <ReactToPrint
                              trigger={() => {
                                // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                                // to the root node of the returned component as it will be overwritten.
                                return (
                                  <ButtonGroup className="d-flex">
                                    <Button
                                      variant="primary"
                                      className="btn-block"
                                    >
                                      Previous University Report
                                    </Button>
                                  </ButtonGroup>
                                );
                              }}
                              content={() => this.prevuniversityreport.current}
                            />
                            <div style={{ display: "none" }}>
                              <Table ref={this.prevuniversityreport}>
                                <thead>
                                  <th>Last Name</th>
                                  <th>First Name</th>
                                  <th>Student ID</th>
                                  <th>Degree</th>
                                  <th>Honors</th>
                                </thead>
                                <tbody>
                                  {this.state.studentsToGraduate.map(
                                    (student, index) => (
                                      <>
                                        {this.state.graduationResponse ? (
                                          this.state.graduationResponse[index]
                                            ?.success ? (
                                            <tr>
                                              <td>{student.last_name}</td>
                                              <td>{student.first_name}</td>
                                              <td>{student.id}</td>
                                              <td>
                                                {student.year_in_program === "3"
                                                  ? "Juris Doctor"
                                                  : "Master of Law"}
                                              </td>
                                              <td>
                                                {student.honors === "C"
                                                  ? "Cum Laude"
                                                  : student.honors === "M"
                                                  ? "Magna Cum Laude"
                                                  : student.honors === "S"
                                                  ? "Summa Cum Laude"
                                                  : student?.honors}
                                              </td>
                                            </tr>
                                          ) : (
                                            ""
                                          )
                                        ) : (
                                          ""
                                        )}
                                      </>
                                    )
                                  )}
                                </tbody>
                              </Table>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </Col>
                    </Row>
                    <Row className="py-1 px-0 justify-content-center">
                      <Col xs={5}></Col>
                      <Col>
                        {this.state.togglePrintReport ? (
                          <div>
                            <ReactToPrint
                              trigger={() => {
                                // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                                // to the root node of the returned component as it will be overwritten.
                                return (
                                  <ButtonGroup className="d-flex">
                                    <Button
                                      variant="primary"
                                      className="btn-block"
                                    >
                                      Previous Detailed Report
                                    </Button>
                                  </ButtonGroup>
                                );
                              }}
                              content={() => this.prevdetailedreport.current}
                            />
                            <div style={{ display: "none" }}>
                              <Table ref={this.prevdetailedreport}>
                                <thead>
                                  <th>Name on Degree</th>
                                  <th>ID</th>
                                  <th>Degree</th>
                                  <th>Honors</th>
                                  <th>Honors Exception</th>
                                  <th>Joint Degree</th>
                                  <th>Success</th>
                                  <th>Missing Requirements</th>
                                </thead>
                                <tbody>
                                  {this.state.studentsToGraduate.map(
                                    (student, index) => (
                                      <tr>
                                        <td>{student.name_on_diploma}</td>
                                        <td>{student.id}</td>
                                        <td>
                                          {student.year_in_program === "3"
                                            ? "JD"
                                            : "LLM"}
                                        </td>
                                        <td>{student.honors}</td>
                                        <td>
                                          {student.honors_exception
                                            ? "Yes"
                                            : "No"}
                                        </td>
                                        <td>{student.joint_degree}</td>
                                        <td>
                                          {this.state.graduationResponse
                                            ? this.state.graduationResponse[
                                                index
                                              ]?.success
                                              ? "Success"
                                              : "Failure"
                                            : ""}
                                        </td>
                                        <td>
                                          {this.state.messagedResponses[
                                            index
                                          ]?.map((field) => {
                                            return <div>{field}</div>;
                                          })}
                                        </td>
                                      </tr>
                                    )
                                  )}
                                </tbody>
                              </Table>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Container>
            </Nav>
          </Col>
          <Col
            xs={12}
            md={7}
            lg={8}
            xl={9}
            className="no-margin-but-padding directory-display-wrapper"
          >
            {!this.state.showIndividualStudent ? (
              <React.Fragment>
                <Card className="directory-shadow">
                  <Table striped bordered hover responsive className="mb-0">
                    <thead>
                      <tr>
                        {this.state.fieldsToShow.map((field) => (
                          <th>
                            {field}{" "}
                            <Button
                              className="m-0 p-0"
                              size="sm"
                              variant="success"
                              onClick={this.handleSort.bind(this, field)}
                            >
                              <FontAwesomeIcon
                                icon={faSort}
                                style={{ color: "002e5d" }}
                              />
                            </Button>
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>{DisplayData}</tbody>
                  </Table>
                </Card>
              </React.Fragment>
            ) : (
              <StudentInformation
                isGraduate={this.props.fromGraduate}
                fromDirectory
                fromMassGrad={"Return to Mass Graduation"}
                disableDirectoryPageNav={this.disablePageNav}
                enableDirectoryPageNav={this.enablePageNav}
                handleReturnToDirectory={this.handleCancel}
                students={this.state.allData}
                studentId={this.state.selectedData.id}
                roles={this.props.roles}
                populateDataParent={this.populateData}
              />
            )}
          </Col>
        </Row>
      </React.Fragment>
    ) : (
      <div className="loadingDiv">
        <h1 className="loadingText" style={{marginBottom: '1em', marginLeft: '0.5em'}}>Loading Data...</h1>
        <Spinner animation={"border"} />
      </div>
    );
  }
}

export default MassGraduationEvaluation;
