import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// src\services\api.js
import axios from "axios";
import React from "react";
import Select from "react-select";
import {
  Button,
  Card,
  Col,
  Form,
  OverlayTrigger,
  Row,
  Tooltip,
  Container,
} from "react-bootstrap";
import api from "../../services/api";
import { handleImageError } from "../../utils/functions";
import History from "../History";
import AddPhotoModal from "./AddPhotoModal";
import { CleanImage } from "../SafeImage";

class EditFacultyInformation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showHistory: false,
      showPhotoModal: false,
      photoFile: "",
      editablePhoto: "",
      link: "",
      photoChanged: false,
    };
    this.toggleHistory = this.toggleHistory.bind(this);
    this.togglePhotoModal = this.togglePhotoModal.bind(this);
    this.handleAddPhoto = this.handleAddPhoto.bind(this);
    this.handleSelectPhoto = this.handleSelectPhoto.bind(this);
    this.handleSavePhotoChange = this.handleSavePhotoChange.bind(this);
    this.displayPhoto = this.displayPhoto.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  togglePhotoModal() {
    this.setState({ showPhotoModal: !this.state.showPhotoModal });
  }

  /**
   * Saves a new photo to the server for the person.
   *
   * @return {Promise<void>} This function does not return anything.
   */
  async handleSavePhotoChange() {
    if (this.state.photoChanged === true) {
      try {
        await api.post(`images/${this.props.editablePerson.id}`, {
          file: this.state.editablePhoto,
          person_type: "faculty",
        });
      } catch (err) {
        if (!axios.isCancel(err)) {
          console.error(err);
          this.setState({
            errorMessage: err.response?.data ? err.response.data : err.message,
          });
        }
      }

      this.props.handlePhotoSave(this.state.link);
    }
  }

  /**
   * Handles the action of adding a photo.
   *
   * @return {void}
   */
  async handleAddPhoto() {
    // try {
    //   await api.post(`/images/${this.props.editablePerson.id}`, {
    //     file: this.state.photoFile,
    //     person_type: "faculty",
    //   });
    // } catch (err) {
    //   if (!axios.isCancel(err)) {
    //     console.error(err);
    //     this.setState({
    //       errorMessage: err.response?.data ? err.response.data : err.message,
    //     });
    //   }
    // }

    let idValue = this.props.editablePerson.id;
    let link;
    if (process.env.REACT_APP_STAGE === "dev") {
      link =
        "https://s3.amazonaws.com/content.law-info.byu.edu-dev/faculty-pictures/" +
        idValue +
        ".jpg";
    } else {
      link =
        "https://s3-us-west-2.amazonaws.com/content.law-info.byu.edu/faculty-pictures/" +
        idValue +
        ".jpg";
    }

    this.setState({ link: link });

    this.props.handlePhotoChange();
    this.props.editablePerson.picture_link = link;
    this.setState({ photoChanged: true });
    this.togglePhotoModal();
  }

  /**
   * Saves the event in state as editablePhoto.
   *
   * @param {event} event - The event object.
   * @return {void} This function does not return a value.
   */
  handleSelectPhoto(event) {
    // this.setState({ photoFile: event });
    this.setState({ editablePhoto: event });
  }

  /**
   * Toggles the showHistory state between true and false.
   *
   * @return {void}
   */
  toggleHistory() {
    this.setState({ showHistory: !this.state.showHistory });
  }

  /**
   * Returns the URL of the photo or the base64 encoded icon.
   *
   * @return {string} - The URL of the photo or the base64 encoded icon.
   */
  displayPhoto() {
    var base64Icon = this.state.editablePhoto;

    if (this.state.editablePhoto === "") {
      return (
        `${this.props.editablePerson.picture_link}?` + new Date().getTime()
      );
    } else {
      return base64Icon;
      // return {uri: `${this.state.editablePhoto}`} ;
    }
  }

  /**
   * Handles the cancel action. Clears the editablePhoto state and closes the photo modal.
   *
   * @return {void}
   */
  handleCancel() {
    this.setState({ editablePhoto: "" });

    //this.props.changeEdit();
    // edit();
    this.togglePhotoModal();
  }

  render() {
    const {
      roles,
      handleFormSubmit,
      handleChange,
      handleDelete,
      changeEdit,
      editablePerson,
      tooltips,
      departments,
      category,
      handleCategoryChange,
      handleRestore,
      facultyEdited,
      categoryEdited,
    } = this.props;
    return (
      <Container className="p-5 bg-white">
        <Form onSubmit={handleFormSubmit}>
          <Row className="no-margin justify-content-center p-4">
            <Card className="shadow personal-info-card">
              <Card.Body>
                <Row>
                  <Col xs={"auto"} sm={6} md={4} lg={3} xl={2} className="mt-3">
                    <Row>
                      <div
                        style={{ display: "inline-flex", position: "relative" }}
                      >
                        <div
                          style={{
                            position: "absolute",
                            bottom: 0,
                            left: 15,
                            zIndex: 1,
                          }}
                        >
                          <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Tooltip>Edit Photo</Tooltip>}
                          >
                            <Button
                              className="m-2 p-0"
                              variant="success"
                              onClick={this.togglePhotoModal}
                              aria-label={"Edit Faculty Photo"}
                            >
                              <FontAwesomeIcon
                                icon={faEdit}
                                style={{ color: "ffffff" }}
                              />
                            </Button>
                          </OverlayTrigger>
                        </div>
                        <div style={{ postion: "relative" }}>
                          <CleanImage
                            //src={this.displayPhoto()}
                            src={
                              editablePerson.picture_link +
                              "?" +
                              new Date().getTime()
                            }
                            alt={`Faculty Profile Photo`}
                            //  src={{uri: `${this.state.editablePhoto}`}}
                            // source={{uri: `data:image/gif;base64,${this.state.editablePhoto}`}}
                            placeholder="person_img"
                            style={{ display: "block" }}
                            onError={(e) => {
                              handleImageError(e, editablePerson);
                            }}
                            fluid
                            person={editablePerson}
                            // onClick={this.togglePhotoModal}
                          />
                        </div>
                      </div>
                    </Row>
                    <Row>
                      <AddPhotoModal
                        show={this.state.showPhotoModal}
                        onHide={this.handleCancel}
                        addPhoto={this.handleAddPhoto}
                        selectPhoto={this.handleSelectPhoto}
                        changeLink={this.props.handleChange}
                      />
                    </Row>
                    <hr />
                    <h5>Cropped Image Preview: </h5>
                    <OverlayTrigger
                      delay={{ show: 250, hide: 400 }}
                      placement={"top"}
                      overlay={tooltips.croppedTooltip}
                    >
                      <Row className="image-display-container mb-2">
                        <CleanImage
                          // src={`${editablePerson.picture_link}`}
                          src={this.displayPhoto()}
                          alt={`Preview of New Faculty Profile Photo`}
                          placeholder="person_img"
                          className="image-display-content"
                          onError={(e) => {
                            handleImageError(e, editablePerson);
                          }}
                          person={editablePerson}
                          fluid
                        />
                      </Row>
                    </OverlayTrigger>
                  </Col>
                  <Col
                    xs={"auto"}
                    sm={6}
                    md={8}
                    lg={9}
                    xl={10}
                    className="mt-3"
                  >
                    <Row>
                      <Col xs={12} lg={6}>
                        <Row>
                          <Form.Group>
                            <Form.Label>
                              <strong>First Name</strong>
                            </Form.Label>
                            <Form.Control
                              id="first_name"
                              value={
                                editablePerson.first_name
                                  ? editablePerson.first_name
                                  : ""
                              }
                              onChange={(e) => handleChange(e)}
                            />
                          </Form.Group>
                        </Row>
                        <Row>
                          <Form.Group>
                            <Form.Label>
                              <strong>Middle Name</strong>
                            </Form.Label>
                            <Form.Control
                              id="middle_name"
                              value={
                                editablePerson.middle_name
                                  ? editablePerson.middle_name
                                  : ""
                              }
                              onChange={(e) => handleChange(e)}
                            />
                          </Form.Group>
                        </Row>
                        <Row>
                          <Form.Group>
                            <Form.Label>
                              <strong>Last Name</strong>
                            </Form.Label>
                            <Form.Control
                              id="last_name"
                              value={
                                editablePerson.last_name
                                  ? editablePerson.last_name
                                  : ""
                              }
                              onChange={(e) => handleChange(e)}
                            />
                          </Form.Group>
                        </Row>
                        <Row>
                          <Form.Group>
                            <Form.Label>
                              <strong>Suffix </strong>
                            </Form.Label>
                            <Form.Control
                              id="suffix"
                              placeholder="Jr., Sr., etc"
                              value={
                                editablePerson.suffix
                                  ? editablePerson.suffix
                                  : ""
                              }
                              onChange={(e) => handleChange(e)}
                            />
                          </Form.Group>
                        </Row>
                        <Row>
                          <Form.Group>
                            <Form.Label>
                              <strong>Title</strong>
                            </Form.Label>
                            <Form.Control
                              id="title"
                              value={
                                editablePerson.title ? editablePerson.title : ""
                              }
                              onChange={(e) => handleChange(e)}
                            />
                          </Form.Group>
                        </Row>
                        <Row>
                          <Form.Group>
                            <Form.Label>
                              <strong>Known By</strong>
                            </Form.Label>
                            <Form.Control
                              id="known_by"
                              value={
                                editablePerson.known_by
                                  ? editablePerson.known_by
                                  : ""
                              }
                              onChange={(e) => handleChange(e)}
                            />
                          </Form.Group>
                        </Row>
                        <Row>
                          <Form.Group>
                            <Form.Label>
                              <strong>Category</strong>
                            </Form.Label>
                            <Select
                              id="category"
                              isMulti
                              defaultValue={() => {
                                let val = [];
                                let person = editablePerson;
                                category?.forEach((cat) => {
                                  if (person.category?.includes(cat.value)) {
                                    val.push(cat);
                                  }
                                });
                                return val;
                              }}
                              options={category}
                              className="category-multi-select"
                              classNamePrefix="select"
                              onChange={handleCategoryChange}
                            ></Select>
                          </Form.Group>
                        </Row>
                        {/* <Row>
                          <Form.Group>
                            <Form.Label>
                              <strong>Picture Link</strong>
                            </Form.Label>
                            <Form.Control
                              id="picture_link"
                              placeholder="Link to a picture of yourself - preview on the left"
                              value={editablePerson.picture_link}
                              onChange={(e) => handleChange(e)}
                            />
                          </Form.Group>
                        </Row> */}
                      </Col>
                      <Col xs={12} lg={6}>
                        <Row>
                          <Form.Group>
                            <Form.Label>
                              <strong>BYU ID</strong>
                            </Form.Label>
                            <Form.Control
                              id="id"
                              disabled={true}
                              value={editablePerson.id}
                              onChange={(e) => handleChange(e)}
                            />
                          </Form.Group>
                        </Row>
                        <Row>
                          <Form.Group>
                            <Form.Label>
                              <strong>Net ID</strong>
                            </Form.Label>
                            <Form.Control
                              id="net_id"
                              disabled={!roles.admin}
                              value={
                                editablePerson.net_id
                                  ? editablePerson.net_id
                                  : ""
                              }
                              onChange={(e) => handleChange(e)}
                            />
                          </Form.Group>
                        </Row>
                        <Row>
                          <Form.Group>
                            <Form.Label>
                              <strong>Phone</strong>
                            </Form.Label>
                            <Form.Control
                              id="phone"
                              value={
                                editablePerson.phone ? editablePerson.phone : ""
                              }
                              onChange={(e) => handleChange(e)}
                            />
                          </Form.Group>
                        </Row>
                        <Row>
                          <Form.Group>
                            <Form.Label>
                              <strong>E-mail</strong>
                            </Form.Label>
                            <Form.Control
                              id="email"
                              value={
                                editablePerson.email ? editablePerson.email : ""
                              }
                              onChange={(e) => handleChange(e)}
                              disabled={!roles.admin}
                            />
                          </Form.Group>
                        </Row>
                        <Row>
                          <Form.Group>
                            <Form.Label>
                              <strong>Room Number</strong>
                            </Form.Label>
                            <Form.Control
                              id="room"
                              value={
                                editablePerson.room ? editablePerson.room : ""
                              }
                              onChange={(e) => handleChange(e)}
                            />
                          </Form.Group>
                        </Row>
                        <Row>
                          <Form.Group>
                            <Form.Label>
                              <strong>Department</strong>
                            </Form.Label>
                            <Form.Select
                              id="department"
                              defaultValue={
                                editablePerson.department
                                  ? editablePerson.department
                                  : ""
                              }
                              onChange={(e) => handleChange(e)}
                            >
                              <option value="Law School">Law School</option>
                              {departments?.map((department) => (
                                <option
                                  key={department.title}
                                  value={department.title}
                                >
                                  {department.title}
                                </option>
                              ))}
                            </Form.Select>
                          </Form.Group>
                        </Row>
                        <Row>
                          <Form.Group>
                            <Form.Label>
                              <strong>Bio</strong>
                            </Form.Label>
                            <Form.Control
                              id="bio"
                              value={
                                editablePerson.bio_link
                                  ? editablePerson.bio_link
                                  : ""
                              }
                              onChange={(e) => handleChange(e)}
                            />
                          </Form.Group>
                        </Row>
                        <Row>
                          <Col xs="6">
                            <Form.Group>
                              <Form.Label>
                                <strong>Display Records</strong>
                              </Form.Label>
                              <OverlayTrigger
                                delay={{ show: 250, hide: 400 }}
                                overlay={tooltips.hideRecordTooltip}
                                placement="top"
                              >
                                <Form.Check
                                  id="show_on_public"
                                  type="switch"
                                  checked={editablePerson.show_on_public}
                                  onChange={(e) => handleChange(e)}
                                />
                              </OverlayTrigger>
                            </Form.Group>
                          </Col>
                          {roles.admin && (
                            <Col xs="6">
                              <Form.Group>
                                <Form.Label>
                                  <strong>Current Faculty</strong>
                                </Form.Label>
                                <OverlayTrigger
                                  delay={{ show: 250, hide: 400 }}
                                  overlay={tooltips.currentlyEmployedTooltip}
                                  placement="top"
                                >
                                  <Form.Check
                                    id="active"
                                    type="switch"
                                    checked={editablePerson.active_faculty}
                                    onChange={(e) => handleChange(e)}
                                  />
                                </OverlayTrigger>
                              </Form.Group>
                            </Col>
                          )}
                        </Row>
                        <Row className="justify-content-end">
                          <Col className="my-2" xs="auto">
                            <Button
                              style={{ marginRight: ".5rem" }}
                              variant="outline-info"
                              type=""
                              id="cancel-button"
                              onClick={changeEdit}
                            >
                              Cancel
                            </Button>
                            <Button
                              variant="info"
                              type="submit"
                              disabled={!(facultyEdited || categoryEdited)}
                              onClick={this.handleSavePhotoChange}
                            >
                              Save Changes
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Row>
        </Form>
        {roles.admin && (
          <React.Fragment>
            <Row className="px-4 mb-4">
              <Col xs="auto">
                {this.state.showHistory ? (
                  <Button
                    className="shadow-button"
                    onClick={this.toggleHistory}
                  >
                    Hide History
                  </Button>
                ) : (
                  <Button
                    className="shadow-button"
                    onClick={this.toggleHistory}
                  >
                    Show History
                  </Button>
                )}
              </Col>
              <Col xs="auto">
                <Button
                  className="shadow-button"
                  variant="outline-danger"
                  onClick={handleDelete}
                >
                  Delete Record
                </Button>
              </Col>
            </Row>
            {this.state.showHistory && (
              <Card className="shadow mx-4 mb-4">
                <Card.Header as="h5">Change history: </Card.Header>
                <Card.Body style={{ height: "85vh" }}>
                  <History
                    apis={[`/faculty/${editablePerson.id}`]}
                    enableHighlight={[true]}
                    onRestore={handleRestore}
                    displayNames={["Faculty Data"]}
                  />
                </Card.Body>
              </Card>
            )}
          </React.Fragment>
        )}
      </Container>
    );
  }
}

export default EditFacultyInformation;
