import React from "react";
import { withRouter } from "react-router-dom";
import MassAdvanceTable from "../components/records/MassAdvanceTable";

class MassAdvance extends React.Component {
  render() {
    return <MassAdvanceTable fromStudent {...this.props} />;
  }
}

export default withRouter(MassAdvance);