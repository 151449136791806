import React from "react";
import { Container, Row } from "react-bootstrap";
import ErrorDisplay from "../../ErrorDisplay";
import DisplayPersonalInfo from "./DisplayPersonalInfo";
import EditPersonalInfo from "./EditPersonalInfo";

class PersonalInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      studentId: "",
      student: {
        address: [],
        degrees: [],
        languages: [],
        errorMessage: null,
      },
    };
    this.state.studentId = this.props.studentId;
    this.state.student = this.props.student;

    this.updateStudent = this.props.getStudent;
  }

  render() {
    const { editMode, changeEdit, roles, handleClickEdit } = this.props;
    const student = this.state.student;

    return (
      <Container className="p-0 mt-4">
        <React.Fragment>
          {!editMode ? (
            <DisplayPersonalInfo
              isGraduate={this.props.isGraduate}
              student={student}
              updateStudent={this.updateStudent}
              roles={roles}
              handleClickEdit={handleClickEdit}
            />
          ) : (
            <EditPersonalInfo
              isGraduate={this.props.isGraduate}
              student={student}
              studentId={this.state.studentId}
              onClose={changeEdit}
              updateStudent={this.updateStudent}
              roles={roles}
              refreshStudentList={this.props.refreshStudentList}
            />
          )}
          {this.state.errorMessage && (
            <Row className="mt-3">
              <ErrorDisplay errorMessage={this.state.errorMessage} />
            </Row>
          )}
        </React.Fragment>
      </Container>
    );
  }
}

export default PersonalInfo;
