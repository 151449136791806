import { PDFDocument } from "pdf-lib";

async function generateFlashCards(students) {
  let data = new Array(students.length);

  async function fetchPicture(picture, index) {
    try {
      const response = await fetch(picture);
      data[index] = await response.blob();
      if (data[index].type === "application/xml") {
        data[index] = null;
      }
    } catch (err) {
      console.error(err);
    }
  }

  function convert(imgFileData, targetFormat) {
    try {
      targetFormat = targetFormat.toLowerCase();
      let reader = new FileReader();
      return new Promise((resolve) => {
        reader.onload = function (readerEvent) {
          let image = new Image();

          image.onload = function () {
            let canvas = document.createElement("canvas");
            canvas.width = image.width;
            canvas.height = image.height;
            canvas
              .getContext("2d")
              .drawImage(image, 0, 0, image.width, image.height);
            let mimeType = "image/" + targetFormat;
            let dataUrl = canvas.toDataURL(mimeType);

            let convertedImage = {
              name: imgFileData.name.substring(
                0,
                imgFileData.name.lastIndexOf(".")
              ),
              format: targetFormat,
              data: dataUrl,
            };
            resolve(convertedImage);
          };
          image.src = readerEvent.target.result;
        };
        reader.readAsDataURL(imgFileData);
      });
    } catch (e) {
      console.log("Error occurred while converting : ", e);
    }
  }

  try {
    await Promise.all(
      students.map((student, index) =>
        fetchPicture(student.picture_link.split("?")[0], index)
      )
    );
  } catch (err) {
    console.error(err);
  }

  const pdfDoc = await PDFDocument.create();

  let numPages = Math.ceil(students.length / 6);

  for (let i = 0; i < numPages; i++) {
    pdfDoc.addPage();
  }

  const pages = pdfDoc.getPages();

  const fontSize = 20;

  let currentPageIndex = 0;
  let x = 55;
  let y = 585;

  let currentPage = pages[currentPageIndex];

  for (let i = 0; i < students.length; i++) {
    currentPage.drawText(students[i].first_name, {
      x: x,
      y: y,
      size: fontSize,
    });
    try {
      if (!data[i]) {
        const response = await fetch(`notFound.jpg`);

        data[i] = await response.blob();
      }
      // This just pulls the first image out of Data to be used to draw on a pdf
      let gifImageFile = new File([data[i]], i.toString());

      let pngImageFile = await convert(gifImageFile, "png");

      // This pulls the image data out of the pngImageFile
      let pngImageBytes = pngImageFile.data;
      const pngImage = await pdfDoc.embedPng(pngImageBytes);

      currentPage.drawImage(pngImage, {
        x: x,
        y: y + 30,
        width: 139,
        height: 139,
      });
    } catch (err) {
      console.error(err, "Problem Converting Images...");
    }
    if ((i + 1) % 6 === 0) {
      x = 55;
      y = 585;
      currentPageIndex++;
      currentPage = pages[currentPageIndex];
    } else if (i % 2 === 0) {
      x += 306;
    } else {
      x -= 306;
      y -= 264;
    }
  }
  let pdfBytes = await pdfDoc.save();

  const file = new Blob([pdfBytes], {
    type: "application/pdf",
  });
  file.name = "flash-cards.pdf";
  const fileURL = URL.createObjectURL(file);
  window.open(fileURL);
}

export default generateFlashCards;
