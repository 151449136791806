import React from "react";
import { Modal, Table } from "react-bootstrap";

class CourseStatsModal extends React.Component {
  render() {
    let { courseStats, show, toggleCourseStatsModal } = this.props;
    return (
      <Modal show={show} onHide={toggleCourseStatsModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Course Grade Stats</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table responsive bordered>
            <thead>
              <tr>
                <th>Requirements</th>
                <th> Value </th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(courseStats).map((key, i) => (
                <tr key={i}>
                  {key !== "distribution" ? (
                    <>
                      <td>
                        {key.charAt(0).toUpperCase() +
                          key.replaceAll("_", " ").slice(1)}
                      </td>
                      <td>
                        {String(courseStats[key]) === "true" ? (
                          <strong>&#10003;</strong>
                        ) : String(courseStats[key]) === "false" ? (
                          <strong>X</strong>
                        ) : (
                          String(courseStats[key])
                        )}
                      </td>
                    </>
                  ) : (
                    <>
                      <td>
                        {key.charAt(0).toUpperCase() +
                          key.replaceAll("_", " ").slice(1)}
                      </td>
                      <td>
                        <Table responsive bordered>
                          <thead>
                            <tr>
                              {Object.keys(courseStats[key][0]).map(
                                (name, j) => (
                                  <>
                                    <th>
                                      {name.charAt(0).toUpperCase() +
                                        name.replaceAll("_", " ").slice(1)}
                                    </th>
                                  </>
                                )
                              )}
                            </tr>
                          </thead>
                          <tbody>
                            {Object.keys(courseStats[key]).map(
                              (distribution) => (
                                <tr>
                                  {Object.keys(
                                    courseStats[key][distribution]
                                  ).map((name, j) => (
                                    <td>
                                      {String(
                                        courseStats[key][distribution][name]
                                      ) === "true" ? (
                                        <strong>&#10003;</strong>
                                      ) : String(
                                          courseStats[key][distribution][name]
                                        ) === "false" ? (
                                        <strong>X</strong>
                                      ) : (
                                        String(
                                          courseStats[key][distribution][name]
                                        )
                                      )}
                                    </td>
                                  ))}
                                </tr>
                              )
                            )}
                          </tbody>
                        </Table>
                      </td>
                    </>
                  )}
                </tr>
              ))}
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>
    );
  }
}

export default CourseStatsModal;
