import React from "react";
import SimpleViewCourseItem from "./SimpleViewCourseItem";

class SimpleViewCourse extends React.Component {



  render() {
    const {
      dataSet,
      handleDisplayClick,
    } = this.props;

    return (
      <React.Fragment>
        {dataSet.map((course, index) => (
          <SimpleViewCourseItem
            data={course}
            handleDisplayClick={handleDisplayClick}
            key={course.semester + course.catalog_number + course.section_number}/>
        ))}
      </React.Fragment>
    )
  }
}

export default SimpleViewCourse;