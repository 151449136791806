const potentialRoles = {
  admin: 1,
  registrar: 2,
  student: 3,
  faculty: 4,
  graduate: 5,
  operator: 6,
  secretary: 7,
};

export const getToken = () => {
  if (sessionStorage.getItem("actastoken")) {
    return sessionStorage.getItem("actastoken");
  } else if (sessionStorage.getItem("idtoken")) {
    return sessionStorage.getItem("idtoken");
  }
  return "invalidToken";
};
