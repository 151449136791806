import React, { useState } from "react";
import { Modal } from "react-bootstrap";

const SessionExpiring = ({ show }) => {
  const [showModal, setShowModal] = useState(show);

/**
 * Closes the modal.
 *
 * @param {none} - This function does not take any parameters.
 * @return {none} - This function does not return any value.
 */
  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <Modal
      className="d-flex align-items-center"
      show={showModal}
      onHide={handleCloseModal}
    >
      <Modal.Header closeButton>
        <Modal.Title>Session Expiring!</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Please save your work now. Your login expires in 5 minutes. Logout
        and log back in to make sure our site stays secure.
        <div className="d-flex justify-content-center mt-2">
          <button
            className="btn btn-primary"
            onClick={() => {
              setShowModal(false);
              window.location.assign("/logged-out");
            }}
          >
            Logout Now
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SessionExpiring;