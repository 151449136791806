import React from "react";
import { Button } from "react-bootstrap";

class JiraFeedback extends React.Component {
  render() {
    return (
      <Button
        className="no-margin"
        id="feedback-button"
        onClick={() => window.showCollectorDialog()}
        style={
          sessionStorage.getItem("actastoken") && {
            backgroundColor: "#e6ac00",
            border: "none",
          }
        }
      >
        Give Feedback
      </Button>
    );
  }
}

export default JiraFeedback;
