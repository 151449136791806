import React from "react";
import { Col, Row } from "react-bootstrap";
import PreLawSchools from "../records/personal_info/PreLawSchools";
import { getAddressOfType } from "../../utils/functions";

class ApplicationInformation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      barDate: "",
      gradDate: "",
    };
  }

  /**
   * Set the formatted barDate and gradDate in the component state based
   * on the student's plan dates.
   */
  componentDidMount() {
    let barDate = this.props.student.plan_bar_date
      ? new Date(this.props.student.plan_bar_date).toLocaleString("en-US", {
          timeZone: "Etc/UTC",
          month: "long",
          year: "numeric",
        })
      : "";
    let gradDate = this.props.student.plan_grad_date
      ? new Date(this.props.student.plan_grad_date).toLocaleString("en-US", {
          timeZone: "Etc/UTC",
          month: "long",
          year: "numeric",
        })
      : "";
    this.setState({ barDate, gradDate });
  }

  render() {
    let { student } = this.props;
    let studentAddress = getAddressOfType(this.props.student.address, "home");

    return (
      <React.Fragment>
        <Row className="justify-content-center">
          <Col xs="12" md="6">
            <strong>Name: </strong>
            {student.name_on_diploma
              ? student.name_on_diploma
              : student.full_name}{" "}
            (Ensure this name is as you would like it to appear on your diploma)
            <br />
            <strong>Degree to be recieved: </strong>
            {student.enrollment_status === "L" ? (
              <span>LLM</span>
            ) : (
              <span>J.D.</span>
            )}{" "}
            (Contact the registrar to get this changed)
          </Col>
          <Col xs="12" md="6">
            <strong>I plan to graduate: </strong>
            {this.state.gradDate}
          </Col>
          <Col xs="12" md="6">
            <strong>Current Address: </strong>
            {studentAddress.street1} {studentAddress.street2}{" "}
            {studentAddress.city}, {studentAddress.state}{" "}
            {studentAddress.postal_code} {studentAddress.country}
          </Col>
          <Col xs="12" md="6">
            <strong>Phone Number: </strong>
            {studentAddress.phone}
          </Col>
          <PreLawSchools degrees={student.pre_law_degrees} noShadow />
          <Col xs="12">
            <strong>I will be taking the following State Bar Exam: </strong>
            {student.plan_bar_state}
            <strong> When? </strong>
            {this.state.barDate}
          </Col>
          <Col xs="12">
            <strong>
              What Bar Preparation Course are you planning to take?{" "}
            </strong>
            {student.bar_prep_course}
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default ApplicationInformation;
