import { faClipboard, faFilePdf } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Modal,
  Row,
  Spinner,
} from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { Link, withRouter } from "react-router-dom";
import ErrorDisplay from "../components/ErrorDisplay";
import ReleaseGradesModal from "../components/ReleaseGradesModal";
import AllYearReportModal from "../components/reps_utils/AllYearReportModal";
import AssignExamNumbersModal from "../components/reps_utils/AssignExamNumberModal";
import UpdateFinalExamScheduleModal from "../components/reps_utils/UpdateFinalExamScheduleModal";
import ViewExamNumberModal from "../components/reps_utils/ViewExamNumberModal";
import ViewGraduateReportModal from "../components/reps_utils/ViewGraduateReportModal";
import OITGradeReportModal from "../components/reps_utils/OITGradeReportModal";
import GradesAlphabeticalModal from "../components/reps_utils/GradesAlphabeticalModal";
import ClassMeanReportModal from "../components/reps_utils/ClassMeanReportModal";
import PercentileReportModal from "../components/reps_utils/PercentileReportModal";
import DeansListModal from "../components/reps_utils/DeansListModal";
import api from "../services/api";

import {
  displaySemester,
  releaseGradesGetCurrentSemester,
} from "../utils/functions";

function createPastFourSemestersObject() {
  let obj = {};
  let prev = releaseGradesGetCurrentSemester();
  for (let i = 0; i < 5; i++) {
    if (prev[4] !== "2") obj[i] = prev;
    prev =
      prev.toString()[4] === "1"
        ? (parseInt(prev.toString().substring(0, 4)) - 1).toString() + "5"
        : (parseInt(prev) - 1).toString();
  }
  return obj;
}
class GlobalRepUtil extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showExamNumberModal: false,
      showViewExamNumberModal: false,
      showReleaseGradesModal: false,
      errorMessage: null,
      customMessage: null,
      currentSemester: releaseGradesGetCurrentSemester(),
      currentSemesterDisplay:
        displaySemester(releaseGradesGetCurrentSemester())[1] +
        " " +
        displaySemester(releaseGradesGetCurrentSemester())[0],
      loading: false,
      apiResponse: "",
      showRelaseGradesModalSuccess: false,
      showGraduateReportModal: false,
      showUpdateFinalScheduleModal: false,
      semesterDropdownValueMap: createPastFourSemestersObject(),
      showAllYearReportModal: false,
      showOITGradeReportModal: false,
      showGradesAlphabeticalModal: false,
      showClassMeanReportModal: false,
      showDeansListModal: false,
      showPercentileReportModal: false,
    };
    this.openExamNumbersModal = this.openExamNumbersModal.bind(this);
    this.hideExamNumbersModal = this.hideExamNumbersModal.bind(this);
    this.openViewExamNumbersModal = this.openViewExamNumbersModal.bind(this);
    this.hideViewExamNumbersModal = this.hideViewExamNumbersModal.bind(this);
    this.toggleReleaseGradesModal = this.toggleReleaseGradesModal.bind(this);
    this.handleReleaseOneLGrades = this.handleReleaseOneLGrades.bind(this);
    this.handleReleaseGrades = this.handleReleaseGrades.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.hideGraduateReportModal = this.hideGraduateReportModal.bind(this);
    this.openGraduateReportModal = this.openGraduateReportModal.bind(this);
    this.handleSemesterSelect = this.handleSemesterSelect.bind(this);
    this.toggleClassMeanReportModal =
      this.toggleClassMeanReportModal.bind(this);

    this.toggleUpdateFinalScheduleModal =
      this.toggleUpdateFinalScheduleModal.bind(this);
    this.toggleAllYearReportsModal = this.toggleAllYearReportsModal.bind(this);
    this.toggleOITGradeReportModal = this.toggleOITGradeReportModal.bind(this);
    this.downloadAllReports = this.downloadAllReports.bind(this);
    this.hideGradesAlphabeticalModal =
      this.hideGradesAlphabeticalModal.bind(this);
    this.openGradesAlphabeticalModal =
      this.openGradesAlphabeticalModal.bind(this);
    this.toggleDeansListModal = this.toggleDeansListModal.bind(this);
    this.togglePercentileReportModal =
      this.togglePercentileReportModal.bind(this);
  }

  async downloadAllReports(year) {
    try {
      // const url = `reports/allstoredreports?report=progressreport&studentid=all${year}s`;
      const url = `reports/progressreport?semester=${this.state.currentSemester}&format=pdf&year_in_program=${year}&merged=1`;
      const report = await api.get(url);

      const urls = report.data.url;
      const search = "merge";
      const index = urls.findIndex((el) => el.includes(search));

      window.open(report.data.url[index]);
    } catch (err) {
      console.error(err);
      this.setState({
        errorMessage: err.response?.data ? err.response.data : err.message,
        customMessage: `Error getting ${year}Ls; `,
      });
    }
  }

  toggleAllYearReportsModal() {
    this.setState({
      showAllYearReportModal: !this.state.showAllYearReportModal,
    });
  }
  openExamNumbersModal() {
    this.setState({
      showExamNumberModal: true,
      showViewExamNumberModal: false,
    });
  }

  hideExamNumbersModal() {
    this.setState({ showExamNumberModal: false });
  }

  handleClose() {
    this.setState({ showRelaseGradesModalSuccess: false });
  }

  openViewExamNumbersModal() {
    this.setState({
      showExamNumberModal: false,
      showViewExamNumberModal: true,
    });
  }

  hideViewExamNumbersModal() {
    this.setState({ showViewExamNumberModal: false });
  }

  openGraduateReportModal() {
    this.setState({ showGraduateReportModal: true });
  }

  hideGraduateReportModal() {
    this.setState({ showGraduateReportModal: false });
  }

  toggleUpdateFinalScheduleModal() {
    this.setState({
      showUpdateFinalScheduleModal: !this.state.showUpdateFinalScheduleModal,
    });
  }

  toggleReleaseGradesModal() {
    this.setState({
      showReleaseGradesModal: !this.state.showReleaseGradesModal,
    });
  }

  toggleOITGradeReportModal() {
    this.setState({
      showOITGradeReportModal: !this.state.showOITGradeReportModal,
    });
  }

  hideGradesAlphabeticalModal() {
    this.setState({ showGradesAlphabeticalModal: false });
  }

  openGradesAlphabeticalModal() {
    this.setState({ showGradesAlphabeticalModal: true });
  }

  toggleClassMeanReportModal() {
    this.setState({
      showClassMeanReportModal: !this.state.showClassMeanReportModal,
    });
  }

  handleSemesterSelect(e) {
    this.setState({
      currentSemester: e.target.value,
      currentSemesterDisplay:
        displaySemester(e.target.value)[1] +
        " " +
        displaySemester(e.target.value)[0],
    });
  }

  toggleDeansListModal() {
    this.setState({ showDeansListModal: !this.state.showDeansListModal });
  }

  togglePercentileReportModal() {
    this.setState({
      showPercentileReportModal: !this.state.showPercentileReportModal,
    });
  }

  async handleReleaseOneLGrades() {
    this.setState({ loading: true });
    try {
      const { data } = await api.post(
        `/grades/actions/one-l-release?semester=${this.state.currentSemester}`
      );
      this.setState({
        showRelaseGradesModalSuccess: true,
        apiResponse: data,
      });
    } catch (err) {
      console.log(err);
      this.setState({
        errorMessage: err.response?.data ? err.response.data : err.message,
        showReleaseGradesModal: true,
      });
    }
    this.setState({ loading: false });
  }

  async handleReleaseGrades() {
    this.setState({ loading: true });
    try {
      const { data } = await api.post(
        `/grades/actions/all-release?semester=${this.state.currentSemester}`
      );
      this.setState({
        showRelaseGradesModalSuccess: true,
        apiResponse: data,
      });
    } catch (err) {
      console.log(err);
      this.setState({
        errorMessage: err.response?.data ? err.response.data : err.message,
        showReleaseGradesModal: true,
      });
    }
    this.setState({ loading: false });
  }

  // var config = {
  //   method: 'post',
  //   url: 'http://localhost:3000/dev/grades/actions/one-l-release?semester=20215',
  //   headers: {
  //     'Username': 'someuser',
  //     'Authorization': 'Bearer test-registrar-2222222222@byulaw.org'
  //   }
  // };

  render() {
    return (
      <React.Fragment>
        <ReleaseGradesModal
          show={this.state.showReleaseGradesModal}
          toggleReleaseGradesModal={this.toggleReleaseGradesModal}
          errorMessage={this.state.errorMessage}
        />
        <Modal
          show={this.state.showRelaseGradesModalSuccess}
          onHide={this.handleClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>Grade Release Feedback</Modal.Title>
          </Modal.Header>
          <Modal.Body>{this.state.apiResponse}</Modal.Body>
        </Modal>
        <Container className="p-0 mt-4">
          {this.state.errorMessage && (
            <ErrorDisplay
              errorMessage={this.state.errorMessage}
              customMessage={this.state.customMessage}
            />
          )}
          <Row className="mt-3">
            <Col xs="12" md="6">
              <Card className="shadow mb-4">
                <Card.Header as="h5">Select Report:</Card.Header>
                <Card.Body>
                  <ul className="list-unstyled">
                    <li>
                      <FontAwesomeIcon
                        icon={faFilePdf}
                        style={{ color: "002e5d" }}
                      />{" "}
                      <Button
                        variant="link"
                        className="p-0 border-0"
                        onClick={this.openViewExamNumbersModal}
                      >
                        View Exam Numbers
                      </Button>
                    </li>
                    <li>
                      <FontAwesomeIcon
                        icon={faFilePdf}
                        style={{ color: "002e5d" }}
                      />{" "}
                      <Button
                        variant="link"
                        className="p-0 border-0"
                        onClick={this.openGraduateReportModal}
                      >
                        View Graduate Report
                      </Button>
                    </li>
                    <li>
                      <FontAwesomeIcon
                        icon={faFilePdf}
                        style={{ color: "002e5d" }}
                      />{" "}
                      <Button
                        variant="link"
                        className="p-0 border-0"
                        onClick={this.toggleAllYearReportsModal}
                      >
                        View all Progress Reports (1L, 2L or 3L)
                      </Button>
                    </li>

                    {/* New Code */}
                    <li>
                      <FontAwesomeIcon
                        icon={faFilePdf}
                        style={{ color: "002e5d" }}
                      />{" "}
                      <Button
                        variant="link"
                        className="p-0 border-0"
                        onClick={this.toggleClassMeanReportModal}
                      >
                        View Class Mean Report
                      </Button>
                    </li>
                    <li>
                      <FontAwesomeIcon
                        icon={faFilePdf}
                        style={{ color: "002e5d" }}
                      />{" "}
                      <Button
                        variant="link"
                        className="p-0 border-0"
                        onClick={this.openGradesAlphabeticalModal}
                      >
                        View Grades Report (Alphabetical)
                      </Button>
                    </li>
                    <li>
                      <FontAwesomeIcon
                        icon={faFilePdf}
                        style={{ color: "002e5d" }}
                      />{" "}
                      <Button
                        variant="link"
                        className="p-0 border-0"
                        onClick={this.toggleDeansListModal}
                      >
                        View Dean's List Report
                      </Button>
                    </li>
                    <li>
                      <FontAwesomeIcon
                        icon={faFilePdf}
                        style={{ color: "002e5d" }}
                      />{" "}
                      <Button
                        variant="link"
                        className="p-0 border-0"
                        onClick={this.togglePercentileReportModal}
                      >
                        View Percentile Report
                      </Button>
                    </li>
                    <li>
                      <FontAwesomeIcon
                        icon={faFilePdf}
                        style={{ color: "002e5d" }}
                      />{" "}
                      <Button
                        variant="link"
                        className="p-0 border-0"
                        onClick={this.toggleOITGradeReportModal}
                      >
                        View OIT Grade Report
                      </Button>
                    </li>
                    {/* End of New Code */}
                  </ul>
                </Card.Body>
              </Card>
            </Col>
            <Col xs="12" md="6">
              <Card className="shadow mb-4">
                <Card.Header as="h5">
                  Select Utility:{" "}
                  {this.state.loading ? (
                    <Spinner animation="border" size="sm" />
                  ) : (
                    ""
                  )}
                </Card.Header>
                <Card.Body>
                  <ul className="list-unstyled">
                    {(this.props.roles.admin || this.props.roles.registrar) && (
                      <>
                        <li>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            Semester Specific Utilties:
                            <Button
                              variant="link"
                              className="p-0 border-0"
                              style={{ marginLeft: 10, float: "right" }}
                            >
                              <Form.Select
                                aria-label="Default select example"
                                value={this.state.currentSemester}
                                onChange={this.handleSemesterSelect}
                                size="sm"
                              >
                                {Object.keys(
                                  this.state.semesterDropdownValueMap
                                ).map((value) => {
                                  return (
                                    <option
                                      value={
                                        this.state.semesterDropdownValueMap[
                                          value
                                        ]
                                      }
                                    >
                                      {displaySemester(
                                        this.state.semesterDropdownValueMap[
                                          value
                                        ]
                                      )[1] +
                                        " " +
                                        displaySemester(
                                          this.state.semesterDropdownValueMap[
                                            value
                                          ]
                                        )[0]}
                                    </option>
                                  );
                                })}
                              </Form.Select>
                              {"  "}
                            </Button>
                          </div>
                          <hr style={{ marginTop: 4 }}></hr>
                        </li>
                        <li>
                          <FontAwesomeIcon
                            icon={faClipboard}
                            style={{ color: "002e5d" }}
                          />{" "}
                          <Button
                            variant="link"
                            className="p-0 border-0"
                            onClick={this.handleReleaseOneLGrades}
                          >
                            Release 1L Grades for:{" "}
                            <strong>{this.state.currentSemesterDisplay}</strong>
                          </Button>
                        </li>
                        <li>
                          <FontAwesomeIcon
                            icon={faClipboard}
                            style={{ color: "002e5d" }}
                          />{" "}
                          <Button
                            variant="link"
                            className="p-0 border-0"
                            onClick={this.handleReleaseGrades}
                          >
                            Release All Grades for:{" "}
                            <strong>{this.state.currentSemesterDisplay}</strong>
                          </Button>
                        </li>
                        <li>
                          <FontAwesomeIcon
                            icon={faClipboard}
                            style={{ color: "002e5d" }}
                          />{" "}
                          <Button
                            variant="link"
                            className="p-0 border-0"
                            onClick={this.toggleUpdateFinalScheduleModal}
                          >
                            Update Final Exam Schedule for:{" "}
                            <strong>{this.state.currentSemesterDisplay}</strong>
                          </Button>
                        </li>
                        <li style={{ marginTop: 10 }}>
                          {" "}
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            Other Utilities:
                          </div>
                        </li>{" "}
                        <hr style={{ marginTop: 4 }}></hr>
                        {this.props.roles.admin && (
                          <li>
                            <FontAwesomeIcon
                              icon={faClipboard}
                              style={{ color: "002e5d" }}
                            />{" "}
                            <Button
                              variant="link"
                              className="p-0 border-0"
                              onClick={this.openExamNumbersModal}
                            >
                              Assign Exam Numbers
                            </Button>
                          </li>
                        )}
                        <li>
                          <FontAwesomeIcon
                            icon={faClipboard}
                            style={{ color: "002e5d" }}
                          />{" "}
                          <Link to="/mass-advance">
                            <Button variant="link" className="p-0 border-0">
                              Mass Advance
                            </Button>
                          </Link>
                        </li>
                        <li>
                          <FontAwesomeIcon
                            icon={faClipboard}
                            style={{ color: "002e5d" }}
                          />{" "}
                          <Link to="/mass-graduate">
                            <Button variant="link" className="p-0 border-0">
                              Mass Graduate
                            </Button>
                          </Link>
                        </li>
                      </>
                    )}
                  </ul>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          {this.props.roles.admin && (
            <AssignExamNumbersModal
              onHide={this.hideExamNumbersModal}
              show={this.state.showExamNumberModal}
            />
          )}
          <ViewExamNumberModal
            onHide={this.hideViewExamNumbersModal}
            show={this.state.showViewExamNumberModal}
          />
          <ViewGraduateReportModal
            onHide={this.hideGraduateReportModal}
            show={this.state.showGraduateReportModal}
          />
          <GradesAlphabeticalModal
            onHide={this.hideGradesAlphabeticalModal}
            show={this.state.showGradesAlphabeticalModal}
          />
          <PercentileReportModal
            onHide={this.togglePercentileReportModal}
            show={this.state.showPercentileReportModal}
          />
          <AllYearReportModal
            onHide={this.toggleAllYearReportsModal}
            show={this.state.showAllYearReportModal}
            downloadAllReports={this.downloadAllReports}
          />
          <OITGradeReportModal
            onHide={this.toggleOITGradeReportModal}
            show={this.state.showOITGradeReportModal}
          />
          <ClassMeanReportModal
            onHide={this.toggleClassMeanReportModal}
            show={this.state.showClassMeanReportModal}
          />
          <DeansListModal
            onHide={this.toggleDeansListModal}
            show={this.state.showDeansListModal}
          />
          <UpdateFinalExamScheduleModal
            onHide={this.toggleUpdateFinalScheduleModal}
            show={this.state.showUpdateFinalScheduleModal}
            currentSemester={this.state.currentSemester}
            currentSemesterDisplay={this.state.currentSemesterDisplay}
          />
        </Container>
      </React.Fragment>
    );
  }
}

export default withRouter(GlobalRepUtil);
