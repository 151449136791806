import React from "react";
import { Modal, Button, Table } from "react-bootstrap";

import ErrorDisplay from "../../ErrorDisplay";

class ForceGraduationModal extends React.Component {
  render() {
    let { graduateStudent, show, toggleForceGradModal, incompleteGradInfo } =
      this.props;
    return (
      <Modal show={show} onHide={toggleForceGradModal}>
        <Modal.Header closeButton>
          <Modal.Title>Requirements Not Met</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table bordered>
            <thead>
              <tr>
                <th>Requirements</th>
                <th> Value </th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(incompleteGradInfo).map((key, i) => (
                <tr key={i}>
                  <td>
                    {key.charAt(0).toUpperCase() +
                      key.replaceAll("_", " ").slice(1)}
                  </td>
                  <td>
                    {String(incompleteGradInfo[key]) === "true" ? (
                      <strong style={{ color: "#228c22" }}>&#10003;</strong>
                    ) : String(incompleteGradInfo[key]) === "false" ? (
                      <strong style={{ color: "#bb1919" }}>X</strong>
                    ) : (
                      String(incompleteGradInfo[key])
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <strong>Are you sure you want to graduate this student?</strong> This
          student hasn't met all of the requirements to graduate. If you would
          like to force graduate, press the "Force Graduate" button, otherwise
          click "X" at the top.
          <br />
          <Button id="forceGraduate" variant="info" onClick={graduateStudent}>
            Force Graduate
          </Button>
        </Modal.Body>
        {this.props.errorMessage && this.props.showGradErrorMessage && (
          <ErrorDisplay errorMessage={this.props.errorMessage} />
        )}
      </Modal>
    );
  }
}

export default ForceGraduationModal;
