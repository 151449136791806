import React from "react";
import {
  Container,
  Row,
  Col,
  Spinner,
  Button,
  Card,
  Table,
  Form,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import api from "../../../services/api";
import ErrorDisplay from "../../ErrorDisplay";
import History from "../../History";
import { DateTime } from "luxon";
import axios from "axios";
import GradeSubmission from "./GradeSubmission";
import ConfirmDeleteModal from "../../ConfirmDeleteModal";
import parser from "html-react-parser";
import ToolButton from "../../tools/ToolButton.jsx";
import { generateCSV } from "../../../utils/functions.js";

export default class CourseDirectoryCourseDisp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      axiosCancelSource: false,
      loading: false,
      courseListLoading: false,
      courseData: this.props.selectedCourseData,
      editableCourseData: {},
      errorMessage: null,
      edit: false,
      gradeSubmissionDisplay: this.props.gradeSubmissionDisplay,
      isInstructorEdited: false,
      showDeleteCourseModal: false,
      remote: 1,
      searchInstructor: null,
    };
    this.courses = this.state.courseData;
    this.changeEdit = this.changeEdit.bind(this);
    this.getCourseData = this.getCourseData.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.toggleHistory = this.toggleHistory.bind(this);
    this.onGradeDisplay = this.onGradeDisplay.bind(this);
    this.addNewInstructor = this.addNewInstructor.bind(this);
    this.deleteInstructor = this.deleteInstructor.bind(this);
    this.handleInstructorChange = this.handleInstructorChange.bind(this);
    this.getInstructorName = this.getInstructorName.bind(this);
    this.updateCourseURL = this.updateCourseURL.bind(this);
    this.toggleDeleteCourseModal = this.toggleDeleteCourseModal.bind(this);
    this.handleDeleteCourse = this.handleDeleteCourse.bind(this);
    this.updateCourseData = this.updateCourseData.bind(this);
    this.downloadCourseList = this.downloadCourseList.bind(this);
    this._isMounted = false;
  }

  async componentDidMount() {
    this._isMounted = true;
    let pathArray = window.location.pathname.split("/");

    if (pathArray.length > 5) {
      if (pathArray[pathArray.length - 1] === "GradeSubmission") {
        this.setState({
          gradeSubmissionDisplay: true,
        });
        await this.getCourseData(
          pathArray[pathArray.length - 4],
          pathArray[pathArray.length - 3],
          pathArray[pathArray.length - 2]
        );
      }
    } else if (pathArray.length > 2) {
      this.updateCourseURL(
        pathArray[pathArray.length - 3],
        pathArray[pathArray.length - 2],
        pathArray[pathArray.length - 1]
      );
      await this.getCourseData(
        pathArray[pathArray.length - 3],
        pathArray[pathArray.length - 2],
        pathArray[pathArray.length - 1]
      );
    } else {
      this.updateCourseURL(
        this.state.courseData.semester,
        this.state.courseData.catalog_number,
        this.state.courseData.section_number
      );
    }

    if (this.state.courseData.remote === 1) {
      this._isMounted && this.setState({ remote: 1 });
    } else {
      this._isMounted && this.setState({ remote: 0 });
    }
  }

  /**
   * Unmounts the component and cancels any ongoing Axios requests.
   *
   * @param {none}
   * @return {void}
   */
  componentWillUnmount() {
    this._isMounted = false;
    if (this.state.axiosCancelSource) {
      this.state.axiosCancelSource.cancel();
    }
  }

  /**
   * Updates the course URL in the slug based on the provided semester, catalog, and section.
   *
   * @param {string} semester - The semester of the course.
   * @param {string} catalog - The catalog of the course.
   * @param {string} section - The section of the course.
   * @return {void} This function does not return a value.
   */
  updateCourseURL(semester, catalog, section) {
    const nextURL =
      window.location.protocol +
      "//" +
      window.location.host +
      "/" +
      "course-directory" +
      "/" +
      semester +
      "/" +
      catalog +
      "/" +
      section;
    const nextTitle = document.title;

    window.history.replaceState({}, nextTitle, nextURL);
  }

  /**
   * Toggles the edit mode and enables or disables the directory page navigation based on the edit mode state.
   *
   * @return {void} 
   */
  changeEdit() {
    if (this.state.edit) {
      this.props.enableDirectoryPageNav();
    } else {
      this.props.disableDirectoryPageNav();
    }
    this.setState({
      edit: !this.state.edit,
      editableCourseData: JSON.parse(JSON.stringify(this.state.courseData)),
      showHistory: false,
    });
  }

  /**
   * Toggles the history display.
   *
   * @return {void} 
   */
  toggleHistory() {
    this.setState({
      showHistory: !this.state.showHistory,
    });
  }

  /**
   * Toggles the display of the grade submission.
   *
   * @return {void} 
   */
  onGradeDisplay() {
    this.setState({
      gradeSubmissionDisplay: !this.state.gradeSubmissionDisplay,
    });

    let pathArray = window.location.pathname.split("/");
    this.getCourseData(pathArray[2], pathArray[3], pathArray[4]);
    this.updateCourseURL(pathArray[2], pathArray[3], pathArray[4]);
  }

  /**
   * Handles the change event for the input fields when in edit mode.
   *
   * @param {object} e - the event object
   * @return {void} - does not return anything
   */
  handleChange(e) {
    const { id, value } = e.target;
    const course = this.state.editableCourseData;
    switch (id) {
      case "catalog-title":
        course.catalog_title = value;
        break;
      case "description":
        course.description = value;
        break;
      case "grade-requirements":
        course.grade_requirements = value;
        break;
      case "grade-status":
        course.grade_status = value;
        break;
      case "notes":
        course.notes = value;
        break;
      case "remote":
        course.remote = e.target.checked === true ? true : false;
        break;
      default:
        break;
    }

    this.setState({
      editableCourseData: course,
    });

    this.updateCourseURL(
      this.state.courseData.semester,
      this.state.courseData.catalog_number,
      this.state.courseData.section_number
    );
  }

  /**
   * Saves the course data to the server and reloads the page with the new data.
   *
   * @param {object} e - The event object for the form submission.
   * @return {void} This function does not return anything.
   */
  async handleSubmit(e) {
    e.preventDefault();
    e.stopPropagation();
    try {
      this._isMounted && this.setState({ errorMessage: null, loading: true });
      let { data: course } = await api.post(
        `/courses/${this.state.courseData.semester}/${this.state.courseData.catalog_number}/${this.state.courseData.section_number}`,
        this.state.editableCourseData
      );
      this._isMounted &&
        this.setState({
          errorMessage: null,
          loading: false,
          courseData: course,
          showHistory: false,
        });

      await this.props.populateNewData(this.props.lastFilters);
      this.props.updateSelectedCourse(this.state.courseData);

      this.changeEdit();
    } catch (err) {
      console.error(err);
      this._isMounted &&
        this.setState({
          errorMessage: err.response?.data ? err.response.data : err.message,
          loading: false,
          showHistory: false,
        });
    }
  }

  /**
   * Retrieves course data for a specific semester, catalog, and section.
   *
   * @param {string} semester - The semester of the course.
   * @param {string} catalog - The catalog number of the course.
   * @param {string} section - The section number of the course.
   * @return {Promise} A promise that resolves to the course data.
   */
  async getCourseData(semester, catalog, section) {
    try {
      this._isMounted && this.setState({ errorMessage: null, loading: true });
      const { data: course } = await api.get(
        `/courses/directory?semester=${semester}&catalog_number=${catalog}&section_number=${section}`
      );
      this._isMounted &&
        this.setState({
          editableCourseData: JSON.parse(JSON.stringify(course[0])),
          courseData: course[0],
          loading: false,
        });
    } catch (err) {
      console.error("Error: ", err);
      this._isMounted &&
        this.setState({
          errorMessage: err.response?.data ? err.response.data : err.message,
          loading: false,
        });
    }
  }

  /**
   * Adds a new instructor to the editable course data in the state.
   *
   * @return {void} - does not return anything
   */
  addNewInstructor() {
    const { editableCourseData } = this.state;
    editableCourseData.instructors?.push({
      id: "",
      first_name: "",
      last_name: "",
    });

    this.setState({ editableCourseData, searchInstructor: undefined });
  }

  /**
   * Deletes an instructor from the editable course data in the state.
   *
   * @param {object} e - The event object.
   * @return {void} This function does not return anything.
   */
  deleteInstructor(e) {
    let { id: index } = e.target;
    let { editableCourseData } = this.state;
    index = Number(index.split("-")[0]);
    if (editableCourseData.instructors.length === 1) {
      editableCourseData.instructors = [];
    } else {
      editableCourseData.instructors.splice(index, 1);
    }
    this.setState({ editableCourseData, isInstructorEdited: true });
  }

  /**
   * Retrieves the instructor's name based on the given index and instructor ID.
   *
   * @param {number} index - The index of the instructor in the editableCourseData.instructors array.
   * @param {string} instructorID - The ID of the instructor.
   * @return {Promise<void>} A Promise that resolves when the instructor's name is retrieved.
   */
  async getInstructorName(index, instructorID) {
    if (this.state.axiosCancelSource) this.state.axiosCancelSource.cancel();
    const cancelSource = axios.CancelToken.source();
    this._isMounted && this.setState({ axiosCancelSource: cancelSource });
    const cancelToken = cancelSource.token;

    let editableCourseData = this.state.editableCourseData;

    if (instructorID?.match(/^\d{9}$/)) {
      try {
        const { data: instructorReceived } = await api.get(
          `/person/${instructorID}`,
          { cancelToken }
        );
        if (instructorReceived && instructorReceived.last_name) {
          editableCourseData.instructors[index].last_name =
            instructorReceived.last_name;
          editableCourseData.instructors[index].first_name =
            instructorReceived?.first_name;
        } else {
          editableCourseData.instructors[index].last_name = "Can't Find Prof";
        }
        this._isMounted &&
          this.setState({ editableCourseData, errorMessage: null });
      } catch (err) {
        if (!axios.isCancel(err)) {
          if (err.response.status === 404) {
            editableCourseData.instructors[index].last_name = "Can't Find Prof";
            this._isMounted && this.setState({ editableCourseData });
          } else {
            console.error("error", err);
            this._isMounted &&
              this.setState({
                errorMessage: err.response?.data
                  ? err.response.data
                  : err.message,
              });
          }
        }
      }
    }
  }

  /**
   * Handles the change event for the instructor input field.
   *
   * @param {object} e - The event object
   * @return {void}
   */
  handleInstructorChange(e) {
    let { id, value } = e.target;
    if (e.keyCode === 8) {
      this.setState({ searchInstructor: value });
    }
    let field = "";
    let { editableCourseData } = this.state;
    [id, field] = id.split("-");
    const index = Number(id);
    this.setState({ searchInstructor: value });

    if (value.length === 9) {
      this.getInstructorName(index, value);
      editableCourseData.instructors[index].id = value;
      this.setState({ editableCourseData, isInstructorEdited: true });
    }
  }

  editedInstructorTooltip = (props) => (
    <Tooltip id="instructor-tooltip" {...props}>
      Changes will NOT be saved unless the "Save Changes" button is clicked.
    </Tooltip>
  );

  toggleDeleteCourseModal() {
    this.setState({ showDeleteCourseModal: !this.state.showDeleteCourseModal });
  }

  /**
   * Updates the course data in the state.
   *
   * @return {void} No return value.
   */
  updateCourseData() {
    this.setState({ courseData: this.props.selectedCourseData });
  }

/**
 * Deletes a course from the API.
 *
 * @return {void} No return value.
 */
  async handleDeleteCourse() {
    try {
      await api.delete(
        `/courses/${this.state.courseData.semester}/${this.state.courseData.catalog_number}/${this.state.courseData.section_number}`
      );
    } catch (err) {
      if (!axios.isCancel(err)) {
        console.error(err);
        this._isMounted &&
          this.setState({
            errorMessage: err.response?.data ? err.response.data : err.message,
          });
      }
    }
    window.location.assign(window.location.origin + "/course-directory");
  }

  async getStudentData() {
    const { courseData } = this.state;

    try {
      const { data: studentData } = await api.get(
        `/grades/${courseData.semester}/${courseData.catalog_number}/${courseData.section_number}`
      );
      return studentData.map((student) => {
        return {
          name: `${student.last_name}, ${student.first_name}`,
          id: student.id,
          exam_number: student.exam_number,
        };
      });
    } catch (err) {
      if (!axios.isCancel(err)) {
        console.error(err);
        this.setState({
          errorMessageArray: [
            ...this.state.errorMessageArray,
            {
              errorMessage: err.response?.data
                ? err.response.data
                : err.message,
            },
          ],
        });
      }
    }
  }

  async downloadCourseList() {
    this.setState({ courseListLoading: true });
    const studentData = await this.getStudentData();
    const { courseData } = this.state;
    generateCSV(
      studentData,
      `course-list-${courseData.semester}-${courseData.catalog_number}-${courseData.section_number}.csv`
    );
    this.setState({ courseListLoading: false });
  }

  render() {
    if (this.state.loading) {
      return (
        <div className="directoryLoading">
          <h1
            className="loadingText"
            style={{ marginBottom: "1em", marginLeft: "0.5em" }}
          >
            Loading Course Data...
          </h1>
          <Spinner animation={"border"} />
        </div>
      );
    }
    if (this.state.errorMessage) {
      return (
        <React.Fragment>
          <Row className="justify-content-center">
            <Col xs={"auto"}>
              <ErrorDisplay errorMessage={this.state.errorMessage} />
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col xs={"auto"}>
              <Button
                style={{ margin: "auto" }}
                onClick={this.props.handleCancel}
                //onClick={}
                className="shadow"
              >
                Return to Directory
              </Button>
            </Col>
          </Row>
        </React.Fragment>
      );
    }
    let course = this.state.courseData;
    const edit = this.state.edit;
    let editableCourseData = this.state.editableCourseData;

    if (!this.state.gradeSubmissionDisplay) {
      return (
        <Container>
          <Form onSubmit={this.handleSubmit}>
            <Row className="justify-content-center no-margin">
              <Col xs={"auto"} className="my-4">
                {(this.props.roles.admin || this.props.roles.registrar) &&
                  (!edit ? (
                    <Button
                      className="me-4 shadow-button"
                      onClick={this.changeEdit}
                    >
                      Edit
                    </Button>
                  ) : (
                    <Button
                      className="me-4 shadow-button"
                      onClick={this.changeEdit}
                    >
                      Cancel
                    </Button>
                  ))}
                <Button
                  style={{ margin: "auto" }}
                  onClick={async () => {
                    await this.props.handlePrev();
                    this.updateCourseData();
                    this.updateCourseURL(
                      this.state.courseData.semester,
                      this.state.courseData.catalog_number,
                      this.state.courseData.section_number
                    );
                  }}
                  className="me-4 shadow-button"
                  disabled={this.state.edit}
                >
                  ← Prev Course{" "}
                </Button>
                <Button
                  style={{ margin: "auto" }}
                  onClick={async () => {
                    await this.props.handleNext();
                    this.updateCourseData();
                    this.updateCourseURL(
                      this.state.courseData.semester,
                      this.state.courseData.catalog_number,
                      this.state.courseData.section_number
                    );
                  }}
                  className="me-4 shadow-button"
                  disabled={this.state.edit}
                >
                  Next Course →
                </Button>
                <Button
                  style={{ margin: "auto" }}
                  onClick={this.props.handleCancel}
                  className="shadow-button"
                  disabled={this.state.edit}
                >
                  Return to Directory
                </Button>
              </Col>
            </Row>
            <Row className="mb-0">
              <Col xs={12} lg={6} xl={3} className="mb-4">
                <Card className="shadow fill-parent">
                  <Card.Header as="h5">Course Information</Card.Header>
                  <Card.Body>
                    <p className="m-0 mb-2">
                      <strong>Course Title: </strong>
                      {this.state.edit ? (
                        <Form.Control
                          id="catalog-title"
                          value={editableCourseData.catalog_title}
                          placeholder="Catalog Title"
                          onChange={this.handleChange}
                        />
                      ) : (
                        course.catalog_title
                      )}
                    </p>
                    <p className="m-0 mb-2">
                      <strong>Semester/Term: </strong> {course.semester}
                    </p>
                    <p className="m-0 mb-2">
                      <strong>Catalog Number: </strong> {course.catalog_number}
                    </p>
                    <p className="m-0 mb-2">
                      <strong>Section Number: </strong> {course.section_number}
                    </p>
                    <p className="m-0 mb-2">
                      <strong>Credit Hours: </strong> {course.credit_hours}
                    </p>

                    <p className="m-0">
                      <strong>Professor(s):</strong>
                    </p>
                    <ul className="list-unstyled">
                      {!edit &&
                      (course.instructors.length === undefined ||
                        course.instructors.length < 1) ? (
                        <li>No instructors found</li>
                      ) : (
                        course.instructors.map(
                          (item) =>
                            !edit &&
                            item?.last_name !== "[object Object]" && (
                              <li key={item?.last_name + item?.first_name}>
                                {"- " +
                                  item?.last_name +
                                  (item?.first_name
                                    ? ", " + item?.first_name
                                    : "")}
                              </li>
                            )
                        )
                      )}
                      {edit && (
                        <OverlayTrigger
                          delay={{ show: 250, hide: 400 }}
                          placement={"top"}
                          overlay={this.editedInstructorTooltip}
                        >
                          <div>
                            {editableCourseData.instructors?.map(
                              (item, index) =>
                                (this.props.roles.admin ||
                                  this.props.roles.registrar) &&
                                edit && (
                                  <Row key={item.id + "row"}>
                                    <Col
                                      className="col-5 ps-3 pe-1 mt-1 mb-1"
                                      key={item.id + "col"}
                                    >
                                      <Form.Control
                                        id={`${index}-id`}
                                        type="number"
                                        size="sm"
                                        className="mb-1"
                                        value={
                                          item.last_name !== "" &&
                                          item.last_name !== "Can't Find Prof"
                                            ? item?.id
                                            : this.state.searchInstructor
                                        }
                                        key={item.id + "form"}
                                        placeholder="BYU ID (9)"
                                        onChange={this.handleInstructorChange}
                                        disabled={
                                          editableCourseData.instructors[index]
                                            ?.last_name !== "" &&
                                          editableCourseData.instructors[index]
                                            ?.last_name !== "Can't Find Prof"
                                        }
                                      />
                                    </Col>
                                    <Col
                                      className="col-5 px-0 pe-1 mt-1 mb-1"
                                      key={item.id + "col2"}
                                    >
                                      <Form.Control
                                        id={`${index}-last_name`}
                                        size="sm"
                                        className="mb-1"
                                        value={item?.last_name}
                                        key={item.last_name + "form"}
                                        placeholder="Last Name"
                                        onChange={this.handleInstructorChange}
                                        disabled={true}
                                      />
                                    </Col>
                                    <Col
                                      className="col-2 px-0 mt-1 mb-1"
                                      key={item.id + "col3"}
                                    >
                                      <Button
                                        id={`${index}-delete`}
                                        size="sm"
                                        key={item.id + "delete"}
                                        variant="outline-danger"
                                        onClick={this.deleteInstructor}
                                      >
                                        X
                                      </Button>
                                    </Col>
                                  </Row>
                                )
                            )}
                          </div>
                        </OverlayTrigger>
                      )}
                      {(this.props.roles.admin || this.props.roles.registrar) &&
                        (edit ? (
                          <Row>
                            <Col>
                              <Button
                                size="sm"
                                variant="outline-primary"
                                onClick={this.addNewInstructor}
                              >
                                Add
                              </Button>
                            </Col>
                          </Row>
                        ) : (
                          ""
                        ))}
                    </ul>

                    <p className="m-0 mb-2">
                      <strong>Grading Type: </strong>
                      {this.state.edit ? (
                        <Form.Select
                          id="grade-requirements"
                          value={editableCourseData.grade_requirements}
                          onChange={this.handleChange}
                        >
                          <option>1L Advocacy Class</option>
                          <option>1L Non Advocacy Class</option>
                          <option>
                            2L and 3L Non Paper Based Class Under 25 Students
                          </option>
                          <option>2L and 3L Class Over 25 Students</option>
                          <option>
                            2L and 3L Paper Based Class Under 25 Students
                          </option>
                          <option>Pass/Fail Course</option>
                          <option>None</option>
                        </Form.Select>
                      ) : (
                        course.grade_requirements
                      )}
                    </p>

                    <p className="m-0 mb-2">
                      <strong>Grade Status: </strong>

                      {this.state.edit ? (
                        course.grade_status === null ||
                        course.grade_status === "registrar" ||
                        course.grade_status === "approved" ? (
                          <Form.Select
                            id="grade-status"
                            value={editableCourseData.grade_status}
                            onChange={this.handleChange}
                          >
                            <option value="registrar">registrar</option>
                            <option value="approved">approved</option>
                            <option value="rejected">rejected</option>
                          </Form.Select>
                        ) : (
                          course.grade_status
                        )
                      ) : (
                        course.grade_status
                      )}
                    </p>
                  </Card.Body>
                </Card>
              </Col>
              <Col xs={12} lg={6} xl={3} className="mb-4">
                <Card className="shadow fill-parent">
                  <Card.Header as="h5">Description</Card.Header>
                  <Card.Body>
                    {this.state.edit ? (
                      <Form.Control
                        as="textarea"
                        rows={10}
                        id="description"
                        value={editableCourseData.description}
                        placeholder="Description"
                        onChange={this.handleChange}
                      />
                    ) : (
                      <p className="m-0 mb-2">
                        {course.description
                          ? course.description
                          : "No description found..."}
                      </p>
                    )}
                  </Card.Body>
                </Card>
              </Col>
              <Col xs={12} lg={6} xl={3} className="mb-4">
                <Card className="shadow fill-parent">
                  <Card.Header as="h5">Schedule</Card.Header>
                  <Card.Body>
                    <Table size="sm" responsive>
                      <thead>
                        <tr>
                          <th>Day</th>
                          <th>Time</th>
                          <th>Room</th>
                        </tr>
                      </thead>
                      <tbody>
                        {course.schedule.map((item) => (
                          <tr key={item.day + item.room + item.start + "tr"}>
                            <td key={item.day + item.room + item.start + "td1"}>
                              {item.day}
                            </td>
                            <td key={item.day + item.room + item.start + "td2"}>
                              {DateTime.fromFormat(
                                item.start,
                                "HH:mm:ss"
                              ).toFormat("h:mm a") +
                                "-" +
                                DateTime.fromFormat(
                                  item.end,
                                  "HH:mm:ss"
                                ).toFormat("h:mm a")}
                            </td>
                            <td key={item.day + item.room + item.start + "td3"}>
                              {item.room}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <Row>
                      <Col>
                        <p className="m-0">
                          <strong>Enrollment</strong>
                        </p>
                        <p className="m-0">Current: {course.current_seating}</p>
                        <p className="m-0 ">Max: {course.seating_capacity}</p>
                      </Col>
                      {this.state.edit ? (
                        <Col>
                          {" "}
                          <strong>Remote Class</strong>
                          <div className="d-flex align-items-center">
                            <Form.Check
                              type="switch"
                              id="remote"
                              checked={editableCourseData.remote}
                              onChange={this.handleChange}
                            />
                            {editableCourseData.remote ? "Yes" : "No"}
                          </div>
                        </Col>
                      ) : (
                        <Col>
                          {" "}
                          <strong>Remote Class</strong>
                          <div className="d-flex align-items-center">
                            <Form.Check
                              type="switch"
                              id="remote"
                              disabled={true}
                              checked={this.state.remote}
                              onChange={this.handleChange}
                            />
                            {course.remote ? "Yes" : "No"}
                          </div>
                        </Col>
                      )}
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
              <Col xs={12} lg={6} xl={3} className="mb-4">
                <Card className="shadow fill-parent">
                  <Card.Header as="h5">Misc. Info</Card.Header>
                  <Card.Body>
                    <p className="m-0 mb-2">
                      <strong>Short Title: </strong>
                      {course.short_title}
                    </p>
                    <p className="m-0 mb-2">
                      <strong>Curriculum ID: </strong>
                      {course.curriculum_id}
                    </p>
                    <p className="m-0 mb-2">
                      <strong>Type: </strong>
                      {course.class_schedule_block_type}
                    </p>
                    <p className="m-0 mb-2">
                      <strong>Title Code: </strong>
                      {course.title_code}
                    </p>
                    <p className="m-0 mb-2">
                      <strong>Variable Hours: </strong>
                      {course.text_for_blank_credit_hour}
                    </p>
                    <p className="m-0">
                      <strong>Notes: </strong>
                      {this.state.edit ? (
                        <Form.Control
                          as="textarea"
                          rows={10}
                          id="notes"
                          value={editableCourseData.notes}
                          placeholder="Notes"
                          onChange={this.handleChange}
                        />
                      ) : (
                        parser(course.notes)
                      )}
                    </p>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            {this.state.edit && (
              <Row>
                <Col className="d-flex justify-content-start mr-4" xs={5}>
                  <Button
                    className="mb-4 shadow-button"
                    style={{ marginRight: ".5rem" }}
                    onClick={this.toggleHistory}
                  >
                    {this.state.showHistory ? "Hide History" : "Show History"}
                  </Button>
                  <Button
                    className="mb-4 shadow-button"
                    variant="danger"
                    onClick={this.toggleDeleteCourseModal}
                  >
                    {" "}
                    Delete Course{" "}
                  </Button>
                </Col>
                <Col
                  className="d-flex justify-content-end mr-4"
                  xs={{ span: 3, offset: 4 }}
                >
                  <Button
                    className="mb-4 shadow-button"
                    style={{ marginRight: ".5rem" }}
                    variant="outline-info"
                    id="cancel-button"
                    onClick={this.changeEdit}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="mb-4 shadow-button"
                    variant="info"
                    type="submit"
                  >
                    Save Changes
                  </Button>
                </Col>
              </Row>
            )}
            {(this.props.roles.registrar || this.props.roles.admin) &&
              !this.state.edit && (
                <React.Fragment>
                  <Row>
                    <Col xs={12} md={6} className="mb-4">
                      <Card className="shadow fill-parent">
                        <Card.Header as="h5">Reports</Card.Header>
                        <Card.Body>
                          <Row>
                            <ToolButton
                              text="Course Information"
                              className="border-secondary mb-1"
                              disabled={true}
                              tooltipText="Under Construction"
                            />
                          </Row>
                          <Row>
                            <ToolButton
                              text="Course Enrollment"
                              className="border-secondary mb-1"
                              disabled={true}
                              tooltipText="Under Construction"
                            />
                          </Row>
                          <Row>
                            <ToolButton
                              text="Course Enrollment and Median Cumulative GPA"
                              className="border-secondary"
                              disabled={true}
                              tooltipText="Under Construction"
                            />
                          </Row>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col xs={12} md={6} className="mb-4">
                      <Card className="shadow fill-parent">
                        <Card.Header as="h5">Utilities</Card.Header>
                        <Card.Body>
                          <Row>
                            <ToolButton
                              text="Grade Submission"
                              icon="link"
                              onClick={this.onGradeDisplay}
                              className="border-secondary mb-1"
                            />
                          </Row>
                          <Row>
                            <ToolButton
                              text="Download Course List"
                              icon="utility"
                              onClick={this.downloadCourseList}
                              className="border-secondary mb-1"
                              loading={this.state.courseListLoading}
                              tooltipText="Loading..."
                            />
                          </Row>
                          <Row>
                            <ToolButton
                              text="Create Canvas Course File"
                              className="border-secondary"
                              disabled={true}
                              tooltipText="Under Construction"
                            />
                          </Row>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                </React.Fragment>
              )}
            {this.state.showHistory && (
              <Row>
                <Col xs={12} className="mb-4">
                  <Card className="shadow">
                    <Card.Header as="h5">History</Card.Header>
                    <Card.Body style={{ height: "90vh" }}>
                      <History
                        apis={[
                          `/courses/directory?semester=${this.state.courseData.semester}&catalog_number=${this.state.courseData.catalog_number}&section_number=${this.state.courseData.section_number}`,
                        ]}
                        onRestore={() => {
                          this.getCourseData(
                            this.state.courseData.semester,
                            this.state.courseData.catalog_number,
                            this.state.courseData.section_number
                          );
                          this.changeEdit();
                        }}
                        enableHighlight={[true]}
                        displayNames={["Course Data"]}
                      />
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            )}
            {this.props.roles.faculty && this.state.courseData.can_edit && (
              <Row className="justify-content-center">
                <Card
                  className="shadow fill-parent p-0 mb-4"
                  style={{ maxWidth: "25rem" }}
                >
                  <Card.Header as="h5">Utilities</Card.Header>
                  <Card.Body>
                    <Col className="tools-card">
                      <Row>
                        <ToolButton
                          text="Course Grades"
                          icon="link"
                          id="grade-submission"
                          onClick={this.onGradeDisplay}
                        />
                      </Row>
                      <Row>
                        <ToolButton
                          text="Download Course List"
                          icon="utility"
                          onClick={this.downloadCourseList}
                          loading={this.state.courseListLoading}
                          tooltipText="Loading..."
                        />
                      </Row>
                    </Col>
                  </Card.Body>
                </Card>
              </Row>
            )}
          </Form>

          <ConfirmDeleteModal
            show={this.state.showDeleteCourseModal}
            onHide={this.toggleDeleteCourseModal}
            deleteRole={this.handleDeleteCourse}
            message={
              "This will permanently delete the section of this course for this semester. Are you sure you want to proceed?"
            }
          />
        </Container>
      );
    } else if (this.state.gradeSubmissionDisplay) {
      return (
        <Container>
          <GradeSubmission
            course={this.state.courseData}
            gradeSubmissionDisplay={this.state.gradeSubmissionDisplay}
            onGradeDisplay={this.onGradeDisplay}
            roles={this.props.roles}
            handleReturnToDirectory={this.props.handleCancel}
            display={this.state.gradeSubmissionDisplay}
          />
        </Container>
      );
    }
  }
}
