import React from "react";
import {
  Button,
  Form,
  Modal,
  Spinner,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import api from "../../services/api";

class AllYearReportModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      year: 0,
      loading: false,
    };
    this.selectYear = this.selectYear.bind(this);
    this.downloadAllReports = this.downloadAllReports.bind(this);
  }

  selectYear(e) {
    this.setState({ year: e.target.value });
  }

  async downloadAllReports() {
    this.setState({ loading: true });
    await this.props.downloadAllReports(this.state.year);
    this.setState({ loading: false });
    this.props.onHide();
  }

  render() {
    let { show, onHide } = this.props;
    return (
      <Modal show={show} onHide={this.onHide}>
        <Modal.Header>
          <Modal.Title>All Year Reports</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Please select the year of the reports you wish to view</p>
          <Form.Select
            id="year"
            onChange={this.selectYear}
            disabled={this.state.loading === true}
          >
            <option value={0}>Select Year</option>
            <option value={1}>1L</option>
            <option value={2}>2L</option>
            <option value={3}>3L</option>
          </Form.Select>
        </Modal.Body>
        <Modal.Footer>
          {this.state.loading ? (
            <Button variant="info" disabled={true}>
              <Spinner
                animation={"border"}
                size={"sm"}
                style={{ marginRight: ".5rem" }}
              />
              View
            </Button>
          ) : (
            <OverlayTrigger
              placement="left"
              overlay={<Tooltip>This may take several seconds.</Tooltip>}
            >
              <Button
                variant="info"
                disabled={this.state.year == 0}
                onClick={this.downloadAllReports}
              >
                View
              </Button>
            </OverlayTrigger>
          )}
          <Button variant="outline-info" onClick={onHide}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default AllYearReportModal;
