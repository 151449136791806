import React from "react";
import Directory from "../components/directories/Directory";
import { withRouter } from "react-router-dom";
import TableHeader from "../components/directories/TableHeader";

class CourseDirectory extends React.Component {
  render() {
    return <Directory fromCourse {...this.props} />;
  }
}

export default withRouter(CourseDirectory);
