import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import DeletedRecords from "../components/DeletedRecords";
import ToolsNav from "../components/ToolsNav";

class DeletedFaculty extends React.Component {
  render() {
    return (
      <>
        <ToolsNav currentTab="admin-tools" roles={this.props.roles} isLink={true} />
        <Container className="bg-white">
          <Row>
            <Col xs="12" className="mt-4">
              <DeletedRecords
                api="/faculty/directory"
                postApi="/faculty/create"
                title="Deleted Faculty"
                redirectURL="/faculty-directory/"
              />
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default DeletedFaculty;
