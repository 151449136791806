import { faSort } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import {
  Button,
  Card,
  Table,
} from "react-bootstrap";
import api from "../../services/api";
import CourseStatsModal from "./course_directory/CourseStatsModal";
import TextOnlyRow from "./TextOnlyRow";

class TextOnly extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSet: this.props.dataSet,
      courseStats: {},
      showCourseStats: false,
      errorMessage: null,
    };
    this.getCourseStats = this.getCourseStats.bind(this);
    this.toggleCourseStatsModal = this.toggleCourseStatsModal.bind(this);
    this.submitStatus = this.submitStatus.bind(this);
    this.getImpersonateField = this.getImpersonateField.bind(this);
  }

  /**
   * Toggles the visibility of the course stats modal.
   *
   */
  toggleCourseStatsModal() {
    this.setState({ showCourseStats: !this.state.showCourseStats });
  }

  /**
   * Submits the status of an element.
   *
   * @param {Object} element - The element to submit the status for.
   * @param {string} status - The status to submit.
   * @return {Promise} A promise that resolves with the course statistics data.
   */
  async submitStatus(element, status) {
    let course = element;
    course.grade_status = status;
    try {
      const { data: courseStats } = await api.post(
        `/courses/${element.semester}/${element.catalog_number}/${element.section_number}`,
        course
      );
      this.setState({ dataSet: courseStats });
      this.props.handleEditStatus(element);
    } catch (err) {
      this.setState({
        errorMessage: err.response?.data ? err.response.data : err.message,
      });
    }
  }

  /**
   * Asynchronously retrieves the course data and tests the grade requirements for the given element.
   *
   * @param {element} element - The element to retrieve course statistics for.
   * @return {Promise<void>} - A promise that resolves when the course statistics are retrieved.
   */
  async getCourseStats(element) {
    this.setState({ errorMessage: null, courseStats: {} });
    this.props.handleLoadingStatus(element);
    try {
      const { data: courseData } = await api.get(
        `/grades/${element.semester}/${element.catalog_number}/${element.section_number}`
      );
      const { data: courseStats } = await api.post(
        `/grades/${element.semester}/${element.catalog_number}/${element.section_number}`,
        courseData,
        {
          params: { test: true },
        }
      );
      this.setState({ courseStats, showCourseStats: true });
    } catch (err) {
      this.setState({
        errorMessage: err.response?.data ? err.response.data : err.message,
        showCourseStats: true,
      });
    }

    this.props.handleLoadingStatus(element);
  }

  /**
   * Retrieves the field to display the impersonation button on if user is registrar.
   *
   * @return {string|null} The field name to be used for impersonation or null if no suitable field is found.
   */
  getImpersonateField() {
    if (!this.props.roles.registrar) return null

    const allowedFields = ["display_name", "first_name", "last_name"]
    for (const field of this.props.fieldsToShow) {
      if (allowedFields.includes(field?.field_name)) {
        return field.field_name
      }
    }
    return null
  }

  render() {
    const {
      handleDisplayClick,
      handleDisplayButton,
      fieldsToShow,
      dataSet,
      tableTop,
    } = this.props;
    return (
      <React.Fragment>
        <CourseStatsModal
          show={this.state.showCourseStats}
          toggleCourseStatsModal={this.toggleCourseStatsModal}
          load={this.state.loading}
          toggleLoading={this.toggleLoading}
          courseStats={this.state.courseStats}
          showStatsErrorMessage={this.state.showStatsErrorMessage}
          errorMessage={this.state.errorMessage}
        />
        <Card className="directory-shadow">
          {this.props.fromPrint ? (
            <Card.Title>{this.props.pageName}</Card.Title>
          ) : (
            ""
          )}
          <Table
            striped={!this.props.fromPrint}
            bordered={!this.props.fromPrint}
            hover
            responsive
            className="mb-0 header-fixed"
            id="bootstrap-override"
          >
            <thead ref={tableTop}>
              <tr>
                {fieldsToShow.map((field) => (
                  <th className="border-bottom" key={field.display_name}>
                    {!this.props.fromPrint ? (
                      <Button
                        className="m-0 p-0"
                        size="sm"
                        variant="success"
                        onClick={this.props.handleSort.bind(
                          this,
                          field.display_name
                        )}
                      >
                        {field.display_name}{" "}
                        <FontAwesomeIcon
                          title={`sort by ${field.display_name}`}
                          icon={faSort}
                          style={{ color: "002e5d" }}
                        />
                      </Button>
                    ) : (
                      ""
                    )}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {dataSet.map((element, index) => (
                <TextOnlyRow
                  key={index}
                  element={element}
                  dataSet={dataSet}
                  handleDisplayClick={handleDisplayClick}
                  handleDisplayButton={handleDisplayButton}
                  fieldsToShow={fieldsToShow}
                  roles={this.props.roles}
                  fromStudent={this.props.fromStudent}
                  fromFaculty={this.props.fromFaculty}
                  fromGraduate={this.props.fromGraduate}
                  fromCourse={this.props.fromCourse}
                  impersonateField={this.getImpersonateField()}
                  submitStatus={this.submitStatus}
                  getCourseStats={this.getCourseStats}
                />
              ))}
            </tbody>
          </Table>
        </Card>
      </React.Fragment>
    );
  }
}

export default TextOnly;
