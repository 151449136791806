import React from "react";
import { Row, Col, Image } from "react-bootstrap";
import byuWordmark from "../images/byu-wordmark-ring.svg";
import byuLogo from "../images/byu-logo-white-small.svg";
import deployDate from "../utils/deployDate";
import JiraFeedback from "./JiraFeedback";

class Footer extends React.Component {
  render() {
    return (
      <footer>
        <Row
          // className="footer-div text-center"
          className={
            sessionStorage.getItem("actastoken")
              ? "no-margin footer-yellow text-center"
              : "no-margin footer-div text-center"
          }
        >
          <Col className="m-2" style={{ paddingTop: 10 }}>
            <div className="d-lg-none mb-2">
              <Image src={byuLogo} alt="BYU Logo" />
            </div>
            <div className="d-none d-lg-block mb-2">
              <Image src={byuWordmark} alt="BYU Wordmark" />
            </div>
            Provo, UT 84602, USA | © 2024 All Rights Reserved
            <br className="d-none d-lg-inline" />
            <p className="d-inline d-lg-none"> | </p>
            Build Date: {deployDate}
            <br className="d-none d-md-inline d-lg-none" /> <span> | </span>
            <JiraFeedback />
          </Col>
        </Row>
      </footer>
    );
  }
}

export default Footer;
