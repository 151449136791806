import React from "react";
import { Modal, Button, Form, Alert, Spinner } from "react-bootstrap";
import { Table } from "react-bootstrap";
import api from "../../services/api";
import ReactToPrint from "react-to-print";
class MassGraduationSubmitModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      graduation_date: new Date().toISOString().split("T")[0],
      graduateClicked: false,
      responseModal: false,
      messagedResponses: "",
      loading: false,
    };

    this.universityreport = React.createRef();
    this.detailedreport = React.createRef();
    this.handleGraduate = this.handleGraduate.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.toggleGraduateAlert = this.toggleGraduateAlert.bind(this);
    this.toggleResponseModal = this.toggleResponseModal.bind(this);
  }

  toggleGraduateAlert() {
    this.setState({ graduateClicked: !this.state.graduateClicked });
  }

  handleDateChange(e) {
    this.setState({ graduation_date: e.target.value });
  }

  toggleResponseModal() {
    this.setState({ responseModal: !this.state.responseModal });
    this.props.showPrintReport();
    this.props.setGraduationResponse(this.state.graduationResponse);
  }

  async handleGraduate() {
    this.setState({ loading: true });

    let graduationArray = [];
    this.props.studentsToGraduate.forEach((student) => {
      let object = {};
      object.id = student.id;
      if (student.year_in_program !== "LLM") {
        object.degree = "Juris Doctor";
      } else {
        object.degree = "Master of Law";
      }
      object.graduation_date = this.state.graduation_date;

      if (student.honors === "Magna Cum Laude") {
        object.honors = "M";
      }
      if (student.honors === "Cum Laude") {
        object.honors = "C";
      }
      if (student.honors === "Summa Cum Laude") {
        object.honors = "S";
      }
      if (student.honors_exception === "true") {
        object.honors_exception = true;
      }
      if (student.honors_exception === "false") {
        object.honors_exception = false;
      }
      if (student.honors_exception === true) {
        object.honors_exception = true;
      }
      if (
        student.honors_exception === false ||
        student.honors_exception === null
      ) {
        object.honors_exception = false;
      }

      graduationArray.push(object);
    });

    //Close Modal to show response modal
    let response = await api.post(`/students/massgraduation`, graduationArray);
    await this.setState({ graduationResponse: response.data });

    this.setState({ loading: false });
    this.props.toggleGraduateModal();

    let responseMessages = [];
    response.data.forEach((response) => {
      try {
        let message = response.message;
        let fieldsArray = [];

        Object.keys(message).forEach((key) => {
          if (message[key] === false) {
            let str = key;
            str = str.replace(/(^|_)(\S)/g, (s) => s.toUpperCase());
            str = str.replaceAll("_", " ");
            fieldsArray.push(str + ": False");
          }
        });
        responseMessages.push(fieldsArray);
      } catch (e) {
        responseMessages.push([response.message]);
      }
    });

    await this.setState({ messagedResponses: responseMessages });
    this.props.setMessagedResponses(responseMessages);
    this.setState({ responseModal: true });
    this.setState({ graduateClicked: !this.state.graduateClicked });

    //pass back to parent component to be able to print a previous
  }

  render() {
    let { show, studentsToGraduate, toggleGraduateModal } =
      this.props;
    return (
      <>
        <Modal
          show={show}
          onHide={toggleGraduateModal}
          dialogClassName="modal-40vw"
        >
          <Modal.Header closeButton>
            <Modal.Title>Graduate Students</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {studentsToGraduate.length === 0 ? (
              "You have not selected any students to graduate"
            ) : (
              <React.Fragment>
                <Table>
                  <thead>
                    <th>Name on Degree</th>
                    <th>Honors</th>
                    <th>Honors Exception</th>
                    <th>Degree</th>
                    <th>Joint Degree</th>
                  </thead>
                  <tbody>
                    {studentsToGraduate.map((student) => (
                      <tr>
                        <td>{student.name_on_diploma}</td>
                        <td>{student.honors}</td>
                        <td>{student.honors_exception ? "Yes" : "No"}</td>
                        <td>
                          {student.year_in_program === "3" ? "JD" : "LLM"}
                        </td>
                        <td>{student.joint_degree}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <Form.Label>
                  <strong>Graduation Date</strong>
                  <p>Here you can select the date for everyone to graduate</p>
                </Form.Label>
                <Form.Control
                  id="graduation-date"
                  type="date"
                  value={this.state.graduation_date}
                  onChange={this.handleDateChange}
                  style={{ width: 200 }}
                ></Form.Control>
                {!this.state.graduateClicked ? (
                  <Button
                    id="noForceGraduate"
                    variant="info"
                    className="mt-2 float-end"
                    onClick={this.toggleGraduateAlert}
                  >
                    Graduate
                  </Button>
                ) : (
                  ""
                )}
                {this.state.graduateClicked ? (
                  <>
                    <Alert
                      variant="info"
                      className="m-2"
                      onClose={this.toggleGraduateAlert}
                    >
                      This action is not easily reversible, are you sure you
                      want to proceed? Also, it may take up to 10 minutes to
                      process your request.
                    </Alert>
                    <Button
                      id="noForceGraduate"
                      variant="info"
                      className="m-2 float-end"
                      onClick={this.handleGraduate}
                    >
                      Yes, Graduate
                    </Button>
                    <Button
                      id="noForceGraduate"
                      variant="outline-danger"
                      className="m-2 float-end"
                      onClick={this.toggleGraduateAlert}
                    >
                      No, Cancel
                    </Button>
                  </>
                ) : (
                  ""
                )}
                {this.state.loading ? <Spinner animation="border" /> : ""}
              </React.Fragment>
            )}
          </Modal.Body>
        </Modal>
        {this.state.graduationResponse !== [] ? (
          <Modal
            show={this.state.responseModal}
            onHide={() => {
              this.toggleResponseModal();
              this.props.populateData();
            }}
            dialogClassName="modal-90vw"
          >
            <Modal.Header closeButton>
              <Modal.Title>Mass Graduation Response</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {studentsToGraduate.length === 0 ? (
                "You have not selected any students to graduate"
              ) : (
                <React.Fragment>
                  <Table>
                    <thead>
                      <th>Name on Degree</th>
                      <th>ID</th>
                      <th>Degree</th>
                      <th>Honors</th>
                      <th>Honors Exception</th>
                      <th>Joint Degree</th>
                      <th>Success</th>
                      <th>Missing Requirements</th>
                    </thead>
                    <tbody>
                      {studentsToGraduate.map((student, index) => (
                        <tr>
                          <td>{student.name_on_diploma}</td>
                          <td>{student.id}</td>
                          <td>
                            {student.year_in_program === "3" ? "JD" : "LLM"}
                          </td>
                          <td>{student.honors}</td>
                          <td>{student.honors_exception ? "Yes" : "No"}</td>
                          <td>{student.joint_degree}</td>
                          <td>
                            {this.state.graduationResponse
                              ? this.state.graduationResponse[index]?.success
                                ? this.state.graduationResponse[index]?.message
                                : "Failure"
                              : ""}
                          </td>
                          <td>
                            {this.state.messagedResponses[index]?.map(
                              (field) => {
                                return <div>{field}</div>;
                              }
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </React.Fragment>
              )}
              <p>
                For any failed graduations, they will need to be individually
                graduated through the 'Force Graduation' feature. We are unable
                to Mass Force Graduate students who don't meet all the
                requirements.
              </p>
              <div>
                <ReactToPrint
                  trigger={() => {
                    // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                    // to the root node of the returned component as it will be overwritten.
                    return (
                      <Button variant="info" className="float-end m-2">
                        Detailed Report
                      </Button>
                    );
                  }}
                  content={() => this.detailedreport.current}
                />
                <div style={{ display: "none" }}>
                  <Table ref={this.detailedreport}>
                    <thead>
                      <th>Name on Degree</th>
                      <th>ID</th>
                      <th>Degree</th>
                      <th>Honors</th>
                      <th>Honors Exception</th>
                      <th>Joint Degree</th>
                      <th>Success</th>
                      <th>Missing Requirements</th>
                    </thead>
                    <tbody>
                      {studentsToGraduate.map((student, index) => (
                        <tr>
                          <td>{student.name_on_diploma}</td>
                          <td>{student.id}</td>
                          <td>
                            {student.year_in_program === "3" ? "JD" : "LLM"}
                          </td>
                          <td>{student.honors}</td>
                          <td>{student.honors_exception ? "Yes" : "No"}</td>
                          <td>{student.joint_degree}</td>
                          <td>
                            {this.state.graduationResponse
                              ? this.state.graduationResponse[index]?.success
                                ? "Success"
                                : "Failure"
                              : ""}
                          </td>
                          <td>
                            {this.state.messagedResponses[index]?.map(
                              (field) => {
                                return <div>{field}</div>;
                              }
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </div>
              <div>
                <ReactToPrint
                  trigger={() => {
                    // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                    // to the root node of the returned component as it will be overwritten.
                    return (
                      <Button variant="info" className="float-end m-2">
                        University Report
                      </Button>
                    );
                  }}
                  content={() => this.universityreport.current}
                />
                <div style={{ display: "none" }}>
                  <Table ref={this.universityreport}>
                    <thead>
                      <th>Last Name</th>
                      <th>First Name</th>
                      <th>Student ID</th>
                      <th>Degree</th>
                      <th>Honors</th>
                    </thead>
                    <tbody>
                      {studentsToGraduate.map((student, index) => (
                        <>
                          {this.state.graduationResponse ? (
                            this.state.graduationResponse[index]?.success ? (
                              <tr>
                                <td>{student.last_name}</td>
                                <td>{student.first_name}</td>
                                <td>{student.id}</td>
                                <td>
                                  {student.year_in_program === "3"
                                    ? "Juris Doctor"
                                    : "Master of Law"}
                                </td>
                                <td>
                                  {student.honors === "C"
                                    ? "Cum Laude"
                                    : student.honors === "M"
                                    ? "Magna Cum Laude"
                                    : student.honors === "S"
                                    ? "Summa Cum Laude"
                                    : student?.honors}
                                </td>
                              </tr>
                            ) : (
                              ""
                            )
                          ) : (
                            ""
                          )}
                        </>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        ) : (
          ""
        )}
      </>
    );
  }
}

export default MassGraduationSubmitModal;
