import React from "react";
import { Form, Col } from "react-bootstrap";

class EditPersonalInfoEntry extends React.Component {
  render() {
    const {
      label,
      id,
      value,
      onChange,
      disabled,
      placeholder,
    } = this.props;

    return (
      <div className="entry">
        <Col xs="auto">
          <Form.Label>
            <strong className="p-0">{label}</strong>
          </Form.Label>
        </Col>
        <Col className="entry-select">
          <Form.Control
            id={id}
            value={value}
            onChange={onChange}
            disabled={disabled}
            placeholder={placeholder}
          />
        </Col>
      </div>
    );
  }
}

export default EditPersonalInfoEntry;