import React from "react";
import { Col, Row, Button, Card, Table } from "react-bootstrap";
import {
  getIndexOfAddressOfType,
  handleImageError,
} from "../../../utils/functions";
import { CleanImage } from "../../SafeImage";

class StudentDirectoryInfo extends React.Component {
  render() {
    const { student, handleReturnToDirectory, roles } = this.props;
    let indexOfHomeAddress;
    let pic;
    if (process.env.REACT_APP_STAGE === "dev") {
      pic = `https://s3.amazonaws.com/content.law-info.byu.edu-dev/student-pictures/${student.id}.jpg?`;
    } else {
      pic = `https://s3-us-west-2.amazonaws.com/content.law-info.byu.edu/student-pictures/${student.id}.jpg?`;
    }
    if (student.address) {
      indexOfHomeAddress = getIndexOfAddressOfType(student.address, "home");
    }
    return (
      <React.Fragment>
        <Row className="no-margin justify-content-center">
          <Col xs={"auto"} className="mt-2">
            {!(roles.registrar || roles.admin) && (
              <Button onClick={handleReturnToDirectory} className="shadow mx-2">
                Return to Directory
              </Button>
            )}
          </Col>
        </Row>
        <Row className="no-margin mt-2 mb-5 justify-content-center">
          <Col
            xs={"auto"}
            sm={6}
            md={4}
            lg={3}
            className="mt-2 justify-content-md-center"
          >
            <CleanImage
              src={pic}
              alt={`student headshot`}
              placeholder="student_img"
              style={{ display: "block", marginLeft: "auto" }}
              className="shadow card-img"
              onError={(e) => {
                handleImageError(e, student);
              }}
              fluid
              rounded
              person={student}
            />
          </Col>
          <Col xs={"auto"} className="my-2">
            <Card className="shadow personal-info-card">
              {student.known_by && student.known_by !== student.last_name ? (
                <Card.Header as="h3">
                  {student.known_by + " " + student.last_name}
                </Card.Header>
              ) : (
                <Card.Header as="h3">
                  {student.first_name + " " + student.last_name}
                </Card.Header>
              )}

              <Card.Body>
                <Table size="sm">
                  <tbody>
                    {this.props.fromStudent && (
                      <React.Fragment>
                        <tr>
                          <td>
                            <strong>Class:</strong>
                          </td>
                          <td>{student.year_in_program}</td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Phone:</strong>
                          </td>
                          <td>
                            {student.address ? (
                              student.address[indexOfHomeAddress] ? (
                                <React.Fragment>
                                  {student.address[indexOfHomeAddress].phone}
                                </React.Fragment>
                              ) : (
                                <React.Fragment />
                              )
                            ) : (
                              <React.Fragment>{student.phone}</React.Fragment>
                            )}
                          </td>
                        </tr>
                        {student.carrel ? (
                          <tr>
                            <td>
                              <strong>Carrel:</strong>
                            </td>
                            <td>{student.carrel}</td>
                          </tr>
                        ) : (
                          <React.Fragment />
                        )}
                        <tr>
                          <td>
                            <strong>E-mail:</strong>
                          </td>
                          <td>{student.email}</td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Birthday:</strong>
                          </td>
                          <td>
                            {student.date_of_birth
                              ? new Date(
                                  student.date_of_birth
                                ).toLocaleDateString("en-US", {
                                  timeZone: "UTC",
                                  month: "long",
                                  day: "numeric",
                                })
                              : ""}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Address:</strong>
                          </td>
                          <td>
                            <ul className="list-unstyled">
                              <li></li>
                              {student.address ? (
                                student.address[indexOfHomeAddress] ? (
                                  <React.Fragment>
                                    <li>
                                      {
                                        student.address[indexOfHomeAddress]
                                          .street1
                                      }
                                    </li>
                                    <li>
                                      {student.address[indexOfHomeAddress]
                                        .street2 !== ("" || undefined)
                                        ? ""
                                        : student.address[indexOfHomeAddress]
                                            .street2}
                                    </li>
                                    <li>
                                      {student.address[indexOfHomeAddress]
                                        .city +
                                        " " +
                                        student.address[indexOfHomeAddress]
                                          .state +
                                        " " +
                                        student.address[indexOfHomeAddress]
                                          .postal_code}
                                    </li>
                                  </React.Fragment>
                                ) : (
                                  <React.Fragment />
                                )
                              ) : (
                                <React.Fragment>
                                  <li>{student.street1}</li>
                                  <li>{student.street2}</li>
                                  <li>
                                    {student.city +
                                      " " +
                                      student.state +
                                      " " +
                                      student.postal_code}
                                  </li>
                                </React.Fragment>
                              )}
                            </ul>
                          </td>
                        </tr>
                      </React.Fragment>
                    )}
                    {this.props.fromGraduate && (
                      <React.Fragment>
                        <tr>
                          <td>
                            <strong>Class:</strong>
                          </td>
                          <td>
                            {new Date(
                              student.law_degrees[
                                student.law_degrees > 0
                                  ? student.law_degrees.length - 1
                                  : 0
                              ].date_degree_received
                            ).toLocaleString("en-US", {
                              timeZone: "UTC",
                              year: "numeric",
                            })}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Phone:</strong>
                          </td>
                          <td>
                            {student.address ? (
                              student.address[indexOfHomeAddress] ? (
                                <React.Fragment>
                                  {student.address[indexOfHomeAddress].phone}
                                </React.Fragment>
                              ) : (
                                <React.Fragment />
                              )
                            ) : (
                              <React.Fragment>{student.phone}</React.Fragment>
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <strong>E-mail:</strong>
                          </td>
                          <td>{student.email}</td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Address:</strong>
                          </td>
                          <td>
                            <ul className="list-unstyled">
                              <li></li>
                              {student.address ? (
                                student.address[indexOfHomeAddress] ? (
                                  <React.Fragment>
                                    <li>
                                      {
                                        student.address[indexOfHomeAddress]
                                          .street1
                                      }
                                    </li>
                                    <li>
                                      {student.address[indexOfHomeAddress]
                                        .street2 !== ("" || undefined)
                                        ? ""
                                        : student.address[indexOfHomeAddress]
                                            .street2}
                                    </li>
                                    <li>
                                      {student.address[indexOfHomeAddress]
                                        .city +
                                        " " +
                                        student.address[indexOfHomeAddress]
                                          .state +
                                        " " +
                                        student.address[indexOfHomeAddress]
                                          .postal_code}
                                    </li>
                                  </React.Fragment>
                                ) : (
                                  <React.Fragment />
                                )
                              ) : (
                                <React.Fragment>
                                  <li>{student.street1}</li>
                                  <li>{student.street2}</li>
                                  <li>
                                    {student.city +
                                      " " +
                                      student.state +
                                      " " +
                                      student.postal_code}
                                  </li>
                                </React.Fragment>
                              )}
                            </ul>
                          </td>
                        </tr>
                      </React.Fragment>
                    )}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default StudentDirectoryInfo;
