import React from "react";

import { Row, Col, Table, Card, Form, Button } from "react-bootstrap";

import ErrorDisplay from "../../ErrorDisplay";
import api from "../../../services/api";

class BarAttempts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      student: this.props.student,
      barAttempts: this.props.student.bar_attempts,
      editableBarAttempts: JSON.parse(
        JSON.stringify(this.props.student.bar_attempts)
      ),
      loading: false,
      errorMessage: null,
    };
    this.handleEditToggle = this.handleEditToggle.bind(this);
    this.formatEditableBarAttempts = this.formatEditableBarAttempts.bind(this);
    this.handleSaveBarAttempt = this.handleSaveBarAttempt.bind(this);
    this.handleEditBarAttemptTable = this.handleEditBarAttemptTable.bind(this);
    this.addBarAttempt = this.addBarAttempt.bind(this);
    this.handleDeleteBarAttempt = this.handleDeleteBarAttempt.bind(this);
  }

  async componentDidMount() {
    this.formatEditableBarAttempts(true);
  }

  formatEditableBarAttempts(addDisableEdit) {
    let { editableBarAttempts } = this.state;
    editableBarAttempts.forEach((barAttempt) => {
      if (addDisableEdit) {
        barAttempt.disableEdit = true;
      }
      barAttempt.date = barAttempt.date
        ? new Date(barAttempt.date).toISOString().split("T")[0]
        : "";
    });
    this.setState({ editableBarAttempts });
  }

  getElementIndex(e) {
    const [attempt, type] = e.target.id.split("-");
    const editableBarAttempts = this.state.editableBarAttempts;
    const barAttemptIndex = editableBarAttempts.findIndex(
      (barAttempt) => parseInt(barAttempt.attempt) === parseInt(attempt)
    );
    return [barAttemptIndex, type];
  }

  handleEditToggle(e) {
    this.setState({ errorMessage: null });
    const editableBarAttempts = this.state.editableBarAttempts;
    const attemptIndex = this.getElementIndex(e)[0];

    if (
      editableBarAttempts[attemptIndex].newAttempt &&
      !editableBarAttempts[attemptIndex].disableEdit
    ) {
      editableBarAttempts.splice(attemptIndex, 1);
    } else if (editableBarAttempts[attemptIndex].disableEdit) {
      editableBarAttempts[attemptIndex].disableEdit = false;
    } else {
      editableBarAttempts[attemptIndex] = {
        ...this.state.barAttempts[attemptIndex],
      };
      editableBarAttempts[attemptIndex].disableEdit = true;
      this.formatEditableBarAttempts(false);
    }

    this.setState({ editableBarAttempts });
  }

  addBarAttempt() {
    let editableBarAttempts = this.state.editableBarAttempts;

    let highestOrder = null;

    if (editableBarAttempts.length === 0) {
      highestOrder = 0;
    } else {
      highestOrder = Math.max.apply(
        Math,
        editableBarAttempts.map(function (barAttempt) {
          return barAttempt.attempt;
        })
      );
    }

    const newAttempt = {
      state: "",
      date: "",
      disableEdit: "",
      newAttempt: true,
      pass: true,
      attempt: highestOrder + 1,
    };
    editableBarAttempts.push(newAttempt);

    this.setState({ editableBarAttempts });
  }

  async handleSaveBarAttempt(e) {
    this.setState({ disableApiButtons: true });
    let { editableBarAttempts, barAttempts } = this.state;
    const attemptIndex = this.getElementIndex(e)[0];
    const payload = JSON.parse(
      JSON.stringify(editableBarAttempts[attemptIndex])
    );

    try {
      let returnedBarAttempt;

      if (payload.newAttempt) {
        delete payload.disableEdit;
        delete payload.newAttempt;
      }
      payload.date = payload.date ? new Date(payload.date).toISOString() : null;
      let { data } = await api.post(
        `/bar-attempts/${this.state.student.id}`,
        payload
      );
      returnedBarAttempt = data[0];
      barAttempts[attemptIndex] = {
        ...barAttempts[attemptIndex],
        ...returnedBarAttempt,
        newAttempt: false,
      };
      returnedBarAttempt.disableEdit = true;
      editableBarAttempts[attemptIndex] = {
        ...editableBarAttempts[attemptIndex],
        ...returnedBarAttempt,
        newAttempt: false,
      };
      this.setState({
        barAttempts,
        editableBarAttempts,
      });
      this.formatEditableBarAttempts(false);
    } catch (err) {
      console.error(err);
      this.setState({
        errorMessage: err.response?.data ? err.response.data : err.message,
      });
    }

    this.setState({ disableApiButtons: false });
  }

  async handleDeleteBarAttempt(e) {
    this.setState({ disableApiButtons: true });
    let { editableBarAttempts, barAttempts } = this.state;
    const attemptIndex = this.getElementIndex(e)[0];
    const payload = editableBarAttempts[attemptIndex];

    try {
      await api.delete(`/bar-attempts/${this.state.student.id}`, {
        data: payload,
      });
      barAttempts.splice(attemptIndex, 1);
      editableBarAttempts.splice(attemptIndex, 1);

      this.setState({
        barAttempts,
        editableBarAttempts,
      });
    } catch (err) {
      console.error(err);
      this.setState({
        errorMessage: err.response?.data ? err.response.data : err.message,
      });
    }

    this.setState({ disableApiButtons: false });
  }

  handleEditBarAttemptTable(e) {
    let editableBarAttempts = this.state.editableBarAttempts;
    const { value } = e.target;
    const [index, field] = this.getElementIndex(e);
    switch (field) {
      case "state":
        if (value.length <= 2) {
          editableBarAttempts[index].state = value;
        }
        break;
      case "date":
        editableBarAttempts[index].date = value;
        break;
      case "pass":
        editableBarAttempts[index].pass = parseInt(value);
        break;
      default:
        break;
    }
    this.setState({ editableBarAttempts });
  }

  buildBarAttemptsTable(barAttempt) {
    const key = barAttempt.attempt;
    return (
      <React.Fragment key={key + "rf"}>
        <tr key={key + "tr"}>
          <td key={key + "td1"}>{barAttempt.attempt}</td>
          <td key={key + "td2"}>
            {this.props.roles.registrar || this.props.roles.admin ? (
              <Form.Control
                style={{ paddingRight: 0 }}
                value={barAttempt.state}
                id={`${key}-state`}
                key={key + "fc"}
                disabled={barAttempt.disableEdit}
                onChange={this.handleEditBarAttemptTable}
                placeholder="State (two-letter-code)"
              />
            ) : (
              barAttempt.state
            )}
          </td>
          <td key={key + "td3"}>
            {this.props.roles.registrar || this.props.roles.admin ? (
              <Form.Control
                type="date"
                forma="true"
                selected={barAttempt.date}
                value={barAttempt.date}
                id={`${key}-date`}
                key={key + "fc2"}
                disabled={barAttempt.disableEdit}
                onChange={this.handleEditBarAttemptTable}
              />
            ) : (
              barAttempt.date
            )}
          </td>
          <td key={key + "td4"}>
            {barAttempt.disableEdit ? (
              <React.Fragment key={key + "rf2"}>
                {barAttempt.pass ? (
                  <React.Fragment key={key + "rf3"}>Pass</React.Fragment>
                ) : (
                  <React.Fragment key={key + "rf4"}>Fail</React.Fragment>
                )}
              </React.Fragment>
            ) : (
              <Form.Select
                id={`${barAttempt.attempt}-pass`}
                key={key + "pass"}
                disabled={barAttempt.disableEdit}
                value={barAttempt.pass}
                onChange={this.handleEditBarAttemptTable}
              >
                <option value={1}>Pass</option>
                <option value={0}>Fail</option>
              </Form.Select>
            )}
          </td>
          {(this.props.roles.registrar || this.props.roles.admin) &&
            (barAttempt.disableEdit ? (
              <React.Fragment key={key + "rf5"}>
                <td width="10%" key={key + "td10"}>
                  <Button
                    id={`${key}-edit`}
                    key={key + "edit"}
                    onClick={this.handleEditToggle}
                    variant="outline-primary"
                  >
                    Edit
                  </Button>
                </td>
                <td width="10%" key={key + "del"}>
                  <Button
                    id={`${key}-delete`}
                    key={key + "delete"}
                    disabled={this.state.disableApiButtons}
                    onClick={this.handleDeleteBarAttempt}
                    variant="outline-primary"
                  >
                    Delete
                  </Button>
                </td>
              </React.Fragment>
            ) : (
              <React.Fragment key={key + "rf10"}>
                <td width="10%" key={key + "s"}>
                  <Button
                    id={`${key}-save`}
                    key={key + "save"}
                    onClick={this.handleSaveBarAttempt}
                    variant="info"
                    disabled={this.state.disableApiButtons}
                  >
                    Save
                  </Button>
                </td>
                <td width="10%" key={key + "c"}>
                  <Button
                    id={`${key}-cancel`}
                    key={key + "cancel"}
                    onClick={this.handleEditToggle}
                    variant="outline-info"
                  >
                    Cancel
                  </Button>
                </td>
              </React.Fragment>
            ))}
        </tr>
      </React.Fragment>
    );
  }

  render() {
    let { editableBarAttempts } = this.state;
    return (
      <Card className="academic-progress-card" style={{ width: "100%" }}>
        <Row>
          <Col>
            <Card className="shadow mb-4 bg-light">
              <Table striped bordered hover responsive className="mb-0">
                <thead>
                  <tr>
                    <th style={{ width: "20%" }}>Bar Attempt #</th>
                    <th style={{ width: "20%" }}>State</th>
                    <th style={{ width: "20%" }}>Date</th>
                    <th style={{ width: "20%" }}>Pass/Fail</th>
                    {(this.props.roles.registrar || this.props.roles.admin) && (
                      <th colSpan="2" style={{ width: "20%" }}>
                        Options
                      </th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {editableBarAttempts.map((barAttempt) =>
                    this.buildBarAttemptsTable(barAttempt)
                  )}
                </tbody>
              </Table>
            </Card>
          </Col>
        </Row>
        {(this.props.roles.registrar || this.props.roles.admin) && (
          <Row className="justify-content-center mb-3">
            <Col xs="auto">
              <Button
                variant="outline-primary"
                onClick={this.addBarAttempt}
                className="shadow-button"
              >
                + Bar Attempt
              </Button>
            </Col>
          </Row>
        )}
        {this.state.errorMessage && (
          <ErrorDisplay
            className="shadow"
            errorMessage={this.state.errorMessage}
          />
        )}
      </Card>
    );
  }
}

export default BarAttempts;
