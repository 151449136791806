import React from "react";
import { Col, Row, Button, Card, Table } from "react-bootstrap";
import { handleImageError } from "../../utils/functions";
import { CleanImage } from "../SafeImage";

class PublicDisplayFacultyInformation extends React.Component {
  render() {
    const { person } = this.props;
    
    return (
      <Row className="no-margin justify-content-center">
        <Col
          xs={"auto"}
          sm={6}
          md={4}
          lg={3}
          className="mt-3 justify-content-md-center"
        >
          <CleanImage
            src={`${person.picture_link}?` + new Date().getTime()}
            alt={`${person.first_name} ${person.last_name} profile picture`}
            placeholder="person_img"
            style={{ display: "block", marginLeft: "auto" }}
            className="shadow"
            onError={(e) => {
              handleImageError(e, person);
            }}
            person={person}
            fluid
            rounded
          />
        </Col>
        <Col
          xs={12}
          sm={6}
          md={8}
          lg={"auto"}
          className="mt-3 mb-4"
          style={{ minWidth: "300px" }}
        >
          <Card className="shadow fill-parent">
            <Card.Header as="h3">
              {person.first_name +
                (person.last_name ? " " + person.last_name : "")}
            </Card.Header>
            <Card.Body>
              <Table size="sm">
                <tbody>
                  <React.Fragment>
                    <tr>
                      <td>
                        <strong>Name:</strong>
                      </td>
                      <td>{person.first_name + " " + person.last_name}</td>
                    </tr>
                    {person.phone ? (
                      <tr>
                        <td>
                          <strong>Phone:</strong>
                        </td>
                        <td>{person.phone}</td>
                      </tr>
                    ) : (
                      <tr>
                        <td>
                          <strong>Phone:</strong>
                        </td>
                        <td style={{ color: "gray" }}>None</td>
                      </tr>
                    )}
                  </React.Fragment>
                  {person.email ? (
                    <tr>
                      <td>
                        <strong>E-mail:</strong>
                      </td>
                      <td>{person.email}</td>
                    </tr>
                  ) : (
                    <tr>
                      <td>
                        <strong>E-mail:</strong>
                      </td>
                      <td style={{ color: "gray" }}>None</td>
                    </tr>
                  )}
                  {person.bio_link && (
                    <tr>
                      <td>
                        <strong>Bio:</strong>
                      </td>
                      <td>
                        <a
                          href={person.bio_link}
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          {person.first_name} {person.last_name}
                        </a>
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
          <Button onClick={this.props.returnToDirectory}>
            Return To Directory
          </Button>
        </Col>
      </Row>
    );
  }
}

export default PublicDisplayFacultyInformation;
