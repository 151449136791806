import React from 'react';
import { Col } from 'react-bootstrap';

class ProgressReportEntry extends React.Component {
  render() {
    const {
      className,
      data,
      label,
    } = this.props;

    return (
      <div className={className ? className + " entry" : "entry"} >
        <Col xs="auto">
          <strong className="p-0">{label}</strong>
        </Col>
        <Col style={{ textAlign: "right" }}>
          {data}
        </Col>
      </div>
    )
  }
}

export default ProgressReportEntry;