import { useState } from "react";
import { Card, Image, Spinner } from "react-bootstrap";
import { useEffect } from "react";
import { decryptString } from "./auth/secureSessionStorage";


export function CleanCardImage({...props}) {
  const [loading, setLoading] = useState(true);
  const [safeSrc, setSafeSrc] = useState(null);
  async function loadImage() {
    try {
      if (!localStorage.getItem('images')) {
        setLoading(false);
        setSafeSrc(props.src);
        return;
      }
      const allImages = JSON.parse(decryptString(localStorage.getItem('images')));
      if (allImages.includes(props.src.split("?")[0])) {
        setLoading(false);
        setSafeSrc(props.src);
      } else {
        loadDefaultImage();
      }
    } catch (err) {
      loadDefaultImage();
    }
  }
  async function loadDefaultImage() {
    try {
      if (!props?.person) {
        return;
      }
      const fullName = `${props.person.first_name} ${props.person.last_name}`;
      const defaultSrc = `https://ui-avatars.com/api/?name=${fullName}&size=275&background=E2E0DD`;
      setLoading(false);
      setSafeSrc(defaultSrc);
    } catch (err) {
      return;
    }
  }

  useEffect(() => {
    loadImage();
  }, []);

  return loading ? <div /> : <Card.Img {...props} src={safeSrc} />;
}

export function CleanImage({...props}) {
  const [loading, setLoading] = useState(true);
  const [safeSrc, setSafeSrc] = useState(null);
  async function loadImage() {
    try {
      if (!localStorage.getItem('images')) {
        setSafeSrc(props.src);
        if (props.src) {
          setLoading(false);
        }
        return;
      }
      const allImages = JSON.parse(decryptString(localStorage.getItem('images')));
      if (allImages.includes(props.src.split("?")[0])) {
        setSafeSrc(props.src);
        if (props.src) {
          setLoading(false);
        }
      } else {
        loadDefaultImage();
      }
    } catch (err) {
      loadDefaultImage();
    }
  }
  async function loadDefaultImage() {
    try {
      if (!props?.person) {
        return;
      }
      const fullName = `${props.person.first_name} ${props.person.last_name}`;
      const defaultSrc = `https://ui-avatars.com/api/?name=${fullName}&size=275&background=E2E0DD`;
      setSafeSrc(defaultSrc);
      setLoading(false);
    } catch (err) {
      return;
    }
  }
  useEffect(() => {
    loadImage(); 
  }, [props.src]);
  return loading ? <div><Spinner /></div> : <Image {...props} src={safeSrc} />;
}