import React from "react";
import { Nav, Navbar, NavItem, Pagination, Button, Tooltip, OverlayTrigger } from "react-bootstrap";
import chevron from "../../images/chevron.png"

class TableFooter extends React.Component {
  constructor(props) {
    super(props);
    this.state ={
      index: 1,
      pageNumbers: [],
      currentpage: this.props.currentpage,
    }
  }


  render() {
    const {
      currentPage,
      totalPages,
    } = this.props.paginator;

    const { disablePageNav } = this.props;
    const tooltip = (
      <Tooltip id="tooltip-top">
        Only available in the directory
      </Tooltip>
    );
  return (
    <div className="tableNav">
      {disablePageNav ? (
        <OverlayTrigger placement="top" overlay={tooltip}>
          <Navbar
            bg="primary-gray"
            className="p-2 rounded-0 justify-content-center"
          >
            <Nav.Item>
              <Button
                variant="transparent"
                onClick={this.props.paginator.actions.prev}
                disabled={disablePageNav}
              >
                <img
                  src={chevron}
                  alt="next page button"
                  style={{ maxHeight: "30px", transform: "rotate(-180deg)" }}
                />
              </Button>
            </Nav.Item>
            <Nav.Item>
              <Button
                variant="transparent"
                onClick={this.props.paginator.actions.first}
                disabled={disablePageNav}
              >
                {currentPage === 1 ? <strong>1</strong> : 1} 
              </Button>
            </Nav.Item>
            {(currentPage !== 1 && currentPage!== totalPages) ? (
              <>
                {"..."}
                <Nav.Item>
                  <Button
                    variant="transparent"
                    disabled={disablePageNav}
                  >
                    <strong>{currentPage}</strong>
                  </Button>
                </Nav.Item>
                {"..."}
              </>
            ) : (
              <>
                {"..."}
              </>
            )}
            {totalPages > 1 && (
              <Nav.Item>
                <Button
                  variant="transparent"
                  onClick={this.props.paginator.actions.last}
                  disabled={disablePageNav}
                >
                  {currentPage === totalPages ? <strong>{totalPages}</strong> : totalPages}  
                </Button>
              </Nav.Item>
            )}
            <Nav.Item>
              <Button
                variant="transparent"
                onClick={this.props.paginator.actions.next}
                disabled={disablePageNav}
              >
                <img
                  src={chevron}
                  alt="next page button"
                  style={{ maxHeight: "30px" }}
                />
              </Button>
            </Nav.Item>

          </Navbar>
        </OverlayTrigger>
      ) : (
        <Navbar
          bg="primary-gray"
          className="p-2 rounded-0 justify-content-center"
        >
          <Nav.Item>
            <Button
              variant="transparent"
              onClick={this.props.paginator.actions.prev}
              disabled={disablePageNav}
            >
              <img
                src={chevron}
                alt="next page button"
                style={{ maxHeight: "30px", transform: "rotate(-180deg)" }}
              />
            </Button>
          </Nav.Item>
          <Nav.Item>
            <Button
              variant="transparent"
              onClick={this.props.paginator.actions.first}
              disabled={disablePageNav}
            >
              {currentPage === 1 ? <strong>1</strong> : 1} 
            </Button>
          </Nav.Item>
          {(currentPage !== 1 && currentPage!== totalPages) ? (
            <>
              {"..."}
              <Nav.Item>
                <Button
                  variant="transparent"
                  disabled={disablePageNav}
                >
                  <strong>{currentPage}</strong>
                </Button>
              </Nav.Item>
              {"..."}
            </>
          ) : (
            <>
              {"..."}
            </>
          )}
          {totalPages > 1 && (
            <Nav.Item>
              <Button
                variant="transparent"
                onClick={this.props.paginator.actions.last}
                disabled={disablePageNav}
              >
                {currentPage === totalPages ? <strong>{totalPages}</strong> : totalPages}  
              </Button>
            </Nav.Item>
          )}
          <Nav.Item>
            <Button
              variant="transparent"
              onClick={this.props.paginator.actions.next}
              disabled={disablePageNav}
            >
              <img
                src={chevron}
                alt="next page button"
                style={{ maxHeight: "30px" }}
              />
            </Button>
          </Nav.Item>
        </Navbar>
      )}
      
    </div>
  )}
}
export default TableFooter;