import React from "react";
import StudentInformation from "../components/records/StudentInformation";
import { withRouter } from "react-router-dom";

class StudentInformationPage extends React.Component {
  render() {
    return (
      <StudentInformation {...this.props} />
    );
  }
}

export default withRouter(StudentInformationPage);
