import React from "react";
import { Col, Row, Card, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import collapseArrow from "../../images/collapseArrow.png";
import {
  releaseGradesGetCurrentSemester,
} from "../../utils/functions";

class FacultyTools extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingMessage: "Loading...",
      loading: true,
      currentSemester: releaseGradesGetCurrentSemester(),
    };
  }

  render() {
    let currentURL = "/course-directory?my_courses=true&semester=" + this.state.currentSemester.toString();
    return (
      <Container className="p-5 bg-white">
        <React.Fragment>
          <Row>
            <h1>Faculty Tools</h1>
          </Row>
          <Row className="justify-content-center mt-4">
            <Col>
              <Link to={currentURL} className="faculty-tool-button">
                <Card className="shadow tool-button">
                  <Card.Body>
                    <Row>
                      <Col xs={1}></Col>
                      <Col xs={10} className="text-left h4" style={{ color: "black"}}>
                        <strong>Grade Current Courses</strong>
                      </Col>
                      <Col xs={1} className="text-right h4"  style={{ color: "black"}}>
                        <img 
                          src={collapseArrow}
                          className="tool-button-arrow"
                          alt="collapse arrow"
                          style={{ transform: "rotate(90deg)", maxHeight: "40px" }}
                        />
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Link>
            </Col>
          </Row>
          <Row className="justify-content-center mt-4">
            <Col>
              <Link to={"/course-directory?my_courses=true"} className="faculty-tool-button">
                <Card className="shadow tool-button">
                  <Card.Body>
                    <Row>
                      <Col xs={1}></Col>
                      <Col xs={10} className="text-left h4" style={{ color: "black"}}>
                        <strong>View Past Courses Grades</strong>
                      </Col>
                      <Col xs={1} className="text-right h4"  style={{ color: "black"}}>
                        <img 
                          src={collapseArrow} 
                          className="tool-button-arrow" 
                          alt="collapse arrow" 
                          style={{ transform: "rotate(90deg)", maxHeight: "40px" }} 
                        />
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Link>
            </Col>
          </Row>
        </React.Fragment>
      </Container>
    );
  }
}

export default FacultyTools;
