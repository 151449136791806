import React from 'react';
import { Col, Row, Container, Collapse } from "react-bootstrap";
import clock from "../../images/clock.png"
import room from "../../images/room.png"
import person from "../../images/gender.png"
import collapseArrow from "../../images/collapseArrow.png"
import { displaySemester } from '../../utils/functions';

class SimpleViewCourseItem extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      open: false,
    }

    this.handleExpandClick = this.handleExpandClick.bind(this);
  }

  /**
   * Generates the array of day and time in the desired format based on the given schedule.
   *
   * @param {Array} schedule - The schedule array containing day and time information.
   * @return {Array} An array of strings representing the day and time.
   */
  printDayTime(schedule) {
    let dayTime = new Map();
    let day = "";
    let time = "";
    for (const element of schedule) {
      element.day === "Thursday"
        ? day = "Th"
        : day = element.day.charAt(0);
      time = this.getTime(element);
      dayTime.has(time)
        ? dayTime.set(time, (dayTime.get(time) + day))
        : dayTime.set(time, day)
    } 

    let times = [];
    for (const [key, val] of dayTime) {
      times.push(val + " " + key);
    }

    return times;
  }

  /**
   * Generate a formatted time range based on the given element.
   *
   * @param {object} element - The element containing start and end time.
   * @return {string} The formatted time range.
   */
  getTime(element) {
    const amPm = (str) => {
      return parseInt(str.slice(0,2)) > 11
        ? "PM"
        : "AM"
    }
    const subtractTwelve = (str) => {
      let hour = parseInt(str.slice(0,2));
      if (hour > 12) {
        hour -= 12;
      }
      return hour + str.slice(2);
    }
    let start = element.start.slice(0,5);
    let end = element.end.slice(0,5);
    const startHour = amPm(start);
    const endHour = amPm(end);

    if (startHour === endHour) {
      end += endHour;
    } else {
      start += startHour;
      end += endHour
    }

    return  subtractTwelve(start) + "-" + subtractTwelve(end);
  }

  /**
   * Handles the click event for expanding or collapsing.
   *
   */
  handleExpandClick() {
    this.setState({ open: !this.state.open })
  }

  render() {
    const {
      data,
      handleDisplayClick,
    } = this.props;

    const KEY = data.semester + data.catalog_number + data.section_number;
    const semesterArray = displaySemester(data.semester);
    const semester = semesterArray[1] + " " + semesterArray[0];

    return (
      <React.Fragment key={KEY}>
        <Row 
          className="simple-view-row clickable px-4 py-3"
          onClick={() => handleDisplayClick(data, false)}
        >
          <Col md={3} className="px-4">
            <Row className="simple-view-text">
              {semester}
            </Row>
            <Row style={{ fontSize: 20, fontWeight: "bold"}}>
                Law {data.catalog_number}: {data.short_title}
            </Row>
            <Row>
              Section {data.section_number}
            </Row>
          </Col>
          <Col md={2} className="d-flex align-content-center flex-wrap">
            <Col md={"auto"}>
              <img
                className="simple-view-icon"
                src={clock}
                alt={`clock icon`}
              />
            </Col>
            <Col>
              {this.printDayTime(data.schedule)?.map((element) => (
                <p key={element}>{element}</p>
              ))}
            </Col>
          </Col>
          <Col md={2} className="simple-view-text">
            <Row>
              <img
                className="simple-view-icon"
                src={room}
                alt={`room icon`}
              />
              Room {data.room}
            </Row>
          </Col>
          <Col md={3} className="simple-view-text pe-4">
          <Row>
            <img
              className="simple-view-icon"
              src={person}
              alt={`person icon`}
            />
            {data.professors}
            </Row>
          </Col>

          <Col md={2} className="simple-view-text align-content-end">
            {data.credit_hours.slice(0,-1)} Credits
          </Col>
        </Row>
        <Collapse in={this.state.open} className="simple-view-collapse">
          <Container>
            <p>
              Description:
              <br/>
              {data.description}
            </p>
          </Container>
        </Collapse>
        <Row className="justify-content-center">
          <img
            src={collapseArrow}
            alt={"collapse arrow"}
            onClick={() => this.handleExpandClick()}
            className={this.state.open ? "rotate simple-view-icon" : "rotate-back simple-view-icon collapse-arrow"}
          />
        </Row>
        <hr className="my-1"></hr>
      </React.Fragment>
    )
  }
}

export default SimpleViewCourseItem;