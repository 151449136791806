import React from "react";
import { Container } from "react-bootstrap";
import CreatePerson from "../components/CreatePerson";
import ToolsNav from "../components/ToolsNav";

class CreateStudentPage extends React.Component {
  render() {
    return (
      <>
        <ToolsNav currentTab="admin-tools" roles={this.props.roles} isLink={true} />
        <Container className="bg-white">
          <CreatePerson {...this.props} isStudent />
        </Container>
      </>
    );
  }
}

export default CreateStudentPage;
