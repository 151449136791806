import React from "react";
import ErrorDisplay from "./ErrorDisplay";

class MultipleErrorDisplay extends React.Component {
  render() {
    if (this.props.errorMessageArray) {
      return this.props.errorMessageArray.map((error, index) => {
        return (
          <ErrorDisplay
            errorMessage={error?.errorMessage}
            customMessage={error?.customMessage}
            noHelpDesk={error?.noHelpDesk}
            errorMessageArray={this.props.errorMessageArray}
            key={index}
          />
        );
      });
    }
  }
}
export default MultipleErrorDisplay;
