import React from "react";
import { Card, Tab, Table, Tabs } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { displaySemester } from "../../../utils/functions";

export default class CourseTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      student: this.props.student,
      courses: [], //this.props.courses,
      JDCourses: this.props.courses?.JD ? this.props.courses.JD.courses : null,
      LLMCourses: this.props.courses?.LLM
        ? this.props.courses.LLM.courses
        : null,
      OtherCourses: this.props.courses?.Other ? this.props.courses.Other.courses : null,
      showJD: false,
      showLLM: false,
      showOther: false,
    };
    this.lastSemester = -1;

    this.handleSelect = this.handleSelect.bind(this);
    this.checkDegree = this.checkDegree.bind(this);
  }

  async componentDidMount() {
    await this.checkDegree();
  }

  checkDegree() {
    let courses = this.state;

    if (this.props.courses?.JD) {
      this.setState({ showJD: true });
      courses = this.state.JDCourses;
    } else if (this.props.courses?.LLM) {
      this.setState({ showLLM: true });
      courses = this.state.LLMCourses;
    } else if (this.props.courses?.Other) {
      this.setState({ showOther: true });
      courses = this.state.OtherCourses;
    } else {
      courses = this.props.courses;
    }
    this.setState({ courses });

    if (window.location.pathname.startsWith("/graduate-directory")) {
      if (this.props.courses?.JD && this.props.courses.JD.completed) {
        this.handleSelect("JD");
      } else if (this.props.courses?.LLM && this.props.courses.LLM.completed) {
        this.handleSelect("LLM");
      }
    } else if (this.props.showJD && this.props.showLLM && !this.props.showOther) {
      if (!this.props.courses?.JD.completed) {
        this.handleSelect("JD");
      } else if (!this.props.courses?.LLM.completed) {
        this.handleSelect("LLM");
      }
    } else if (this.props.showJD && this.props.showLLM && this.props.showOther) {
      if (!this.props.courses?.JD.completed) {
        this.handleSelect("JD");
      } else if (!this.props.courses?.LLM.completed) {
        this.handleSelect("LLM");
      } else {
        this.handleSelect("Other");
      }
    }
  }

  handleSelect(eventKey) {
    if (eventKey === "JD") {
      this.setState({ showLLM: false });
      this.setState({ showJD: true });
      this.setState({showOther: false});
      this.setState({ activeKey: "JD" });
      this.setState({ courses: this.state.JDCourses });
    } else if (eventKey === "LLM") {
      this.setState({ showJD: false });
      this.setState({ showLLM: true });
      this.setState({showOther: false});
      this.setState({ activeKey: "LLM" });
      this.setState({ courses: this.state.LLMCourses });
    } else if ( eventKey === "Other") {
      this.setState({ showJD: false });
      this.setState({ showLLM: false });
      this.setState({showOther: true});
      this.setState({ activeKey: "Other" });
      this.setState({ courses: this.state.OtherCourses });
    }
  }

  buildTable(course, index) {
    const key = course.semester + course.catalog_number + course.section_number + course.catalog_title;
    if (this.lastSemester === course.semester) {
      return (
        <tr key={key}>
          <td width="10%" key={key + "sem"}>{course.semester}</td>
          <td width="8%" key={key + "cat"}>{course.catalog_number}</td>
          <td width="5%" key={key + "sec"}>{course.section_number}</td>
          <td key={key + "det"}>
            {course.catalog_number === "790R" &&
            course.instructors[0]?.last_name !== "[object Object]"
              ? course.catalog_title +
                " - " +
                course.instructors[0]?.first_name +
                " " +
                course.instructors[0]?.last_name
              : course.catalog_title}
          </td>
          <td width="5%" key={key + "rem"}>{course.remote ? "Yes": "No" }</td>
          {(this.props.roles.admin ||
            this.props.roles.registrar ||
            this.state.student) && (
            <>
              <td width="8%" key={key + "ch"}>{course.credit_hour}</td>
              {!course.grade_is_released ? (
                <OverlayTrigger
                  placement={"top"}
                  key={key + "ot"}
                  overlay={
                    <Tooltip id={`tooltip-${"top"}`} key={key + "tt"}>
                      This grade has not been released
                    </Tooltip>
                  }
                >
                  <td
                    width="8%"
                    style={{
                      color: 
                        course?.grade_is_released === false ? "#5bb0e2" : "",
                    }}
                    key={key + "gr"}
                  >
                   
                    {course.grade === null
                      ? course.grade
                      : course.grade.length > 1
                      ? course.grade.slice(0, 1) +
                        "." +
                        course.grade.slice(1, 2)
                      : course.grade}
                  </td>
                </OverlayTrigger>
              ) : (
                <td
                  width="8%"
                  style={{
                    color: course?.grade_is_released === false ? "#5bb0e2" : "",
                  }}
                  key={key + "grd"}
                >
                  {course.grade === null
                    ? course.grade
                    : course.grade.length > 1
                    ? course.grade.slice(0, 1) + "." + course.grade.slice(1, 2)
                    : course.grade}
                </td>
              )}
            </>
          )}
        </tr>
      );
    } else {
      this.lastSemester = course.semester;
      let [yearNumber, semesterString] = displaySemester(course.semester);
      return (
        <React.Fragment key={key + "rf"}>
          <tr  key={key + "2"}>
            <td
              className="text-center"
              colSpan="7"
              style={{ fontWeight: "bold" }}
              key={key + "td2"}
            >
              {semesterString} {yearNumber}
            </td>
          </tr>
          <tr key={key + "3"}>
            <td width="fit-content(10%)" key={key + "sem2"}>{course.semester}</td>
            <td width="fit-content(8%)" key={key + "cat2"}>{course.catalog_number}</td>
            <td width="fit-content(5%)" key={key + "sec2"}>{course.section_number}</td>
            <td width="50%" key={key + "det2"}>
              {course.catalog_number === "790R" && course.instructors
                ? course.catalog_title +
                  (course.instructors[0]?.first_name ||
                  course.instructors[0]?.last_name
                    ? " - "
                    : "") +
                  (course.instructors[0]?.first_name
                    ? course.instructors[0]?.first_name
                    : "") +
                  " " +
                  (course.instructors[0]?.last_name
                    ? course.instructors[0]?.last_name
                    : "")
                : course.catalog_title}
            </td>
            <td width="5%" key={key + "rem2"}>{course.remote ? "Yes": "No" }</td>
            {(this.props.roles.admin ||
              this.props.roles.registrar ||
              this.state.student) && (
              <>
                <td width="fit-content(15%)" key={key + "ch2"}>{course.credit_hour}</td>
                {!course.grade_is_released ? (
                <OverlayTrigger
                  placement={"top"}
                  key={key + "ot"}
                  overlay={
                    <Tooltip id={`tooltip-${"top"}`} key={key + "tt"}>
                      This grade has not been released
                    </Tooltip>
                  }
                >
                  <td
                    width="8%"
                    style={{
                      color: 
                        course?.grade_is_released === false ? "#5bb0e2" : "",
                    }}
                    key={key + "gr"}
                  >
                   
                    {course.grade === null
                      ? course.grade
                      : course.grade.length > 1
                      ? course.grade.slice(0, 1) +
                        "." +
                        course.grade.slice(1, 2)
                      : course.grade}
                  </td>
                </OverlayTrigger>
              ) : (
                <td
                  width="8%"
                  style={{
                    color: course?.grade_is_released === false ? "#5bb0e2" : "",
                  }}
                  key={key + "grd"}
                >
                  {course.grade === null
                    ? course.grade
                    : course.grade.length > 1
                    ? course.grade.slice(0, 1) + "." + course.grade.slice(1, 2)
                    : course.grade}
                </td>
              )}
              </>
            )}
          </tr>
        </React.Fragment>
      );
    }
  }

  render() {
    return (
      <React.Fragment>
        <Card className="shadow mb-4 bg-light">
          <Tabs
            id="tab-degree"
            activeKey={this.state.activeKey}
            transition={true}
            onSelect={this.handleSelect}
            className="mb-2"
          >
            {this.props.courses?.JD && (
              <Tab
                eventKey="JD"
                title={
                  this.props.courses.JD.completed
                    ? `JD${
                        this.props.courses?.JD && this.props.courses?.LLM
                          ? " - Degree Complete"
                          : ""
                      }`
                    : `JD${
                        this.props.courses?.JD && this.props.courses?.LLM
                          ? " - Degree Not Complete"
                          : ""
                      }`
                }
              ></Tab>
            )}
            {this.props.courses?.LLM && (
              <Tab
                eventKey="LLM"
                title={
                  this.props.courses.LLM.completed
                    ? `LLM${
                        this.props.courses?.JD && this.props.courses?.LLM
                          ? " - Degree Complete"
                          : ""
                      }`
                    : `LLM${
                        this.props.courses?.JD && this.props.courses?.LLM
                          ? " - Degree Not Complete"
                          : ""
                      }`
                }
              ></Tab>
            )}
            {this.props.courses?.Other && (
              <Tab
                eventKey="Other"
                title={`Other`}
              ></Tab>
            )}
          </Tabs>
          <Table className="m-0 p-0" striped bordered hover responsive>
            <thead>
              <tr key="header">
                <th>Semester</th>
                <th>Course</th>
                <th>Section</th>
                <th>Course Title</th>
                <th>Remote</th>
                <th>Hours</th>
                {!this.props.roles.operator && (
                  <>
                    <th>GD</th>
                  </>
                )}
              </tr>
            </thead>
            <tbody>
              {this.state.courses.map((course, index) =>
                this.buildTable(course, index)
              )}
            </tbody>
          </Table>
        </Card>
      </React.Fragment>
    );
  }
}
