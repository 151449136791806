import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Row, Col } from "react-bootstrap";

class GraduationRequirementsJD extends React.Component {
  render() {
    return (
      <Row>
        <Col xs="6">
          <Row>
            <Col xs="12">
              <strong>First-year classes:</strong>
            </Col>
            <Col xs="12">
              Civil Procedure{" "}
              {this.props.gradRequirementsMet.civil_procedure ? (
                <FontAwesomeIcon style={{ color: "002e5d" }} icon={faCheck} />
              ) : (
                <FontAwesomeIcon style={{ color: "002e5d" }} icon={faTimes} />
              )}
            </Col>
            <Col xs="12">
              Contracts{" "}
              {this.props.gradRequirementsMet.contracts ? (
                <FontAwesomeIcon style={{ color: "002e5d" }} icon={faCheck} />
              ) : (
                <FontAwesomeIcon style={{ color: "002e5d" }} icon={faTimes} />
              )}
            </Col>
            <Col xs="12">
              Criminal Law{" "}
              {this.props.gradRequirementsMet.criminal_law ? (
                <FontAwesomeIcon style={{ color: "002e5d" }} icon={faCheck} />
              ) : (
                <FontAwesomeIcon style={{ color: "002e5d" }} icon={faTimes} />
              )}
            </Col>
            <Col xs="12">
              Introduction to Legal Research and Writing{" "}
              {this.props.gradRequirementsMet
                .intro_to_legal_research_writing ? (
                <FontAwesomeIcon style={{ color: "002e5d" }} icon={faCheck} />
              ) : (
                <FontAwesomeIcon style={{ color: "002e5d" }} icon={faTimes} />
              )}
            </Col>
            <Col xs="12">
              Introduction to Advocacy{" "}
              {this.props.gradRequirementsMet.intro_to_advocacy ? (
                <FontAwesomeIcon style={{ color: "002e5d" }} icon={faCheck} />
              ) : (
                <FontAwesomeIcon style={{ color: "002e5d" }} icon={faTimes} />
              )}
            </Col>
            <Col xs="12">
              Legislation and Regulation{" "}
              {this.props.gradRequirementsMet.legislation_regulation ? (
                <FontAwesomeIcon style={{ color: "002e5d" }} icon={faCheck} />
              ) : (
                <FontAwesomeIcon style={{ color: "002e5d" }} icon={faTimes} />
              )}
            </Col>
            <Col xs="12">
              Property{" "}
              {this.props.gradRequirementsMet.property ? (
                <FontAwesomeIcon style={{ color: "002e5d" }} icon={faCheck} />
              ) : (
                <FontAwesomeIcon style={{ color: "002e5d" }} icon={faTimes} />
              )}
            </Col>
            <Col xs="12">
              Structures of the Constitution{" "}
              {this.props.gradRequirementsMet.structures_constitution ? (
                <FontAwesomeIcon style={{ color: "002e5d" }} icon={faCheck} />
              ) : (
                <FontAwesomeIcon style={{ color: "002e5d" }} icon={faTimes} />
              )}
            </Col>
            <Col xs="12">
              Torts{" "}
              {this.props.gradRequirementsMet.torts ? (
                <FontAwesomeIcon style={{ color: "002e5d" }} icon={faCheck} />
              ) : (
                <FontAwesomeIcon style={{ color: "002e5d" }} icon={faTimes} />
              )}
            </Col>
            <Col xs="12">
              <strong>Other required classes:</strong>
            </Col>
            <Col xs="12">
              Professional Responsibility{" "}
              {this.props.gradRequirementsMet.prof_responsibility ? (
                <FontAwesomeIcon style={{ color: "002e5d" }} icon={faCheck} />
              ) : (
                <FontAwesomeIcon style={{ color: "002e5d" }} icon={faTimes} />
              )}
            </Col>
            <Col xs="12">
              <strong>Misc:</strong>
            </Col>
            <Col xs="12">
              Skills{" "}
              {this.props.gradRequirementsMet.library_skills ? (
                <FontAwesomeIcon style={{ color: "002e5d" }} icon={faCheck} />
              ) : (
                <FontAwesomeIcon style={{ color: "002e5d" }} icon={faTimes} />
              )}
            </Col>
            <Col xs="12">
              Substantial Writing{" "}
              {this.props.gradRequirementsMet.substantial_writing ? (
                <FontAwesomeIcon style={{ color: "002e5d" }} icon={faCheck} />
              ) : (
                <FontAwesomeIcon style={{ color: "002e5d" }} icon={faTimes} />
              )}
            </Col>
          </Row>
        </Col>
        <Col xs="6">
          <Row>
            <Col xs="12">
              <strong>Credits:</strong>
            </Col>
            <Col xs="12">
              Credits Earned: {this.props.gradRequirementsMet.credits}*
            </Col>
            <Col xs="12">
              Credits Currently Enrolled In:{" "}
              {this.props.gradRequirementsMet.current_credits}*
            </Col>
            <Col xs="12">
              Required credits:{" "}
              {this.props.gradRequirementsMet.required_credits}
            </Col>
            <Col xs="12">
              *Not all credits count towards graduation. See{" "}
              <a
                href="https://jrcb-course-catalog.byu.edu/graduation-requirements.php"
                target="_blank"
                rel="noreferrer"
              >
                this link
              </a>{" "}
              for full details.
            </Col>
            <Col xs="12">
              <strong>Credit details:</strong>
            </Col>
            <Col xs="12">
              Pass/Fail: {this.props.gradRequirementsMet.pass_fail_credits}
            </Col>
            <Col xs="12">
              Graded: {this.props.gradRequirementsMet.graded_credits}
            </Col>
            {/* <Col xs="12">
              21 Hour Bucket:{" "}
              {
                get21HourBucketCredits(
                  this.props.student.courses
                ) // FIXME: Move logic to backend
              }
            </Col>
            <Col xs="12">
              Joint Degree Hours:{" "}
              {
                getJointDegreeCredits(
                  this.props.student.courses
                ) // FIXME: Move logic to backend
              }
            </Col>
            <Col xs="12">
              Other Law School:{" "}
              {
                getOtherLawSchoolCredits(
                  this.props.student.courses
                ) // FIXME: Move logic to backend
              }
            </Col> */}
            <Col xs="12">
              <strong>GPA:</strong>
            </Col>
            <Col xs="12">
              {this.props.student.gpa}{" "}
              {this.props.gradRequirementsMet.gpa_requirement_met ? (
                <FontAwesomeIcon style={{ color: "002e5d" }} icon={faCheck} />
              ) : (
                <FontAwesomeIcon style={{ color: "002e5d" }} icon={faTimes} />
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

export default GraduationRequirementsJD;
