import React from "react";
import { Switch } from "react-router-dom";
import RoleCheckRoute from "./components/auth/RoleCheckRoute";
import CourseDirectory from "./pages/CourseDirectory";
import CreateStudentPage from "./pages/CreateStudentPage";
import CreateUserPage from "./pages/CreateUserPage";
import DeletedFaculty from "./pages/DeletedFaculty";
import DeletedStudents from "./pages/DeletedStudents";
import Departments from "./pages/Departments";
import ErrorPage from "./pages/ErrorPage";
import FacultyDirectory from "./pages/FacultyDirectory";
import FacultyInformationPage from "./pages/FacultyInformationPage";
import GlobalRepUtil from "./pages/GlobalRepUtil";
import GraduateArchive from "./pages/GraduateArchive";
import GraduateDirectory from "./pages/GraduateDirectory";
import GraduationEvaluation from "./pages/GraduationEvaluation";
import Home from "./pages/Home";
import MassAdvance from "./pages/MassAdvance";
import StudentDirectory from "./pages/StudentDirectory";
import StudentInformationPage from "./pages/StudentInformationPage";
import StudentRecord from "./pages/StudentRecord";
import NotAuthorizedPage from "./pages/NotAuthorizedPage";
import LoggedOutPage from "./pages/LoggedOutPage";

const Routes = ({userDetails}) => {
  const admin = "admin";
  const registrar = "registrar";
  const student = "student";
  const faculty = "faculty";
  const graduate = "graduate";
  const operator = "operator";
  const secretary = "secretary";
  const [roles, setRoles] = React.useState({
    admin: false,
    registrar: false,
    student: false,
    faculty: false,
    graduate: false,
    operator: false,
    secretary: false,
  });
  const [gotRole, setGotRole] = React.useState(false);

  React.useEffect(() => {
    try {
      setGotRole(false);
      let devBooleanRoles = null;
      if (process.env.REACT_APP_STAGE === "dev") {
        if (sessionStorage.getItem("devRoles")) {
          devBooleanRoles = JSON.parse(sessionStorage.getItem("devRoles"));
        }
      }
      const roles = userDetails.roles;
      const booleanRoles = {
        admin: false,
        registrar: false,
        student: false,
        faculty: false,
        graduate: false,
        operator: false,
        secretary: false,
      };
      roles.forEach((role) => {
        booleanRoles[role] = true;
      });

      if (devBooleanRoles) {
        setRoles(devBooleanRoles);
      } else {
        setRoles(booleanRoles);
      }
      setGotRole(true);
    } catch (error) {
      console.log('Error caught in routes.jsx, resetting roles to empty', error);
      setRoles([]);
      setGotRole(true);
    }
  }, [userDetails]);

  if (gotRole === false) {
    return (<NotAuthorizedPage />);
  }

  return (
    <Switch>
      <RoleCheckRoute
        path="/students/:studentId?/:subPage?"
        exact
        allowedRoles={[student]}
        roles={roles}
        Component={StudentRecord}
      />

      {/*           
          FIXME: This needs to access student Record, however, there are some issues,
          but only some are known like Certain things need to be blocked for graduates in student record */}

      <RoleCheckRoute
        path="/graduates/:studentId?/:subPage?"
        exact
        allowedRoles={[graduate]}
        roles={roles}
        Component={StudentRecord}
      />
      {/* <RoleCheckRoute
            path="/graduate-archive/information/:page?/:studentId?"
            exact
            allowedRoles={[graduate]}
            Component={GraduateArchive}
          />  */}
      <RoleCheckRoute
        path="/enrollment/:page?/:studentId?"
        exact
        roles={roles}
        allowedRoles={[admin, registrar, student, graduate]}
        Component={StudentInformationPage}
      />
      <RoleCheckRoute
        path="/student-directory/:page?/:byuId?/:subPage?"
        exact
        roles={roles}
        allowedRoles={[admin, registrar, student, faculty, operator, secretary]}
        Component={StudentDirectory}
      />
      <RoleCheckRoute
        path="/graduate-archive/:page?/:studentId?"
        exact
        roles={roles}
        allowedRoles={[admin, registrar, graduate, operator]}
        Component={GraduateArchive}
      />
      <RoleCheckRoute
        path="/graduate-directory/:page?/:byuId?"
        exact
        roles={roles}
        allowedRoles={[admin, registrar, faculty, operator, secretary]}
        Component={GraduateDirectory}
      />
      <RoleCheckRoute
        path="/course-directory/:semester?/:courseNum?/:section?/:subPage?"
        exact
        roles={roles}
        allowedRoles={[admin, registrar, faculty, student, operator, secretary]}
        Component={CourseDirectory}
      />
      <RoleCheckRoute
        path="/faculty-directory/:byuId?"
        exact
        roles={roles}
        allowedRoles={[admin, registrar, student, faculty, operator, secretary]}
        Component={FacultyDirectory}
      />
      <RoleCheckRoute
        path="/faculty/:tab?/"
        exact
        roles={roles}
        allowedRoles={[faculty, registrar, operator, admin, secretary]}
        Component={FacultyInformationPage}
      />
      <RoleCheckRoute
        path="/deleted-students"
        exact
        roles={roles}
        allowedRoles={[admin, registrar]}
        Component={DeletedStudents}
      />
      <RoleCheckRoute
        path="/mass-graduate/:page?/:byuId?/:subPage?"
        exact
        roles={roles}
        allowedRoles={[admin, registrar]}
        Component={GraduationEvaluation}
      />
      <RoleCheckRoute
        path="/deleted-faculty"
        exact
        roles={roles}
        allowedRoles={[admin]}
        Component={DeletedFaculty}
      />
      <RoleCheckRoute
        path="/create-student"
        exact
        allowedRoles={[admin, registrar, operator]}
        Component={CreateStudentPage}
        roles={roles}
      />
      <RoleCheckRoute
        path="/create-user"
        exact
        allowedRoles={[admin, registrar]}
        Component={CreateUserPage}
        roles={roles}
      />
      <RoleCheckRoute
        path="/global-rep-util"
        exact
        allowedRoles={[admin, registrar, operator, secretary]}
        Component={GlobalRepUtil}
        roles={roles}
      />
      <RoleCheckRoute
        path="/departments"
        exact
        roles={roles}
        allowedRoles={[admin]}
        Component={Departments}
      />
      <RoleCheckRoute
        path="/mass-advance/:page?/:byuId?/:subPage?"
        exact
        allowedRoles={[admin, registrar]}
        Component={MassAdvance}
        roles={roles}
      />
      <RoleCheckRoute
        path="/logged-out"
        exact
        noHeaderButtons
        allowedRoles={"any"}
        Component={LoggedOutPage}
        roles={roles}
      />
      <RoleCheckRoute
        path="/"
        exact
        roles={roles}
        allowedRoles={[
          admin,
          registrar,
          student,
          faculty,
          graduate,
          operator,
          secretary,
        ]}
        Component={Home}
      />
      <RoleCheckRoute
        path="/"
        roles={roles}
        allowedRoles={[
          admin,
          registrar,
          student,
          faculty,
          graduate,
          operator,
          secretary,
        ]}
        Component={ErrorPage}
      />
    </Switch>
  );
};

export default Routes;
