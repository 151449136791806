import React from "react";
import {
  Modal,
  Button,
  Form,
  Col,
  Row,
  Container,
  Spinner,
} from "react-bootstrap";

import ErrorDisplay from "../../ErrorDisplay";

class ArchiveStudentModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      archiveComments: this.props.comments,
      archiveClass: "",
      archiveStatus: "",
      archiveLoading: false,
    };
    this.handleArchiveChange = this.handleArchiveChange.bind(this);
    this.archiveStudent = this.archiveStudent.bind(this);
    this.unarchiveStudent = this.unarchiveStudent.bind(this);
  }
  componentDidMount() {
    if (this.props.year_in_program === "A") {
      this.setState({
        archiveComments: "Unarchived: \n" + this.props.comments,
      });
    } else {
      this.setState({
        archiveComments: "Archive: \n" + this.props.comments,
      });
    }
  }

  archiveStudent() {
    this.setState({ archiveLoading: true });
    this.props.saveArchiveValues(
      this.state.archiveClass,
      this.state.archiveStatus,
      this.state.archiveComments
    );
    this.props.archiveApi();
  }

  unarchiveStudent(e) {
    this.setState({ archiveLoading: true });
    this.props.saveArchiveValues(
      this.state.archiveClass,
      this.state.archiveStatus,
      this.state.archiveComments
    );
    this.props.handleFormSubmit(e);
  }

  handleArchiveChange(e) {
    const { id, value } = e.target;

    switch (id) {
      case "enrollment-status":
        this.setState({ archiveStatus: value });
        break;
      case "class-year":
        this.setState({ archiveClass: value });
        break;
      case "comments":
        this.setState({ archiveComments: value });
        break;
      default:
        break;
    }
  }

  render() {
    let {
      show,
      toggleArchiveModal,
      archive,
    } = this.props;

    return (
      <Modal show={show} onHide={toggleArchiveModal}>
        {!archive ? (
          <Container>
            <Modal.Header closeButton={toggleArchiveModal}>
              <Modal.Title>Archive Student</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              To archive a student, please add explanation:
              <Form.Group as={Row} className="mb-2 align-items-center">
                <Form.Label column xs="auto">
                  <strong>Enrollment Status:</strong>
                </Form.Label>
                <Col>
                  <Form.Select
                    id="enrollment-status"
                    size="sm"
                    value={this.state.archiveStatus}
                    onChange={this.handleArchiveChange}
                    isInvalid={
                      !(
                        this.state.archiveStatus === "T" ||
                        this.state.archiveStatus === "NL" ||
                        this.state.archiveStatus === "W" ||
                        this.state.archiveStatus === "D"
                      )
                    }
                  >
                    <option>Select Status</option>
                    <option value="T" key="T">
                      Transfer
                    </option>
                    <option value="NL" key="NL">
                      Non-Law
                    </option>
                    <option value="W" key="W">
                      Withdrawn
                    </option>
                    <option value="D" key="D">
                      Dismissed
                    </option>
                  </Form.Select>
                </Col>
              </Form.Group>
              <Form.Group>
                <Form.Label>
                  <strong>Please add comments:</strong>
                </Form.Label>
                <Form.Control
                  id="comments"
                  as="textarea"
                  rows={3}
                  size="sm"
                  isInvalid={
                    this.state.archiveComments?.length === 0 ||
                    this.state.archiveComments === null
                  }
                  value={this.state.archiveComments}
                  onChange={this.handleArchiveChange}
                />
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Container>
                <strong>NOTE:</strong> Make any changes before you archive
                student. Once student is archived, you cannot edit.
              </Container>
              {this.state.archiveLoading ? (
                <h5>
                  {" "}
                  <Spinner animation="border" /> Archiving...
                </h5>
              ) : (
                <div>
                  <Button variant="secondary" onClick={toggleArchiveModal}>
                    {" "}
                    Cancel
                  </Button>{" "}
                  <Button
                    variant="info"
                    onClick={this.archiveStudent}
                    disabled={
                      this.state.archiveComments?.length === 0 ||
                      this.state.archiveComments === null ||
                      !(
                        this.state.archiveStatus === "T" ||
                        this.state.archiveStatus === "NL" ||
                        this.state.archiveStatus === "W" ||
                        this.state.archiveStatus === "D"
                      )
                    }
                  >
                    {" "}
                    Archive{" "}
                  </Button>{" "}
                </div>
              )}
            </Modal.Footer>
          </Container>
        ) : (
          <Container>
            <Modal.Header closeButton={toggleArchiveModal}>
              <Modal.Title>Unarchive Student</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              To unarchive a student, please fill out the following:
              <Form.Group as={Row} className="mb-2 align-items-center">
                <Form.Label column xs="auto">
                  <strong>Enrollment Status:</strong>
                </Form.Label>
                <Col>
                  <Form.Select
                    id="enrollment-status"
                    size="sm"
                    value={this.state.archiveStatus}
                    onChange={this.handleArchiveChange}
                    isInvalid={
                      !(
                        this.state.archiveStatus === "NL" ||
                        this.state.archiveStatus === "JD" ||
                        this.state.archiveStatus === "LLM" ||
                        this.state.archiveStatus === "V"
                      )
                    }
                  >
                    <option>Select Status</option>
                    <option value="NL" key="NL">
                      Non-Law
                    </option>
                    <option value="JD" key="JD">
                      JD
                    </option>
                    <option value="LLM" key="LLM">
                      LLM
                    </option>
                    <option value="V" key="V">
                      Visiting
                    </option>
                  </Form.Select>
                </Col>

                <Form.Label column xs="auto">
                  <strong>Class:</strong>
                </Form.Label>
                <Col>
                  <Form.Select
                    id="class-year"
                    size="sm"
                    value={this.archiveStatus}
                    onChange={this.handleArchiveChange}
                    isInvalid={
                      !(
                        this.state.archiveClass === "1" ||
                        this.state.archiveClass === "2" ||
                        this.state.archiveClass === "3" ||
                        this.state.archiveClass === "LLM" ||
                        this.state.archiveClass === "NL"
                      )
                    }
                  >
                    <option>Select Class</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="LLM">LLM</option>
                    <option value="NL">Non-Law</option>
                  </Form.Select>
                </Col>
              </Form.Group>
              <Form.Group>
                <Form.Label>
                  <strong>Please add comments:</strong>
                </Form.Label>
                <Form.Control
                  id="comments"
                  as="textarea"
                  rows={3}
                  size="sm"
                  value={this.state.archiveComments}
                  onChange={this.handleArchiveChange}
                  isInvalid={
                    this.state.archiveComments?.length === 0 ||
                    this.state.archiveComments === null
                  }
                />
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Container>
                <strong>NOTE:</strong> Unarchiving a student puts them back in
                student directory.
              </Container>
              {this.state.archiveLoading ? (
                <h5>
                  {" "}
                  <Spinner animation="border" /> Unarchiving...
                </h5>
              ) : (
                <div>
                  <Button variant="secondary" onClick={toggleArchiveModal}>
                    {" "}
                    Cancel
                  </Button>{" "}
                  <Button
                    variant="info"
                    onClick={this.unarchiveStudent}
                    disabled={
                      this.state.archiveComments?.length === 0 ||
                      this.state.archiveComments === null ||
                      !(
                        this.state.archiveClass === "1" ||
                        this.state.archiveClass === "2" ||
                        this.state.archiveClass === "3" ||
                        this.state.archiveClass === "LLM" ||
                        this.state.archiveClass === "NL"
                      ) ||
                      !(
                        this.state.archiveStatus === "NL" ||
                        this.state.archiveStatus === "JD" ||
                        this.state.archiveStatus === "LLM" ||
                        this.state.archiveStatus === "V"
                      )
                    }
                  >
                    {" "}
                    Unarchive{" "}
                  </Button>{" "}
                </div>
              )}
            </Modal.Footer>
          </Container>
        )}
        {this.props.errorMessage && (
          <ErrorDisplay errorMessage={this.props.errorMessage} />
        )}
      </Modal>
    );
  }
}

export default ArchiveStudentModal;
