import React from "react";
import { Button, Form, Modal, Spinner, Row, Col } from "react-bootstrap";
import api from "../../services/api";
import { releaseGradesGetCurrentSemester } from "../../utils/functions";
import ErrorDisplay from "../ErrorDisplay";

class OITGradeReportModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      year: 0,
      semester: 0,
      loading: false,
      errorMessage: "",
      customMessage: "",
    };
    this.downloadAllReports = this.downloadAllReports.bind(this);
  }

  async downloadAllReports() {
    this.setState({ loading: true });
    const yearSemester = String(this.state.year) + String(this.state.semester);

    try {
      const url = `reports/oit-grades?format=raw&semester=${yearSemester}`;
      const report = await api.get(url);
      window.open(report.data.url);
    } catch (err) {
      console.error(err);
      this.setState({
        errorMessage: err.response?.data ? err.response.data : err.message,
        customMessage: `Error getting report for semester ${yearSemester}; `,
      });
    }

    this.setState({ loading: false });
    if (this.state.errorMessage === ""){
      this.props.onHide();
    }
  }

  setYearOptions() {
    const thisYear = releaseGradesGetCurrentSemester().slice(0,4);
    let options = [];
    for (let i = thisYear; i >= thisYear - 5; i--) {
      options.push(i);
    }
    return options;
  }

  render() {
    const { show, onHide } = this.props;
    const yearOptions = this.setYearOptions();

    return (
      <Modal show={show} onHide={onHide}>
        <Modal.Header>
          <Modal.Title>OIT Grade Reports</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p> Please select the year and semester of reports you wish</p>
          <Row>
            <Col md={6}>
              <Form.Select
                id="year"
                onChange={(e) => this.setState({ year: e.target.value })}
                disabled={this.state.loading}
                value={this.state.year}
              >
                <option value={0}>Select Year</option>
                {yearOptions.map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </Form.Select>
            </Col>
            <Col md={6}>
              <Form.Select
                id="semester"
                onChange={(e) => this.setState({ semester: e.target.value })}
                disabled={this.state.loading}
                value={this.state.semester}
              >
                <option value={0}>Select Semester</option>
                <option value={5}>Fall</option>
                <option value={1}>Winter</option>
                <option value={3}>Spring</option>
                <option value={4}>Summer</option>
              </Form.Select>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          {this.state.loading ? (
            <Button variant="info" disabled={true}>
              <Spinner
                animation={"border"}
                size={"sm"}
                style={{ marginRight: "0.5rem" }}
              />
              View
            </Button>
          ) : (
            <Button
              variant="info"
              disabled={this.state.year === 0 || this.state.semester === 0}
              onClick={this.downloadAllReports}
            >
              View
            </Button>
          )}
          <Button variant="outline-info" onClick={onHide}>
            Cancel
          </Button>
        </Modal.Footer>
        {this.state.errorMessage && (
          <ErrorDisplay errorMessage={this.state.errorMessage} customMessage={this.state.customMessage} />
        )}
      </Modal>
    );
  }
}

export default OITGradeReportModal;