import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import DeletedRecords from "../components/DeletedRecords";
import ToolsNav from "../components/ToolsNav";

class DeletedStudents extends React.Component {
  render() {
    return (
      <>
        <ToolsNav currentTab="admin-tools" roles={this.props.roles} isLink={true} />
        <Container className="bg-white">
          <Row>
            <Col xs="12" className="mt-4">
              <DeletedRecords
                api="/students/directory?deleted=1"
                postApi="/students/create"
                title="Deleted Students"
                redirectURL="/student-directory/information/"
              />
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default DeletedStudents;
