import React from "react";
import { Button, Modal } from "react-bootstrap";
import api from "../../services/api";
import { encryptString } from "../auth/secureSessionStorage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMask } from "@fortawesome/free-solid-svg-icons";

class Impersonate extends React.Component {
  /**
   * An asynchronous function that impersonates a person by logging in as that person, storing their token in session storage, and reloading the page.
   *
   * @param {Object} person - The person object to impersonate.
   * @return {Promise} A promise that resolves when the impersonation is complete.
   */
  async impersonate(person) {
    sessionStorage.setItem("devRolesOn", false);
    let data = await api
      .get(`/login/impersonate/${person.id}`)
      .then((response) => {
        sessionStorage.setItem("actastoken", response.data);
        console.log(person.id);

        sessionStorage.removeItem("userToken");
      });

    api.get("/login/getinfo", { params: { token: data } }).then((response) => {
      sessionStorage.setItem(
        "userToken",
        encryptString(JSON.stringify(response.data))
      );
    });

    window.location.assign("/");

  }

  render() {
    const { show, onHide, person, index } = this.props;
    return (
      <Modal show={show} onHide={this.onHide}>
        <Modal.Header>
          <Modal.Title>
            Confirm Impersonation
            <FontAwesomeIcon
              icon={faMask}
              style={{ color: "#e6ac00", marginLeft: 5 }}
            />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            You are about to act as {person?.display_name || "the selected person"}. Do you wish to
            continue?
          </p>
          <Button
            variant="primary"
            style={{ margin: 2 }}
            onClick={() => this.impersonate(person)}
          >
            Impersonate
          </Button>
          <Button variant="danger" onClick={onHide} style={{ margin: 2 }}>
            Cancel
          </Button>
        </Modal.Body>
      </Modal>
    );
  }
}

export default Impersonate;
