import React from "react";
import { Button } from "react-bootstrap";
import ErrorDisplay from "../ErrorDisplay";
import Footer from "../Footer";
import Header from "../Header";
import { Route } from "react-router-dom/cjs/react-router-dom.min";

const RoleCheckRoute = (props) => {
  /**
   * Checks if the user has an allowed role.
   *
   * @return {boolean} Returns true if the user has an allowed role, false otherwise.
   */
  const checkRoles = () => {
    if (allowedRoles === "any") {
      return true;
    }
    for (const allowedRole of allowedRoles) {
      if (roles[allowedRole]) return true;
    }
    return false;
  };

  /**
   * Logs the user out by clearing the local storage and session storage.
   *
   * @return {Promise<void>} This function does not return anything.
   */
  const logout = async () => {
    localStorage.clear();
    sessionStorage.clear();
  };

  const { Component, allowedRoles, roles, noHeaderButtons } = props;
  // DPNote: Added
  // console.log(`allowedRoles=${JSON.stringify(allowedRoles)}, roles=${JSON.stringify(roles)}`);

  if (checkRoles(allowedRoles, roles)) {
    return (
      <React.Fragment>
        <div
          className={
            sessionStorage.getItem("actastoken")
              ? "impersonate-wrapper"
              : "page-wrapper"
          }
        >
          <Header
            roles={roles}
            userDetails={props.userDetails}
            noButtons={noHeaderButtons}
          />
          <Route
            path={props.path}
            render={() => <Component roles={roles} {...props} />}
          />
        </div>
        <Footer />
      </React.Fragment>
    );
  } else {
    return (
      <Route
        path={props.path}
        render={() => (
          <React.Fragment>
            <div className="page-wrapper">
              <Header
                noButtons
                roles={"unauthorized"}
                userDetails={props.userDetails}
              />
              <div className="centererror">
                <ErrorDisplay
                  errorMessage={
                    "You were able to authenticate, but you are not authorized to see this page. Contact the Help Desk if this is incorrect"
                  }
                  noHelpDesk={true}
                />
                <div className="text-center">
                  <Button size="lg" onClick={() => logout()}>
                    Try Again
                  </Button>
                </div>
              </div>
            </div>
            <Footer />
          </React.Fragment>
        )}
      />
    );
  }
};

export default RoleCheckRoute;
