import React from "react";
import { Button, Col, Image, Row } from "react-bootstrap";
import { handleImageError } from "../../utils/functions";
import phone from "../../images/phone.png";
import office from "../../images/office.png";
import gender from "../../images/gender.png";
import birthday from "../../images/birthday.png";
import address from "../../images/address.png";
import email from "../../images/email.png";
import { CleanImage } from "../SafeImage";
import CopyIcon from "./CopyIcon";
import Impersonate from "./Impersonate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMask } from "@fortawesome/free-solid-svg-icons";

class SimpleViewPersonRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showImpersonateModal: false,
    };
    this.toggleImpersonateModal = this.toggleImpersonateModal.bind(this);
  }

  toggleImpersonateModal(event) {
    this.setState({ showImpersonateModal: !this.state.showImpersonateModal });
    event.stopPropagation();
  }

  render() {
    const {
      person,
      index,
      dataSet,
      handleDisplayClick,
      fromStudent,
      fromFaculty,
      fromGraduate,
    } = this.props;

    return (
      <React.Fragment>
        <Row
          className="simple-view-row clickable py-2 simple-view-mobile"
          onClick={() => handleDisplayClick(person, false)}
        >
          <Col
            md={2}
            className="d-flex align-items-center justify-content-center"
          >
            <div className="avatar simple-view-img">
              <CleanImage
                src={`${dataSet[index].picture_link}`}
                alt={`headshot`}
                placeholder="person_img"
                onError={(e) => {
                  handleImageError(e, person);
                }}
                person={person}
              />
            </div>
          </Col>
          <Col
            md={2}
            className="d-flex align-content-center flex-wrap simple-view-mobile"
          >
            <Row className="d-flex">
              <strong>
                {person.known_by
                  ? person.known_by + " " + person.last_name
                  : person.first_name + " " + person.last_name}
              </strong>
              {this.props.roles.registrar && (
                <div>
                  <FontAwesomeIcon
                    icon={faMask}
                    style={{ color: "#e6ac00" }}
                    onClick={(event) => {
                      this.toggleImpersonateModal(event);
                    }}
                  />
                </div>
              )}
              <Impersonate
                dataset={dataSet}
                show={this.state.showImpersonateModal}
                onHide={this.toggleImpersonateModal}
                person={person}
              />
            </Row>
          </Col>
          <Col md={2} className="simple-view-text pe-0 simple-view-mobile">
            {fromStudent
              ? person.year_in_program === "A"
                ? "Archived"
                : person.year_in_program === "1" ||
                  person.year_in_program === "2" ||
                  person.year_in_program === "3"
                ? person.year_in_program + "L Year"
                : person.year_in_program
              : fromFaculty
              ? person.category.join(", ")
              : "Graduated " +
                new Date(
                  person.law_degrees[
                    person.law_degrees > 0 ? person.law_degrees.length - 1 : 0
                  ]?.date_degree_received
                ).toLocaleString("en-US", {
                  timeZone: "UTC",
                  year: "numeric",
                })}
          </Col>

          {fromStudent || fromGraduate ? (
            <Col md={3} className="simple-view-text simple-view-mobile">
              {person?.gender && (
                <Row className="d-flex">
                  <img
                    className="simple-view-icon"
                    src={gender}
                    alt={`gender icon`}
                  />
                  <div style={{ flex: 1, padding: 0 }}>
                    Gender: {person.gender === "male" ? "Male" : "Female"}
                  </div>
                </Row>
              )}
              {person?.date_of_birth && !isNaN(new Date(person.date_of_birth).getTime()) && (
                <Row className="d-flex">
                  <img
                    className="simple-view-icon"
                    src={birthday}
                    alt={`birthday icon`}
                  />
                  <div style={{ flex: 1, padding: 0 }}>
                    Birthday:{" "}
                    {new Date(person.date_of_birth).toLocaleDateString(
                      "en-US",
                      {
                        timeZone: "Etc/UTC",
                        month: "long",
                        day: "numeric",
                      }
                    )}
                  </div>
                </Row>
              )}
              {(person?.city || person?.state || person?.postal_code) && (
                <Row className="d-flex">
                  <img
                    className="simple-view-icon"
                    src={address}
                    alt={`address icon`}
                  />
                  <div style={{ flex: 1, padding: 0 }}>
                    Address:{" "}
                    {person?.city +
                      (person?.city && person?.state ? ", " : "") +
                      person?.state +
                      (person?.postal_code && (person?.state || person?.city)
                        ? ", "
                        : "")}
                    {person?.postal_code}
                  </div>
                </Row>
              )}
            </Col>
          ) : (
            <Col md={3} className="simple-view-text simple-view-mobile">
              <Row>{person.department}</Row>
              {person.type && (
                <Row>
                  {
                    person.type.charAt(0).toUpperCase() +
                      person.type.slice(1) /* Capitalize first letter */
                  }
                </Row>
              )}
            </Col>
          )}
          <Col md={3} className="simple-view-text pe-4 simple-view-mobile">
            {person.email && (
              <Row>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    textAlign: "right",
                  }}
                >
                  <img
                    className="simple-view-icon"
                    src={email}
                    alt={`email icon`}
                    style={{ marginRight: "5px" }}
                  />
                    <a
                      href={`mailto:${person.email}`}
                      style={{ color: "black" }}
                      onClick={(e) => e.stopPropagation()}
                    >
                      {person.email}
                    </a>
                    <CopyIcon item={person.email} />
                </div>
              </Row>
            )}
            {person.phone && (
              <Row>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    textAlign: "right",
                  }}
                >
                  <img
                    className="simple-view-icon"
                    src={phone}
                    alt={`phone icon`}
                    style={{ marginRight: "5px" }}
                  />
                  <a
                    href={`tel:${person.phone}`}
                    style={{ color: "black" }}
                    onClick={(e) => e.stopPropagation()}
                  >
                    {person.phone}
                  </a>
                </div>
              </Row>
            )}
            {person.room && (
              <Row
                className="justify-content-end"
                style={{ padding: "0 12px" }}
              >
                <img
                  className="simple-view-icon"
                  src={office}
                  alt={`office icon`}
                />
                {person.room}
              </Row>
            )}
          </Col>
        </Row>
        <hr className="my-1"></hr>
      </React.Fragment>
    );
  }
}

export default SimpleViewPersonRow;
