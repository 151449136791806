import React, { useState, useEffect } from "react";
import { Route, useHistory } from "react-router-dom";
import PublicFacultyInformationPage from "./pages/PublicFacultyInformationPage";
import Routes from "./routes";
import "./styles/App.scss";
import api from "./services/api.js";
import Loading from "./pages/Loading";
import { RedirectToLogin } from "./components/auth/login.jsx";
import NotAuthorizedPage from "./pages/NotAuthorizedPage.jsx";
import NewUserPage from "./pages/NewUserPage.jsx";
import {
  decryptString,
  encryptString,
} from "./components/auth/secureSessionStorage";
import SessionExpiring from "./components/auth/SessionExpiring";

const App = () => {
  const [loading, setLoading] = useState(true);
  const [userInfo, setUserInfo] = useState(null);
  const [authenticated, setAuthenticated] = useState(false);
  const [showSessionExpiring, setShowSessionExpiring] = useState(false);

/**
 * Sets up a timer to check if the token has expired every 15 minutes.
 *
 */
  const checkTokenSetup = () => {
    let refreshTiming = 15 * 60 * 1000; // Check for expired token every 15 minutes.
    const expirationDate = parseInt(sessionStorage.getItem("expirationDate"));

    /**
     * Checks if the token is valid and performs appropriate actions.
     * If the token is expired, it redirects to the logout page.
     * If the token is about to expire, it displays a warning message.
     * The function is scheduled to run again after 15 minutes.
     *
     */
    const checkToken = () => {
      if (expirationDate && (Date.now() > expirationDate)) {
        window.location.assign("/logged-out"); //Direct to logout if token is expired.
      } else if (
        expirationDate &&
        ((expirationDate - Date.now()) < 20 * 60 * 1000)
      ) {
        setShowSessionExpiring(true); //Display warning message 5 minutes before token expiration.
      }
      setTimeout(checkToken, refreshTiming); //Run again in 15 minutes
    };

    setTimeout(checkToken, refreshTiming); //This starts the checkToken loop
  };

/**
 * Sets the user roles based on the given array of roles and sets devRolesOn to false if it is not already set.
 *
 * @param {Array} roles - An array of roles to set for the user.
 */
  const setUserRoles = (roles) => {
    let booleanRoles = {
      admin: false,
      registrar: false,
      student: false,
      faculty: false,
      graduate: false,
      operator: false,
      secretary: false,
    };
    for (const role of roles) {
      if (role in booleanRoles) booleanRoles[role] = true;
    }
    sessionStorage.setItem("devRoles", JSON.stringify(booleanRoles));
    if (!sessionStorage.getItem("devRolesOn")) {
      sessionStorage.setItem("devRolesOn", false);
    }
  };

  const history = useHistory();

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    let token = "";
    if (sessionStorage.getItem("actastoken")) {
      token = sessionStorage.getItem("actastoken");
    } else {
      token = queryParams.get("token");
    }
    // console.log(`url=${JSON.stringify(window.location)} querystring=${JSON.stringify(queryParams)}`);

    if (token && !sessionStorage.getItem("userToken")) {
      api
        .get("/login/getinfo", { params: { token: token } })
        .then((response) => {
          sessionStorage.setItem(
            "userToken",
            encryptString(JSON.stringify(response.data))
          );
          sessionStorage.setItem("idtoken", token); // Save the actual token.  This is needed for all API calls
          const expirationDate = Date.now() + 3.7 * 60 * 60 * 1000; // Set the token to expire in 4 hours.
          sessionStorage.setItem("expirationDate", expirationDate);
          checkTokenSetup();
          setUserInfo(response.data);
          setUserRoles(response.data.roles);
          history.push({ search: "" });
          setLoading(false);
          setAuthenticated(true);
          console.log("getInfo complete token is stored");
        })
        .catch((error) => {
          console.log(error);
          console.log("Not Authenticated. Redirecting to login page...");
          setAuthenticated(false);
          setLoading(false);
        });
    } else if (sessionStorage.getItem("userToken")) {
      console.log("userToken found, using to set authentication");
      setUserInfo(
        JSON.parse(decryptString(sessionStorage.getItem("userToken")))
      );
      checkTokenSetup();
      setLoading(false);
      setAuthenticated(true);
    } else {
      console.log("Not Authenticated. Redirecting to login page...");
      setAuthenticated(false);
      RedirectToLogin();
    }
  }, [history]);

  if (loading) {
    return <Loading />;
  }
  if (!authenticated) {
    return <NotAuthorizedPage />;
  }

  api
    .get(`/person/${userInfo.id}`)
    .then((response) => {
      if (response.status === 404) {
        return <NewUserPage />;
      }
    })
    .catch((error) => {
      console.log(error);
    });

  return (
    <div className="App">
      <header className="App-header">
        <Route
          path="/public-faculty-information/"
          exact
          component={PublicFacultyInformationPage}
        />
        <Route
          path="/public-faculty-information/:name"
          exact
          component={PublicFacultyInformationPage}
        />
        {userInfo && (
          <>
            <SessionExpiring
              key={showSessionExpiring}
              show={showSessionExpiring}
            />
            <Routes userDetails={userInfo} />
          </>
        )}
      </header>
    </div>
  );
};

export default App;
