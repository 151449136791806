import React from "react";
import MassGraduationEvaluation from "../components/graduation_evaulation/MassGraduationEvaluation"
import { withRouter } from "react-router-dom";

class GraduationEvaluation extends React.Component {
  render() {
    return <MassGraduationEvaluation fromStudent {...this.props}/>;
  }
}

export default withRouter(GraduationEvaluation);