import React from "react";
import api from "../services/api";

import {
  Col,
  Row,
  Form,
  Button,
  Container,
  Card,
  Spinner,
  Alert,
} from "react-bootstrap";
import ErrorDisplay from "./ErrorDisplay";
import GraduateToFacultyModal from "./GraduateToFacultyModal";

export default class CreatePerson extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: null,
      loading: false,
      success: false,
      showGraduateToFacultyModal: false,
      personID: "",
      firstName: "",
      middleName: "",
      lastName: "",
      netID: "",
      email: "",
      gender: "male",
      user_type: "student",
      year_in_program: "",
      active_faculty: null,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.isFormValid = this.isFormValid.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.changeType = this.changeType.bind(this);
    this.handleModalCancel = this.handleModalCancel.bind(this);
    this.toggleGraduateFacultyModal =
      this.toggleGraduateFacultyModal.bind(this);
  }

  /**
   * Updates the state based on the input change event.
   *
   * @param {object} e - The input change event.
   */
  handleChange(e) {
    const field = e.target.id;
    const value = e.target.value;
    const checked = e.target.checked;

    switch (field) {
      case "type":
        this.setState({ user_type: value });
        this.setState({ active_faculty: value === "faculty" });
        break;
      case "personID":
        this.setState({ personID: value });
        break;
      case "firstName":
        this.setState({ firstName: value });
        break;
      case "middleName":
        this.setState({ middleName: value });
        break;
      case "lastName":
        this.setState({ lastName: value });
        break;
      case "netID":
        this.setState({ netID: value });
        break;
      case "email":
        this.setState({ email: value });
        break;
      case "gender":
        this.setState({ gender: value });
        break;
      case "year_in_program":
        this.setState({ year_in_program: value });
        break;
      case "active_faculty":
        this.setState({ active_faculty: checked });
      default:
        break;
    }
  }

  /**
   * Cancels the current operation and redirects to the faculty admin tools page.
   */
  handleCancel() {
    window.location.assign("/faculty/admin-tools");
  }

  /**
   * Checks if the form is valid by verifying that all required fields are filled in.
   *
   * @return {boolean} Returns true if all required fields are filled in, false otherwise.
   */
  isFormValid() {
    const form = this.state;
    if (
      !form.personID ||
      !form.firstName ||
      !form.lastName ||
      !form.netID ||
      !form.email ||
      (form.year_in_program === "" && form.type === "student")
    ) {
      return false;
    }
    return true;
  }

  /**
   * Closes the graduate faculty modal.
   */
  handleModalCancel() {
    this.toggleGraduateFacultyModal();
  }

  /**
   * Sets loading to false and toggles the graduate faculty modal by updating the state.
   */
  toggleGraduateFacultyModal() {
    this.setState({
      loading: false,
      showGraduateToFacultyModal: !this.state.showGraduateToFacultyModal,
    });
  }

  /**
   * Posts the info in state to the server and closes the modal.
   *
   * @return {Promise<void>} A promise that resolves when the type is changed.
   */
  async changeType() {
    const person_info = {
      id: this.state.personID,
      email: this.state.email,
      gender: this.state.gender,
      first_name: this.state.firstName,
      middle_name: this.state.middleName,
      last_name: this.state.lastName,
      full_name: this.state.middleName
        ? `${this.state.firstName} ${this.state.middleName} ${this.state.lastName}`
        : `${this.state.firstName} ${this.state.lastName}`,
      net_id: this.state.netID,
      type: this.state.user_type,
    };

    await api.post(`/person/${this.state.personID}`, person_info);

    this.handleModalCancel();
    this.handleSuccess();
  }

  /**
   * Handles the success case by updating the state, displaying a success message,
   * and redirecting the user to the appropriate directory page based on their user type.
   */
  handleSuccess() {
    this.setState({ errorMessage: null, loading: false, success: true });
    setTimeout(() => {
      // give the user time to read the success message before navigating away
      window.location.assign(
        this.props.isStudent || this.state.user_type === "student"
          ? `/student-directory/information/${this.state.personID}`
          : this.props.isFaculty || this.state.user_type === "faculty"
          ? `/faculty-directory/${this.state.personID}`
          : this.state.user_type === "graduate"
          ? `/graduate-directory/information/${this.state.personID}`
          : "/"
      );
    }, 1500);
  }

  /**
   * Handles the form submission for creating a new person.
   * Checks if the person already exists and if not, creates a new person.
   *
   * @param {Event} e - The form submission event.
   * @return {Promise<void>} A promise that resolves when the form submission is complete.
   */
  async handleSubmit(e) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ errorMessage: null, loading: true });

    if (!this.isFormValid()) {
      this.setState({
        errorMessage: "* Required field",
        loading: false,
      });
      return;
    }

    //check to make sure this student doesn't already exist
    let people = [];
    try {
      const { data } = await api.get("/person");
      people = data;
      if (!Array.isArray(people)) {
        throw new Error("Expected an array of people");
      }
    } catch (err) {
      this.setState({
        errorMessage: err.response?.data
          ? err.response.data
          : "Create User failed",
        loading: false,
      });
      console.error(err);
      return;
    }

    const personIndex = people.findIndex(
      (person) => parseInt(person.id) === parseInt(this.state.personID)
    );
    if (personIndex !== -1) {
      if (
        people[personIndex].type === "graduate" &&
        this.state.user_type === "faculty"
      ) {
        this.toggleGraduateFacultyModal();
        return;
      } else {
        this.setState({
          errorMessage: "A person with this ID already exists!",
          loading: false,
        });
        return;
      }
    } else {
      if (this.state.user_type === "graduate") {
        this.setState({ year_in_program: "G" });
      }

      const data = {
        id: this.state.personID,
        email: this.state.email,
        gender: this.state.gender,
        first_name: this.state.firstName,
        middle_name: this.state.middleName,
        last_name: this.state.lastName,
        full_name: this.state.middleName
          ? `${this.state.firstName} ${this.state.middleName} ${this.state.lastName}`
          : `${this.state.firstName} ${this.state.lastName}`,
        net_id: this.state.netID,
        type: this.props.isStudent
          ? "student"
          : this.props.isFaculty
          ? "faculty"
          : this.state.user_type,
        year_in_program: this.state.year_in_program,
        active_faculty: this.state.active_faculty,
      };

      try {
        await api.post(
          "/person/create",
          data
          // this.props.isStudent
          //   ? "/person/create"
          //   : this.props.isFaculty
          //   ? "/person/create"
          //   : "/person/create",
          // data
        );
      } catch (err) {
        this.setState({
          errorMessage: err.response?.data
            ? err.response.data
            : "Create " + this.props.isStudent
            ? "student failed"
            : this.props.isFaculty
            ? "faculty failed"
            : "user failed",
          loading: false,
        });
        console.error(err);
        return;
      }

      this.handleSuccess();
    }
  }

  render() {
    const userType = this.props.isStudent
      ? "Student"
      : this.props.isFaculty
      ? "Faculty"
      : "User";
    return (
      <Container
        className="justify-content-center "
        style={{ display: "flex" }}
      >
        <GraduateToFacultyModal
          show={this.state.showGraduateToFacultyModal}
          onHide={this.handleModalCancel}
          changeType={this.changeType}
        />
        <Card className="mt-4 shadow mb-4" style={{ maxWidth: "60rem" }}>
          <Card.Header as="h3" className="text-center">
            Create a New{" "}
            {userType}{" "}
            Record
          </Card.Header>
          <Card.Body>
            <Form className="my-2 mx-3" onSubmit={this.handleSubmit}>
              <Row className="justify-content-center">
                <Col xs="12">
                  Before creating a new{" "}
                  {userType}{" "}
                  record, make sure that the{" "}
                  {userType}{" "}
                  does not already appear in the{" "}
                  {this.props.isStudent
                    ? "Student Directory"
                    : this.props.isFaculty
                    ? "Faculty and Staff Directory"
                    : "Database"}
                  . It is important to include the Route-Y ID (BYU Net ID),
                  since much of the information throughout the Law School
                  systems is linked together using this ID. Select the SAVE
                  button to create the new record. To cancel the creation,
                  select the CANCEL button.
                </Col>
              </Row>
              {!this.props.isStudent && !this.props.isFaculty && (
                <Form.Group as={Row} className="justify-content-center my-2">
                  <Col xs="12">
                    <Form.Select
                      id="type"
                      defaultValue={this.state.user_type}
                      onChange={this.handleChange}
                    >
                      <option value="">Select User Type</option>
                      <option value="faculty">Faculty</option>
                      <option value="employee">Employee</option>
                    </Form.Select>
                  </Col>
                </Form.Group>
              )}
              <Form.Group as={Row} className="justify-content-center my-2">
                <Col xs="12">
                  <Form.Control
                    placeholder={"BYU ID"}
                    id="personID"
                    value={this.personID}
                    type="number"
                    isInvalid={
                      (this.state.personID.match(/^[0-9]{9}$/g)
                        ? false
                        : true) && this.state.errorMessage
                    }
                    onChange={this.handleChange}
                  />
                  <Form.Control.Feedback type="invalid">
                    * Please enter 9 numbers
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="justify-content-center my-2">
                <Col xs="12">
                  <Form.Control
                    placeholder="First Name"
                    id="firstName"
                    value={this.firstName}
                    isInvalid={!this.state.firstName && this.state.errorMessage}
                    onChange={this.handleChange}
                  />
                  <Form.Control.Feedback type="invalid">
                    *
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="justify-content-center my-2">
                <Col xs="12">
                  <Form.Control
                    placeholder="Middle Name"
                    id="middleName"
                    value={this.middleName}
                    onChange={this.handleChange}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="justify-content-center my-2">
                <Col xs="12">
                  <Form.Control
                    placeholder="Last Name"
                    id="lastName"
                    value={this.lastName}
                    isInvalid={!this.state.lastName && this.state.errorMessage}
                    onChange={this.handleChange}
                  />
                  <Form.Control.Feedback type="invalid">
                    *
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="justify-content-center my-2">
                <Col xs="12">
                  <Form.Control
                    placeholder="Net ID"
                    id="netID"
                    value={this.state.netID}
                    isInvalid={!this.state.netID && this.state.errorMessage}
                    onChange={this.handleChange}
                  />
                  <Form.Control.Feedback type="invalid">
                    *
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>

              <Form.Group as={Row} className="justify-content-center my-2">
                <Col xs="12">
                  <Form.Control
                    placeholder="BYU Law Email"
                    id="email"
                    value={this.state.email}
                    type="email"
                    isInvalid={
                      (this.state.email.match(/^.*[@]{1}.*[.]{1}.*$/)
                        ? false
                        : true) && this.state.errorMessage
                    }
                    //isInvalid={!this.state.email && this.state.errorMessage}
                    onChange={this.handleChange}
                  />
                  <Form.Control.Feedback type="invalid">
                    * Please use pattern: example@law.byu.edu
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>

              <Form.Group as={Row} className="justify-content-center my-2">
                <Col xs="12">
                  <Form.Select
                    id="gender"
                    defaultValue={this.state.gender}
                    onChange={this.handleChange}
                  >
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                  </Form.Select>
                </Col>
              </Form.Group>
              {this.state.user_type === "faculty" && (
                <Form.Group as={Row} className="justify-content-center my-2">
                  <Col xs="12" className="d-flex">
                    Current Faculty
                    <Form.Switch
                      id="active_faculty"
                      checked={this.state.active_faculty}
                      onChange={this.handleChange}
                      className="mx-3"
                    />
                  </Col>
                </Form.Group>
              )}
              {this.props.isStudent && (
                <Form.Group as={Row} className="justify-content-center my-2">
                  <Col xs="12">
                    <Form.Select
                      id="year_in_program"
                      defaultValue={this.state.year_in_program}
                      onChange={this.handleChange}
                      isInvalid={
                        this.state.year_in_program === "" &&
                        this.state.errorMessage
                      }
                    >
                      <option value="">Select Year in Program</option>
                      <option>1</option>
                      <option>2</option>
                      <option>3</option>
                      <option>LLM</option>
                      <option value="NL">Non-Law</option>
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      * Please select Year in Program
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
              )}
              <Form.Group as={Row} className="justify-content-center my-2">
                <Col
                  as={Button}
                  className="m-2"
                  xs="9"
                  sm="4"
                  md="2"
                  type="submit"
                  variant={this.state.success ? "success" : "info"}
                  disabled={
                    this.state.personID.match(/^[0-9]{9}$/g)
                      ? false
                      : this.state.loading || this.state.success
                  }
                >
                  {this.state.loading ? (
                    <React.Fragment>
                      <Spinner size="sm" animation="border" />
                      <strong> Saving...</strong>
                    </React.Fragment>
                  ) : this.state.success ? (
                    <strong>Success!</strong>
                  ) : (
                    <strong>Save</strong>
                  )}
                </Col>
                <Col
                  as={Button}
                  className="m-2"
                  xs="9"
                  sm="4"
                  md="2"
                  variant="outline-info"
                  onClick={this.handleCancel}
                  disabled={this.state.loading}
                >
                  <strong>CANCEL</strong>
                </Col>
              </Form.Group>
              {this.state.errorMessage && (
                <React.Fragment>
                  {this.state.errorMessage.startsWith("* Required") ? (
                    <Row className="justify-content-center">
                      <Col className="m-2" xs="auto">
                        <Alert className="text-center shadow" variant="danger">
                          {this.state.errorMessage}
                        </Alert>
                      </Col>
                    </Row>
                  ) : (
                    <ErrorDisplay
                      errorMessage={this.state.errorMessage}
                      customMessage={"Error Creating Student: "}
                      noHelpDesk={true}
                    />
                  )}
                </React.Fragment>
              )}
            </Form>
          </Card.Body>
        </Card>
      </Container>
    );
  }
}
