import React from "react";
import EditProgressReport from "./EditProgressReport";
import DisplayProgressReport from "./DisplayProgressReport";
import ErrorDisplay from "../../ErrorDisplay";

export default class ProgressReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      endpoint: this.props.isGraduate ? "graduates" : "students",
      student: this.props.student,
      errorMessage: null,
    };

    this.updateStudent = this.props.getStudent;
  }

  render() {
    const { edit, changeEdit, roles, isGraduate } = this.props;

    if (this.state.errorMessage) {
      return <ErrorDisplay errorMessage={this.state.errorMessage} />;
    } else if (edit) {
      return (
        <EditProgressReport
          onClose={changeEdit}
          updateStudent={this.updateStudent}
          student={this.state.student}
          roles={this.props.roles}
          isGraduate={isGraduate}
        />
      );
    } else {
      return (
        <DisplayProgressReport
          student={this.state.student}
          roles={roles}
          updateStudent={this.updateStudent}
          isGraduate={isGraduate}
        />
      );
    }
  }
}
