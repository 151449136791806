import React from "react";
import api from "../../services/api";

/**
 * Redirects the user to the login page based on the current environment.
 *
 * @return {boolean} Returns `true` if the user is successfully redirected to the login page, `false` otherwise.
 */
export const RedirectToLogin = async () => {
  const oktaApplicationEnvironment = window.location.origin.includes(
    "info.byulaw.org"
  )
    ? "production"
    : "development";
  try {
    console.log(
      `Redirect Calling /login?environment=${oktaApplicationEnvironment}`
    );

    await api
      .get("/login", { params: { environment: oktaApplicationEnvironment } })
      .then((response) => {
        const loginUrl = response.data.location;
        const fullUrl = `${loginUrl}?RelayState=${encodeURI(window.location)}`;
        console.log(`redirecting to ${fullUrl}`);

        window.location.replace(fullUrl);
      });
    return true;
  } catch (err) {
    console.log(err);
    return false;
  }
};
