import React from "react";
import { Navbar } from "react-bootstrap";
import NavBarItem from "./NavBarItem.jsx";
import myInfoDark from "../images/myInfoDark.png";
import myInfoLight from "../images/myInfoLight.png";
import documentsLight from "../images/documentsLight.png";
import documentsDark from "../images/documentsDark.png";
import reportCardLight from "../images/reportCardLight.png";
import reportCardDark from "../images/reportCardDark.png";
import adminToolsLight from "../images/adminToolsLight.png";
import adminToolsDark from "../images/adminToolsDark.png";



class ToolsNav extends React.Component {

  render () {
    const {
      currentTab,
      handleTabChange,
      roles,
      isLink,
    } = this.props;

    return (
      <div className="directoryNav">
        {isLink ? (
          <Navbar
            bg="secondary"
            className="px-5 pt-3 justify-content-center d-flex flex-wrap"
          >
            <NavBarItem
              isLink={true}
              title="My Info"
              iconDark={myInfoDark}
              iconLight={myInfoLight}
              alt="my info icon"
              isFromPage={currentTab === "information"}
              linkRef={
                roles.student || roles.graduate ?
                  "/enrollment/information" :
                  "/faculty/information"
              }
              disabled={this.props.disabled}
            />
            {(roles.admin || roles.registrar || roles.operator) && (() => {
              const pageName = roles.admin ? "Admin Tools" : roles.registrar ? "Registrar Tools" : "Operator Tools"
              return (
                <NavBarItem
                  isLink={true}
                  title={pageName}
                  iconDark={adminToolsDark}
                  iconLight={adminToolsLight}
                  alt="admin tools icon"
                  isFromPage={currentTab === "admin-tools"}
                  linkRef="/faculty/admin-tools"
                  disabled={this.props.disabled}
                />
              )
            })}
            {roles.faculty && (
              <NavBarItem
                isLink={true}
                title="Faculty Tools"
                iconDark={adminToolsDark}
                iconLight={adminToolsLight}
                alt="faculty tools icon"
                isFromPage={currentTab === "tools"}
                linkRef="/faculty/tools"
                disabled={this.props.disabled}
              />
            )}
            {(roles.student || roles.graduate) && (
              <NavBarItem
                isLink={true}
                title="Academic Progress"
                iconDark={reportCardDark}
                iconLight={reportCardLight}
                alt="academic progress icon"
                isFromPage={currentTab === "progress-report"}
                linkRef="/enrollment/progress-report"
                disabled={this.props.disabled}
              />
            )}
            {(roles.admin || roles.registrar || roles.operator || roles.secretary) && (
              <NavBarItem
                isLink={true}
                title="Documents"
                iconDark={documentsDark}
                iconLight={documentsLight}
                alt="documents icon"
                isFromPage={currentTab === "documents"}
                linkRef="/faculty/documents"
                disabled={this.props.disabled}
              />
            )}
            {(roles.student || roles.graduate) && (
              <NavBarItem
                isLink={true}
                title="Documents"
                iconDark={documentsDark}
                iconLight={documentsLight}
                alt="documents icon"
                isFromPage={currentTab === "student-record"}
                linkRef="/enrollment/student-record"
                disabled={this.props.disabled}
              />
            )}
          </Navbar>
        ) : (
          <Navbar
            bg="secondary"
            className="px-5 pt-3 justify-content-center d-flex flex-wrap"
          >
            <NavBarItem
              isFunction={true}
              title="My Info"
              iconDark={myInfoDark}
              iconLight={myInfoLight}
              alt="my info icon"
              isFromPage={currentTab === "information"}
              handlerFunction={handleTabChange}
              functionArgs={["information"]}
              disabled={this.props.disabled}
            />
            {(roles.admin || roles.registrar || roles.operator) && (
              <NavBarItem
                isFunction={true}
                title={roles.admin ? "Admin Tools" : roles.registrar ? "Registrar Tools" : "Operator Tools"}
                iconDark={adminToolsDark}
                iconLight={adminToolsLight}
                alt="registrar tools icon"
                isFromPage={currentTab === "admin-tools"}
                handlerFunction={handleTabChange}
                functionArgs={["admin-tools"]}
                disabled={this.props.disabled}
              />
            )}
            {roles.faculty && (
              <NavBarItem
                isFunction={true}
                title="Faculty Tools"
                iconDark={adminToolsDark}
                iconLight={adminToolsLight}
                alt="faculty tools icon"
                isFromPage={currentTab === "tools"}
                handlerFunction={handleTabChange}
                functionArgs={["tools"]}
                disabled={this.props.disabled}
              />
            )}
            {(roles.student || roles.graduate) && (
              <NavBarItem
                isFunction={true}
                title="Academic Progress"
                iconDark={reportCardDark}
                iconLight={reportCardLight}
                alt="academic progress icon"
                isFromPage={currentTab === "progress-report"}
                handlerFunction={handleTabChange}
                functionArgs={["progress-report"]}
                disabled={this.props.disabled}
              />
            )}
            {(roles.admin || roles.registrar || roles.operator || roles.secretary) && (
              <NavBarItem
                isFunction={true}
                title="Documents"
                iconDark={documentsDark}
                iconLight={documentsLight}
                alt="documents icon"
                isFromPage={currentTab === "documents"}
                handlerFunction={handleTabChange}
                functionArgs={["documents"]}
                disabled={this.props.disabled}
              />
            )}
            {(roles.student || roles.graduate) && (
              <NavBarItem
                isFunction={true}
                title="Documents"
                iconDark={documentsDark}
                iconLight={documentsLight}
                alt="documents icon"
                isFromPage={currentTab === "student-record"}
                handlerFunction={handleTabChange}
                functionArgs={["student-record"]}
                disabled={this.props.disabled}
              />
            )}
          </Navbar>
        )}
      </div>
    )
  }
}

export default ToolsNav;