import React from "react";
import { Modal, Table } from "react-bootstrap";

//import ErrorDisplay from "../../ErrorDisplay";

class ReleaseGradesModal extends React.Component {
  render() {
    let { errorMessage, show, toggleReleaseGradesModal } = this.props;
    let errorTable = errorMessage
      ? errorMessage
          .substring(errorMessage.indexOf(":") + 1)
          .split(/\s+/)
          .map((element) => element.replace(",", ""))
          .filter((element) => element !== "grade_status:")
      : "";
    errorTable = errorTable.slice(1);
    let errorCaption = errorMessage ? errorMessage.split(":")[0] + ": " : "";
    return (
      <Modal show={show} onHide={toggleReleaseGradesModal} size="md">
        <Modal.Header closeButton>
          <Modal.Title>Error Releasing Grades</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{errorCaption}</p>
          <Table>
            <tbody>
              {errorTable
                ? errorTable.map((element, i) => (
                    <tr>{i % 2 === 0 && element + ": " + errorTable[i + 1]}</tr>
                  ))
                : ""}
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>
    );
  }
}

export default ReleaseGradesModal;
